import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isObject } from '../../../utils/ObjectCheck';

export function useBack() {
    const Venue = useSelector((state: any) => state.Venue);
    const specificMenuId = useSelector((state: any) => state.specificMenuId);
    const groupName = useSelector((state: any) => state.GroupName);
    const history = useHistory();

    function goBack() {
        history.push(generateURL());
    }
    function generateURL(isGroupVenue?: boolean) {
        return getBackURL(Venue, groupName, specificMenuId, isGroupVenue);
    }
    return {
        goBack,
        generateURL,
    };
}

function getBackURL(venue, groupName, specificMenuId, isGroupVenue) {
    if (!isObject(venue, true)) {
        const pathNameSplit = window.location.pathname.split('/menu')[0];
        return pathNameSplit;
    }
    const windowSplit = window.location.pathname.split('/');

    if (windowSplit[1] === 'g') {
        if (isGroupVenue) {
            return `/g/${groupName}/${venue.venue_nickname}`;
        }
        return `/g/${groupName}`;
    } else if (windowSplit[1] === 'v') {
        /**IF you navigate to a new venues menu without going to the menu page, navigate back to the venue page */
        if (windowSplit[2] !== venue.venue_nickname) {
            return '/v/' + windowSplit[2];
        }
        if (Boolean(specificMenuId)) {
            return '/v/' + venue.venue_nickname + `?menu_id=${specificMenuId}`;
        }
        return '/v/' + venue.venue_nickname;
    } else {
        return '/a/hayes-cardiff/' + venue.venue_nickname;
    }
}
