import { IAPIDefinitions } from '../types';
import { merchantAPIDefs, APIMerchantDashboard } from './MerchantAPIDefs';
import { APIOpenBanking, openBankingDefs } from './OpenBanckingAPIDefs';
import { APIPay360, pay360Defs } from './Pay360APIDefs';
import { consumerAPIDefs, APIConsumer } from './ConsumerAPIDefs';
import { APIUserStatus, userStatusAPIDefs } from './UserStatusAPIDefs';

/**If you add extra API defintions files, then you must add here */
const APIExtensions = {
    ...APIMerchantDashboard,
    ...APIPay360,
    ...APIOpenBanking,
    ...APIConsumer,
    ...APIUserStatus,
};

export const APIDefinitions: IAPIDefinitions = {
    ...merchantAPIDefs,
    ...pay360Defs,
    ...openBankingDefs,
    ...consumerAPIDefs,
    ...userStatusAPIDefs,
};

export default APIExtensions;
