import moment from 'moment';
import { useSelector } from 'react-redux';
import useAPI from '../../../yoello-lib/modules/API/useAPI';
import { APIExtensions } from '../../../yoello-lib/modules/API/APIDefs';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';
import { IOrderBody } from '../../../typings/IPayment';
import { checkIfMenuIsAvailable } from '../../Utils/getAvailableMenuId';

enum SelectedOrderType {
    CLICK = 'COLLECTION',
    TABLE = 'TABLE_DELIVERY',
    DELIVERY = 'HOME_DELIVERY',
}

/**getting fresh available menus */
export function useGetAvailableMenus() {
    const Venue = useSelector((state: any) => state.Venue);
    const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
    const Venues = useSelector((state: any) => state.Group.venues);
    const { callAPI: AvailableMenusAPI } = useAPI(APIExtensions.availableMenus, () => null, {
        onMountDisabled: true,
        query: Venue.id,
    });

    async function checkMenusAvailability(orderBody: IOrderBody) {
        const disabledItems = [];
        if (
            !Venue.on_menu_setup ||
            ((orderBody.order_type === SelectedOrderType.DELIVERY ||
                orderBody.order_type === SelectedOrderType.CLICK) &&
                Venue.preorders_enabled)
        ) {
            return {
                disabledItems,
            };
        }
        let menus = (await AvailableMenusAPI(HTTPMethods.GET, {
            query: Venue.id,
            params: {
                order_type: SelectedMenuType,
                datetime: `${moment.utc().format()}`,
            },
        })) as IAvailableMenus[];

        const { menu_items: items } = orderBody;
        for (const item of items) {
            let isMenuRemoved = true;
            if (!item.menu_id) {
                disabledItems.push(item.menu_item_id);
            } else {
                for (const menu of menus) {
                    if (menu.id === item.menu_id) {
                        isMenuRemoved = false;
                        if (!menu.enabled) {
                            disabledItems.push(item.menu_item_id);
                        } else if (menu.timings && menu.timings.length > 0) {
                            if (!checkIfMenuIsAvailable(menu.timings)) {
                                disabledItems.push(item.menu_item_id);
                            }
                        }
                    }
                }
                if (isMenuRemoved) {
                    disabledItems.push(item.menu_item_id);
                }
            }
        }
        return {
            disabledItems,
        };
    }
    async function checkMultiOrderMenusAvailability(orderBody: Partial<IOrderBody>, key: string) {
        const disabledItems = [];
        let menus = Venues[key].available_menus as IAvailableMenus[];
        const { menu_items: items } = orderBody;
        for (const item of items) {
            let isMenuRemoved = true;
            if (!item.menu_id) {
                disabledItems.push(item.menu_item_id);
            } else {
                for (const menu of menus) {
                    if (menu.id === item.menu_id) {
                        isMenuRemoved = false;
                        if (!menu.enabled) {
                            disabledItems.push(item.menu_item_id);
                        } else if (menu.timings && menu.timings.length > 0) {
                            if (!checkIfMenuIsAvailable(menu.timings)) {
                                disabledItems.push(item.menu_item_id);
                            }
                        }
                    }
                }
                if (isMenuRemoved) {
                    disabledItems.push(item.menu_item_id);
                }
            }
        }
        return {
            disabledItems,
        };
    }

    return {
        checkMenusAvailability,
        checkMultiOrderMenusAvailability,
    };
}
