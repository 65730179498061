import { useEffect, useState } from 'react';
import Styles from './styles.module.scss';
import DrawerHeader from '../DrawerHeader/DrawerHeader';
import Button from '../Button';
import { useSelector } from 'react-redux';
import { PostCodeValidator } from '../../../utils/PostCodeValidator';
import { checkPostCodeInDeliveryRadius } from '../../../utils/checkPostCodeInDeliveryRadius';
import PostCodeAlert from './PostCodeAlert/PostCodeAlert';
import { AddressForm } from '../AddressForm/AddressForm';
import useDebounce from '../../../customHooks/useDebounce';
import { useTranslation } from 'react-i18next';
import useDelivery from '../../Payment/PaymentHooks/useDelivery';
import { addGMapsScript } from '../../Utils/addGooleMapApitoDom';

export enum postcodeCheckStatusType {
    InDeliveryRadius = 'InDeliveryRadius',
    OutOfDeliveryRadius = 'OutOfDeliveryRadius',
    PostCodeInValid = 'PostCodeInValid',
    PinFarFromAddress = 'PinFarFromAddress',
    LocationNotFound = 'LocationNotFound',
}

interface IPostCodeCheckerProps {
    closeDrawer: () => void;
    goToMenuPage: () => void;
}

const requiredFields: string[] = ['street_address', 'city', 'postcode'];

function PostCodeChecker({ closeDrawer, goToMenuPage }: IPostCodeCheckerProps) {
    const { t } = useTranslation();
    const [postcodeCheckStatus, setPostcodeCheckStatus] = useState<postcodeCheckStatusType>(null);
    const [postcodeCheckButtonLoading, setPostcodeCheckButtonLoading] = useState<boolean>(false);
    const [hasValidationError, setHasValidationError] = useState(false);

    const Venue = useSelector((state: any) => state.Venue);

    const [userSavedAddress, setUserSavedAddress] = useState<DeliveryAddressType>({
        apartment: '',
        street_address: '',
        city: '',
        postcode: '',
    });
    const handleDeliveryDetailsChange = (deliveryDetails: DeliveryAddressType) => {
        if (hasValidationError) {
            setHasValidationError(false);
        }

        setUserSavedAddress(deliveryDetails);
    };

    const { debouncedValue, isTyping } = useDebounce(userSavedAddress.postcode, 350);

    const { setDeliveryChargeBasedOnAddress } = useDelivery();

    useEffect(() => {
        addGMapsScript();
    }, []);

    useEffect(() => {
        if (debouncedValue.length > 0 && !isTyping) {
            validatePostCode();
        }
        //eslint-disable-next-line
    }, [debouncedValue, isTyping]);

    const validatePostCode = () => {
        // Check if all required fields are filled
        const allRequiredFieldsFilled = requiredFields.every((field) => {
            return userSavedAddress[field] !== '';
        });

        if (!allRequiredFieldsFilled) {
            setHasValidationError(!allRequiredFieldsFilled);

            return;
        }

        const value = userSavedAddress.postcode;

        if (value.length > 2) {
            const postCodeValid = PostCodeValidator(value);
            if (postCodeValid) {
                setPostcodeCheckStatus(null);
                return true;
            }
        }
        setPostcodeCheckStatus(postcodeCheckStatusType.PostCodeInValid);
        return false;
    };

    async function checkPostCode() {
        // TO DO: move this logic into a reuseable component
        if (validatePostCode()) {
            const deliveryAddress = `${Venue.address_country}, ${userSavedAddress.street_address}, ${userSavedAddress.city}, ${userSavedAddress.postcode}`;
            setPostcodeCheckButtonLoading(true);
            try {
                await checkPostCodeInDeliveryRadius(
                    Venue,
                    deliveryAddress,
                    Venue.delivery_radius_km,
                    afterResponseFunction,
                    setDeliveryChargeBasedOnAddress,
                );
            } catch (error) {
                setPostcodeCheckButtonLoading(false);
            }
        }
    }

    function afterResponseFunction(InRadius) {
        if (InRadius) {
            setPostcodeCheckStatus(postcodeCheckStatusType.InDeliveryRadius);
            window.localStorage.setItem('BillingAddress', JSON.stringify(userSavedAddress));
            setTimeout(() => {
                closeDrawer();
                goToMenuPage();
            }, 1000);
        } else {
            setPostcodeCheckStatus(postcodeCheckStatusType.OutOfDeliveryRadius);
            setPostcodeCheckButtonLoading(false);
        }
    }

    return (
        <div data-component-name="PostCodeChecker" className={Styles.PostCodeChecker}>
            <DrawerHeader title={t('Forms.EnterAddress') as string} closeDrawer={closeDrawer} />

            <div className={Styles.PostCodeCheckerContent}>
                <p className={Styles.Description}>{t('Venue.AddressDescription')}</p>
                <AddressForm
                    handleDeliveryInfoChange={handleDeliveryDetailsChange}
                    setPostCodeChanged={() => null}
                    noFullName={true}
                    onPostcodeBlur={() => null}
                    userSavedAddress={userSavedAddress}
                    hasValidationError={hasValidationError}
                />
                {postcodeCheckStatus && (
                    <div className={Styles.Alert}>
                        <PostCodeAlert postcodeCheckStatus={postcodeCheckStatus} />
                    </div>
                )}
                <div>
                    <Button
                        isYoelloBtn
                        title="CheckPostCode"
                        onClick={checkPostCode}
                        isLoading={postcodeCheckButtonLoading}
                        disabled={hasValidationError}
                        dataComponentName="PostcodeCheckBtn"
                        className="dark-theme-uninvert-svg-div"
                    />
                </div>
            </div>
        </div>
    );
}

export default PostCodeChecker;
