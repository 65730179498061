import React from 'react';
import { generateExtraPricingOptionName } from '../../../utils/generateExtraPricingOptionName';
import ChoiceBox from '../ChoiceBox/ChoiceBox';
import EmptyMinExtras from './EmptyMinExtras';
import Styles from './styles.module.scss';

interface Props {
    selectedExtras: any;
    extrasData: IMenuExtra;
    menuItemExtras: any;
    Venue: any;
    searchText?: string;
    DisplayeExtrasAllergens(menuItem: any): void;
    handleExtraChange: (
        menuItem: any,
        pricingOptionName: string,
        price: number,
        increaseCountBoolean?: boolean,
        extra_tax_ids?: string[],
        option_discount?: number,
    ) => void;
}

const Extras = ({
    selectedExtras,
    extrasData,
    menuItemExtras,
    Venue,
    searchText,
    DisplayeExtrasAllergens,
    handleExtraChange,
}: Props) => {
    const { extras, ids } = selectedExtras;
    const hasMinQuantity = extrasData.menu_item_extra_group_quantity_min >= 0;
    const hasStock = !!extrasData.menu_item_extra_group_item_id_list.length;

    // Display an unavailable extras warning when extras have a min quantity and no stock
    if (hasMinQuantity && !hasStock) return <EmptyMinExtras />;

    // filter out any extras that don't meet the search criteria
    const filteredExtras = extrasData?.menu_item_extra_group_item_id_list;

    // build than render the vaild extras
    const renderExtras = () =>
        filteredExtras.map((extra: any) => {
            const menuItem = menuItemExtras[extra];

            if (menuItem?.extra_pricing_options_extras) {
                return Object.keys(menuItem?.extra_pricing_options_extras).map((po) => {
                    const priceOption: IPricingOptions = menuItem?.extra_pricing_options_extras[po];
                    const index = ids.indexOf(extra);
                    const pricingOptionName = generateExtraPricingOptionName(
                        menuItem?.extra_name_public,
                        priceOption?.option_name ?? '',
                    );

                    return (
                        <div
                            style={{
                                flexBasis: filteredExtras.length === 1 ? '100%' : '',
                            }}
                            key={`${menuItem?.extra_name_public}-${po}`}
                        >
                            <ChoiceBox
                                onClick={() =>
                                    handleExtraChange(
                                        menuItem,
                                        po,
                                        priceOption.option_price,
                                        undefined,
                                        menuItem.extra_tax_ids,
                                        priceOption.option_discount,
                                    )
                                }
                                choiceBoxName={pricingOptionName}
                                isSelected={index > -1 && extras[index].priceName === po ? true : false}
                                choiceBoxPricingOption={priceOption}
                                menuItem={menuItem}
                                setDisplayeExtrasAllergens={DisplayeExtrasAllergens}
                                changeCount={(increaseCountBoolean) => {
                                    handleExtraChange(
                                        menuItem,
                                        po,
                                        priceOption.option_price,
                                        increaseCountBoolean,
                                        menuItem.extra_tax_ids,
                                        priceOption.option_discount,
                                    );
                                }}
                                extraCount={extras[index]?.count}
                                Square={Venue.payment_processor === 'SQUARE'}
                            />
                        </div>
                    );
                });
            } else return null;
        });

    return (
        <>
            <div className={Styles.ExtrasContainer}> {renderExtras()}</div>
        </>
    );
};

export default Extras;
