import React, { FunctionComponent } from 'react';
import useCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import CountComponent from '../../Widgets/CountComponent/CountComponent';
import Styles from './styles.module.scss';
import { ReactComponent as DeleteSVG } from '../../../imges/delete.svg';
import makeStyles from '@material-ui/styles/makeStyles';
import useColors from '../../../customHooks/useColors';

interface IChoiceBoxProps {
    isSelected: boolean;
    onClick: () => void;
    choiceBoxName: string;
    choiceBoxPricingOption?: { option_price: number; option_discount: number };
    menuItem?: any;
    setDisplayeExtrasAllergens?: (menuItem: any) => void;
    disabled?: boolean;
    changeCount?: (increaseCountBoolean: boolean) => void;
    extraCount?: number;
    Square?: boolean;
}

const ChoiceBox: FunctionComponent<IChoiceBoxProps> = ({
    isSelected,
    onClick,
    choiceBoxPricingOption,
    choiceBoxName,
    menuItem,
    setDisplayeExtrasAllergens,
    disabled,
    changeCount,
    extraCount,
    Square,
}) => {
    const { secondaryLowerOpacityButtonColor, primaryBorderColor, primaryBackgroundColor } = useColors();

    const useStyles = makeStyles({
        ChoiceBoxContainer: {
            background: '#f9f9f9',
            borderRadius: '0.875rem',
            borderBottom: '0.70775px solid #ffffff',
            padding: '1rem 1.5625rem',
            margin: '0.5rem 0',
            position: 'relative',
            cursor: 'pointer',
            '& .ChoiceBoxName': {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '0.9375rem',
                color: '#424242',
                margin: '0',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
        },
        ChoiceBoxInnerDiv: {
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            '& >div:first-child': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '& >div:first-child': {
                    marginLeft: '-1rem',
                    marginRight: '1rem',
                    paddingBottom: '2px',
                },
            },
        },
        Selected: {
            background: `linear-gradient(0deg,${secondaryLowerOpacityButtonColor},${secondaryLowerOpacityButtonColor}),#f9f9f9 !important`,
            border: `1px solid ${primaryBorderColor} !important`,
            // boxSizing: "border-box !important",
            '& .ChoiceBoxName': {
                fontWeight: 600,
            },
        },
        ChoiceBoxContainerDisabled: {
            background: '#eeeeee !important',
            '& .ChoiceBoxName': {
                color: '#bdbdbd !important',
            },
        },
        SelectedBoxLine: {
            background: primaryBackgroundColor,
            borderRadius: '0.75rem',
            width: '0.25rem',
            marginRight: '-0.5rem',
            marginLeft: '0.25rem',
            height: '1.25rem',
        },
        ExtrasAllergens: {
            position: 'absolute',
            right: '-1rem',
            display: 'flex',
            top: '0',
            bottom: '0',
            margin: 'auto',
            alignItems: 'center',
            cursor: 'pointer',
            height: '32px',
            width: '32px',
            justifyContent: 'center',
            backgroundColor: '#f1f9ff',
            borderRadius: '8px',
        },
    });

    const classes = useStyles();
    const getAfterDiscountPrice = (price: number, discount: number) => {
        let finalPrice = price;
        if (discount) {
            finalPrice = price - (price * discount) / 100;
        }
        return finalPrice;
    };
    const price = getAfterDiscountPrice(choiceBoxPricingOption?.option_price, choiceBoxPricingOption?.option_discount);
    const { formatCurrency } = useCurrency();
    const formattedPrice = formatCurrency(price);

    const handleExtrasAllergense = (e) => {
        e.stopPropagation();
        setDisplayeExtrasAllergens(menuItem);
    };

    const handleChoiceBoxClick = () => {
        // this is for without pricing options(for example Preferences / Table selection)
        if (!disabled && !choiceBoxPricingOption) {
            onClick();
        }
    };

    const reduceItem = (event) => {
        event.cancelBubble = true;
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        changeCount(false);
    };
    return (
        <div
            onClick={handleChoiceBoxClick}
            data-component-name="ChoiceBox"
            className={`${classes.ChoiceBoxContainer} ${isSelected && !disabled ? classes.Selected : ''} ${
                disabled ? classes.ChoiceBoxContainerDisabled : ''
            }`}
        >
            <div
                className={classes.ChoiceBoxInnerDiv}
                onClick={() => {
                    // this is for with Pricing Options meaning Extras
                    if (!disabled && choiceBoxPricingOption) {
                        changeCount(true);
                    }
                }}
            >
                {choiceBoxPricingOption !== undefined && typeof choiceBoxPricingOption === 'object' ? (
                    // this is for with Pricing options
                    <>
                        <div
                            style={{
                                maxWidth: '65%',
                            }}
                        >
                            {isSelected && (
                                <div onClick={reduceItem} className="dark-theme-uninvert-svg-div">
                                    <DeleteSVG width="16px" data-component-name="DeleteItem" />
                                </div>
                            )}

                            <p
                                className={`ChoiceBoxName  ${
                                    choiceBoxPricingOption !== undefined && typeof choiceBoxPricingOption === 'object'
                                        ? Styles.ExtraChoiceBoxName
                                        : null
                                }`}
                                data-component-name={
                                    choiceBoxPricingOption !== undefined && typeof choiceBoxPricingOption === 'object'
                                        ? 'extraItem'
                                        : 'preferenceItem'
                                }
                            >
                                {choiceBoxName}
                            </p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <p
                                className={'ChoiceBoxName'}
                                style={{
                                    width: 'fit-content',
                                    paddingRight: '10px',
                                }}
                            >
                                +{formattedPrice}
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {isSelected && extraCount > 0 && (
                                    <CountComponent
                                        onMinusClick={() => changeCount(false)}
                                        onPlusClick={() => changeCount(true)}
                                        count={extraCount}
                                        disablePlus={Square}
                                        choiceBox={true}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    // this is for without pricing options(for example Preferences / Table selection)
                    <>
                        <p
                            className={`ChoiceBoxName  ${
                                choiceBoxPricingOption !== undefined && typeof choiceBoxPricingOption === 'object'
                                    ? Styles.ExtraChoiceBoxName
                                    : null
                            }`}
                            data-component-name={
                                choiceBoxPricingOption !== undefined && typeof choiceBoxPricingOption === 'object'
                                    ? 'extraItem'
                                    : 'preferenceItem'
                            }
                        >
                            {choiceBoxName}
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <p
                                className={'ChoiceBoxName'}
                                style={{
                                    width: 'fit-content',
                                    paddingRight: '10px',
                                }}
                            ></p>
                            <div>{isSelected && <div className={classes.SelectedBoxLine}></div>}</div>
                        </div>
                    </>
                )}
            </div>
            {menuItem?.extra_tags?.length > 0 && (
                <div className={classes.ExtrasAllergens} onClick={handleExtrasAllergense}>
                    <i className={`consumer-info-circle ${Styles.InfoIcon}`} />
                </div>
            )}
        </div>
    );
};

export default React.memo(ChoiceBox);
