export const TransitionStyles = {
    entering: { transform: 'translate3d(0, 100%, 0)' },
    entered: { transform: 'none' },
    exiting: { transform: 'translate3d(0, 100%, 0)' },
    exited: { display: 'none' },
};

export const BackdropStyles = {
    entering: { opacity: '0' },
    entered: { opacity: '1' },
    exiting: { opacity: '0' },
    exited: { display: 'none' },
};

export interface ICustomizations {
    duration: number;
    hideScrollbars: boolean;
}

export const getClassNames = (identifier: string) => ({
    backdrop: `ArashReactDrawer-${identifier}-db`,
    drawer: `ArashReactDrawer-${identifier}-dr`,
    handleWrapper: `ArashReactDrawer-${identifier}-hw`,
    handle: `ArashReactDrawer-${identifier}-h`,
    contentWrapper: `ArashReactDrawer-${identifier}-cw`,
});

const globalStylesheet = (identifier: string, matches: boolean, { duration, hideScrollbars }: ICustomizations) => {
    const classNames = getClassNames(identifier);
    return `
  ${
      matches
          ? `.${classNames.backdrop} {
        position: fixed;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
        top: unset;
        left: unset;
        bottom:2.5rem;
        border-radius:2.5rem;
        height: calc(100% - 5rem);
        width: 768px;
        transition: opacity ${duration}ms;
   }`
          : `.${classNames.backdrop} {
        position: fixed;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: opacity ${duration}ms;
   }`
  }
   ${
       matches
           ? `.${classNames.drawer} {
      position: fixed;
      z-index: 11;
      bottom: 2.5rem;
      width: 100vw;
      background: white;
      transition: transform ${duration}ms;
      width: 768px;
      height: calc(100% - 5rem);
      border-radius: 2.5rem;
    }`
           : `.${classNames.drawer} {
      position: fixed;
      z-index: 11;
      left: 0;
      bottom: 0;
      height:100%;
      width: 100vw;
      background: white;
      transition: transform ${duration}ms;
    }`
   }

  .${classNames.handleWrapper} {
    display: flex;
    justify-content: center;
  }
  .${classNames.handle} {
    background: #e3e3e3;
    height: 5px;
    width: 70px;
    border-radius: 5px;
  }
  .${classNames.contentWrapper} {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    ${
        hideScrollbars
            ? `
      scrollbar-width: none;
      -ms-overflow-style: none;
    `
            : ''
    }
  }
  ${
      hideScrollbars
          ? `
  .${classNames.contentWrapper}::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  `
          : ''
  }
    
`
        .split('\n')
        .map((l) => l.trim())
        .join('');
};
export default globalStylesheet;
