import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Route } from 'react-router-dom';
import { LOGINV2_START } from '../../constants/URL.const';
import useGenerateNavPath from '../../customHooks/useGenerateNavPath';
import { useAuthenticationContext } from '../../yoello-lib/modules/auth/AuthenticationContext';
import PageLoading from '../Page/PageLoading';
import useGuestUserData from '../../customHooks/useGuestUserData';

export function ProtectedRoute(props) {
    const { verifyAuth, currentUser } = useAuthenticationContext();
    const { generateNavPath } = useGenerateNavPath();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { isGuest } = useGuestUserData();

    const history = useHistory();
    const location = useLocation<locationState>();

    useEffect(() => {
        let isMounted = true;
        async function checkUserVerification() {
            try {
                const user = await verifyAuth();
                if (isMounted) {
                    if (!user) {
                        if (isGuest) {
                            setIsLoading(false);
                        } else {
                            history.push(generateNavPath(LOGINV2_START), {
                                to: location.pathname,
                                from: location.state?.from,
                            });
                        }
                    } else {
                        setIsLoading(false);
                    }
                }
            } catch (error) {
                console.log({ error });
            }
        }
        if (isMounted) {
            checkUserVerification();
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, [currentUser]);

    if (isLoading) return <PageLoading />;
    return <Route {...props} />;
}
