import React, { useEffect } from 'react';
import ProductPriceOption from './ProductPriceOption';
import Styles from '../styles.module.scss';
interface IProductPricingOptionsProps {
    menuItemPricingOptions?: Record<string, IPricingOptions>;
    selectedPrice: string;
    onClickPricingOption: onClickPricingOption;
}

export type onClickPricingOption = (item: string, data: IPricingOptions) => void;
//pass through the pricing options
//pass through the select pricing option
//set up map for all the pricing options
function ProductPricingOptions({
    menuItemPricingOptions,
    selectedPrice,
    onClickPricingOption,
}: IProductPricingOptionsProps) {
    useEffect(() => {
        if (selectedPrice === null && menuItemPricingOptions !== undefined) {
            // if (Object.keys(menuItemPricingOptions).length === 1) {
            const firstKey = Object.keys(menuItemPricingOptions)[0];
            const pricingOp = menuItemPricingOptions[firstKey];
            onClickPricingOption(firstKey, pricingOp);
            // }
        }
        // eslint-disable-next-line
    }, [menuItemPricingOptions]);
    return (
        <div className={Styles.PricingOptionsContainer}>
            {menuItemPricingOptions !== undefined && typeof menuItemPricingOptions === 'object'
                ? Object.keys(menuItemPricingOptions).map((key) => {
                      const pricingOptions = menuItemPricingOptions[key];
                      return (
                          <div
                              style={{
                                  flexBasis: Object.keys(menuItemPricingOptions).length === 1 ? '100%' : '',
                              }}
                          >
                              <ProductPriceOption
                                  key={key}
                                  isSelected={selectedPrice === key}
                                  pricingOptions={pricingOptions}
                                  onClick={() => onClickPricingOption(key, pricingOptions)}
                              />
                          </div>
                      );
                  })
                : null}
        </div>
    );
}

export default React.memo(ProductPricingOptions);
