import Divider from '@material-ui/core/Divider';
import Styles from './menuItemsPageSkeleton.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';

export const MenuItemsNavigation = () => (
    <div className={Styles.header}>
        <div className={Styles.headerBtn}>
            <Skeleton />
        </div>
        <div className={Styles.headerImg}>
            <Skeleton />
        </div>
        <div className={Styles.headerBtn}>
            <Skeleton />
        </div>
    </div>
);
export const MenusListLoading = () => (
    <div className={Styles.topMenuContainer}>
        <div className={Styles.topMenu}>
            <div className={Styles.mainCats}>
                {[...Array(3).keys()].map((index) => (
                    <div key={`${index}-main-categories`}>
                        <Skeleton />
                    </div>
                ))}
            </div>
            <Divider />
            <div className={Styles.subCats}>
                {[...Array(5).keys()].map((index) => (
                    <div key={`${index}-sub-categories`}>
                        <Skeleton />
                    </div>
                ))}
            </div>
        </div>
        <div className={Styles.menuContents}>
            {[...Array(10).keys()].map((index) => (
                <div key={`${index}-menu-items`}>
                    <Skeleton />
                </div>
            ))}
        </div>
    </div>
);
const MenuItemsSkeleton = () => {
    return (
        <div className={Styles.menuItemsPageContainer}>
            <MenuItemsNavigation />
            <MenusListLoading />
        </div>
    );
};

export default MenuItemsSkeleton;
