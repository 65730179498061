export const hostname = window && window.location && window.location.hostname;

export const consumer_api = 'https://capi.yllo.uk/'; //consumer-api
export const merchant_dashboard_api = 'https://mdapi.yllo.uk/'; //merchant-dashboard-api
export const merchant_terminal_api = 'https://mapi.yllo.uk/'; //merchant-terminal
export const merchant_api = 'https://mgmtapi.yllo.uk/'; //merchant-api (admin-api)
// mtapi.yllo.uk    not used ???

export enum EEnvironmentStages {
    development = 'dev',
    staging = 'staging',
    production = 'prod',
}

export interface IServiceURLs {
    consumerAPI: string;
    dashboardFinanceAPI: string;
    dashboardMenusAPI: string;
    dashboardOrdersAPI: string;
    dashboardReportsAPI: string;
    dashboardUtilitiesAPI: string;
    dashboardVenueAPI: string;
    terminalAPI: string;
    merchantAuthorizersAPI: string;
    merchantFinanceAPI: string;
    merchantMenusAPI: string;
    merchantVenuesAPI: string;
}
/**Generates the new service endpoint urls */
export function generateServiceEndpoints(environmentStage?: string, overwriteHost?: string, serviceOverride?: string) {
    if (typeof environmentStage === 'undefined' || environmentStage === null) {
        throw new Error('No environment stage given.');
    }

    const prefix = environmentStage === EEnvironmentStages.production ? '' : `${environmentStage}-`;

    return overwriteHost ? overwriteHost : `https://${prefix}${serviceOverride || 'api'}.yllo.uk`;
}

export function generatePay360Endpoints(environmentStage?: string, overwriteHost?: string) {
    if (typeof environmentStage === 'undefined' || environmentStage === null) {
        throw new Error('No environment stage given.');
    }

    const prefix = environmentStage === EEnvironmentStages.production ? '' : `${environmentStage}-`;

    return overwriteHost ? overwriteHost : `https://${prefix}pay360.yllo.uk`;
}
export function generateUserStausEndpoints(environmentStage?: string, overwriteHost?: string) {
    if (typeof environmentStage === 'undefined' || environmentStage === null) {
        throw new Error('No environment stage given.');
    }

    const prefix = environmentStage === EEnvironmentStages.production ? '' : `${environmentStage}-`;

    return overwriteHost ? overwriteHost : `https://${prefix}cognito.yllo.uk`;
}
// Returns all the service endpoints based on the passed in environment stage
// If the environment stage is "dev" then it will listen to the "overwriteHost"
// which allows for overwriting the host URL for redirecting traffic in local development
export function getServiceEndpoints(
    environmentStage?: string,
    overwriteHost?: string,
    isV2: boolean = false,
): IServiceURLs {
    if (typeof environmentStage === 'undefined' || environmentStage === null) {
        throw new Error('No environment stage given.');
    }

    let endpoints;

    let prefix = environmentStage === EEnvironmentStages.production ? '' : `${environmentStage}-`;
    let suffix = environmentStage === EEnvironmentStages.production ? '' : `-${environmentStage}`;

    if (isV2) {
        prefix += 'v3-';
    }

    if (overwriteHost && environmentStage === EEnvironmentStages.development) {
        endpoints = {
            consumerAPI: `${overwriteHost}${prefix}pay`,
            dashboardFinanceAPI: `${overwriteHost}${prefix}finance`,
            dashboardMenusAPI: `${overwriteHost}${prefix}menus`,
            dashboardOrdersAPI: `${overwriteHost}${prefix}orders`,
            dashboardReportsAPI: `${overwriteHost}${prefix}reports`,
            dashboardUtilitiesAPI: `${overwriteHost}${prefix}utilities`,
            dashboardVenueAPI: `${overwriteHost}${prefix}venues`,
            terminalAPI: `${overwriteHost}terminal${suffix}`, //terminal-staging
            merchantAuthorizersAPI: `${overwriteHost}${prefix}authorizers`,
            merchantFinanceAPI: `${overwriteHost}${prefix}mgmt-finance`,
            merchantMenusAPI: `${overwriteHost}${prefix}mgmt-menus`,
            merchantVenuesAPI: `${overwriteHost}${prefix}mgmt-venues`,
        };
    } else {
        endpoints = {
            consumerAPI: `${consumer_api}${prefix}pay`,
            dashboardFinanceAPI: `${merchant_dashboard_api}${prefix}finance`,
            dashboardMenusAPI: `${merchant_dashboard_api}${prefix}menus`,
            dashboardOrdersAPI: `${merchant_dashboard_api}${prefix}orders`,
            dashboardReportsAPI: `${merchant_dashboard_api}${prefix}reports`,
            dashboardUtilitiesAPI: `${merchant_dashboard_api}${prefix}utilities`,
            dashboardVenueAPI: `${merchant_dashboard_api}${prefix}venues`,
            terminalAPI: `${merchant_terminal_api}terminal${suffix}`, //terminal-staging
            merchantAuthorizersAPI: `${merchant_api}${prefix}authorizers`,
            merchantFinanceAPI: `${merchant_api}${prefix}mgmt-finance`,
            merchantMenusAPI: `${merchant_api}${prefix}mgmt-menus`,
            merchantVenuesAPI: `${merchant_api}${prefix}mgmt-venues`,
        };
    }

    if (endpoints === undefined) {
        throw new Error('No API endpoints has been set');
    }
    return endpoints;
}
