import { useEffect, useState } from 'react';

const useDebounce = (value: any, delay: number, allowEmpty?: boolean) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const [isTyping, setIsTyping] = useState(false);
    useEffect(() => {
        if (value === undefined || (!allowEmpty && value?.length === 0)) {
            return;
        }

        setIsTyping(true);
        const handler = setTimeout(() => {
            setDebouncedValue(value);
            setIsTyping(false);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
        // eslint-disable-next-line
    }, [value]);

    return { debouncedValue, isTyping };
};

export default useDebounce;
