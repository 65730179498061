import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductDetailsBack } from './BackButton/ProductDetailsBack';
import Styles from './styles.module.scss';
import { ProductDetailsReducer } from './state/ProductDetails.state';
import PageTitle from './utils/PageTitle';
import { useSelector } from 'react-redux';
import Button from '../Widgets/Button';
import { ProductDetails } from './ProductDetails';
import Preferences from './Preferences/Preferences';
import GroupedExtras from './Extras/GroupedExtras';
import { ActionBar } from './BottomBar/ActionBar';
import Drawer from '../Drawer/CustomDrawer';
import ProductTags from './Tags/ProductTags';
import {
    setExtras,
    setIsTagsShown,
    setItemCount,
    setPreferences,
    setDisplayeExtrasAllergens,
} from './state/ProductDetails.action';
import { AddToCartFunction, ISelectedExtras } from './types';
import getAvailableExtras from './utils/getAvailableExtras';
import { useSnackbarMessages } from '../../yoello-lib/modules/components/snackbar/SnackbarContext';

interface IProductDetailsContainerProps {
    menuItem: IMenuItem;
    closeDrawer: () => void;
    addToCart: AddToCartFunction;
}

const initalState = {
    position: 0,
    selectedPrice: null,
    selectedPriceName: null,
    isShownTags: false,
    isContinue: false,
    selectedExtras: {},
    selectedExtrasPageIndex: {},
    selectedPreferences: {},
    itemCount: 1,
    direction: 'right',
    menuItemAsExtra: null,
    isOpen: false,
};

/**
 * this handles the creation of the cart item by the user
 *
 * @param param0
 */
export function ProductDetailsContainer({
    menuItem,
    closeDrawer,
    addToCart,
}: IProductDetailsContainerProps): JSX.Element {
    const [state, dispatch] = useReducer(ProductDetailsReducer, initalState);
    const { t } = useTranslation();

    const {
        position,
        isShownTags,
        selectedPriceName,
        selectedExtras,
        selectedPreferences,
        selectedPrice,
        itemCount,
        menuItemAsExtra,
        isOpen,
    } = state;
    const extras = useSelector((state: any) => state.Extras);
    const [requiredSteps, setRequiredSteps] = useState<string[]>([]);
    const [passedSteps, setPassedSteps] = useState<string[]>([]);
    const { sendSnackbarMessage } = useSnackbarMessages();
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);

    useEffect(() => {
        if (snackbarMessage) {
            sendSnackbarMessage(snackbarMessage as string, 'error', 5000);
            setSnackbarMessage(null);
        }
        // eslint-disable-next-line
    }, [snackbarMessage]);

    function onSelectedPreferences(preferences: Record<string, string>) {
        dispatch(setPreferences(preferences));
    }
    function onSelectedExtras(extraId: string, extras: ISelectedExtras[]) {
        dispatch(setExtras(extraId, extras));
    }
    function onChangeItemCount(itemcount: number) {
        dispatch(setItemCount(itemcount));
    }
    function canContinue(internalName: string, canContinue: boolean) {
        let steps = passedSteps;
        if (!canContinue) {
            const index = steps.indexOf(internalName);
            if (index > -1) {
                steps.splice(index, 1);
            }
            setPassedSteps(steps);
            return;
        }
        if (!steps.includes(internalName)) {
            steps.push(internalName);
            setPassedSteps(steps);
            const element = document.getElementById(internalName);
            if (element) {
                element.style.color = 'unset';
            }
        }
    }

    function setIsShowingTags(isTagsShown: boolean) {
        dispatch(setIsTagsShown(isTagsShown));
    }
    function addToCartFunction() {
        if (canAddToCart()) {
            addToCart(selectedExtras, selectedPreferences, selectedPriceName, selectedPrice, menuItem, itemCount);
        }
    }

    function canAddToCart() {
        if (requiredSteps.length === passedSteps.length) {
            return true;
        } else {
            const ID = requiredSteps.find((item) => !passedSteps.includes(item));
            const element = document.getElementById(ID);
            if (element) {
                setSnackbarMessage(element?.textContent.split(':')[0]);
                element.style.color = '#F65164';
                element.classList.add('dark-theme-uninvert');
                element?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                });
            }
        }
    }

    function renderExtras() {
        const availabilyExtras = getAvailableExtras(
            menuItem.menu_item_extras_groups_ids,
            extras,
            selectedExtras,
            onSelectedExtras,
        );
        return availabilyExtras.map((id) => {
            return (
                <GroupedExtras
                    extrasId={id}
                    extrasData={extras[id]}
                    extrasState={selectedExtras[id] || []}
                    handleExtrasChange={onSelectedExtras}
                    canContinue={canContinue}
                    dispatch={dispatch}
                    key={id}
                />
            );
        });
    }
    useEffect(() => {
        let steps: string[] = [];
        const availabilyExtras = getAvailableExtras(
            menuItem.menu_item_extras_groups_ids,
            extras,
            selectedExtras,
            onSelectedExtras,
        );
        availabilyExtras.forEach((id) => {
            if (extras[id].menu_item_extra_group_quantity_min > 0) {
                steps.push(id);
            }
        });
        setRequiredSteps(steps);
        // eslint-disable-next-line
    }, [menuItem, extras]);

    const handleCloseDrawer = () => {
        if (isShownTags) {
            setIsShowingTags(false);
        } else {
            closeDrawer();
        }
    };

    return (
        <div className={Styles.ProductDetailsContainer}>
            <div className={Styles.drawerHeader} data-component-name="drawerHeader">
                <div className={Styles.lineBar}>
                    <span></span>
                </div>
                <div>
                    <ProductDetailsBack
                        position={position}
                        dispatch={dispatch}
                        isAllergyTags={menuItem.menu_item_tags?.length > 0}
                        isShownTags={isShownTags}
                        onClickShownTags={setIsShowingTags}
                    />
                    <PageTitle menuItem={menuItem} isShownTags={isShownTags} extras={extras} position={position} />
                    <Button
                        onClick={handleCloseDrawer}
                        dataComponentName="ExitButton"
                        iconClassName={'consumer-close'}
                        style={{
                            position: 'unset',
                            color: '#AAB6D3',
                            fontSize: '1.5rem',
                        }}
                        isHeaderBtn
                    />
                </div>
            </div>

            <div className={Styles.container}>
                <div className={Styles.detailsWrapper} data-component-name={'ProductDetailsWrapper'}>
                    <ProductDetails
                        dispatch={dispatch}
                        menuItem={menuItem}
                        selectedPriceName={selectedPriceName}
                        isShownTags={isShownTags}
                    />
                    {!isShownTags && (
                        <div
                            style={{
                                marginBottom: '2rem',
                            }}
                        >
                            <Preferences
                                handlePreferencesChange={onSelectedPreferences}
                                menuItemPreferences={menuItem.menu_item_preference_ids}
                                preferenceState={selectedPreferences}
                            />
                            {renderExtras()}
                        </div>
                    )}
                    <ActionBar
                        {...state}
                        dispatch={dispatch}
                        menuItem={menuItem}
                        extras={selectedExtras}
                        selectedOption={selectedPriceName}
                        displayAddToCart={false}
                        addToCart={addToCartFunction}
                        count={state.itemCount}
                        changeCount={onChangeItemCount}
                    />
                </div>
            </div>

            <Drawer
                isOpen={isOpen}
                setIsOpen={() => dispatch(setDisplayeExtrasAllergens(null, false))}
                title={t('buttons.Allergens') as string}
                className={Styles.allergensDrawerContainer}
            >
                <ProductTags
                    menuItemName={menuItemAsExtra?.extra_name_public}
                    menuItemTags={menuItemAsExtra?.extra_tags}
                />
            </Drawer>
        </div>
    );
}
