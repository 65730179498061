import { useDrawer } from '../../Drawer/DrawerContext';
import LeavingWarning from '../../Menu/UI/LeavingWarning';

export function useLeavingWarning() {
    const { openDrawer, closeDrawer } = useDrawer()!;
    function openWarningDrawer(props) {
        if (openDrawer) {
            openDrawer(
                LeavingWarning,
                {
                    ...props,
                    onContinue: () => {
                        props.onContinue();
                        closeDrawer();
                    },
                    onCancel: closeDrawer,
                },
                'bottom',
                'WarningDrawer',
            );
        }
    }
    return {
        openWarningDrawer,
    };
}
