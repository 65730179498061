import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ConsumerCreate, ConsumerUpdate, ConsumerUpdatePreferences } from '../../../Api.js';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAPIV2 } from '../../../yoello-lib/modules/API2/useAPIv2';
import { APIConsumer } from '../../../yoello-lib/modules/API2/APIDefs/ConsumerAPIDefs';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';
import { SelectedOrderType } from '../../../yoello-lib/modules/utils/venueOpen';

export type profileData = {
    international_code: string;
    contact_phone: string;
    contact_email: string;
    first_name: string;
    last_name: string;
    notification_method: string;
    opt_out: boolean;
    consumer_preferences: {
        deleted: boolean;
        id: string;
        language: string;
        marketing_email: boolean;
        marketing_sms: boolean;
        notify_by_email: boolean;
        notify_by_sms: boolean;
    };
};

const useSubmitProfile = (onSuccessPath?: string) => {
    const { retrieveAccessToken } = useAuthenticationContext();
    const { t } = useTranslation();
    const history = useHistory();
    const { sendSnackbarMessage } = useSnackbarMessages();
    const SelectedMenuType = useSelector((state:any)=>state.SelectedMenuType);
    const {callAPI} = useAPIV2(APIConsumer.consumers,{
        onMountDisabled:true,
    })
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onFailSubmit = (message: string, e: Error) => {
        sendSnackbarMessage(message, 'error');
        throw e;
    };

    const getConsumerLanguagePreference = () => {
        const storedCountryCode = localStorage.getItem('countryCode');
        return storedCountryCode === 'ES' || storedCountryCode === 'FR' ? storedCountryCode.toLowerCase() : 'en';
    };

    const submitProfile = async (formData: profileData) => {
        setIsSubmitting(true);
        let token;
        try {
            token = await retrieveAccessToken();
        } catch (e) {
            onFailSubmit(t('Login.Errors.UserTokenFailed').toString(), e);
        }
        if (!token) {
            onFailSubmit(t('Login.Errors.UserTokenFailed').toString(), Error("Token doesn't exist."));
        }
        try {
            const consumer = await ConsumerCreate(
                {
                    contact_phone: `${formData.contact_phone}`,
                    contact_email: formData.contact_email.replace(/\s/g, ''),
                    first_name: formData.first_name.trim(),
                    last_name: formData.last_name.trim(),
                },
                token,
            ).catch((e) => {
                /**if account already exists carry on */
                if (e?.response?.status === 412) {
                    return;
                }
                throw e;
            });
            if(consumer && SelectedMenuType === SelectedOrderType.DELIVERY){
                // Creating consumer address location
                const address = JSON.parse(window.localStorage.getItem('BillingAddress')) as unknown as object;
                await callAPI(HTTPMethods.POST, {
                    body:{...address},
                    query: consumer.data?.data?.consumer_id + "/locations"
                })
            }
        } catch (e) {
            onFailSubmit(t('Login.Errors.CouldntCreateUser').toString(), e);
        }
        try {
            await ConsumerUpdate(
                {
                    contact_phone: `+${formData.contact_phone}`,
                    contact_email: formData.contact_email.replace(/\s/g, ''),
                    first_name: formData.first_name.trim(),
                    last_name: formData.last_name.trim(),
                },
                token,
            );
        } catch (e) {
            onFailSubmit(t('Login.Errors.CouldntUpdateUser').toString(), e);
        }
        try {
            await ConsumerUpdatePreferences(
                {
                    notify_by_sms: formData.notification_method === 'sms',
                    notify_by_email: formData.notification_method === 'email',
                    marketing_email: formData.notification_method === 'email' && !formData.opt_out,
                    marketing_sms: formData.notification_method === 'sms' && !formData.opt_out,
                    language: getConsumerLanguagePreference(),
                },
                token,
            );
        } catch (e) {
            onFailSubmit(t('Login.Errors.CouldntUpdatePreferences').toString(), e);
        }
        history.push(onSuccessPath);
    };

    return {
        submitProfile,
        isSubmitting,
    };
};

export default useSubmitProfile;
