import { useEffect } from 'react';
import { SetValueFunction } from './interfaces/IDynamicFormRow';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';

export interface IFormElementProps {
    register: UseFormRegister<any>;
    keyName: string;
    value: any;
    setValue?: SetValueFunction;
    children: JSX.Element;
    registerOptions?: RegisterOptions;
}

/**
 * Registers the child component to the dynamic forms libary. When inputRef doesn't pick up then use this component.
 * I'd also suggest using this component when the state being returned from the form element is complex
 *  */
function FormElement({ register, keyName, value, setValue, children, registerOptions }: IFormElementProps) {
    useEffect(() => {
        if (register) {
            register(keyName, { ...registerOptions });
        } else {
            console.error(`You have not registered ${keyName}`);
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (setValue) {
            if (value) {
                setValue(keyName, value);
            }
        } else {
            console.warn('You are missing the setValue property');
        }
        // eslint-disable-next-line
    }, [value]);
    return children;
}

export default FormElement;
