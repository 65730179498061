export default function getAvailableExtras(
    ids: string[],
    extras: Record<string, IMenuExtra>,
    selectedExtras: Record<string, ISelectedExtras[]>,
    onSelectedExtras: (id: string, extras: ISelectedExtras[]) => void,
): string[] {
    return ids.filter((extraId) => {
        const extra = extras?.[extraId];
        if (!extra) return false;

        const hasMinQuantity = extra.menu_item_extra_group_quantity_min >= 1;
        const isOutOfStock = !!!extra.menu_item_extra_group_item_id_list.length;

        if (!hasMinQuantity && isOutOfStock) return false;

        // FOR ZONAL: Parent extra group needs to max out in order to show the child extra group
        if (extra?.menu_item_extra_group_metadata?.parent_id) {
            let parentExtraMaxedOut = false;
            // IDs are currently a bit messy and they have -paid/-inclusive at the end of them which we need to remove
            const parentID = extra?.menu_item_extra_group_metadata?.parent_id?.split('-')[0];
            // Finding the parent extra group for this extra group
            Object.keys(extras).forEach((extraID) => {
                if (extras?.[extraID]?.menu_item_extra_group_metadata?.child_id) {
                    const childID = extras?.[extraID]?.menu_item_extra_group_metadata?.child_id?.split('-')[0];
                    const maximum = extras?.[extraID]?.menu_item_extra_group_quantity_max;
                    if (parentID === childID) {
                        let selectedCount = 0;
                        selectedExtras[extraID]?.forEach((selectedExtra) => {
                            selectedCount += selectedExtra.count;
                        });
                        const maxedOut = selectedCount === maximum;
                        // If parent is maxed out we show the extra group(Set the boolean to true)
                        parentExtraMaxedOut = maxedOut;
                        // If it goes back to hidden state again we need to clean the selected options inside that Extra Group
                        if (!maxedOut && selectedExtras[extra.menu_item_extra_group_id]?.length > 0) {
                            onSelectedExtras(extra.menu_item_extra_group_id, []);
                        }
                    }
                }
            });
            extra.hidden = !parentExtraMaxedOut;
        }

        return true;
    });
}
