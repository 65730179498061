import makeStyles from '@material-ui/styles/makeStyles';
import ICommonFormProps from '../../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    forgotLink: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.9375rem',
        lineHeight: '1.25rem',
        color: '#4F3CC9',
        position: 'absolute',
        top: '-1.5rem',
        cursor: 'pointer',
    },
});
interface IForgotPasswordLink extends ICommonFormProps {}
export default function ForgotPasswordLink(props: IForgotPasswordLink) {
    const { forgotLink } = useStyles();
    const { t } = useTranslation();
    return (
        <p className={forgotLink} onClick={props.formProps.onClick}>
            {t('Login.Texts.forgotPassword')}
        </p>
    );
}
