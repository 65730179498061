import { useHistory } from 'react-router-dom';
import { ORDERS, QR_CODE } from '../../../../constants/URL.const';
import { AlertStatusCard } from '../../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard';
import { OrderCollectionActive, OrderCollectionComplete } from './OrderCollection';
import Styles from './orderCollection.module.scss';
import { ReactComponent as BlueLocation } from '../../../../imges/BlueLocation.svg';
import { useTranslation } from 'react-i18next';
import { IOrders } from '../../../../typings/IOrders.d';
import useGuestUserData from '../../../../customHooks/useGuestUserData';

interface IOrderCollectionContainerProps {
    orderItem: IOrders;
}

const isDisabled = false;

export function OrderCollectionContainer({ orderItem }: IOrderCollectionContainerProps) {
    const history = useHistory();
    const { t } = useTranslation();
    const { consumerId, guestId, isGuest } = useGuestUserData();

    function onClickQRCode() {
        isGuest
            ? history.push(
                  `${ORDERS}/${orderItem.placed_order_id}${QR_CODE}?session=${guestId}&consumer_id=${consumerId}`,
              )
            : history.push(`${ORDERS}/${orderItem.placed_order_id}${QR_CODE}`);
    }
    if (isDisabled) return null;

    function renderQR() {
        const props = {
            onClick: onClickQRCode,
            orderId: orderItem.placed_order_ref,
            collectionPointName: orderItem.collection_point_name,
        };
        switch (orderItem.placed_order_status) {
            case 'READY_FOR_COLLECTION':
                return <OrderCollectionActive {...props} />;
            case 'FINISHED':
                return <OrderCollectionComplete {...props} />;
            case 'ACCEPTED':
            case 'FUNDS_RECEIVED':
            case 'PLACED':
                return (
                    <div
                        style={{
                            flexBasis: '100%',
                        }}
                    >
                        <AlertStatusCard
                            alertTitle={<b>{t('OrderHistory.collectionPoint').toString()}</b>}
                            alertMessage={t('OrderHistory.collectionPointInfo')}
                            alertProps={{
                                color: 'info',
                                icon: <BlueLocation />,
                            }}
                        />
                    </div>
                );
            default:
                return null;
        }
    }

    return <div className={Styles.OrderCollectionParent}>{renderQR()}</div>;
}
