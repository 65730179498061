import { IDiscountStoreData } from '../Components/Payment/types';
import { IAddCartItem } from '../redux/Reducers/Cart';
import { ITaxType } from '../yoello-lib/modules/interfaces/ITaxes';
import { calculateInclusiveTax, calculateAdditiveTax } from '../yoello-lib/modules/utils/TaxController';
import { isObject } from './ObjectCheck';

/**Takes a pence price into pound returns a string */
export function priceToPounds(price: number) {
    if (price === undefined || price === null || typeof price === 'string') {
        return parseFloat('0').toFixed(2);
    }
    const roundedPrice = roundPriceOption(price);
    return parseFloat((roundedPrice / 100).toString()).toFixed(2);
}

/**Takes a pound price into pence */
export function priceToPence(price: number) {
    return price * 100;
}
/**Rounds the price
 * Only to be used on pence
 */
export function roundPriceOption(price: number): number {
    //@ts-ignore
    return Math.round(price.toFixed(2));
}

/**gets the cart sub total using the final price */
export function getCartSubTotal(cartItems) {
    let price = 0;
    for (const cartItem of cartItems) {
        price += cartItem.finalPrice;
    }
    return price;
}
const Hundred = 100;

export function generateTips(tipType, cartItems, customTip) {
    let price;
    switch (tipType) {
        case 0:
            return 0;
        case 'ROUNDUP':
            price = priceToPounds(getCartSubTotal(cartItems));

            return (Math.ceil(price) - price) * 100;

        case '10%':
            price = getCartSubTotal(cartItems);
            price = (price * 10) / Hundred;
            return price;

        case 'CUSTOM':
            if (customTip) {
                console.log(priceToPence(customTip));
                return priceToPence(customTip);
            } else {
                return 0;
            }
        default:
            return 0;
    }
}

/**generates discount price */
export function generateDiscountPrice(optionPrice: number, optionDiscount: number, discounts?: IDiscountStoreData) {
    if (optionPrice === undefined || optionPrice === null) {
        throw new Error('An option price was missing from the discount');
    }
    let optionDisc = optionDiscount;
    if (optionDiscount === undefined || optionDiscount === null) {
        optionDisc = 0;
    }
    if (optionDiscount > 100) {
        throw new Error('A discount is greater than 100%!');
    }
    let finalPrice = optionPrice - (optionPrice * optionDisc) / 100;
    // this is external whole order discount and should only be used for calculating the Taxes
    if (discounts?.value) {
        finalPrice = finalPrice - (finalPrice * discounts?.value) / 100;
    }
    return finalPrice;
}
/**generates the bulk price */
export function generateBulkPrice(optionPrice: number, bulkOptions: number) {
    if (optionPrice === undefined || optionPrice === null) {
        throw new Error('An option price was missing from the bulk price');
    }
    if (bulkOptions === undefined || bulkOptions === null) {
        throw new Error('An bulk option was missing from the bulk price');
    }
    return optionPrice * bulkOptions;
}

export function getCartItemTaxAmount(cartItems: IAddCartItem[], discounts?: IDiscountStoreData) {
    let totalTaxAmount = 0;
    let totalInclusiveTaxAmount = 0;
    for (const cartItem of cartItems) {
        let itemTaxAmount = 0;
        let itemInclusiveTaxAmount = 0;
        const menuItem = cartItem.menuItem;
        const taxArray = menuItem.menu_item_taxes;
        const extras = cartItem.extras;
        const itemCount = cartItem.itemCount;
        const priceName = cartItem.measure;
        const priceOptions = menuItem.menu_item_pricing_options?.[priceName];

        const price = generateDiscountPrice(priceOptions.option_price, priceOptions.option_discount ?? 0, discounts);
        if (taxArray?.length > 0) {
            for (const taxItem of taxArray) {
                if (taxItem?.tax_inclusion_type === ITaxType.ADDITIVE) {
                    itemTaxAmount += calculateItemAdditive(taxItem.tax_percentage, price, itemCount);
                } else if (taxItem?.tax_inclusion_type === ITaxType.INCLUSIVE) {
                    itemInclusiveTaxAmount += calculateItemInclusive(taxItem.tax_percentage, price, itemCount);
                }
            }
        } else {
            /**If no tax items use old model */
            itemInclusiveTaxAmount += calculateItemInclusive(menuItem.menu_item_vat_rate || 0, price, itemCount);
        }
        /**Check if the object is empty */
        if (isObject(extras, true)) {
            /**For each extra group */
            for (const id in extras) {
                const extraArray = extras[id];
                /**Each extra item of the selected extra group */
                for (const item of extraArray) {
                    let extraTaxAmount = 0;
                    let extraInclusiveTaxAmount = 0;

                    const price = generateDiscountPrice(item.price, item.option_discount ?? 0, discounts);

                    /**For any tax items assigned to the item */
                    if (item.extra_taxes && item.extra_taxes.length > 0) {
                        for (const tax of item.extra_taxes) {
                            if (tax?.tax_inclusion_type === ITaxType.ADDITIVE) {
                                extraTaxAmount += calculateItemAdditive(
                                    tax.tax_percentage,
                                    price,
                                    itemCount * item.count,
                                );
                            } else if (tax?.tax_inclusion_type === ITaxType.INCLUSIVE) {
                                extraInclusiveTaxAmount += calculateItemInclusive(
                                    tax.tax_percentage,
                                    price,
                                    itemCount * item.count,
                                );
                            }
                        }
                    } else {
                        /**If no tax items use old model */
                        extraInclusiveTaxAmount += calculateItemInclusive(
                            item.extra_vat_rate || 0,
                            price,
                            itemCount * item.count,
                        );
                    }
                    totalTaxAmount += extraTaxAmount;
                    totalInclusiveTaxAmount += extraInclusiveTaxAmount;
                }
            }
        }
        totalTaxAmount += itemTaxAmount;
        totalInclusiveTaxAmount += itemInclusiveTaxAmount;
    }
    return {
        totalTaxAmount: totalTaxAmount,
        totalInclusiveTaxAmount: totalInclusiveTaxAmount,
    };
}

function calculateItemAdditive(taxPercent, itemPrice, itemCount) {
    return calculateAdditiveTax(taxPercent, itemPrice) * itemCount;
}

function calculateItemInclusive(taxPercent, itemPrice, itemCount) {
    return calculateInclusiveTax(itemPrice, taxPercent) * itemCount;
}
