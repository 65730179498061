import { IAddCartItem } from '../redux/Reducers/Cart';
import { IPlaceOrders, IPlaceOrdersExtras } from '../typings/IOrders.d';
import { generateDiscountPrice } from './pricingCalculator';

interface IGetPlaceItemReturn {
    isDisabled: boolean;
    menuItem: IMenuItem | {};
    extras: ISelectedCartExtras;
    preferences: Record<string, string>;
    priceName: string;
    price: number;
    quantity: number;
}

/**Genereates a place order item array using the cart items */
export function generatePlaceOrderArray(cartItems: IAddCartItem[], rawExtraItem: Record<string, IExtraItem>) {
    const items = [];
    for (let i = 0; i < cartItems.length; i++) {
        const { measure, menuItem, extras, preferences, itemCount, price, finalPrice } = cartItems[i];
        items.push(
            createPlaceOrderItem(menuItem, extras, preferences, rawExtraItem, itemCount, measure, price, finalPrice),
        );
    }
    return items;
}
/**
 * Returns back an object determining if the object can be reordered along with all its extras,preferences and quantitiy
 * @param placeOrder A menu item order entry
 * @param rawItems array of all the available menu items
 * @param extras object of all the extra groups
 * @param preferences object of all the preference groups and there values
 * @param rawExtraItem object of the menu items available as menu items
 */
export function getPlaceOrderItem(
    placeOrder: IPlaceOrders,
    rawItems: IMenuItem[],
    extras: Record<string, IExtra>,
    preferences: Record<string, IMenuItemPreference>,
    rawExtraItem: Record<string, IExtraItem>,
): IGetPlaceItemReturn {
    const returnObject = {
        isDisabled: true,
        menuItem: {},
        extras: {},
        preferences: {},
        price: 0,
        priceName: '',
        quantity: 0,
    };
    const placedOrderIndex = findPlaceOrderIndex(placeOrder, rawItems);

    returnObject.menuItem = {
        menu_item_id: placeOrder.main_in_order_menu_item_id,
    };
    /**If the item exists in the array of raw items then its available */
    if (placedOrderIndex > -1) {
        const priceName = placeOrder.main_in_order_item_pricing_option_name;
        const quantity = placeOrder.main_in_order_item_quantity;
        /**divide the gross price by the quanity to match with the pricing options */
        const menuItem = rawItems[placedOrderIndex];
        const pricingOptions = menuItem.menu_item_pricing_options;

        const price = placeOrder.main_in_order_item_gross / quantity;

        returnObject.menuItem = menuItem;

        /** check the pricing options exist & equal the same */
        const { isPriceAvailable, priceKey } = getPriceOptions(pricingOptions, priceName, price);
        if (isPriceAvailable) {
            returnObject.price = pricingOptions[priceKey].option_price;
            returnObject.priceName = priceKey;
            returnObject.isDisabled = false;
            returnObject.quantity = calculateQuantity(priceKey, pricingOptions, quantity);
            /**if there are any extra items check if they are available */
            if (placeOrder.main_in_order_item_extras.length > 0) {
                /**Checks if the venue has any extras available */
                if (Object.keys(extras).length > 0) {
                    for (const extraItem of placeOrder.main_in_order_item_extras) {
                        const { isDisabled, extra } = getExtraItem(
                            extraItem,
                            rawExtraItem,
                            extras,
                            menuItem.menu_item_extras_groups_ids,
                        );
                        if (isDisabled) {
                            console.log(`isDisabled extra`, isDisabled);
                            returnObject.isDisabled = true;
                            break;
                        } else {
                            const extraObj = extra as any;
                            appendToObject(returnObject.extras, extraObj.id!, {
                                priceName: extraObj.priceName,
                                id: extraObj.menuId,
                                price: extraObj.price,
                                count: extraItem.extra_in_order_item_quantity / quantity,
                            });
                        }
                    }
                } else {
                    returnObject.isDisabled = true;
                    console.log(`isDisabled extra other reasons`);
                    return returnObject;
                }
            }
            /**If any preferences then check if available */
            if (placeOrder.main_in_order_item_preferences.length > 0) {
                if (Object.keys(preferences).length > 0) {
                    for (const pref of placeOrder.main_in_order_item_preferences) {
                        let prefName = pref;
                        if (Array.isArray(pref)) {
                            prefName = pref[0];
                        }
                        if (returnObject.isDisabled) {
                            break;
                        }
                        const { isDisabled, preference } = getPreferenceItem(
                            prefName,
                            preferences,
                            menuItem.menu_item_preference_ids,
                        );
                        if (isDisabled) {
                            console.log(`isDisabled preference other reasons`);
                            returnObject.isDisabled = true;
                            break;
                        } else {
                            const returnedPref = preference as ISelectedExtras;
                            appendToObject(returnObject.preferences, returnedPref.id!, prefName);
                        }
                    }
                } else {
                    console.log(`isDisabled preference other reasons`);
                    returnObject.isDisabled = true;
                    return returnObject;
                }
            }
        } else {
            returnObject.isDisabled = true;
        }
    }
    return returnObject;
}
/**
 * gets the preference item from the list of available preferences
 * @param preferences preference string name
 * @param preferenceData the available preference group list
 * @param menuItemPreferences the available preference groups assigned to the menu item
 */
function getPreferenceItem(
    preference: string,
    preferenceData: Record<string, IMenuItemPreference>,
    menuItemPreferences,
) {
    const returnObj = {
        preference: {},
        isDisabled: true,
    };
    if (menuItemPreferences.length > 0) {
        let prefId;
        for (const ids of menuItemPreferences) {
            const prefObj = preferenceData[ids];
            if (prefObj) {
                const index = prefObj.menu_item_preference_preferences.indexOf(preference);
                if (index > -1) {
                    prefId = ids;
                    break;
                }
            }
        }
        if (prefId) {
            returnObj.isDisabled = false;
            returnObj.preference = { id: prefId, name: preference };
        }
    }
    return returnObj;
}

/**
 * gets the extras item from the available
 * @param extraItem The extra item assigned to the menu item
 * @param rawExtraItem object of the menu items available as menu items
 * @param menuItemGroups object of all the extra groups
 * @param menuItemExtras object of the menu items available as menu items
 */
function getExtraItem(extraItem: IPlaceOrdersExtras, rawExtraItem, menuItemGroups, menuItemExtras) {
    const returnObj = {
        extra: {},
        isDisabled: true,
    };
    const itemId = extraItem.extra_in_order_menu_item_id;
    if (menuItemExtras.length > 0) {
        let extraId;
        /**is the id part of the extras still */
        for (const ids of menuItemExtras) {
            const exObj = menuItemGroups[ids];
            const index = exObj?.menu_item_extra_group_item_id_list?.indexOf(itemId);
            if (index > -1) {
                extraId = ids;
                break;
            }
        }
        /** if still available and pricing options match then its all good */
        if (extraId) {
            const rawItem = rawExtraItem[itemId];

            const extraPrice = extraItem.extra_in_order_item_gross / extraItem.extra_in_order_item_quantity;

            const { isPriceAvailable, priceKey, price } = getPriceOptions(
                rawItem.extra_pricing_options_extras,
                extraItem.extra_in_order_item_pricing_option_name,
                extraPrice,
            );
            if (isPriceAvailable) {
                returnObj.extra = {
                    id: extraId,
                    menuId: itemId,
                    priceName: priceKey,
                    price,
                };
                returnObj.isDisabled = false;
            }
        }
    }
    return returnObj;
}
/**
 * returns back the pricing option if its still available
 * @param pricingOptions the items pricing options
 * @param priceName the price name that was selected
 * @param price the price
 */
function getPriceOptions(pricingOptions: Record<string, IPricingOptions>, priceName: string, price: number) {
    let isPriceAvailable = false;
    let priceKey;
    let generatedPrice;
    for (const key in pricingOptions) {
        const object = pricingOptions[key];
        try {
            /**Generates a discount price so that we can check the price */
            generatedPrice = generateDiscountPrice(object.option_price, object.option_discount);
        } catch (error) {
            isPriceAvailable = false;
            break;
        }
        console.log({ generatedPrice });
        /**If the name exists then price name should match else price may only match  */
        if (
            (!priceName && generatedPrice === price) ||
            (object.option_name === priceName && generatedPrice === price)
        ) {
            isPriceAvailable = true;
            priceKey = key;
            break;
        }
    }
    return { isPriceAvailable, priceKey, price: generatedPrice };
}

/**
 * finds the index of the place order inside the menu item array
 * @param placedOrderItem place order item
 * @param rawItemArray array of all the menu items
 */
function findPlaceOrderIndex(placedOrderItem: IPlaceOrders, rawItemArray: IMenuItem[]) {
    const menuItemId = placedOrderItem.main_in_order_menu_item_id;
    const index = rawItemArray.findIndex((rawItem: IMenuItem) => rawItem.menu_item_id === menuItemId);
    return index;
}

/**
 * calculates the quantity based of the pricing option
 * @param priceKey the key of the pricing option
 * @param menuItemPricingOptions the items object of pricing options
 * @param quantity quantity of the placed order
 */
function calculateQuantity(
    priceKey: string,
    menuItemPricingOptions: Record<string, IPricingOptions>,
    quantity: number,
) {
    const pricingOp = menuItemPricingOptions[priceKey];
    if (pricingOp.option_bulk_qty) {
        return quantity / pricingOp.option_bulk_qty;
    }
    return quantity;
}

/**Creates the place order item with its preferences & extras*/
function createPlaceOrderItem(
    item: IMenuItem,
    extras: Record<string, ISelectedExtras[]>,
    preferences: Record<string, string>,
    ExtraItems: Record<string, IExtraItem>,
    itemCount: number,
    priceOptionName: string,
    price: number,
    finalPrice: number,
): IPlaceOrders {
    const main_in_order_item_extras = generateExtras(extras, ExtraItems, itemCount);
    const main_in_order_item_preferences = generatePreferences(preferences);
    const pricingOptions = item.menu_item_pricing_options[priceOptionName];
    const generatePrice = generateDiscountPrice(pricingOptions.option_price, pricingOptions.option_discount);
    const object = {
        main_and_extras_total_price: finalPrice,
        main_in_order_item_extras,
        main_in_order_item_gross: generatePrice * itemCount,
        main_in_order_item_id: '',
        main_in_order_menu_id: item.menu_id,
        main_in_order_menu_item_id: item.menu_item_id,
        main_in_order_item_name_internal: item.menu_item_name_public,
        main_in_order_item_name_public: item.menu_item_name_public,
        main_in_order_item_preferences,
        main_in_order_item_pricing_option_name: pricingOptions.option_name,
        main_in_order_item_quantity: itemCount,
        main_in_order_item_refund_quantity: 0,
        main_in_order_item_discount_code_price: 0,
        main_in_order_item_discount_code_amount: 0,
        main_in_order_item_discount_code_percentage: 0,
        main_in_order_item_discount_amount: 0,
        main_in_order_item_discount_percentage: 0,
    };
    return object;
}

/**Generates a list of extra items for an menu item */
function generateExtras(
    extras: Record<string, ISelectedExtras[]>,
    ExtraItems: Record<string, IExtraItem>,
    itemCount: number,
) {
    const itemExtras = [];
    if (extras && typeof extras === 'object') {
        for (const key in extras) {
            const extraList = extras[key];
            if (extraList.length > 0) {
                for (const item of extraList) {
                    itemExtras.push(createPlaceOrderExtras(item, ExtraItems, itemCount));
                }
            }
        }
    }
    return itemExtras;
}
/**Generates a list of preferences for the menu item */
function generatePreferences(preferences: Record<string, string>) {
    if (preferences && typeof preferences === 'object') {
        return Object.keys(preferences).map((key) => preferences[key]);
    }
    return [];
}
/**Creates the extra item object*/
function createPlaceOrderExtras(
    item: ISelectedExtras,
    extraItems: Record<string, IExtraItem>,
    itemCount: number,
): IPlaceOrdersExtras {
    const extraItem = extraItems[item.id];
    const pricingOps = extraItem?.extra_pricing_options_extras[item.priceName];

    const generatePrice = generateDiscountPrice(pricingOps?.option_price, pricingOps?.option_discount);

    return {
        extra_in_order__item_name_internal: extraItem.extra_name_internal,
        extra_in_order_item_gross: generatePrice * itemCount,
        extra_in_order_item_id: '',
        extra_in_order_item_name_public: extraItem.extra_name_public,
        extra_in_order_item_pricing_option_name: pricingOps?.option_name,
        extra_in_order_menu_item_id: extraItem.extra_id,
        extra_in_order_item_quantity: itemCount,
        extra_in_order_item_refund_quantity: 0,
        extra_in_order_item_sold_as_extra: true,
        extra_in_order_item_sold_with_main_in_order_item_id: '',
        extra_in_order_item_discount_price: 0,
        extra_in_order_item_discount_code_amount: 0,
        extra_in_order_item_discount_code_percentage: 0,
        extra_in_order_item_discount_code_price: 0,
        extra_in_order_item_discount_amount: 0,
        extra_in_order_item_discount_percentage: 0,
    };
}

/**
 * Appends to the object or creates a new array with the object
 * @param object the object you want to append the data too
 * @param key the key of the object
 * @param value the value to add to the object
 */
function appendToObject(object: any, key, value) {
    const obj = object[key];
    /**push if it already exists */
    if (obj) {
        object[key].push(value);
    } else {
        object[key] = [value];
    }
}
