import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    termsConditions: {
        fontSize: '0.875rem',
        color: '#737fa4',
        '@media (min-width: 768px)': {
            margin: '0',
        },
        '& > a': {
            color: '#4f3cc9 !important',
            fontWeight: 600,
            textDecoration: 'none',
        },
    },
});
export default function TermsCondition() {
    const { termsConditions } = useStyles();
    const { t } = useTranslation();
    return (
        <p className={termsConditions}>
            {' '}
            {t('Login.Texts.Byregistering')}
            <a rel="noopener noreferrer" href={'https://www.yoello.com/terms-conditions'} target="_blank">
                {t('Login.Texts.Terms')}
            </a>
            {t('Login.Texts.AndOur')}
            <a rel="noopener noreferrer" href={'https://www.yoello.com/privacy-policy'} target="_blank">
                {t('Login.Texts.Privacy')}
            </a>
        </p>
    );
}
