import React, { useContext } from 'react';
import Styles from '../styles.module.scss';
import { PaymentContext } from '../PaymentStore';
interface IOnApplyMessageComponent {
    venueId?: string;
}
export function OnApplyMessageComponent({ venueId }: IOnApplyMessageComponent) {
    const { discounts, basket } = useContext(PaymentContext);
    return (
        <div className={Styles.discountsCodeApplyMessage}>
            {((discounts.message && discounts.error) ||
                (basket[venueId]?.discounts?.message && basket[venueId]?.discounts?.error)) && (
                <p
                    data-component-name="DiscountsErrorText"
                    className={[Styles.errorMessage, 'dark-theme-uninvert'].join(' ')}
                >
                    {discounts.message}
                    {basket[venueId]?.discounts?.message}
                </p>
            )}
        </div>
    );
}
