import Styles from '../shopList.module.scss';
import { useSelector } from 'react-redux';
import CartOrderText from './CartOrderText';

import Button from '../../Widgets/Button';

interface ICartFooter {
    cartAmount: number;
    onConfirmationClick: () => void;
}

export function CartFooter({ cartAmount, onConfirmationClick }: ICartFooter) {
    const cartItems = useSelector((state: any) => state.Cart);

    return (
        <div className={Styles.footer}>
            <CartOrderText cartLength={cartItems.length || 0} totalPrice={cartAmount} />
            <Button
                title={'Checkout'}
                onClick={onConfirmationClick}
                dataComponentName="ConfirmOrderButton"
                iconClassName={cartItems.length > 0 ? 'consumer-right-arrow' : undefined}
                className={cartItems.length > 0 ? Styles.confirmOrderBtn : Styles.emptyCheckoutBtn}
                defaultBackground={true}
            />
        </div>
    );
}
