import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router';
import { NOT_FOUND, VENUE_MENU } from '../../constants/URL.const';
import { MenuContainer } from '../Menu/MenuContainer';
import venueLandingPageLoader from '../Widgets/Loading/VenueLandigPageSkeleton';
import { VenueComponent } from '../Venue/VenueContainer';
import MenuItemsSkeleton from '../Widgets/Loading/MenuItemsPage';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import VenuePageContainer from '../Page/VenuePageContainer';
import MenusPageContainer from '../Page/MenusPageContainer';

export function VenueRouter() {
    let { path } = useRouteMatch();
    const venue = useSelector((state: any) => state.Venue);
    const orderType = useSelector((state: any) => state.SelectedMenuType);
    const localization = useSelector((state: any) => state.localization);

    const ldClient = useLDClient();

    if (venue && venue.id) {
        ldClient?.identify({ key: venue && venue.id, kind: 'user' });
    }

    return (
        <Switch>
            <Route
                exact
                path={path}
                render={(props) => (
                    <VenuePageContainer
                        {...props}
                        apiConfig={{
                            dispatcherParams: [
                                venue !== null && venue?.venue_nickname === props.match.params['id'] ? true : false,
                                localization.language ?? 'EN',
                            ],
                            query: props.match.params['id'],

                            headers: {},
                        }}
                        LoadingComponent={venueLandingPageLoader}
                        ChildComponent={VenueComponent}
                        errorFunction={() => props.history.push(NOT_FOUND, { message: 'Venue not found' })}
                    />
                )}
            />
            <Route
                path={VENUE_MENU}
                render={(props) => (
                    <MenusPageContainer
                        {...props}
                        area={false}
                        apiConfig={{
                            query: props.match.params['id'],
                            headers: {},
                            params: { order_type: orderType },
                            dispatcherParams: [orderType, venue],
                        }}
                        ChildComponent={MenuContainer}
                        errorFunction={() =>
                            props.history.push(NOT_FOUND, {
                                message: 'Menu not found',
                            })
                        }
                        LoadingComponent={MenuItemsSkeleton}
                    />
                )}
            />
        </Switch>
    );
}
