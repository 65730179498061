import React, { createContext, useState, useContext, ComponentType } from 'react';
import DrawerParent from './DrawerParent';
interface IDrawerContext {
    openDrawer: openDrawerFunction;
    closeDrawer: () => void;
    isOpen: boolean;
}
export const DrawerContext = createContext<IDrawerContext | undefined>(undefined);

export const useDrawer = () => useContext(DrawerContext);

function DrawerProvider(props: any) {
    const [drawerState, setDrawerState] = useState<IDrawerState>({
        isOpen: false,
        Component: null,
        props: {},
        anchor: 'bottom',
        ID: '',
        isProductDetailsDrawer: false,
        onCloseFunction: null,
    });
    const openDrawer = (
        Component: ComponentType,
        props = {},
        anchor: anchors,
        ID: string,
        isProductDetailsDrawer?: boolean,
        onCloseFunction?: Function,
    ) => {
        setDrawerState({
            isOpen: true,
            Component,
            props,
            anchor,
            ID,
            onCloseFunction: onCloseFunction ? onCloseFunction : null,
            isProductDetailsDrawer: isProductDetailsDrawer,
        });
    };
    const closeDrawer = () => {
        setDrawerState((prevState) => ({
            ...prevState,
            isOpen: false,
        }));
    };
    return (
        <DrawerContext.Provider value={{ openDrawer, closeDrawer, isOpen: drawerState?.isOpen ?? false }}>
            <DrawerParent {...drawerState} closeDrawer={closeDrawer} />
            {props.children}
        </DrawerContext.Provider>
    );
}

export default DrawerProvider;
