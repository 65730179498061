import { useContext } from 'react';

import { PaymentContext } from '../PaymentStore';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { convertToUTCTime } from '../../../yoello-lib/modules/utils/TimeConversion';

import usePaymentView from '../PaymentHooks/usePaymentView';

import { SelectedOrderType } from '../types.d';
import { _ChangeRedirect, _ChangeTable, _RepeatCart, _resetCart } from '../../../redux/actions';
import { CONFIRM, LOGIN } from '../../../constants/URL.const';
import useAPI from '../../../yoello-lib/modules/API/useAPI';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';
import APIExtensions from '../../../yoello-lib/modules/API/APIDefs';
import { setSelectedPaymentMethod } from '../reducer/PaymentActions';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import useGuestUserData from '../../../customHooks/useGuestUserData';
import { IGroupedOrder } from '../../Widgets/ConfirmPayment/ConfirmCardPayment';
import { useMultiVendorBasketView } from '../useMultiVendorBasketView';

const useOnSuccessfulOrder = () => {
    const {
        selectedOrderType,
        selectedTable,
        selectedTime,
        deliveryAddress,
        areaName,
        dispatch: contextDispatch,
        deliveryTimes,
    } = useContext(PaymentContext);
    const { getRedirectURL } = usePaymentView();
    const Venues = useSelector((state: any) => state.Group.venues);
    const Cart = useSelector((state: any) => state.Cart);
    const { isGuest, getGuestOrderDetails } = useGuestUserData();
    const { itemsInCartGroupedByVenueId } = useMultiVendorBasketView();
    const dispatch = useDispatch<any>();
    const history = useHistory();
    const { retrieveAccessToken, isAuthenticated } = useAuthenticationContext()!;
    const { callAPI } = useAPI<any>(APIExtensions.consumerOrders, retrieveAccessToken, {
        onMountDisabled: true,
    });

    const onSuccessfulOrder = async (orderId: string, orderLongID: string, isOpenBankingOrder?: boolean) => {
        const nextAvailableTimeSlot = deliveryTimes?.find(
            (item) => !item.asap_delivery_requested && (item.collection || item.delivery),
        );
        const placedOrderId = localStorage.getItem('placed_order_id');
        dispatch(_RepeatCart(Cart));
        dispatch(_resetCart());
        dispatch(_ChangeRedirect(null));
        const url = getRedirectURL() + CONFIRM;
        if (!isOpenBankingOrder) {
            if (selectedOrderType === SelectedOrderType.TABLE) {
                dispatch(_ChangeTable(selectedTable));
            }
            const historyPropsObject = {
                orderId: orderId,
                OrderType: selectedOrderType,
                TimeSlot: selectedTime ? convertToUTCTime(selectedTime.venue_time) : '',
                deliveryAddress: deliveryAddress,
                tableArea: areaName,
                orderLongID: orderLongID,
                asapDeliveryRequested: selectedTime?.asap_delivery_requested,
            };
            if (selectedTime?.asap_delivery_requested) {
                // This is for getting the next approximate time for ASAP
                historyPropsObject.TimeSlot = convertToUTCTime(nextAvailableTimeSlot.venue_time) ?? '';
            }
            history.push(url, historyPropsObject);
        } else {
            if (!isAuthenticated && !isGuest) {
                history.push(LOGIN);
            } else {
                try {
                    let response;
                    if (isGuest) {
                        response = await getGuestOrderDetails(placedOrderId);
                    } else {
                        response = await callAPI(HTTPMethods.GET, {
                            query: placedOrderId,
                        });
                    }

                    if (response) {
                        if (selectedOrderType === SelectedOrderType.TABLE) {
                            dispatch(_ChangeTable(response.placed_order_delivery_table));
                        }
                        const historyProps = {
                            orderId: response.placed_order_ref,
                            OrderType: response.placed_order_order_type,
                            TimeSlot: response.placed_order_takeaway_timeslot
                                ? convertToUTCTime(response.placed_order_takeaway_timeslot)
                                : '',
                            deliveryAddress: response.placed_order_delivery_address,
                            tableArea: response.placed_order_table_area,
                            orderLongID: response.id || orderLongID,
                            asapDeliveryRequested: response.placed_order_asap_delivery_requested,
                        };
                        if (response.placed_order_asap_delivery_requested) {
                            // This is for getting the next approximate time for ASAP
                            historyProps.TimeSlot = convertToUTCTime(response.placed_order_takeaway_timeslot) ?? '';
                        }
                        history.push(url, historyProps);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }

        contextDispatch(setSelectedPaymentMethod(null));
    };
    const onSuccessfulMultiOrder = async (groupOrder: IGroupedOrder) => {
        dispatch(_RepeatCart(Cart));
        const orders = [];
        Object.keys(itemsInCartGroupedByVenueId).forEach((key: string) => {
            groupOrder.orders.forEach((order, index) => {
                if (Venues[key] && order.venue_id === Venues[key].id) {
                    orders.push({
                        orderId: order.order_reference_number,
                        prepTime: Venues[key].food_prep_time,
                        venueName: Venues[key].venue_name,
                        venueLogo: Venues[key].url_logo,
                        isLastOrder: index === groupOrder.orders.length - 1,
                    });
                }
            });
        });
        dispatch(_resetCart());
        dispatch(_ChangeRedirect(null));
        const url = getRedirectURL() + CONFIRM;
        if (selectedOrderType === SelectedOrderType.TABLE) {
            dispatch(_ChangeTable(selectedTable));
        }
        history.push(url, {
            orders: orders,
            OrderType: selectedOrderType,
            tableArea: areaName,
        });

        contextDispatch(setSelectedPaymentMethod(null));
    };

    return {
        onSuccessfulOrder,
        onSuccessfulMultiOrder,
    };
};

export default useOnSuccessfulOrder;
