import { useMemo, useRef } from 'react';
import { useMenu } from '../MenuContext';

/**Used for giving the scrolled effects */
export function useScrolled(isScrolledClassName: string, scrolledBackClassName?: string) {
    const {
        state: { isScrolled },
    } = useMenu();

    const hasScrolled = useRef(false);

    const className = useMemo(() => {
        if (isScrolled) {
            hasScrolled.current = true;
            return isScrolledClassName;
        } else if (hasScrolled.current) {
            hasScrolled.current = false;
            return scrolledBackClassName ?? '';
        }
    }, [isScrolled, isScrolledClassName, scrolledBackClassName]);

    return {
        className,
        hasScrolled,
    };
}
