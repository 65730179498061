import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LOGINV2_PROFILE, LOGINV2_START, VENUE } from '../../constants/URL.const';
import { LoginStorage } from '../../constants/storageVars.const';
import { useSnackbarMessages } from '../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { useAuthenticationContext } from '../../yoello-lib/modules/auth/AuthenticationContext';
import PageLoading from '../Page/PageLoading';
import useGenerateNavPath from '../../customHooks/useGenerateNavPath';
import { getConsumer } from '../../Api.js';
import useGetLoginParams from './useGetLoginParams';
import { useTranslation } from 'react-i18next';
import { deleteCookie } from './util/cookies';
import { GuestUser } from './GuestView/types.d';
import { useSelector } from 'react-redux';
import useGuestUserData from '../../customHooks/useGuestUserData';
import consumerErrorHandler from '../../utils/consumerError';
import useConsumerLocations from '../../customHooks/useConsumerLocations';
import { SelectedOrderType } from '../../yoello-lib/modules/utils/venueOpen';

export function OAuthRedirector() {
    const history = useHistory();
    const specificMenuId = useSelector((state: any) => state.specificMenuId);
    const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
    const { t } = useTranslation();
    const { generateNavPath } = useGenerateNavPath();
    const { username } = useGetLoginParams();
    const { isGuest } = useGuestUserData();
    const { updateUserLocations } = useConsumerLocations();
    const { isAuthenticated, retrieveAccessToken, userLogout } = useAuthenticationContext();

    const { sendSnackbarMessage } = useSnackbarMessages();

    useEffect(() => {
        async function retrieveUser() {
            const targetUrl: string = localStorage.getItem(LoginStorage.LOGIN_TO);
            try {
                const token = await retrieveAccessToken();
                try {
                    if (token) {
                        const consumer = await getConsumer(token);
                        const address = JSON.parse(localStorage.getItem('BillingAddress'));
                        // Wait for updateUserLocations to complete
                        try {
                            await updateUserLocations(address || {}, null, consumer?.data?.data, SelectedMenuType === SelectedOrderType.DELIVERY);

                            deleteCookie([GuestUser.ConsumerID, GuestUser.GuestID]);
                            if (consumer?.data?.data?.user_details_set) {
                                if (
                                    (targetUrl === generateNavPath(VENUE) ||
                                        targetUrl + '/' === generateNavPath(VENUE)) &&
                                    Boolean(specificMenuId)
                                ) {
                                    history.push(targetUrl + `?menu_id=${specificMenuId}`);
                                } else {
                                    history.push(targetUrl);
                                }
                            } else {
                                if (consumer.status === 204) {
                                    const profilePath = generateNavPath(LOGINV2_PROFILE);
                                    history.push(profilePath);
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        history.push(generateNavPath(LOGINV2_START));
                    }
                } catch (err) {
                    const { errMsg, errStatus } = consumerErrorHandler(err);
                    console.log({ errStatus }, { errMsg });
                    if (errStatus === 404) {
                        const profilePath = generateNavPath(LOGINV2_PROFILE);
                        history.push(profilePath);
                    } else if (isGuest) {
                        history.push(targetUrl);
                    } else {
                        sendSnackbarMessage(t(errMsg).toString(), 'error');
                        await userLogout();
                    }
                }
            } catch (error) {
                history.push(generateNavPath(LOGINV2_START));
                await userLogout();
            }
        }
        retrieveUser();
        // eslint-disable-next-line
    }, [isAuthenticated, username]);

    return <PageLoading />;
}
