import Styles from '../styles.module.scss';
import makeStyles from '@material-ui/styles/makeStyles';
import { useGuestView } from './useGuestView';
import { TextField } from '../../Widgets/TextField';

import DynamicForm from '../../../yoello-lib/modules/dynamicForms/DynamicForms';

const useStyles = makeStyles({
    root: {
        height: 'calc(100vh - 6.75rem)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        overflow: 'unset!important',
        header: {
            flex: '0 0 auto',
            maxHeight: '200px',
            overflow: 'hidden',
            transition: '0.15s ease-in-out',
            '@media (max-height: 500px)': {
                maxHeight: 0,
            },
            '@media (min-width: 768px)': {
                textAlign: 'center',
            },
            padding: '1.5rem',
        },
        '& form': {
            flex: '1 1 auto',
            position: 'relative',
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '0 1.5rem 12rem 1.5rem',
        },
    },
});

function GuestView() {
    const { formRows, onSubmit } = useGuestView();
    const classes = useStyles();

    return (
        <div className={`${Styles.Login} ${classes.root}`}>
            <header>
                <TextField
                    className={Styles.Text1}
                    text={{
                        key: 'Login.Texts.GuestCheckout',
                    }}
                />
                <TextField
                    className={Styles.Text2}
                    text={{
                        key: 'Login.Texts.GuestCheckoutSubTitle',
                    }}
                />
            </header>
            <DynamicForm
                formRows={formRows}
                formKeyName="guset-user-details"
                useFormOptions={{
                    mode: 'onBlur',
                    reValidateMode: 'onChange',
                }}
                onHandleSubmit={onSubmit}
            />
        </div>
    );
}

export default GuestView;
