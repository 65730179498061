import SocialButton from './SocialButton';
import FacebookIcon from './Icons/FacebookIcon';
import { useTranslation } from 'react-i18next';

const FacebookButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <SocialButton
            onClick={onClick}
            text={t('Login.Texts.facebook').toString()}
            dataComponentName={'continueWithFacebook'}
        >
            <FacebookIcon />
        </SocialButton>
    );
};

export default FacebookButton;
