export interface ICountryCode {
    name: string;
    alpha3: string;
    countryCode: string;
}

export const countryCodes = [
    {
        name: 'United Kingdom',
        alpha3: 'GBR',
        countryCode: '826',
    },
    {
        name: 'United States of America',
        alpha3: 'USA',
        countryCode: '840',
    },
    {
        name: 'Afghanistan',
        alpha3: 'AFG',
        countryCode: '004',
    },
    {
        name: 'Åland Islands',
        alpha3: 'ALA',
        countryCode: '248',
    },
    {
        name: 'Albania',
        alpha3: 'ALB',
        countryCode: '008',
    },
    {
        name: 'Algeria',
        alpha3: 'DZA',
        countryCode: '012',
    },
    {
        name: 'American Samoa',
        alpha3: 'ASM',
        countryCode: '016',
    },
    {
        name: 'Andorra',
        alpha3: 'AND',
        countryCode: '020',
    },
    {
        name: 'Angola',
        alpha3: 'AGO',
        countryCode: '024',
    },
    {
        name: 'Anguilla',
        alpha3: 'AIA',
        countryCode: '660',
    },
    {
        name: 'Antarctica',
        alpha3: 'ATA',
        countryCode: '010',
    },
    {
        name: 'Antigua and Barbuda',
        alpha3: 'ATG',
        countryCode: '028',
    },
    {
        name: 'Argentina',
        alpha3: 'ARG',
        countryCode: '032',
    },
    {
        name: 'Armenia',
        alpha3: 'ARM',
        countryCode: '051',
    },
    {
        name: 'Aruba',
        alpha3: 'ABW',
        countryCode: '533',
    },
    {
        name: 'Australia',
        alpha3: 'AUS',
        countryCode: '036',
    },
    {
        name: 'Austria',
        alpha3: 'AUT',
        countryCode: '040',
    },
    {
        name: 'Azerbaijan',
        alpha3: 'AZE',
        countryCode: '031',
    },
    {
        name: 'Bahamas',
        alpha3: 'BHS',
        countryCode: '044',
    },
    {
        name: 'Bahrain',
        alpha3: 'BHR',
        countryCode: '048',
    },
    {
        name: 'Bangladesh',
        alpha3: 'BGD',
        countryCode: '050',
    },
    {
        name: 'Barbados',
        alpha3: 'BRB',
        countryCode: '052',
    },
    {
        name: 'Belarus',
        alpha3: 'BLR',
        countryCode: '112',
    },
    {
        name: 'Belgium',
        alpha3: 'BEL',
        countryCode: '056',
    },
    {
        name: 'Belize',
        alpha3: 'BLZ',
        countryCode: '084',
    },
    {
        name: 'Benin',
        alpha3: 'BEN',
        countryCode: '204',
    },
    {
        name: 'Bermuda',
        alpha3: 'BMU',
        countryCode: '060',
    },
    {
        name: 'Bhutan',
        alpha3: 'BTN',
        countryCode: '064',
    },
    {
        name: 'Bolivia (Plurinational State of)',
        alpha3: 'BOL',
        countryCode: '068',
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        alpha3: 'BES',
        countryCode: '535',
    },
    {
        name: 'Bosnia and Herzegovina',
        alpha3: 'BIH',
        countryCode: '070',
    },
    {
        name: 'Botswana',
        alpha3: 'BWA',
        countryCode: '072',
    },
    {
        name: 'Bouvet Island',
        alpha3: 'BVT',
        countryCode: '074',
    },
    {
        name: 'Brazil',
        alpha3: 'BRA',
        countryCode: '076',
    },
    {
        name: 'British Indian Ocean Territory',
        alpha3: 'IOT',
        countryCode: '086',
    },
    {
        name: 'Brunei Darussalam',
        alpha3: 'BRN',
        countryCode: '096',
    },
    {
        name: 'Bulgaria',
        alpha3: 'BGR',
        countryCode: '100',
    },
    {
        name: 'Burkina Faso',
        alpha3: 'BFA',
        countryCode: '854',
    },
    {
        name: 'Burundi',
        alpha3: 'BDI',
        countryCode: '108',
    },
    {
        name: 'Cabo Verde',
        alpha3: 'CPV',
        countryCode: '132',
    },
    {
        name: 'Cambodia',
        alpha3: 'KHM',
        countryCode: '116',
    },
    {
        name: 'Cameroon',
        alpha3: 'CMR',
        countryCode: '120',
    },
    {
        name: 'Canada',
        alpha3: 'CAN',
        countryCode: '124',
    },
    {
        name: 'Cayman Islands',
        alpha3: 'CYM',
        countryCode: '136',
    },
    {
        name: 'Central African Republic',
        alpha3: 'CAF',
        countryCode: '140',
    },
    {
        name: 'Chad',
        alpha3: 'TCD',
        countryCode: '148',
    },
    {
        name: 'Chile',
        alpha3: 'CHL',
        countryCode: '152',
    },
    {
        name: 'China',
        alpha3: 'CHN',
        countryCode: '156',
    },
    {
        name: 'Christmas Island',
        alpha3: 'CXR',
        countryCode: '162',
    },
    {
        name: 'Cocos (Keeling) Islands',
        alpha3: 'CCK',
        countryCode: '166',
    },
    {
        name: 'Colombia',
        alpha3: 'COL',
        countryCode: '170',
    },
    {
        name: 'Comoros',
        alpha3: 'COM',
        countryCode: '174',
    },
    {
        name: 'Congo',
        alpha3: 'COG',
        countryCode: '178',
    },
    {
        name: 'Congo, Democratic Republic of the',
        alpha3: 'COD',
        countryCode: '180',
    },
    {
        name: 'Cook Islands',
        alpha3: 'COK',
        countryCode: '184',
    },
    {
        name: 'Costa Rica',
        alpha3: 'CRI',
        countryCode: '188',
    },
    {
        name: "Côte d'Ivoire",
        alpha3: 'CIV',
        countryCode: '384',
    },
    {
        name: 'Croatia',
        alpha3: 'HRV',
        countryCode: '191',
    },
    {
        name: 'Cuba',
        alpha3: 'CUB',
        countryCode: '192',
    },
    {
        name: 'Curaçao',
        alpha3: 'CUW',
        countryCode: '531',
    },
    {
        name: 'Cyprus',
        alpha3: 'CYP',
        countryCode: '196',
    },
    {
        name: 'Czechia',
        alpha3: 'CZE',
        countryCode: '203',
    },
    {
        name: 'Denmark',
        alpha3: 'DNK',
        countryCode: '208',
    },
    {
        name: 'Djibouti',
        alpha3: 'DJI',
        countryCode: '262',
    },
    {
        name: 'Dominica',
        alpha3: 'DMA',
        countryCode: '212',
    },
    {
        name: 'Dominican Republic',
        alpha3: 'DOM',
        countryCode: '214',
    },
    {
        name: 'Ecuador',
        alpha3: 'ECU',
        countryCode: '218',
    },
    {
        name: 'Egypt',
        alpha3: 'EGY',
        countryCode: '818',
    },
    {
        name: 'El Salvador',
        alpha3: 'SLV',
        countryCode: '222',
    },
    {
        name: 'Equatorial Guinea',
        alpha3: 'GNQ',
        countryCode: '226',
    },
    {
        name: 'Eritrea',
        alpha3: 'ERI',
        countryCode: '232',
    },
    {
        name: 'Estonia',
        alpha3: 'EST',
        countryCode: '233',
    },
    {
        name: 'Eswatini',
        alpha3: 'SWZ',
        countryCode: '748',
    },
    {
        name: 'Ethiopia',
        alpha3: 'ETH',
        countryCode: '231',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        alpha3: 'FLK',
        countryCode: '238',
    },
    {
        name: 'Faroe Islands',
        alpha3: 'FRO',
        countryCode: '234',
    },
    {
        name: 'Fiji',
        alpha3: 'FJI',
        countryCode: '242',
    },
    {
        name: 'Finland',
        alpha3: 'FIN',
        countryCode: '246',
    },
    {
        name: 'France',
        alpha3: 'FRA',
        countryCode: '250',
    },
    {
        name: 'French Guiana',
        alpha3: 'GUF',
        countryCode: '254',
    },
    {
        name: 'French Polynesia',
        alpha3: 'PYF',
        countryCode: '258',
    },
    {
        name: 'French Southern Territories',
        alpha3: 'ATF',
        countryCode: '260',
    },
    {
        name: 'Gabon',
        alpha3: 'GAB',
        countryCode: '266',
    },
    {
        name: 'Gambia',
        alpha3: 'GMB',
        countryCode: '270',
    },
    {
        name: 'Georgia',
        alpha3: 'GEO',
        countryCode: '268',
    },
    {
        name: 'Germany',
        alpha3: 'DEU',
        countryCode: '276',
    },
    {
        name: 'Ghana',
        alpha3: 'GHA',
        countryCode: '288',
    },
    {
        name: 'Gibraltar',
        alpha3: 'GIB',
        countryCode: '292',
    },
    {
        name: 'Greece',
        alpha3: 'GRC',
        countryCode: '300',
    },
    {
        name: 'Greenland',
        alpha3: 'GRL',
        countryCode: '304',
    },
    {
        name: 'Grenada',
        alpha3: 'GRD',
        countryCode: '308',
    },
    {
        name: 'Guadeloupe',
        alpha3: 'GLP',
        countryCode: '312',
    },
    {
        name: 'Guam',
        alpha3: 'GUM',
        countryCode: '316',
    },
    {
        name: 'Guatemala',
        alpha3: 'GTM',
        countryCode: '320',
    },
    {
        name: 'Guernsey',
        alpha3: 'GGY',
        countryCode: '831',
    },
    {
        name: 'Guinea',
        alpha3: 'GIN',
        countryCode: '324',
    },
    {
        name: 'Guinea-Bissau',
        alpha3: 'GNB',
        countryCode: '624',
    },
    {
        name: 'Guyana',
        alpha3: 'GUY',
        countryCode: '328',
    },
    {
        name: 'Haiti',
        alpha3: 'HTI',
        countryCode: '332',
    },
    {
        name: 'Heard Island and McDonald Islands',
        alpha3: 'HMD',
        countryCode: '334',
    },
    {
        name: 'Holy See',
        alpha3: 'VAT',
        countryCode: '336',
    },
    {
        name: 'Honduras',
        alpha3: 'HND',
        countryCode: '340',
    },
    {
        name: 'Hong Kong',
        alpha3: 'HKG',
        countryCode: '344',
    },
    {
        name: 'Hungary',
        alpha3: 'HUN',
        countryCode: '348',
    },
    {
        name: 'Iceland',
        alpha3: 'ISL',
        countryCode: '352',
    },
    {
        name: 'India',
        alpha3: 'IND',
        countryCode: '356',
    },
    {
        name: 'Indonesia',
        alpha3: 'IDN',
        countryCode: '360',
    },
    {
        name: 'Iran (Islamic Republic of)',
        alpha3: 'IRN',
        countryCode: '364',
    },
    {
        name: 'Iraq',
        alpha3: 'IRQ',
        countryCode: '368',
    },
    {
        name: 'Ireland',
        alpha3: 'IRL',
        countryCode: '372',
    },
    {
        name: 'Isle of Man',
        alpha3: 'IMN',
        countryCode: '833',
    },
    {
        name: 'Israel',
        alpha3: 'ISR',
        countryCode: '376',
    },
    {
        name: 'Italy',
        alpha3: 'ITA',
        countryCode: '380',
    },
    {
        name: 'Jamaica',
        alpha3: 'JAM',
        countryCode: '388',
    },
    {
        name: 'Japan',
        alpha3: 'JPN',
        countryCode: '392',
    },
    {
        name: 'Jersey',
        alpha3: 'JEY',
        countryCode: '832',
    },
    {
        name: 'Jordan',
        alpha3: 'JOR',
        countryCode: '400',
    },
    {
        name: 'Kazakhstan',
        alpha3: 'KAZ',
        countryCode: '398',
    },
    {
        name: 'Kenya',
        alpha3: 'KEN',
        countryCode: '404',
    },
    {
        name: 'Kiribati',
        alpha3: 'KIR',
        countryCode: '296',
    },
    {
        name: "Korea (Democratic People's Republic of)",
        alpha3: 'PRK',
        countryCode: '408',
    },
    {
        name: 'Korea, Republic of',
        alpha3: 'KOR',
        countryCode: '410',
    },
    {
        name: 'Kuwait',
        alpha3: 'KWT',
        countryCode: '414',
    },
    {
        name: 'Kyrgyzstan',
        alpha3: 'KGZ',
        countryCode: '417',
    },
    {
        name: "Lao People's Democratic Republic",
        alpha3: 'LAO',
        countryCode: '418',
    },
    {
        name: 'Latvia',
        alpha3: 'LVA',
        countryCode: '428',
    },
    {
        name: 'Lebanon',
        alpha3: 'LBN',
        countryCode: '422',
    },
    {
        name: 'Lesotho',
        alpha3: 'LSO',
        countryCode: '426',
    },
    {
        name: 'Liberia',
        alpha3: 'LBR',
        countryCode: '430',
    },
    {
        name: 'Libya',
        alpha3: 'LBY',
        countryCode: '434',
    },
    {
        name: 'Liechtenstein',
        alpha3: 'LIE',
        countryCode: '438',
    },
    {
        name: 'Lithuania',
        alpha3: 'LTU',
        countryCode: '440',
    },
    {
        name: 'Luxembourg',
        alpha3: 'LUX',
        countryCode: '442',
    },
    {
        name: 'Macao',
        alpha3: 'MAC',
        countryCode: '446',
    },
    {
        name: 'Madagascar',
        alpha3: 'MDG',
        countryCode: '450',
    },
    {
        name: 'Malawi',
        alpha3: 'MWI',
        countryCode: '454',
    },
    {
        name: 'Malaysia',
        alpha3: 'MYS',
        countryCode: '458',
    },
    {
        name: 'Maldives',
        alpha3: 'MDV',
        countryCode: '462',
    },
    {
        name: 'Mali',
        alpha3: 'MLI',
        countryCode: '466',
    },
    {
        name: 'Malta',
        alpha3: 'MLT',
        countryCode: '470',
    },
    {
        name: 'Marshall Islands',
        alpha3: 'MHL',
        countryCode: '584',
    },
    {
        name: 'Martinique',
        alpha3: 'MTQ',
        countryCode: '474',
    },
    {
        name: 'Mauritania',
        alpha3: 'MRT',
        countryCode: '478',
    },
    {
        name: 'Mauritius',
        alpha3: 'MUS',
        countryCode: '480',
    },
    {
        name: 'Mayotte',
        alpha3: 'MYT',
        countryCode: '175',
    },
    {
        name: 'Mexico',
        alpha3: 'MEX',
        countryCode: '484',
    },
    {
        name: 'Micronesia (Federated States of)',
        alpha3: 'FSM',
        countryCode: '583',
    },
    {
        name: 'Moldova, Republic of',
        alpha3: 'MDA',
        countryCode: '498',
    },
    {
        name: 'Monaco',
        alpha3: 'MCO',
        countryCode: '492',
    },
    {
        name: 'Mongolia',
        alpha3: 'MNG',
        countryCode: '496',
    },
    {
        name: 'Montenegro',
        alpha3: 'MNE',
        countryCode: '499',
    },
    {
        name: 'Montserrat',
        alpha3: 'MSR',
        countryCode: '500',
    },
    {
        name: 'Morocco',
        alpha3: 'MAR',
        countryCode: '504',
    },
    {
        name: 'Mozambique',
        alpha3: 'MOZ',
        countryCode: '508',
    },
    {
        name: 'Myanmar',
        alpha3: 'MMR',
        countryCode: '104',
    },
    {
        name: 'Namibia',
        alpha3: 'NAM',
        countryCode: '516',
    },
    {
        name: 'Nauru',
        alpha3: 'NRU',
        countryCode: '520',
    },
    {
        name: 'Nepal',
        alpha3: 'NPL',
        countryCode: '524',
    },
    {
        name: 'Netherlands',
        alpha3: 'NLD',
        countryCode: '528',
    },
    {
        name: 'New Caledonia',
        alpha3: 'NCL',
        countryCode: '540',
    },
    {
        name: 'New Zealand',
        alpha3: 'NZL',
        countryCode: '554',
    },
    {
        name: 'Nicaragua',
        alpha3: 'NIC',
        countryCode: '558',
    },
    {
        name: 'Niger',
        alpha3: 'NER',
        countryCode: '562',
    },
    {
        name: 'Nigeria',
        alpha3: 'NGA',
        countryCode: '566',
    },
    {
        name: 'Niue',
        alpha3: 'NIU',
        countryCode: '570',
    },
    {
        name: 'Norfolk Island',
        alpha3: 'NFK',
        countryCode: '574',
    },
    {
        name: 'North Macedonia',
        alpha3: 'MKD',
        countryCode: '807',
    },
    {
        name: 'Northern Mariana Islands',
        alpha3: 'MNP',
        countryCode: '580',
    },
    {
        name: 'Norway',
        alpha3: 'NOR',
        countryCode: '578',
    },
    {
        name: 'Oman',
        alpha3: 'OMN',
        countryCode: '512',
    },
    {
        name: 'Pakistan',
        alpha3: 'PAK',
        countryCode: '586',
    },
    {
        name: 'Palau',
        alpha3: 'PLW',
        countryCode: '585',
    },
    {
        name: 'Palestine, State of',
        alpha3: 'PSE',
        countryCode: '275',
    },
    {
        name: 'Panama',
        alpha3: 'PAN',
        countryCode: '591',
    },
    {
        name: 'Papua New Guinea',
        alpha3: 'PNG',
        countryCode: '598',
    },
    {
        name: 'Paraguay',
        alpha3: 'PRY',
        countryCode: '600',
    },
    {
        name: 'Peru',
        alpha3: 'PER',
        countryCode: '604',
    },
    {
        name: 'Philippines',
        alpha3: 'PHL',
        countryCode: '608',
    },
    {
        name: 'Pitcairn',
        alpha3: 'PCN',
        countryCode: '612',
    },
    {
        name: 'Poland',
        alpha3: 'POL',
        countryCode: '616',
    },
    {
        name: 'Portugal',
        alpha3: 'PRT',
        countryCode: '620',
    },
    {
        name: 'Puerto Rico',
        alpha3: 'PRI',
        countryCode: '630',
    },
    {
        name: 'Qatar',
        alpha3: 'QAT',
        countryCode: '634',
    },
    {
        name: 'Réunion',
        alpha3: 'REU',
        countryCode: '638',
    },
    {
        name: 'Romania',
        alpha3: 'ROU',
        countryCode: '642',
    },
    {
        name: 'Russian Federation',
        alpha3: 'RUS',
        countryCode: '643',
    },
    {
        name: 'Rwanda',
        alpha3: 'RWA',
        countryCode: '646',
    },
    {
        name: 'Saint Barthélemy',
        alpha3: 'BLM',
        countryCode: '652',
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        alpha3: 'SHN',
        countryCode: '654',
    },
    {
        name: 'Saint Kitts and Nevis',
        alpha3: 'KNA',
        countryCode: '659',
    },
    {
        name: 'Saint Lucia',
        alpha3: 'LCA',
        countryCode: '662',
    },
    {
        name: 'Saint Martin (French part)',
        alpha3: 'MAF',
        countryCode: '663',
    },
    {
        name: 'Saint Pierre and Miquelon',
        alpha3: 'SPM',
        countryCode: '666',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        alpha3: 'VCT',
        countryCode: '670',
    },
    {
        name: 'Samoa',
        alpha3: 'WSM',
        countryCode: '882',
    },
    {
        name: 'San Marino',
        alpha3: 'SMR',
        countryCode: '674',
    },
    {
        name: 'Sao Tome and Principe',
        alpha3: 'STP',
        countryCode: '678',
    },
    {
        name: 'Saudi Arabia',
        alpha3: 'SAU',
        countryCode: '682',
    },
    {
        name: 'Senegal',
        alpha3: 'SEN',
        countryCode: '686',
    },
    {
        name: 'Serbia',
        alpha3: 'SRB',
        countryCode: '688',
    },
    {
        name: 'Seychelles',
        alpha3: 'SYC',
        countryCode: '690',
    },
    {
        name: 'Sierra Leone',
        alpha3: 'SLE',
        countryCode: '694',
    },
    {
        name: 'Singapore',
        alpha3: 'SGP',
        countryCode: '702',
    },
    {
        name: 'Sint Maarten (Dutch part)',
        alpha3: 'SXM',
        countryCode: '534',
    },
    {
        name: 'Slovakia',
        alpha3: 'SVK',
        countryCode: '703',
    },
    {
        name: 'Slovenia',
        alpha3: 'SVN',
        countryCode: '705',
    },
    {
        name: 'Solomon Islands',
        alpha3: 'SLB',
        countryCode: '090',
    },
    {
        name: 'Somalia',
        alpha3: 'SOM',
        countryCode: '706',
    },
    {
        name: 'South Africa',
        alpha3: 'ZAF',
        countryCode: '710',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        alpha3: 'SGS',
        countryCode: '239',
    },
    {
        name: 'South Sudan',
        alpha3: 'SSD',
        countryCode: '728',
    },
    {
        name: 'Spain',
        alpha3: 'ESP',
        countryCode: '724',
    },
    {
        name: 'Sri Lanka',
        alpha3: 'LKA',
        countryCode: '144',
    },
    {
        name: 'Sudan',
        alpha3: 'SDN',
        countryCode: '729',
    },
    {
        name: 'Suriname',
        alpha3: 'SUR',
        countryCode: '740',
    },
    {
        name: 'Svalbard and Jan Mayen',
        alpha3: 'SJM',
        countryCode: '744',
    },
    {
        name: 'Sweden',
        alpha3: 'SWE',
        countryCode: '752',
    },
    {
        name: 'Switzerland',
        alpha3: 'CHE',
        countryCode: '756',
    },
    {
        name: 'Syrian Arab Republic',
        alpha3: 'SYR',
        countryCode: '760',
    },
    {
        name: 'Taiwan, Province of China',
        alpha3: 'TWN',
        countryCode: '158',
    },
    {
        name: 'Tajikistan',
        alpha3: 'TJK',
        countryCode: '762',
    },
    {
        name: 'Tanzania, United Republic of',
        alpha3: 'TZA',
        countryCode: '834',
    },
    {
        name: 'Thailand',
        alpha3: 'THA',
        countryCode: '764',
    },
    {
        name: 'Timor-Leste',
        alpha3: 'TLS',
        countryCode: '626',
    },
    {
        name: 'Togo',
        alpha3: 'TGO',
        countryCode: '768',
    },
    {
        name: 'Tokelau',
        alpha3: 'TKL',
        countryCode: '772',
    },
    {
        name: 'Tonga',
        alpha3: 'TON',
        countryCode: '776',
    },
    {
        name: 'Trinidad and Tobago',
        alpha3: 'TTO',
        countryCode: '780',
    },
    {
        name: 'Tunisia',
        alpha3: 'TUN',
        countryCode: '788',
    },
    {
        name: 'Turkey',
        alpha3: 'TUR',
        countryCode: '792',
    },
    {
        name: 'Turkmenistan',
        alpha3: 'TKM',
        countryCode: '795',
    },
    {
        name: 'Turks and Caicos Islands',
        alpha3: 'TCA',
        countryCode: '796',
    },
    {
        name: 'Tuvalu',
        alpha3: 'TUV',
        countryCode: '798',
    },
    {
        name: 'Uganda',
        alpha3: 'UGA',
        countryCode: '800',
    },
    {
        name: 'Ukraine',
        alpha3: 'UKR',
        countryCode: '804',
    },
    {
        name: 'United Arab Emirates',
        alpha3: 'ARE',
        countryCode: '784',
    },
    {
        name: 'United States Minor Outlying Islands',
        alpha3: 'UMI',
        countryCode: '581',
    },
    {
        name: 'Uruguay',
        alpha3: 'URY',
        countryCode: '858',
    },
    {
        name: 'Uzbekistan',
        alpha3: 'UZB',
        countryCode: '860',
    },
    {
        name: 'Vanuatu',
        alpha3: 'VUT',
        countryCode: '548',
    },
    {
        name: 'Venezuela (Bolivarian Republic of)',
        alpha3: 'VEN',
        countryCode: '862',
    },
    {
        name: 'Viet Nam',
        alpha3: 'VNM',
        countryCode: '704',
    },
    {
        name: 'Virgin Islands (British)',
        alpha3: 'VGB',
        countryCode: '092',
    },
    {
        name: 'Virgin Islands (U.S.)',
        alpha3: 'VIR',
        countryCode: '850',
    },
    {
        name: 'Wallis and Futuna',
        alpha3: 'WLF',
        countryCode: '876',
    },
    {
        name: 'Western Sahara',
        alpha3: 'ESH',
        countryCode: '732',
    },
    {
        name: 'Yemen',
        alpha3: 'YEM',
        countryCode: '887',
    },
    {
        name: 'Zambia',
        alpha3: 'ZMB',
        countryCode: '894',
    },
    {
        name: 'Zimbabwe',
        alpha3: 'ZWE',
        countryCode: '716',
    },
];
export const diallingCodes = [
    {
        name: 'Afghanistan',
        dial_code: '+93',
        code: 'AF',
    },
    {
        name: 'Aland Islands',
        dial_code: '+358',
        code: 'AX',
    },
    {
        name: 'Albania',
        dial_code: '+355',
        code: 'AL',
    },
    {
        name: 'Algeria',
        dial_code: '+213',
        code: 'DZ',
    },
    {
        name: 'AmericanSamoa',
        dial_code: '+1684',
        code: 'AS',
    },
    {
        name: 'Andorra',
        dial_code: '+376',
        code: 'AD',
    },
    {
        name: 'Angola',
        dial_code: '+244',
        code: 'AO',
    },
    {
        name: 'Anguilla',
        dial_code: '+1264',
        code: 'AI',
    },
    {
        name: 'Antarctica',
        dial_code: '+672',
        code: 'AQ',
    },
    {
        name: 'Antigua and Barbuda',
        dial_code: '+1268',
        code: 'AG',
    },
    {
        name: 'Argentina',
        dial_code: '+54',
        code: 'AR',
    },
    {
        name: 'Armenia',
        dial_code: '+374',
        code: 'AM',
    },
    {
        name: 'Aruba',
        dial_code: '+297',
        code: 'AW',
    },
    {
        name: 'Australia',
        dial_code: '+61',
        code: 'AU',
    },
    {
        name: 'Austria',
        dial_code: '+43',
        code: 'AT',
    },
    {
        name: 'Azerbaijan',
        dial_code: '+994',
        code: 'AZ',
    },
    {
        name: 'Bahamas',
        dial_code: '+1242',
        code: 'BS',
    },
    {
        name: 'Bahrain',
        dial_code: '+973',
        code: 'BH',
    },
    {
        name: 'Bangladesh',
        dial_code: '+880',
        code: 'BD',
    },
    {
        name: 'Barbados',
        dial_code: '+1246',
        code: 'BB',
    },
    {
        name: 'Belarus',
        dial_code: '+375',
        code: 'BY',
    },
    {
        name: 'Belgium',
        dial_code: '+32',
        code: 'BE',
    },
    {
        name: 'Belize',
        dial_code: '+501',
        code: 'BZ',
    },
    {
        name: 'Benin',
        dial_code: '+229',
        code: 'BJ',
    },
    {
        name: 'Bermuda',
        dial_code: '+1441',
        code: 'BM',
    },
    {
        name: 'Bhutan',
        dial_code: '+975',
        code: 'BT',
    },
    {
        name: 'Bolivia',
        dial_code: '+591',
        code: 'BO',
    },
    {
        name: 'Bosnia and Herzegovina',
        dial_code: '+387',
        code: 'BA',
    },
    {
        name: 'Botswana',
        dial_code: '+267',
        code: 'BW',
    },
    {
        name: 'Brazil',
        dial_code: '+55',
        code: 'BR',
    },
    {
        name: 'British Indian Ocean',
        dial_code: '+246',
        code: 'IO',
    },
    {
        name: 'Brunei Darussalam',
        dial_code: '+673',
        code: 'BN',
    },
    {
        name: 'Bulgaria',
        dial_code: '+359',
        code: 'BG',
    },
    {
        name: 'Burkina Faso',
        dial_code: '+226',
        code: 'BF',
    },
    {
        name: 'Burundi',
        dial_code: '+257',
        code: 'BI',
    },
    {
        name: 'Cambodia',
        dial_code: '+855',
        code: 'KH',
    },
    {
        name: 'Cameroon',
        dial_code: '+237',
        code: 'CM',
    },
    {
        name: 'Canada',
        dial_code: '+1',
        code: 'CA',
    },
    {
        name: 'Cape Verde',
        dial_code: '+238',
        code: 'CV',
    },
    {
        name: 'Cayman Islands',
        dial_code: '+ 345',
        code: 'KY',
    },
    {
        name: 'Central African Republic',
        dial_code: '+236',
        code: 'CF',
    },
    {
        name: 'Chad',
        dial_code: '+235',
        code: 'TD',
    },
    {
        name: 'Chile',
        dial_code: '+56',
        code: 'CL',
    },
    {
        name: 'China',
        dial_code: '+86',
        code: 'CN',
    },
    {
        name: 'Christmas Island',
        dial_code: '+61',
        code: 'CX',
    },
    {
        name: 'Cocos (Keeling) Islands',
        dial_code: '+61',
        code: 'CC',
    },
    {
        name: 'Colombia',
        dial_code: '+57',
        code: 'CO',
    },
    {
        name: 'Comoros',
        dial_code: '+269',
        code: 'KM',
    },
    {
        name: 'Congo',
        dial_code: '+242',
        code: 'CG',
    },
    {
        name: 'Congo',
        dial_code: '+243',
        code: 'CD',
    },
    {
        name: 'Cook Islands',
        dial_code: '+682',
        code: 'CK',
    },
    {
        name: 'Costa Rica',
        dial_code: '+506',
        code: 'CR',
    },
    {
        name: "Cote d'Ivoire",
        dial_code: '+225',
        code: 'CI',
    },
    {
        name: 'Croatia',
        dial_code: '+385',
        code: 'HR',
    },
    {
        name: 'Cuba',
        dial_code: '+53',
        code: 'CU',
    },
    {
        name: 'Cyprus',
        dial_code: '+357',
        code: 'CY',
    },
    {
        name: 'Czech Republic',
        dial_code: '+420',
        code: 'CZ',
    },
    {
        name: 'Denmark',
        dial_code: '+45',
        code: 'DK',
    },
    {
        name: 'Djibouti',
        dial_code: '+253',
        code: 'DJ',
    },
    {
        name: 'Dominica',
        dial_code: '+1767',
        code: 'DM',
    },
    {
        name: 'Dominican Republic',
        dial_code: '+1849',
        code: 'DO',
    },
    {
        name: 'Ecuador',
        dial_code: '+593',
        code: 'EC',
    },
    {
        name: 'Egypt',
        dial_code: '+20',
        code: 'EG',
    },
    {
        name: 'El Salvador',
        dial_code: '+503',
        code: 'SV',
    },
    {
        name: 'Equatorial Guinea',
        dial_code: '+240',
        code: 'GQ',
    },
    {
        name: 'Eritrea',
        dial_code: '+291',
        code: 'ER',
    },
    {
        name: 'Estonia',
        dial_code: '+372',
        code: 'EE',
    },
    {
        name: 'Ethiopia',
        dial_code: '+251',
        code: 'ET',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        dial_code: '+500',
        code: 'FK',
    },
    {
        name: 'Faroe Islands',
        dial_code: '+298',
        code: 'FO',
    },
    {
        name: 'Fiji',
        dial_code: '+679',
        code: 'FJ',
    },
    {
        name: 'Finland',
        dial_code: '+358',
        code: 'FI',
    },
    {
        name: 'France',
        dial_code: '+33',
        code: 'FR',
    },
    {
        name: 'French Guiana',
        dial_code: '+594',
        code: 'GF',
    },
    {
        name: 'French Polynesia',
        dial_code: '+689',
        code: 'PF',
    },
    {
        name: 'Gabon',
        dial_code: '+241',
        code: 'GA',
    },
    {
        name: 'Gambia',
        dial_code: '+220',
        code: 'GM',
    },
    {
        name: 'Georgia',
        dial_code: '+995',
        code: 'GE',
    },
    {
        name: 'Germany',
        dial_code: '+49',
        code: 'DE',
    },
    {
        name: 'Ghana',
        dial_code: '+233',
        code: 'GH',
    },
    {
        name: 'Gibraltar',
        dial_code: '+350',
        code: 'GI',
    },
    {
        name: 'Greece',
        dial_code: '+30',
        code: 'GR',
    },
    {
        name: 'Greenland',
        dial_code: '+299',
        code: 'GL',
    },
    {
        name: 'Grenada',
        dial_code: '+1473',
        code: 'GD',
    },
    {
        name: 'Guadeloupe',
        dial_code: '+590',
        code: 'GP',
    },
    {
        name: 'Guam',
        dial_code: '+1671',
        code: 'GU',
    },
    {
        name: 'Guatemala',
        dial_code: '+502',
        code: 'GT',
    },
    {
        name: 'Guinea',
        dial_code: '+224',
        code: 'GN',
    },
    {
        name: 'Guinea-Bissau',
        dial_code: '+245',
        code: 'GW',
    },
    {
        name: 'Guyana',
        dial_code: '+595',
        code: 'GY',
    },
    {
        name: 'Haiti',
        dial_code: '+509',
        code: 'HT',
    },
    {
        name: 'Holy See',
        dial_code: '+379',
        code: 'VA',
    },
    {
        name: 'Honduras',
        dial_code: '+504',
        code: 'HN',
    },
    {
        name: 'Hong Kong',
        dial_code: '+852',
        code: 'HK',
    },
    {
        name: 'Hungary',
        dial_code: '+36',
        code: 'HU',
    },
    {
        name: 'Iceland',
        dial_code: '+354',
        code: 'IS',
    },
    {
        name: 'India',
        dial_code: '+91',
        code: 'IN',
    },
    {
        name: 'Indonesia',
        dial_code: '+62',
        code: 'ID',
    },
    {
        name: 'Iran',
        dial_code: '+98',
        code: 'IR',
    },
    {
        name: 'Iraq',
        dial_code: '+964',
        code: 'IQ',
    },
    {
        name: 'Ireland',
        dial_code: '+353',
        code: 'IE',
    },
    {
        name: 'Israel',
        dial_code: '+972',
        code: 'IL',
    },
    {
        name: 'Italy',
        dial_code: '+39',
        code: 'IT',
    },
    {
        name: 'Jamaica',
        dial_code: '+1876',
        code: 'JM',
    },
    {
        name: 'Japan',
        dial_code: '+81',
        code: 'JP',
    },
    {
        name: 'Jordan',
        dial_code: '+962',
        code: 'JO',
    },
    {
        name: 'Kazakhstan',
        dial_code: '+77',
        code: 'KZ',
    },
    {
        name: 'Kenya',
        dial_code: '+254',
        code: 'KE',
    },
    {
        name: 'Kiribati',
        dial_code: '+686',
        code: 'KI',
    },
    {
        name: 'North Korea',
        dial_code: '+850',
        code: 'KP',
    },
    {
        name: 'South Korea',
        dial_code: '+82',
        code: 'KR',
    },
    {
        name: 'Kuwait',
        dial_code: '+965',
        code: 'KW',
    },
    {
        name: 'Kyrgyzstan',
        dial_code: '+996',
        code: 'KG',
    },
    {
        name: 'Laos',
        dial_code: '+856',
        code: 'LA',
    },
    {
        name: 'Latvia',
        dial_code: '+371',
        code: 'LV',
    },
    {
        name: 'Lebanon',
        dial_code: '+961',
        code: 'LB',
    },
    {
        name: 'Lesotho',
        dial_code: '+266',
        code: 'LS',
    },
    {
        name: 'Liberia',
        dial_code: '+231',
        code: 'LR',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        dial_code: '+218',
        code: 'LY',
    },
    {
        name: 'Liechtenstein',
        dial_code: '+423',
        code: 'LI',
    },
    {
        name: 'Lithuania',
        dial_code: '+370',
        code: 'LT',
    },
    {
        name: 'Luxembourg',
        dial_code: '+352',
        code: 'LU',
    },
    {
        name: 'Macao',
        dial_code: '+853',
        code: 'MO',
    },
    {
        name: 'Macedonia',
        dial_code: '+389',
        code: 'MK',
    },
    {
        name: 'Madagascar',
        dial_code: '+261',
        code: 'MG',
    },
    {
        name: 'Malawi',
        dial_code: '+265',
        code: 'MW',
    },
    {
        name: 'Malaysia',
        dial_code: '+60',
        code: 'MY',
    },
    {
        name: 'Maldives',
        dial_code: '+960',
        code: 'MV',
    },
    {
        name: 'Mali',
        dial_code: '+223',
        code: 'ML',
    },
    {
        name: 'Malta',
        dial_code: '+356',
        code: 'MT',
    },
    {
        name: 'Marshall Islands',
        dial_code: '+692',
        code: 'MH',
    },
    {
        name: 'Martinique',
        dial_code: '+596',
        code: 'MQ',
    },
    {
        name: 'Mauritania',
        dial_code: '+222',
        code: 'MR',
    },
    {
        name: 'Mauritius',
        dial_code: '+230',
        code: 'MU',
    },
    {
        name: 'Mayotte',
        dial_code: '+262',
        code: 'YT',
    },
    {
        name: 'Mexico',
        dial_code: '+52',
        code: 'MX',
    },
    {
        name: 'Micronesia',
        dial_code: '+691',
        code: 'FM',
    },
    {
        name: 'Moldova',
        dial_code: '+373',
        code: 'MD',
    },
    {
        name: 'Monaco',
        dial_code: '+377',
        code: 'MC',
    },
    {
        name: 'Mongolia',
        dial_code: '+976',
        code: 'MN',
    },
    {
        name: 'Montenegro',
        dial_code: '+382',
        code: 'ME',
    },
    {
        name: 'Montserrat',
        dial_code: '+1664',
        code: 'MS',
    },
    {
        name: 'Morocco',
        dial_code: '+212',
        code: 'MA',
    },
    {
        name: 'Mozambique',
        dial_code: '+258',
        code: 'MZ',
    },
    {
        name: 'Myanmar',
        dial_code: '+95',
        code: 'MM',
    },
    {
        name: 'Namibia',
        dial_code: '+264',
        code: 'NA',
    },
    {
        name: 'Nauru',
        dial_code: '+674',
        code: 'NR',
    },
    {
        name: 'Nepal',
        dial_code: '+977',
        code: 'NP',
    },
    {
        name: 'Netherlands',
        dial_code: '+31',
        code: 'NL',
    },
    {
        name: 'Netherlands Antilles',
        dial_code: '+599',
        code: 'AN',
    },
    {
        name: 'New Caledonia',
        dial_code: '+687',
        code: 'NC',
    },
    {
        name: 'New Zealand',
        dial_code: '+64',
        code: 'NZ',
    },
    {
        name: 'Nicaragua',
        dial_code: '+505',
        code: 'NI',
    },
    {
        name: 'Niger',
        dial_code: '+227',
        code: 'NE',
    },
    {
        name: 'Nigeria',
        dial_code: '+234',
        code: 'NG',
    },
    {
        name: 'Niue',
        dial_code: '+683',
        code: 'NU',
    },
    {
        name: 'Norfolk Island',
        dial_code: '+672',
        code: 'NF',
    },
    {
        name: 'Northern Mariana Islands',
        dial_code: '+1670',
        code: 'MP',
    },
    {
        name: 'Norway',
        dial_code: '+47',
        code: 'NO',
    },
    {
        name: 'Oman',
        dial_code: '+968',
        code: 'OM',
    },
    {
        name: 'Pakistan',
        dial_code: '+92',
        code: 'PK',
    },
    {
        name: 'Palau',
        dial_code: '+680',
        code: 'PW',
    },
    {
        name: 'Palestinian Territory',
        dial_code: '+970',
        code: 'PS',
    },
    {
        name: 'Panama',
        dial_code: '+507',
        code: 'PA',
    },
    {
        name: 'Papua New Guinea',
        dial_code: '+675',
        code: 'PG',
    },
    {
        name: 'Paraguay',
        dial_code: '+595',
        code: 'PY',
    },
    {
        name: 'Peru',
        dial_code: '+51',
        code: 'PE',
    },
    {
        name: 'Philippines',
        dial_code: '+63',
        code: 'PH',
    },
    {
        name: 'Pitcairn',
        dial_code: '+872',
        code: 'PN',
    },
    {
        name: 'Poland',
        dial_code: '+48',
        code: 'PL',
    },
    {
        name: 'Portugal',
        dial_code: '+351',
        code: 'PT',
    },
    {
        name: 'Puerto Rico',
        dial_code: '+1939',
        code: 'PR',
    },
    {
        name: 'Qatar',
        dial_code: '+974',
        code: 'QA',
    },
    {
        name: 'Romania',
        dial_code: '+40',
        code: 'RO',
    },
    {
        name: 'Russia',
        dial_code: '+7',
        code: 'RU',
    },
    {
        name: 'Rwanda',
        dial_code: '+250',
        code: 'RW',
    },
    {
        name: 'Reunion',
        dial_code: '+262',
        code: 'RE',
    },
    {
        name: 'Saint Barthelemy',
        dial_code: '+590',
        code: 'BL',
    },
    {
        name: 'Saint Helena',
        dial_code: '+290',
        code: 'SH',
    },
    {
        name: 'Saint Kitts and Nevis',
        dial_code: '+1869',
        code: 'KN',
    },
    {
        name: 'Saint Lucia',
        dial_code: '+1758',
        code: 'LC',
    },
    {
        name: 'Saint Martin',
        dial_code: '+590',
        code: 'MF',
    },
    {
        name: 'Saint Pierre and Miquelon',
        dial_code: '+508',
        code: 'PM',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        dial_code: '+1784',
        code: 'VC',
    },
    {
        name: 'Samoa',
        dial_code: '+685',
        code: 'WS',
    },
    {
        name: 'San Marino',
        dial_code: '+378',
        code: 'SM',
    },
    {
        name: 'Sao Tome and Principe',
        dial_code: '+239',
        code: 'ST',
    },
    {
        name: 'Saudi Arabia',
        dial_code: '+966',
        code: 'SA',
    },
    {
        name: 'Senegal',
        dial_code: '+221',
        code: 'SN',
    },
    {
        name: 'Serbia',
        dial_code: '+381',
        code: 'RS',
    },
    {
        name: 'Seychelles',
        dial_code: '+248',
        code: 'SC',
    },
    {
        name: 'Sierra Leone',
        dial_code: '+232',
        code: 'SL',
    },
    {
        name: 'Singapore',
        dial_code: '+65',
        code: 'SG',
    },
    {
        name: 'Slovakia',
        dial_code: '+421',
        code: 'SK',
    },
    {
        name: 'Slovenia',
        dial_code: '+386',
        code: 'SI',
    },
    {
        name: 'Solomon Islands',
        dial_code: '+677',
        code: 'SB',
    },
    {
        name: 'Somalia',
        dial_code: '+252',
        code: 'SO',
    },
    {
        name: 'South Africa',
        dial_code: '+27',
        code: 'ZA',
    },
    {
        name: 'South Sudan',
        dial_code: '+211',
        code: 'SS',
    },
    {
        name: 'South Georgia',
        dial_code: '+500',
        code: 'GS',
    },
    {
        name: 'Spain',
        dial_code: '+34',
        code: 'ES',
    },
    {
        name: 'Sri Lanka',
        dial_code: '+94',
        code: 'LK',
    },
    {
        name: 'Sudan',
        dial_code: '+249',
        code: 'SD',
    },
    {
        name: 'Suriname',
        dial_code: '+597',
        code: 'SR',
    },
    {
        name: 'Svalbard and Jan Mayen',
        dial_code: '+47',
        code: 'SJ',
    },
    {
        name: 'Swaziland',
        dial_code: '+268',
        code: 'SZ',
    },
    {
        name: 'Sweden',
        dial_code: '+46',
        code: 'SE',
    },
    {
        name: 'Switzerland',
        dial_code: '+41',
        code: 'CH',
    },
    {
        name: 'Syrian Arab Republic',
        dial_code: '+963',
        code: 'SY',
    },
    {
        name: 'Taiwan',
        dial_code: '+886',
        code: 'TW',
    },
    {
        name: 'Tajikistan',
        dial_code: '+992',
        code: 'TJ',
    },
    {
        name: 'Tanzania',
        dial_code: '+255',
        code: 'TZ',
    },
    {
        name: 'Thailand',
        dial_code: '+66',
        code: 'TH',
    },
    {
        name: 'Timor-Leste',
        dial_code: '+670',
        code: 'TL',
    },
    {
        name: 'Togo',
        dial_code: '+228',
        code: 'TG',
    },
    {
        name: 'Tokelau',
        dial_code: '+690',
        code: 'TK',
    },
    {
        name: 'Tonga',
        dial_code: '+676',
        code: 'TO',
    },
    {
        name: 'Trinidad and Tobago',
        dial_code: '+1868',
        code: 'TT',
    },
    {
        name: 'Tunisia',
        dial_code: '+216',
        code: 'TN',
    },
    {
        name: 'Turkey',
        dial_code: '+90',
        code: 'TR',
    },
    {
        name: 'Turkmenistan',
        dial_code: '+993',
        code: 'TM',
    },
    {
        name: 'Turks and Caicos Islands',
        dial_code: '+1649',
        code: 'TC',
    },
    {
        name: 'Tuvalu',
        dial_code: '+688',
        code: 'TV',
    },
    {
        name: 'Uganda',
        dial_code: '+256',
        code: 'UG',
    },
    {
        name: 'Ukraine',
        dial_code: '+380',
        code: 'UA',
    },
    {
        name: 'United Arab Emirates',
        dial_code: '+971',
        code: 'AE',
    },
    {
        name: 'United Kingdom',
        dial_code: '+44',
        code: 'GB',
    },
    {
        name: 'United States',
        dial_code: '+1',
        code: 'US',
    },
    {
        name: 'Uruguay',
        dial_code: '+598',
        code: 'UY',
    },
    {
        name: 'Uzbekistan',
        dial_code: '+998',
        code: 'UZ',
    },
    {
        name: 'Vanuatu',
        dial_code: '+678',
        code: 'VU',
    },
    {
        name: 'Republic of Venezuela',
        dial_code: '+58',
        code: 'VE',
    },
    {
        name: 'Vietnam',
        dial_code: '+84',
        code: 'VN',
    },
    {
        name: 'Virgin Islands, British',
        dial_code: '+1284',
        code: 'VG',
    },
    {
        name: 'Virgin Islands, U.S.',
        dial_code: '+1340',
        code: 'VI',
    },
    {
        name: 'Wallis and Futuna',
        dial_code: '+681',
        code: 'WF',
    },
    {
        name: 'Yemen',
        dial_code: '+967',
        code: 'YE',
    },
    {
        name: 'Zambia',
        dial_code: '+260',
        code: 'ZM',
    },
    {
        name: 'Zimbabwe',
        dial_code: '+263',
        code: 'ZW',
    },
];
