import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import es from "./translations/es.json";
import us from "./translations/us.json";
import ca from "./translations/ca.json";
import fr from "./translations/fr.json";
import mx from './translations/mx.json'

i18n.use(initReactI18next).init(
  {
    fallbackLng: {
      us: ['en'],
      ca: ['en'],
      fr: ['en'],
      mx: ['es'],
      es: ['en'],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { translation: en },
      es: { translation: es },
      us: { translation: us },
      ca: { translation: ca },
      fr: { translation: fr },
      mx: { translation: mx }
    },
  },
  (err) => {
    if (err) {
      console.error("Error loading translation files", err); // eslint-disable-line
      return;
    }
  }
);

export default i18n;
