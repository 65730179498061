import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { PaymentContext } from '../PaymentStore';

import { CARDIFF_CASTLE } from '../../../constants';
import { checkAbvContainsFood } from '../../../utils/checkAbvContainsFood';
import { SelectedOrderType } from '../types.d';

import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import useFormatCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import { getStripeMinimumOrderValue } from '../../../utils/getStripeMinimumOrderValue';
import { checkTimeslotAgaintsMenuTimings } from '../../../utils/checkTimeslotAgaintsMenuTimings';
import useAPI from '../../../yoello-lib/modules/API/useAPI';
import APIExtensions from '../../../yoello-lib/modules/API/APIDefs';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';
import { setSelectedMenuType } from '../../../yoello-lib/modules/redux/actions/consumer.actions';
import { useCheckIfOrderTypeSelected } from './useCheckIfOrderTypeSelected';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';

const useCanPaymentBeProcessed = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const group = useSelector((state: any) => state.Group);
    const Venue = useSelector((state: any) => state.Venue);
    const cart = useSelector((state: any) => state.Cart);
    const cartItems = useSelector((state: any) => state.Cart);
    const AvailableMenus = useSelector((state: any) => state.AvailableMenus);
    const orderType = useSelector((state: any) => state.SelectedMenuType);
    const { retrieveAccessToken } = useAuthenticationContext();
    const { callAPI } = useAPI(APIExtensions.consumerVenue, retrieveAccessToken, {
        query: Venue.venue_nickname,
    });

    const {
        selectedTime,
        selectedOrderType,
        selectedTable,
        totalPrice,
        deliveryAddress,
        subTotalCost,
        totalDiscounts,
        orderOnly,
    } = useContext(PaymentContext);

    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const { renderOrderTypeDrawer } = useCheckIfOrderTypeSelected();
    const { formatCurrency } = useFormatCurrency();
    const canPaymentBeProcessed = async () => {
        if (!orderOnly) {
            renderOrderTypeDrawer();
        }
        const updatedVenue = await callAPI(HTTPMethods.GET);
        const minPriceMap = {
            COLLECTION: 'min_collection_price',
            HOME_DELIVERY: 'min_delivery_price',
            TABLE_DELIVERY: 'min_dine_in_price',
            COLLECTION_POINT_PICKUP: 'min_pickup_now_price',
        };
        const orderTypeEnabled = {
            TABLE_DELIVERY: 'table_enabled',
            HOME_DELIVERY: 'delivery_enabled',
            COLLECTION: 'pickup_enabled',
            COLLECTION_POINT_PICKUP: 'pickup_now_enabled',
        };

        const stripeMinimumOrderValue = getStripeMinimumOrderValue(Venue.currency_iso);
        let errMsg = t('Payment.Errors.PaymentCouldNotProceed');

        if (Venue?.is_busy) {
            // if venue is busy should show the error message
            errMsg = t('Errors.VenueIsBusy', {
                venue_name: Venue?.venue_name,
            });
        } else if (selectedOrderType === null) {
            errMsg = t('Payment.Errors.SelectOrderType');
        } else if (!updatedVenue?.[orderTypeEnabled[orderType]]) {
            errMsg = t('Payment.Errors.SelectOrderTypeNotAvailable');
            dispatch(setSelectedMenuType(null));
        } else if ((totalPrice < stripeMinimumOrderValue || totalDiscounts > subTotalCost) && !orderOnly) {
            errMsg = t('Payment.Errors.BelowMinimum', {
                price: `${stripeMinimumOrderValue}${Venue.currency_iso === 'GBP' ? 'p' : 'c'}`,
            });
        } else if (totalPrice < updatedVenue[minPriceMap[selectedOrderType]]) {
            errMsg = t('ShopCart.Errors.CartLessThan', {
                price: formatCurrency(updatedVenue[minPriceMap[selectedOrderType]]),
            }) as string;
        } else if (selectedOrderType === SelectedOrderType.TABLE && selectedTable === null) {
            errMsg = t('Payment.Errors.WithoutVenueArea');
        } else if (selectedOrderType === SelectedOrderType.CLICK && selectedTime === null) {
            errMsg = t('Payment.Errors.WithoutCollectionTime');
        } else if (
            selectedOrderType === SelectedOrderType.DELIVERY &&
            (selectedTime === null ||
                deliveryAddress.postcode.length === 0)
        ) {
            errMsg = t('Payment.Errors.WithoutDeliveryInfo');
        } else if (group?.abv_food_threshold && group?.nickname === CARDIFF_CASTLE) {
            if (!checkAbvContainsFood(cart, group?.abv_food_threshold)) {
                const price = formatCurrency(group?.abv_food_threshold);
                errMsg = t('Payment.Errors.CartMustContainMin', {
                    price: price,
                });
                // NEEDS currency localization
            } else {
                return true;
            }
        } else if (checkTimeslotAgaintsMenuTimings(selectedTime, cartItems, AvailableMenus).length) {
            errMsg = t('Payment.Errors.TimeSlotInvalidForMenu');
        } else {
            return true;
        }
        sendSnackbarMessage(errMsg as string, 'error');
        return false;
    };

    return {
        canPaymentBeProcessed,
    };
};

export default useCanPaymentBeProcessed;
