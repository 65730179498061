import React from 'react';
import { useSelector } from 'react-redux';

import { calculateInclusiveTax, calculateAdditiveTax } from '../../../yoello-lib/modules/utils/TaxController';

import { setTransactionFee, setServiceTax, setServiceInclusiveTax } from '../reducer/PaymentActions';

import { ITaxType } from '../../../yoello-lib/modules/interfaces/ITaxes';

const useFeesAndTaxes = () => {
    const venue = useSelector((state: any) => state.Venue);

    const setFeesAndTaxes = (
        totalPrice: number,
        transactionFee: number,
        tipTotal: number,
        dispatch: React.Dispatch<any>,
    ) => {
        let pence = 0;
        let percentage = 0;
        let taxType;
        let taxPercentage = [];
        if (venue?.venue_fees_and_taxes && venue?.venue_fees_and_taxes['SERVICE']) {
            const service = venue?.venue_fees_and_taxes['SERVICE'];
            pence = service.fee_amount;
            percentage = service.fee_percentage;
            for (const taxItem of service.taxes) {
                taxType = taxItem?.tax_inclusion_type;
                taxPercentage.push(taxItem.tax_percentage);
            }
        } else if (venue?.service_charge_pence_amount || venue?.service_charge_percentage_amount) {
            pence = venue.service_charge_pence_amount;
            percentage = venue.service_charge_percentage_amount;
        }
        const serviceCharge = pence + (percentage * (totalPrice - (transactionFee + tipTotal))) / 100;

        if (taxType === ITaxType.ADDITIVE) {
            const serviceChargeTax = taxPercentage.reduce((prevValue, nextItem) => {
                const calc = calculateAdditiveTax(nextItem, serviceCharge);
                return prevValue + calc;
            }, 0);
            dispatch(setServiceTax(serviceChargeTax));
        } else {
            const serviceChargeTax = taxPercentage.reduce((prevValue, nextItem) => {
                const calc = calculateInclusiveTax(serviceCharge, nextItem);
                return prevValue + calc;
            }, 0);
            dispatch(setServiceInclusiveTax(serviceChargeTax));
        }
        dispatch(setTransactionFee(serviceCharge));
    };
    return {
        setFeesAndTaxes,
    };
};

export default useFeesAndTaxes;
