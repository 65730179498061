import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from './ProductDetailsDrawer';
import UnmountDrawerWrapper from './UnmountDrawerWrapper';

interface IDrawerParentProps extends IDrawerState {
    closeDrawer: () => void;
    anchor: anchors;
    ID: string;
}
function DrawerParent({
    Component,
    props,
    isOpen,
    closeDrawer,
    anchor,
    ID,
    onCloseFunction,
    isProductDetailsDrawer,
}: IDrawerParentProps) {
    function onClose() {
        if (onCloseFunction && typeof onCloseFunction === 'function') {
            onCloseFunction();
        }
        closeDrawer();
    }
    return (
        <>
            {!isProductDetailsDrawer ? (
                <SwipeableDrawer
                    anchor={anchor}
                    open={isOpen}
                    onClose={onClose}
                    onOpen={() => null}
                    disableSwipeToOpen={true}
                    disableDiscovery={true}
                    id={ID}
                    transitionDuration={350}
                    data-component-name="SwipableDrawer"
                >
                    {Component !== null && Component !== undefined && (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <UnmountDrawerWrapper closeDrawer={closeDrawer}>
                                <Component {...props} handleCloseModal={closeDrawer} />
                            </UnmountDrawerWrapper>
                        </div>
                    )}
                </SwipeableDrawer>
            ) : (
                <Drawer isVisible={isOpen} onClose={onClose}>
                    {Component !== null && Component !== undefined && (
                        <>
                            <UnmountDrawerWrapper closeDrawer={closeDrawer}>
                                <Component {...props} handleCloseModal={closeDrawer} />
                            </UnmountDrawerWrapper>
                        </>
                    )}
                </Drawer>
            )}
        </>
    );
}

export default DrawerParent;
