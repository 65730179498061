import React from 'react';
import { useVerifyView } from './useVerifyView';
import { TextField } from '../../Widgets/TextField';
import DynamicForm from '../../../yoello-lib/modules/dynamicForms/DynamicForms';
import Styles from '../styles.module.scss';
import useGetLoginParams from '../useGetLoginParams';
import { useTranslation } from 'react-i18next';
interface IEmailVerification {
    password: string;
}
function EmailConfirmation({ password }: IEmailVerification) {
    const { username } = useGetLoginParams();
    const { formRows, verifyEmailCode } = useVerifyView(username!, password);
    const { t } = useTranslation();
    return (
        <div className={Styles.Login}>
            <div>
                <TextField
                    className={Styles.Text1}
                    text={{
                        key: 'Login.Texts.emailVerification',
                    }}
                />
                <p className={Styles.Text2}>
                    {t('Login.Texts.youremail')}
                    <br />
                    <strong>{username}</strong>{' '}
                </p>
            </div>
            <DynamicForm
                formRows={formRows}
                formKeyName="verify-view"
                useFormOptions={{
                    mode: 'onBlur',
                    reValidateMode: 'onChange',
                }}
                onHandleSubmit={verifyEmailCode}
            />
        </div>
    );
}

export default EmailConfirmation;
