import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { _updateItemCount, _deleteCartItem, _removeFromCart } from '../../../redux/actions';
import { useHistory } from 'react-router-dom';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { useTranslation } from 'react-i18next';
import Styles from '../shopList.module.scss';
import { useMenu } from '../../Menu/MenuContext';
import { setDisabledItems } from '../../Menu/state/Menu.Actions';
import { useMultiVendorBasket } from '../../../customHooks/useMultiVendorBasket';
import { MultiVendorBasketCartList } from './MultiVendorBasketCartList';
import { CartItem } from './CartItem';

interface ICartListProps {
    cartItems: any[];
    isOpen: boolean;
}

function CartList({ cartItems, isOpen }: ICartListProps) {
    const dispatch = useDispatch<any>();
    const { t } = useTranslation();
    const history = useHistory<{ disabledItems: string[] }>();
    const {
        state: { disabledItems: menuDisabledItems },
        dispatch: contextDispatch,
    } = useMenu();
    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const { isMultiVendorBasket } = useMultiVendorBasket();
    const removeItemFromList = (deletedItems) => {
        for (let i = 0; i < deletedItems.length; i++) {
            cartItems.forEach((cartItem) => {
                if (cartItem.menuItem.menu_item_id === deletedItems[i])
                    dispatch(_removeFromCart({ menuItemId: cartItem.menuItem.menu_item_id }));
            });
        }
        sendSnackbarMessage(t('ShopCart.Errors.DeletedItems').toString(), 'error');
    };

    useEffect(() => {
        const disabledItems = history.location.state?.disabledItems ?? menuDisabledItems;
        if (isOpen && disabledItems?.length > 0) {
            let i = 0;
            for (; i < disabledItems.length; i++) {
                const element = document.getElementById(disabledItems[i]);
                if (element) {
                    setTimeout(() => {
                        element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center',
                        });
                        element.classList.add(Styles.deletedItem);
                    }, 500 * (i + 1));
                }
            }
            if (i >= disabledItems.length) {
                setTimeout(() => {
                    removeItemFromList(disabledItems);
                    cartItems.forEach((cartItem) => {
                        const element = document.getElementById(cartItem.menuItem.menu_item_id);
                        if (element) {
                            element.classList.remove(Styles.deletedItem);
                        }
                    });
                }, 900 * i);
                contextDispatch(setDisabledItems([]));
            }
        }
        // eslint-disable-next-line
    }, [history.location.state, isOpen]);

    function updateProduct(item, itemCount: number) {
        if (itemCount <= 0) {
            const element = document.getElementById(item.menuItem.menu_item_id);
            if (element) {
                element.classList.add(Styles.deletedFromCart);
            }
            setTimeout(() => {
                dispatch(_deleteCartItem({ index: item.index }));
                cartItems.forEach((cartItem) => {
                    const element = document.getElementById(cartItem.menuItem.menu_item_id);
                    if (element) {
                        element.classList.remove(Styles.deletedFromCart);
                    }
                });
            }, 300);
        } else {
            dispatch(
                _updateItemCount({
                    index: item.index,
                    updateCount: itemCount,
                }),
            );
        }
    }
    return isMultiVendorBasket ? (
        <MultiVendorBasketCartList cartItems={cartItems} updateProduct={updateProduct} />
    ) : (
        <div className="MiddleSection" style={{ paddingBottom: '3.75rem' }}>
            {cartItems.map((cartItem, index) => (
                <CartItem
                    cartItem={cartItem}
                    index={index}
                    updateProduct={updateProduct}
                    key={cartItem.menuItem.menu_item_id}
                />
            ))}
        </div>
    );
}

export default React.memo(CartList);
