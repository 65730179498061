import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMultiVendorBasket } from '../../../customHooks/useMultiVendorBasket';

export function useNavigateBack() {
    const history = useHistory();
    const Venue = useSelector((state: any) => state.Venue);
    const { id } = useParams<{ id: string }>();
    const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
    const { isGroupVenue } = useMultiVendorBasket();

    useEffect(() => {
        if (!isGroupVenue && (id !== Venue.venue_nickname || !SelectedMenuType)) {
            history.push('/v/' + id);
        }
        //eslint-disable-next-line
    }, []);
}
