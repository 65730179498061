import makeStyles from '@material-ui/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import useColors from '../../customHooks/useColors';
import ICommonFormProps from '../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import { generateRegister } from '../../yoello-lib/modules/dynamicForms/utils';

export interface ITextInput extends ICommonFormProps {
    Component: React.FunctionComponent<any>;
    label: string;
    inputOptions?: {
        type: string;
    };
}

const TextInput = (props: ITextInput) => {
    const { keyName, label } = props;
    const { primaryBorderColor, useDarkTheme } = useColors();

    const useStyles = makeStyles({
        root: {},
        label: {
            width: '100%',
        },
        labelText: {
            color: '#626262',
            fontSize: '0.875rem',
            lineHeight: '2rem',
        },
        input: {
            border: 'none',
            outline: 'unset',
            width: '100%',
            position: 'relative',
            '& .MuiInput-root': {
                border: `1px solid ${useDarkTheme ? primaryBorderColor : '#F9F9F9'}`,
                backgroundColor: '#F9F9F9',
                transition: '0.25s ease-in-out',
                borderRadius: '0.5rem',
                width: '100%',
                display: 'block',
                boxSizing: 'border-box',
                '&.Mui-error': {
                    border: '1px solid #F44336',
                },
                '&.Mui-focused': {
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${primaryBorderColor}`,
                },
                '& input': {
                    padding: '1.6rem',
                    boxSizing: 'border-box',
                },
            },
        },
    });
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <label className={classes.label}>
                <span className={classes.labelText}>{label}</span>
                <TextField
                    //@ts-ignore
                    {...generateRegister(props)}
                    name={keyName}
                    className={classes.input}
                    placeholder={label}
                    InputProps={{ disableUnderline: true }}
                    // inputRef={props.register}
                    type={props.inputOptions?.type}
                />
            </label>
        </div>
    );
};

export default TextInput;
