import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getVenueTimeSlotsList } from '../../../Api.js';

import { PaymentContext } from '../PaymentStore';
import { SelectedOrderType } from '../types.d';
import { CARDIFF_CASTLE } from '../../../constants';
import {
    setDeliveryPrice,
    addDeliveryTimes,
    setPostcodeCheckStatus,
    setDeliveryInclusiveTax,
    setDeliveryTax,
    setIsLoading,
} from '../reducer/PaymentActions';
import { checkPostCodeInDeliveryRadius } from '../../../utils/checkPostCodeInDeliveryRadius';
import { postcodeCheckStatusType } from '../../Widgets/PostCodeChecker/PostCodeChecker';
import { ITaxType } from '../../../yoello-lib/modules/interfaces/ITaxes';
import { calculateAdditiveTax, calculateInclusiveTax } from '../../../yoello-lib/modules/utils/TaxController';
import { _SetDeliveryDistance, _SetDeliveryPrice } from '../../../redux/actions/index.js';
import { useTranslation } from 'react-i18next';

const useDelivery = () => {
    const { selectedOrderType, deliveryAddress, dispatch, deliveryPrice } = useContext(PaymentContext);
    const venue = useSelector((state: any) => state.Venue);
    const group = useSelector((state: any) => state.Group);
    // This is from the main app redux store
    const DeliveryPrice = useSelector((state: any) => state.DeliveryPrice);
    const DeliveryDistance = useSelector((state: any) => state.DeliveryDistance);
    const dispatcher = useDispatch<any>();
    const { t } = useTranslation();

    // useEffect(() => {
    //     addGMapsScript();
    // }, []);

    useEffect(() => {
        setPriceForDelivery();
        // eslint-disable-next-line
    }, [venue, group, selectedOrderType, deliveryAddress, dispatch, deliveryPrice]);

    useEffect(() => {
        setDeliveryTimes();
        if (dispatch && selectedOrderType === SelectedOrderType.DELIVERY) {
            dispatch(setDeliveryPrice(DeliveryPrice));
        }
        // eslint-disable-next-line
    }, [DeliveryPrice, dispatch, selectedOrderType]);

    useEffect(() => {
        if (
            venue.delivery_radius_km &&
            DeliveryDistance > venue.delivery_radius_km &&
            dispatch &&
            selectedOrderType === SelectedOrderType.DELIVERY
        ) {
            dispatch(setPostcodeCheckStatus(postcodeCheckStatusType.OutOfDeliveryRadius));
        }
        // eslint-disable-next-line
    }, [DeliveryDistance, venue.delivery_radius_km, dispatch]);

    async function setDeliveryTimes() {
        if (venue && selectedOrderType !== SelectedOrderType.TABLE) {
            try {
                if (!selectedOrderType) return;
                const TimeSlotsData = await getVenueTimeSlotsList(venue.venue_nickname, selectedOrderType);
                let TimeSlotsList = TimeSlotsData.data.data.time_slots;
                const hasOneAvailableTime = TimeSlotsList.find((item) => item.collection || item.delivery);
                const ASAP_DELIVERY = {
                    collection: true,
                    delivery: true,
                    time_utc: hasOneAvailableTime.time_utc,
                    venue_time: t('Misc.ASAP'),
                    asap_delivery_requested: true,
                };
                if (TimeSlotsList.length > 0 && venue.asap_delivery_enabled && hasOneAvailableTime) {
                    TimeSlotsList = [ASAP_DELIVERY, ...TimeSlotsData.data.data.time_slots];
                }
                console.log(TimeSlotsList, 'TIME SLOTS');

                dispatch(addDeliveryTimes(TimeSlotsList));
            } catch (error) {}
        }
    }

    const setDeliveryChargeBasedOnAddress = (distance: number) => {
        dispatcher(_SetDeliveryDistance(distance));
        const deliveryFees = venue.delivery_fees?.fees;
        let deliveryCharge = 0;
        deliveryFees?.forEach((deliveryFee) => {
            if (distance >= deliveryFee.distance_from) {
                deliveryCharge = deliveryFee.amount;
            }
        });
        // This sets it to the main app redux
        dispatcher(_SetDeliveryPrice(deliveryCharge));
    };

    const setPriceForDelivery = () => {
        if (
            (group?.service_charge_enabled && group?.nickname === CARDIFF_CASTLE) ||
            selectedOrderType === SelectedOrderType.DELIVERY
        ) {
            let deliveryCharge = deliveryPrice;
            let taxType;
            let taxPercentage = [];
            if (venue?.venue_fees_and_taxes && venue?.venue_fees_and_taxes['DELIVERY']) {
                const delivery = venue?.venue_fees_and_taxes['DELIVERY'];
                for (const taxItems of delivery.taxes) {
                    taxType = taxItems?.tax_inclusion_type;
                    taxPercentage.push(taxItems.tax_percentage);
                }
            }
            if (taxType === ITaxType.ADDITIVE) {
                const deliveryTax = taxPercentage.reduce((prevValue, nextItem) => {
                    const calc = calculateAdditiveTax(nextItem, deliveryCharge);
                    return prevValue + calc;
                }, 0);
                dispatch(setDeliveryTax(deliveryTax));
            } else {
                const deliveryTax = taxPercentage.reduce((prevValue, nextItem) => {
                    const calc = calculateInclusiveTax(deliveryCharge, nextItem);
                    return prevValue + calc;
                }, 0);
                dispatch(setDeliveryInclusiveTax(deliveryTax));
            }
            dispatch(setDeliveryPrice(deliveryCharge));
        }
    };

    const checkPostCodeRadius = async () => {
        dispatch(setIsLoading(true));
        const userDeliveryAddress = `${venue.address_country}, ${deliveryAddress.street_address}, ${deliveryAddress.city}, ${deliveryAddress.postcode}`;
        await checkPostCodeInDeliveryRadius(
            venue,
            userDeliveryAddress,
            venue.delivery_radius_km,
            (InRadius: any) => {
                if (InRadius) {
                    dispatch(setPostcodeCheckStatus(postcodeCheckStatusType.InDeliveryRadius));
                } else {
                    dispatch(setPostcodeCheckStatus(postcodeCheckStatusType.OutOfDeliveryRadius));
                }
                dispatch(setIsLoading(false));
            },
            setDeliveryChargeBasedOnAddress,
        );
    };

    return {
        setPriceForDelivery,
        setDeliveryTimes,
        checkPostCodeRadius,
        setDeliveryChargeBasedOnAddress,
    };
};

export default useDelivery;
