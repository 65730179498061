import moment from 'moment';

const format = 'YYYY-MM-DD HH:mm';
/**Creates time intervals between two dates
 * We are using dates because times might lap over days
 * intervalInMinutes is defaulted to 15
 */
export default function createTimeIntervals(startDate: string, endDate: string, intervalInMinutes = 15): string[] {
    let start = moment(startDate, format);
    const end = moment(endDate, format);
    /**Sets the start time to the interval */
    start.minutes(Math.ceil(start.minutes() / intervalInMinutes) * intervalInMinutes);

    const intervalsArray = [];

    let current = moment(start);
    /**While current is less than end keep adding times */
    while (current <= end) {
        intervalsArray.push(current.format('HH:mm'));
        current.add(intervalInMinutes, 'minutes');
    }

    return intervalsArray;
}
