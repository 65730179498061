import { HTTPMethods } from '../yoello-lib/modules/API/API';
import APIExtensions from '../yoello-lib/modules/API2/APIDefs';
import { useAPIV2 } from '../yoello-lib/modules/API2/useAPIv2';
import { useAuthenticationContext } from '../yoello-lib/modules/auth/AuthenticationContext';

const useConsumerLocations = () => {
    const { currentUser, setCurrentUser } = useAuthenticationContext();
    const { callAPI } = useAPIV2(APIExtensions.consumers, {
        onMountDisabled: true,
    });

    async function updateUserLocations(
        address: Record<any, any>,
        callbackFunction?: () => void,
        consumerOverride?: Record<any, any>,
        isDelivery?: boolean,
    ) {
        const consumer = consumerOverride ?? currentUser;
        // Not updating if it's not delivery or the user is not fully logged in yet
        if (isDelivery === false || consumer.authenticationFlowType) {
            return;
        }

        try {
            const addressID = consumer?.addresses[0]?.id;
            const result = await callAPI(addressID ? HTTPMethods.PATCH : HTTPMethods.POST, {
                body: address,
                query: addressID ? consumer?.id + '/locations/' + addressID : consumer?.id + '/locations',
            });
            console.log(result, 'RESULT');
            if (result.isSuccessful) {
                setCurrentUser({ ...consumer, addresses: [result.data] });
            }
            if (callbackFunction) {
                callbackFunction();
            }
            return result;
        } catch (error) {
            console.log(error);
            
            return error;
        }
    }
    return {
        updateUserLocations,
        currentUser,
    };
};

export default useConsumerLocations;
