import { useCallback, useMemo } from 'react';
import { useCurrencyState } from '../hooks/useCurrencyState';
import { getBrowserLocales } from './getBrowserLocales';
interface ICurrencyConfig {
    locale: string;
    options: Intl.NumberFormatOptions;
}

export type FormatCurrency = (
    valueInSubunit: number,
    subunit?: boolean,
    currencyIso?: string,
    formatOptions?: Intl.NumberFormatOptions,
) => string;
/**
 * Formats a value using locale currency data
 * @param valueInPence Value in the currency's smallest demonination
 */
function useFormatCurrency() {
    const currency = useCurrencyState();

    const setFormat = useCallback(
        (iso?: string, formatOptions?: Intl.NumberFormatOptions) => {
            const locale = getBrowserLocales();
            const dummyCurrencyConfig: ICurrencyConfig = {
                locale: locale,
                options: {
                    style: 'currency',
                    currency: iso || currency?.iso_code || 'GBP',
                    // currencyDisplay: 'narrowSymbol',
                    ...formatOptions,
                },
            };
            // Pretend to get it from the server
            const config = dummyCurrencyConfig;
            return new Intl.NumberFormat(config.locale, config.options);
        },
        [currency],
    );

    const formatter = useMemo<Intl.NumberFormat>(() => {
        return setFormat();
    }, [setFormat]);
    /**
     *
     * @param valueInSubunit value you're manipulation
     * @param subunit defaulted to true but is it a subunit
     * @param currencyIso pass through if you want to define a new currency ISO without changing the global state
     * @param formatOptions Pass through if you want to extend the format options without changing the global
     */
    const formatCurrency = useCallback(
        (valueInSubunit: number, subunit = true, currencyIso?: string, formatOptions?: Intl.NumberFormatOptions) => {
            let format = formatter;
            /**Incase the format hasn't set yet we'll just provide one
             * or if we want to overide the format
             */
            if (currencyIso || formatOptions || !format) {
                format = setFormat(currencyIso, formatOptions);
            }
            let val = valueInSubunit;
            if (subunit) {
                val = valueInSubunit * 0.01;
            }
            return format.format(val);
        },
        [formatter, setFormat],
    );

    return {
        formatCurrency,
        currency,
    };
}

export function formatCurrencyNoIcon(valueInSubunit: number, subunit = true) {
    const value = subunit ? valueInSubunit * 0.01 : valueInSubunit;
    return value.toFixed(2);
}

export default useFormatCurrency;
