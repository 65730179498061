import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { AlertStatusCard } from '../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard';

const useStyles = makeStyles({
    root: {
        margin: '0 0.5rem',
        '& >div:first-child': {
            borderRadius: '12px',
        },
        '& .MuiAlert-message': {
            color: '#F65164',
        },
    },
});

const EmptyMinExtras = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <AlertStatusCard
                alertTitle={t('Menu.extras.emptyList')}
                alertMessage={t('Menu.extras.speakToStaff')}
                alertProps={{
                    color: 'error',
                    severity: 'error',
                }}
            />
        </div>
    );
};

export default EmptyMinExtras;
