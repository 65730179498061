import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';

interface Props {
    min: number;
    max: number;
    internalName: string;
}

const useStyles = makeStyles({
    root: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.6875rem',
        color: '#757575',
        // padding: "0.5rem 0.8rem",
        marginLeft: '0.9375rem',
        marginBottom: '0.4375rem',
        padding: '0 1.5rem',
    },
});

const ExtrasQuantityTitle = ({ min, max, internalName }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!min && !max) {
        return null;
    } else if (max === 0 || !max) {
        return (
            <p className={classes.root} id={internalName}>
                <Trans i18nKey="Menu.MinText" components={{ bold: <strong /> }} />
                <strong data-component-name={'extraMin'}>{min}</strong> {min > 1 ? t('Menu.Items') : t('Menu.Item')}:
            </p>
        );
    } else if (min === 0 || !min) {
        return (
            <p className={classes.root}>
                <Trans i18nKey="Menu.MaxText" components={{ bold: <strong /> }} />
                <strong>{max}</strong> {max > 1 ? t('Menu.Items') : t('Menu.Item')}:
            </p>
        );
    } else if (min && max) {
        return (
            <p className={classes.root} id={internalName}>
                <Trans i18nKey="Menu.MinMaxTextPartOne" components={{ bold: <strong /> }} />
                <strong data-component-name={'extraMin'}>{min}</strong>
                {min > 1 ? t('Menu.Items') : t('Menu.Item')}
                <Trans i18nKey="Menu.MinMaxTextPartTwo" components={{ bold: <strong /> }} />
                <strong>{max}</strong>
                {max > 1 ? t('Menu.Items') : t('Menu.Item')}:
            </p>
        );
    } else return null;
};

export default ExtrasQuantityTitle;
