import { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import MainTheme from '../../../yoello-lib/modules/themes/MainTheme';
import ThemeWrapper from '../../../yoello-lib/modules/themes/ThemeWrapper';

import { ReactComponent as EmailErrorSVG } from '../../../imges/searchError.svg';
import { useHistory } from 'react-router-dom';
import { LOGINV2 } from '../../../constants/URL.const';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import useColors from '../../../customHooks/useColors';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    },
    imageWrapper: {
        padding: '3rem',
        boxSizing: 'border-box',
    },
    content: {
        padding: '0 2rem',
    },
    contentHeader: {
        fontSize: '1.4rem',
        fontWeight: 600,
        marginBottom: '1rem',
    },
    contentBody: {
        fontSize: '1rem',
    },
    button: {
        width: '100%',
        marginBottom: '1rem',
        textTransform: 'none',
        fontWeight: 600,
        padding: '0.7rem',
        borderRadius: '10px',
    },
    buttonSecondary: {
        backgroundColor: '#F9F9F9',
        fontWeight: 500,
    },
});

const EmailErrorView = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [username, setUsername] = useState();
    const { primaryIconColor } = useColors();

    const onSendNewLinkClick = () => {
        if (!username) {
            goToLogin();
        }
    };

    const goToLogin = () => {
        history.push(LOGINV2);
    };

    useEffect(() => {
        if (localStorage.login_username) {
            setUsername(localStorage.login_username);
        }
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.imageWrapper}>
                <EmailErrorSVG fill={primaryIconColor} />
            </div>
            <div className={classes.content}>
                <h4 className={classes.contentHeader}>{t('Login.emailError.wentWrong')}</h4>
                <p className={classes.contentBody}>{t('Login.emailError.tryRequesting')}</p>
                <ThemeWrapper theme={MainTheme}>
                    <Grid container spacing={1}>
                        {username && (
                            <Grid item xs={12} md={6} sm={12}>
                                <Button
                                    className={classes.button}
                                    onClick={onSendNewLinkClick}
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                >
                                    {t('Login.emailError.sendNew')}
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6} sm={12}>
                            <Button
                                className={`${classes.button} ${username ? classes.buttonSecondary : ''}`}
                                onClick={goToLogin}
                                variant="contained"
                                color={username ? 'default' : 'primary'}
                                disableElevation
                            >
                                {t(username ? 'Login.emailError.signIn' : 'Login.emailError.backToLogin')}
                            </Button>
                        </Grid>
                    </Grid>
                </ThemeWrapper>
            </div>
        </div>
    );
};

export default EmailErrorView;
