import { useMemo } from 'react';
import { useDrawer } from '../../Drawer/DrawerContext';
import Styles from '../groupPage.module.scss';
import { TextField } from '../../Widgets/TextField';
import SelectOrderTypeDrawer from './SelectOrderTypeDrawer';
import { getOrderTypeInfo } from '../util';
import useColors from '../../../customHooks/useColors';
import { useSelector } from 'react-redux';

export function SelectOrderTypeButton() {
    const { openDrawer, closeDrawer } = useDrawer()!;
    const { primaryIconColor } = useColors();
    const orderType = useSelector((state: any) => state.SelectedMenuType);
    const availableOrderTypes = useSelector((state: any) => state.Group.availableOrderTypes);
    const { Icon, title } = useMemo(() => {
        const { Icon, title } = getOrderTypeInfo(orderType ?? '');
        if (!orderType && availableOrderTypes.length > 1) {
            onSelectOrderTypeClick(openDrawer, closeDrawer);
        }
        return {
            Icon,
            title,
        };
        // eslint-disable-next-line
    }, [orderType, availableOrderTypes]);

    return (
        <div className={Styles.SelectOrderTypeWrapper} data-component-name={'orderTypesFilterButton'}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    if (availableOrderTypes.length > 1) {
                        onSelectOrderTypeClick(openDrawer, closeDrawer);
                    }
                }}
            >
                {Icon && <Icon fill={primaryIconColor} />}
                <TextField text={{ key: title }} />
                <span className={'consumer-bottom-arrow'} />
            </div>
        </div>
    );
}

export function onSelectOrderTypeClick(openDrawer, closeDrawer) {
    if (openDrawer) {
        openDrawer(
            SelectOrderTypeDrawer,
            {
                onCloseDrawer: closeDrawer,
            },
            'bottom',
            'GroupPageFilterDrawer',
        );
    }
}
