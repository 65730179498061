export const SET_POSITION = 'SET_POSITION';

export function setPosition(pos: number) {
    return {
        type: SET_POSITION,
        payload: pos,
    };
}

export const SET_SCROLL = 'SET_SCROLL';

export function setIsScrolled(isScrolled: boolean) {
    return {
        type: SET_SCROLL,
        payload: isScrolled,
    };
}

export const SET_SEARCH_TEXT = 'SEARCH_TEXT';

export function setSearchText(searchText: string) {
    return {
        type: SET_SEARCH_TEXT,
        payload: searchText,
    };
}

export const SET_MENU_TAB = 'SET_MENU_TAB';

export function setMenuTab(pos: number) {
    return {
        type: SET_MENU_TAB,
        payload: pos,
    };
}

export const SET_MENU_KEYS = 'SET_MENU_KEYS';

export function setMenuKeys(keys: string[]) {
    return {
        type: SET_MENU_KEYS,
        payload: keys,
    };
}

export const SET_LIST_HEIGHT = 'SET_LIST_HEIGHT';

export function setListHeight(height: number) {
    return {
        type: SET_LIST_HEIGHT,
        payload: height,
    };
}

export const SET_CART_DATA = 'SET_CART_DATA';

export function setCartData(totalCount: number, totalAmount: number) {
    return {
        type: SET_CART_DATA,
        payload: { totalCount, totalAmount },
    };
}

export const SET_DISABLED_ITEMS = 'SET_DISABLED_ITEMS';

export function setDisabledItems(ids: string[]) {
    return {
        type: SET_DISABLED_ITEMS,
        payload: ids,
    };
}
