import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '../../Widgets/Button';
import ICommonFormProps from '../../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        height: 'fit-content',
        backgroundColor: '#FFFFFF',
        width: '100%',
        maxWidth: '768px',
        padding: '1rem 2.5rem',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(52, 53, 66, 0.12)',
        zIndex: 999,

        '& >div': {
            marginBottom: '1rem',
            textAlign: 'center',
        },

        '@media (max-height: 500px)': {
            position: 'relative',
            boxShadow: 'none',
            padding: 0,
        },
        '@media (min-width: 768px)': {
            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: '2.5rem',
            borderBottomLeftRadius: '2.5rem',
            borderBottomRightRadius: '2.5rem',

            '& >div': {
                marginBottom: 'unset',
                textAlign: 'unset',
            },
            '& button': {
                width: '11.375rem',
            },
        },
    },
});

interface IFixedContinue extends ICommonFormProps {}
const FixedContinue = (props: IFixedContinue) => {
    const classes = useStyles();
    const { Component, title, icon, loading } = props.formProps!;
    return (
        <div className={classes.root}>
            <Component />
            <Button
                type="submit"
                iconClassName={icon}
                isYoelloBtn
                title={title}
                isLoading={loading}
                defaultBackground
                className="dark-theme-uninvert-svg-div"
            />
        </div>
    );
};

export default FixedContinue;
