interface ICookie {
    name: string;
    value: string;
}
export function getCookie(cookieName: string) {
    const cookieString = window.document.cookie;
    const cookies: string[][] = cookieString.split(';').map((cookiePair) => cookiePair.split('='));
    const filterCookieMatches = cookies.filter((cookie) => cookie[0].trimStart() === cookieName);
    if (filterCookieMatches.length > 0) {
        return filterCookieMatches[0][1];
    }
    return null;
}

export function setCookie(cookies: ICookie[], exdays: number) {
    cookies.forEach(({ name, value }: ICookie) => {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
    });
}
export function deleteCookie(cookies: string[]) {
    cookies.forEach((name: string) => {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 2000 00:00:01 GMT;';
    });
}
