import React from 'react';

import FacebookButton from './Buttons/FacebookButton';
import GoogleButton from './Buttons/GoogleButton';
import { useAuthenticationContext, Provider } from '../../../../yoello-lib/modules/auth/AuthenticationContext';

const SocialLoginButtons = () => {
    const { federatedSignIn } = useAuthenticationContext();

    return (
        <div>
            <GoogleButton onClick={() => federatedSignIn(Provider.Google)} />
            <FacebookButton onClick={() => federatedSignIn(Provider.Facebook)} />
        </div>
    );
};

export default SocialLoginButtons;
