import { IVenue } from '../typings/IVenue.d';

export async function checkPostCodeInDeliveryRadius(
    Venue: IVenue,
    destinationAddress: string | object,
    deliveryRadius: number,
    afterResponseReturned: (flag: boolean) => void,
    setDeliveryChargeBasedOnAddress: (distance: number) => void,
) {
    let flag: boolean;
    let VenueAddress;
    const venuePickupLocation = Venue.venue_pickup_location;
    if(venuePickupLocation && venuePickupLocation[0]?.latitude && venuePickupLocation[0]?.longitude && Venue.address_country === "GB"){
        VenueAddress = {
            lat: venuePickupLocation[0].latitude,
            lng: venuePickupLocation[0].longitude
        }
    }else if(Venue.address_country === "GB"){
        VenueAddress = `${Venue.address_country}, ${Venue.address_postcode}`
    }else{
        VenueAddress = `${Venue.address_country}, ${Venue.address_city}, ${Venue.location_name}, ${Venue.address_building}, ${Venue.address_postcode}`
    }
    // let VenueAddress = Venue.address_country === "GB" ? `${Venue.address_country}, ${Venue.address_postcode}` : `${Venue.address_country}, ${Venue.address_city}, ${Venue.location_name}, ${Venue.address_building}, ${Venue.address_postcode}`;
    // *** NEEDS TO BE REMOVED ONCE DELIVERY PHASE 3 IS DONE ***
    if(Venue.venue_nickname === "cornerhouse"){
        // @ts-ignore
        VenueAddress = {
            lat: 52.20955608633845,
            lng:0.14354621126694847
        }
    }
    // @ts-ignore
    var service = new google.maps.DistanceMatrixService();
    return await service.getDistanceMatrix(
        {
            origins: [VenueAddress],
            destinations: [destinationAddress],
            // @ts-ignore
            travelMode:  'DRIVING',
        },
        (res) => {
            const distance = res;
            const destinationDistanceInKm = distance?.rows[0]?.elements[0]?.distance?.value / 1000;
            const status = distance?.rows[0]?.elements[0]?.status === 'OK';

            if (status) {
                console.log(deliveryRadius, '= Delivery radius of Venue====================================');
                console.log(destinationDistanceInKm, '= Distance');
                console.log(status, '====================================');
                if (destinationDistanceInKm <= deliveryRadius || deliveryRadius === null) {
                    flag = true;
                } else {
                    flag = false;
                }
            } else {
                flag = false;
            }
            afterResponseReturned(flag);
            setDeliveryChargeBasedOnAddress(destinationDistanceInKm);
        },
    );
}
