import React from 'react';
import { priceToPounds } from '../../../utils/pricingCalculator';
import Styles from './orderDetails.module.scss';
import { TextField } from '../../Widgets/TextField';
import { PaymentDiscount } from './PaymentDiscount';
import { SectionHeader } from './SectionHeader';
import { Status } from '../../Widgets/OrderStatus';
import { PaymentMethods } from './PaymentMethods';
import { OrderOption } from '../../../typings/IPayment.d';
import { IOrders } from '../../../typings/IOrders.d';

const paymentDetailsRow = [
    {
        label: 'Payment.ItemsPrice',
        key: 'placed_order_gross',
        transform: (orderItem, key) => {
            return formatOrderCurrency(orderItem, key);
        },
    },
    {
        label: 'OrderHistory.Discount',
        key: 'discounts',
        transform: (orderItem, key) => {},
        Component: PaymentDiscount,
    },
    {
        label: 'OrderHistory.Tip',
        key: 'placed_order_gratuity',
        transform: (orderItem, key) => {
            return formatOrderCurrency(orderItem, key);
        },
    },
    {
        label: 'OrderHistory.ServiceCharge',
        key: 'placed_order_processing_charge',
        transform: (orderItem, key) => {
            return formatOrderCurrency(orderItem, key);
        },
    },
    {
        label: 'ShopCart.DeliveryCharge',
        key: 'placed_order_service_charge',
        transform: (orderItem, key) => {
            if (!orderItem[key]) return null;
            return formatOrderCurrency(orderItem, key);
        },
    },
    {
        label: 'OrderHistory.TotalVAT',
        key: 'placed_order_total_vat_amount',
        transform: (orderItem, key) => {
            return formatOrderCurrency(orderItem, key);
        },
    },
    {
        label: 'OrderHistory.PaymentMethod',
        key: 'placed_order_payment_method',
        transform: (orderItem, key) => {
            return orderItem[key] ? <PaymentMethods method={orderItem[key]} /> : null;
        },
    },
    {
        label: 'OrderHistory.TotalPrice',
        key: 'placed_order_total',
        transform: (orderItem, key) => {
            return formatOrderCurrency(orderItem, key);
        },
    },
];
const partialRefundRows = [
    {
        label: 'Payment.TotalRefund',
        key: 'placed_order_total_refund_value',
        transform: (orderItem, key) => {
            return formatOrderCurrency(orderItem, key);
        },
    },
    {
        label: 'Payment.TaxRefund',
        key: 'placed_order_tax_refund_value',
        transform: (orderItem, key) => {
            return formatOrderCurrency(orderItem, key);
        },
    },
];

export function PaymentDetails({ orderItem }: { orderItem: IOrders }) {
    function renderRows() {
        return paymentDetailsRow.map(({ key, label, transform, ...rest }) => {
            if (rest.Component) {
                return <rest.Component key={key} orderItem={orderItem} />;
            }

            return <PaymentRow key={key} label={label} value={transform(orderItem, key)} />;
        });
    }
    function partialRefundDetailsRows() {
        return (
            <div className={Styles.PartialRefundDetailsRow}>
                {partialRefundRows.map(({ key, label, transform }) => {
                    return <PaymentRow key={key} label={label} value={transform(orderItem, key)} />;
                })}
            </div>
        );
    }

    if (orderItem.placed_order_order_option === OrderOption.ORDER_ONLY) {
        return (
            <div className={`${Styles.PaymentDetails} ${Styles.OrderOnlyTotal}`}>
                <PaymentRow
                    key={'placed_order_total'}
                    label={'OrderHistory.TotalPrice'}
                    value={formatOrderCurrency(orderItem, 'placed_order_total')}
                />
            </div>
        );
    }
    return (
        <div className={Styles.PaymentDetails}>
            <SectionHeader label={'OrderHistory.PaymentTitle'} />
            {orderItem.placed_order_status === Status.PARTIALLY_REFUNDED && partialRefundDetailsRows()}
            {renderRows()}
        </div>
    );
}

function PaymentRow({ label, value, className = null }) {
    if (!value) return null;

    return (
        <div className={className ?? Styles.PaymentRow} key={label} data-component-name={label}>
            <TextField text={{ key: label }} dataComponentName={`${label}-title`} />
            <p data-component-name={`${label}-value`}>{value}</p>
        </div>
    );
}

export function formatOrderCurrency(orderItem, key) {
    const value = orderItem[key];
    return currencyFormat(orderItem, value);
}
export function currencyFormat(orderItem, value) {
    return `${orderItem.currency_symbol}${value !== null ? priceToPounds(value) : 0}`;
}
