import { useEffect, useState } from 'react';

import { sixDigitNumberCodeRegex } from '../../../yoello-lib/modules/dynamicForms/ValidationUtils';

import { VerificationInput } from './VerificationInput';
import { COGNITO_OAUTH_REDIRECT, LOGINV2_START } from '../../../constants/URL.const';

import IDynamicFormRow from '../../../yoello-lib/modules/dynamicForms/interfaces/IDynamicFormRow';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import { useHistory } from 'react-router-dom';
import SubmitButton from '../util/SubmitButton';
import FormLink from '../util/FormLink';
import { useTranslation } from 'react-i18next';

export function useVerifyView(username: string, password: string) {
    const { userLogout, confirmSignUp, resendSignUp, userLogin } = useAuthenticationContext();
    const { generateNavPath } = useGenerateNavPath();
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setIsLoading] = useState(false);
    const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
    const [formRows, setFormRows] = useState<IDynamicFormRow[]>([]);
    const [oneTimeCode, setOneTimeCode] = useState('');
    const { sendSnackbarMessage } = useSnackbarMessages();

    useEffect(() => {
        const inputRows: IDynamicFormRow[] = [
            {
                keyName: 'code',
                label: '',
                Component: VerificationInput,
                inputProperties: {
                    variant: 'outlined',
                },
                gridColumnOptions: { formCol: 12 },
                formProps: {
                    onChange: changeOneTimeCode,
                    value: oneTimeCode,
                },
                registerOptions: {
                    validate: (value: string) => {
                        return oneTimeCode.toString().trim().match(sixDigitNumberCodeRegex)
                            ? true
                            : (t('ForgotPassword.Errors.codeValidationError') as string);
                    },
                },
            },
            {
                keyName: 'resend_code',
                gridColumnOptions: { formCol: 12 },
                label: '',
                formProps: {
                    onClick: resendEmail,
                    title: t('Login.Texts.recieveEmail'),
                    subTitle: t('Login.Texts.ResendEmail'),
                },
                disabled: loading,
                Component: FormLink,
            },
            {
                keyName: 'confirm',
                gridColumnOptions: { formCol: 12 },
                label: 'Confirm',
                disabled: oneTimeCode.toString().length < 6,
                formProps: {
                    onSubmit: verifyEmailCode,
                    isLoading: isVerifyingEmail,
                },
                Component: SubmitButton,
            },
        ];
        setFormRows(inputRows);
        // eslint-disable-next-line
    }, [oneTimeCode, loading, isVerifyingEmail, username]);

    const resendEmail = async () => {
        if (loading) {
            sendSnackbarMessage(t('Login.Errors.alreadySentCode') as string, 'error');
            return;
        }
        try {
            setIsLoading(true);
            await resendSignUp(username);
            sendSnackbarMessage(t('Login.Errors.codeResent') as string, 'success');
        } catch (e) {
            sendSnackbarMessage(t('Login.Errors.ResendLoginFailed') as string, 'error');
            setIsLoading(false);
            await userLogout();
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 30000);
    };

    async function verifyEmailCode({ code }) {
        setIsVerifyingEmail(true);
        try {
            confirmSignUp(username, oneTimeCode.toString())
                .then(() => {
                    if (password) {
                        userLogin(username, password).then((res) => {
                            if (!res) {
                                history.push(
                                    generateNavPath(COGNITO_OAUTH_REDIRECT, {
                                        username,
                                    }),
                                );
                            } else {
                                sendSnackbarMessage(t('Login.Errors.IncorrectEmailOrPassword').toString(), 'error');
                                history.push(generateNavPath(LOGINV2_START));
                            }
                        });
                    } else {
                        sendSnackbarMessage(t('Login.Texts.accountVerification').toString(), 'success');
                        history.push(
                            generateNavPath(LOGINV2_START, {
                                username: username,
                                method: 'email',
                            }),
                        );
                    }
                })
                .catch((err: any) => {
                    if (err.message.toLowerCase().includes('invalid verification code')) {
                        sendSnackbarMessage(t('ForgotPassword.Errors.invalidCode').toString(), 'error');
                    } else if (err.message.toLowerCase().includes('attempt limit exceeded')) {
                        sendSnackbarMessage(t('ForgotPassword.Errors.attemptLimitExceeded').toString(), 'error');
                    }
                });
        } catch (error) {
            setIsVerifyingEmail(false);
            sendSnackbarMessage(t('Login.Errors.InvalidCode').toString(), 'error');
        }
    }

    function changeOneTimeCode(otp: string) {
        setOneTimeCode(otp);
    }

    return {
        formRows,
        resendEmail,
        verifyEmailCode,
    };
}
