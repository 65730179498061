import React, { useEffect, useState, useReducer } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
    LOGINV2_VERIFY,
    LOGIN_VERIFY,
    LOGIN,
    LOGINV2,
    LOGIN_START,
    LOGINV2_START,
    VENUE_MENU,
    VENUE,
    LOGIN_PASSWORD,
    LOGINV2_PASSWORD,
    LOGINV2_FORGOT_PASSWORD,
    LOGIN_FORGOT_PASSWORD,
    LOGIN_RESET_PASSWORD,
    LOGINV2_RESTE_PASSWORD,
    LOGIN_GUEST,
    LOGINV2_GUEST,
    GROUP,
    GROUP_MENU,
} from '../../constants/URL.const';

import LoginView from './LoginView';
import GuestView from './GuestView';
import VerifyView from './VerifyView';

import useGetToAndFromUrls from './useGetToAndFromUrls';

import useGenerateNavPath from '../../customHooks/useGenerateNavPath';
import useGetLoginParams from './useGetLoginParams';
import PageLoading from '../Page/PageLoading';
import { useAuthenticationContext } from '../../yoello-lib/modules/auth/AuthenticationContext';
import { PasswordView } from './PasswordView';
import ForgotPasswordView from './ForgotPassword/ForgotPasswordView';
import ResetPasswordView from './ForgotPassword/ResetPasswordView';
import { ILoginState, LoginReducer } from './state/Login.state';

const initalState: ILoginState = {
    password: '',
};

const LoginRouter = () => {
    const { verifyAuth } = useAuthenticationContext();
    const { generateNavPath } = useGenerateNavPath();
    const history = useHistory();
    const location = useLocation<locationState>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { method, username } = useGetLoginParams();
    const [state, dispatch] = useReducer(LoginReducer, initalState);
    const { toPath, isLoadingPaths } = useGetToAndFromUrls(
        'login',
        location.state?.area ? generateNavPath(GROUP) : generateNavPath(VENUE),
        location.state?.area ? generateNavPath(GROUP) : generateNavPath(VENUE),
    );

    const onIsNotAuthenticated = async (loading: boolean) => {
        setIsLoading(true);
        const user = await verifyAuth();
        if (!user) {
            history.push(
                generateNavPath(LOGINV2_START, {
                    method,
                    username,
                }),
                location.state,
            );
            setIsLoading(false);
        } else {
            if (!loading) {
                history.push(
                    toPath || location.state?.area ? generateNavPath(GROUP_MENU) : generateNavPath(VENUE_MENU),
                );
            }
        }
    };

    useEffect(() => {
        onIsNotAuthenticated(isLoadingPaths);
        //eslint-disable-next-line
    }, [isLoadingPaths]);

    return (
        <Switch>
            <Route
                exact
                path={[LOGIN_PASSWORD, LOGINV2_PASSWORD]}
                component={() => <PasswordView dispatch={dispatch} />}
            />
            <Route exact path={[LOGIN_FORGOT_PASSWORD, LOGINV2_FORGOT_PASSWORD]} component={ForgotPasswordView} />
            <Route exact path={[LOGIN_RESET_PASSWORD, LOGINV2_RESTE_PASSWORD]} component={ResetPasswordView} />
            <Route
                exact
                path={[LOGIN_VERIFY, LOGINV2_VERIFY]}
                component={() => <VerifyView password={state.password} />}
            />
            <Route exact path={[LOGIN_GUEST, LOGINV2_GUEST]} component={() => <GuestView />} />
            <Route
                exact
                path={[LOGIN_START, LOGINV2_START]}
                render={() => <LoginView isLoading={isLoadingPaths || isLoading} />}
            />

            <Route path={[LOGIN, LOGINV2]} component={PageLoading} />
        </Switch>
    );
};

export default LoginRouter;
