import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import ICommonFormProps from '../../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: '-1.3rem',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.9375rem',
        lineHeight: '1.25rem',
        color: '#4F3CC9',
        cursor: 'pointer',
    },
    disabled: {
        color: '#9e9e9e',
    },
});

export default function FormLink(props: ICommonFormProps) {
    const { root, disabled } = useStyles();
    return (
        <p className={`${root} ${props.disabled && disabled}`}>
            {props.formProps.title}
            <strong onClick={!props.disabled ? props.formProps.onClick : null}>{props.formProps.subTitle}</strong>
        </p>
    );
}
