import { useState } from 'react';

import useAPI from '../../../../yoello-lib/modules/API/useAPI';
import { HTTPMethods } from '../../../../yoello-lib/modules/API/API';
import APIExtensions from '../../../../yoello-lib/modules/API/APIDefs';
import { setIsUsingPaymentRequest } from '../../reducer/PaymentActions';
import { usePaymentState } from '../../PaymentStore';

const usePay360Card = () => {
    const { dispatch, retrieveToken } = usePaymentState();
    const [isLoading, setIsLoading] = useState(false);
    const { callAPI: getCardAPI } = useAPI<any>(APIExtensions.consumerPay360Cards, retrieveToken, {
        onMountDisabled: true,
    });

    const parseCardToDetails = (detailsItem: any) => {
        return {
            ...detailsItem,
            masked_card_number: detailsItem.last4,
            token: detailsItem.card_token,
        };
    };

    const getCard = async (token: string) => {
        setIsLoading(true);
        dispatch(setIsUsingPaymentRequest(true));
        try {
            const details = await getCardAPI(HTTPMethods.GET);
            setIsLoading(false);
            return parseCardToDetails(details);
        } catch (e) {}
    };

    return {
        getCard,
        parseCardToDetails,
        isLoading,
    };
};

export default usePay360Card;
