import moment from 'moment';

export const toUTCTime = (date: string | number, format?: string) => {
    return moment.utc(moment.duration(date).asMilliseconds()).format(format || 'HH:mm:ss');
};

export const convertToUTCTime = (date: string, format?: string) => {
    // parseZone to keep it in the timezone of the date itself
    return moment.parseZone(date).format(format || 'HH:mm');
};

export const toUTCDate = (date: string | Date, format?: string) => {
    return moment(date)
        .utc()
        .format(format || 'YYYY-MM-DDTHH:mm:ss');
};

export const toLocalTime = (date: string | Date, format?: string) => {
    return moment(date).format(format || 'h:mma');
};

export const toLocalDate = (date: string | Date, format?: string) => {
    return moment(date).format(format || 'D MMM YYYY');
};

export const toLocalDateTime = (date: string | Date, format?: string) => {
    return moment(date).format(format || 'DD-MM-YYYYTHH:mm:ss');
};
