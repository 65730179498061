import React from 'react';
import useCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import Styles from '../styles.module.scss';
import makeStyles from '@material-ui/styles/makeStyles';
import useColors from '../../../customHooks/useColors';

interface IProductPriveProps extends IPricingOptions {}

function ProductPrice({ option_price, option_bulk_qty, option_discount }: IProductPriveProps) {
    const { formatCurrency } = useCurrency();
    const formattedStandardPrice = formatCurrency(option_price);
    const formattedDiscountPrice = formatCurrency(option_price - (option_price * option_discount) / 100);
    const formattedBulkPrice = formatCurrency(option_price * option_bulk_qty);
    const formattedBulkDiscountPrice = formatCurrency(
        (option_price - (option_price * option_discount) / 100) * option_bulk_qty,
    );

    const { primaryBackgroundColor } = useColors();

    const useStyles = makeStyles({
        DiscountDiv: {
            background: primaryBackgroundColor,
            borderRadius: '2rem',
            width: '1.875rem',
            height: '1.125rem',
            textAlign: 'center',
            marginRight: '0.375rem',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.6875rem',
            color: '#212121',
        },
    });
    const classes = useStyles();

    if (option_bulk_qty) {
        if (option_discount) {
            return (
                <div className="Flex AlignCenter">
                    <div className={classes.DiscountDiv}>{option_discount}%</div>
                    <p className={Styles.PricingOptionOldPrice}>{formattedBulkPrice}</p>
                    <p className={Styles.PricingOptionCurrentPrice}>{formattedBulkDiscountPrice}</p>
                </div>
            );
        } else {
            return (
                <div className="Flex AlignCenter">
                    <p className={Styles.PricingOptionCurrentPrice}>{formattedBulkPrice}</p>
                </div>
            );
        }
    } else {
        if (option_discount) {
            return (
                <div className="Flex AlignCenter">
                    <div className={Styles.DiscountDiv}>{option_discount}%</div>
                    <p className={Styles.PricingOptionOldPrice}>{formattedStandardPrice}</p>
                    <p className={Styles.PricingOptionCurrentPrice}>{formattedDiscountPrice}</p>
                </div>
            );
        } else {
            return (
                <div className="Flex AlignCenter">
                    <p className={Styles.PricingOptionCurrentPrice}>{formattedDiscountPrice}</p>
                </div>
            );
        }
    }
}

export default React.memo(ProductPrice);
