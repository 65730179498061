import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getConsumer, ConsumerUpdatePreferences } from '../Api.js';
import { profileData } from '../Components/LoginV2/ProfileView/useSubmitProfile';
import { useAuthenticationContext } from '../yoello-lib/modules/auth/AuthenticationContext';
import IConsumerUser from '../yoello-lib/modules/auth/IConsumerUser';
import { setLanguage } from '../yoello-lib/modules/redux/actions/localization';
import { LOGIN } from '../constants/URL.const';
import setRedirectURLs from '../yoello-lib/modules/URL/setRedirectURLs';
import { VenueLanguages } from '../yoello-lib/modules/API/apiDefs/ConsumerAPIDefs';

export interface IUserData {
    getTokenOrLogout: () => Promise<any>;
    user: IConsumerUser | Record<any, any>;
    setUser: (user: Record<any, any>) => void;
    retrieveToken: () => Promise<any>;
    isAuthenticated: boolean;
    hasNoConsumer?: boolean;
}
export function useCurrentUserData(): IUserData {
    const { isAuthenticated, setCurrentUser, currentUser, retrieveAccessToken, userLogout } =
        useAuthenticationContext();

    const appLanguage = useSelector((state: any) => state.localization.language);
    const [hasNoConsumer, setHasNoConsumer] = useState<boolean>();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!history.location.pathname.includes(LOGIN)) {
            setRedirectURLs(history.location.pathname);
        }
        //eslint-disable-next-line
    }, [history.location]);

    useEffect(() => {
        const user = currentUser as IConsumerUser;
        if (isAuthenticated && !user?.user_details_set) {
            getConsumerAndSave();
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    async function getConsumerAndSave() {
        try {
            const token = await retrieveAccessToken();
            if (token) {
                try {
                    const consumer = await getConsumer(token);
                    if (consumer.status !== 204) {
                        setCurrentUser(consumer.data.data);
                        handleConsumerLanguageSettings(consumer.data.data, token);
                    }
                } catch (err) {
                    setHasNoConsumer(true);
                    console.log('not found');
                }
            } else {
                await userLogout();
            }
        } catch (error) {
            await userLogout();
        }
    }

    async function handleConsumerLanguageSettings(ConsumerData: profileData, token: string) {
        if (ConsumerData?.consumer_preferences?.language?.length) {
            dispatch(setLanguage(VenueLanguages[ConsumerData?.consumer_preferences?.language?.toUpperCase()]));
        } else {
            await ConsumerUpdatePreferences(
                {
                    language: appLanguage?.toLowerCase(),
                },
                token,
            );
        }
    }
    async function getTokenOrLogout() {
        let token = undefined;
        try {
            token = await retrieveAccessToken();
            if (token === undefined) {
                history.push(LOGIN);
            } else {
                return token;
            }
        } catch (error) {
            console.error('Something went wrong whilst getting auth token.', error);
            await userLogout();
        }
    }

    return {
        getTokenOrLogout,
        user: currentUser,
        setUser: setCurrentUser,
        retrieveToken: retrieveAccessToken,
        hasNoConsumer,
        isAuthenticated,
    };
}
