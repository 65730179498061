import { useEffect, useState } from 'react';
import IDynamicFormRow from '../../../yoello-lib/modules/dynamicForms/interfaces/IDynamicFormRow';
import { PasswordInput } from '../../Inputs';
import {
    defaultPasswordRegex as passwordRegex,
    sixDigitNumberCodeRegex,
} from '../../../yoello-lib/modules/dynamicForms/ValidationUtils';
import { VerificationInput } from '../VerifyView/VerificationInput';
import ResendCodeText from './ResendCodeText';
import FixedContinue from '../util/FixedContinue';
import { useTranslation } from 'react-i18next';

const useGenerateResetForm = (isLoading: boolean) => {
    const [resetFormRows, setResetFormRows] = useState(undefined);
    const { t } = useTranslation();
    const [oneTimeCode, setOneTimeCode] = useState('');

    const generatePasswordResetFormRows = () => {
        const defaultFormElement: Partial<IDynamicFormRow> = {
            inputProperties: {
                variant: 'outlined',
            },
            gridColumnOptions: { formCol: 12 },
            registerOptions: {
                required: true,
            },
        };

        return [
            {
                ...defaultFormElement,
                keyName: 'code',
                Component: VerificationInput,
                registerOptions: {
                    validate: (data: any) =>
                        oneTimeCode.toString().trim().match(sixDigitNumberCodeRegex)
                            ? true
                            : t('ForgotPassword.Errors.codeValidationError'),
                },
                formProps: {
                    onChange: changeOneTimeCode,
                    value: oneTimeCode,
                },
                label: t('ForgotPassword.Texts.validationCode'),
            },
            {
                ...defaultFormElement,
                keyName: 'password1',
                Component: PasswordInput,
                registerOptions: {
                    validate: (data: any) => {
                        if (!data) {
                            return t('errors.formerrors.isRequired', {
                                keyName: t('ForgotPassword.Texts.newPassword'),
                            });
                        } else if (data.trim().match(passwordRegex)) {
                            return true;
                        } else {
                            return t('ForgotPassword.Errors.passwordValidationError');
                        }
                    },
                },
                label: t('ForgotPassword.Texts.newPassword'),
            },
            {
                ...defaultFormElement,
                keyName: 'password2',
                Component: PasswordInput,
                registerOptions: {
                    validate: (data: any) => {
                        if (!data) {
                            return t('errors.formerrors.isRequired', {
                                keyName: t('ForgotPassword.Texts.confirmPassword'),
                            });
                        } else if (data.trim().match(passwordRegex)) {
                            return true;
                        } else {
                            return t('ForgotPassword.Errors.passwordValidationError');
                        }
                    },
                },
                label: t('ForgotPassword.Texts.confirmPassword'),
            },
            {
                keyName: 'resend_code_submit',
                formProps: {
                    Component: ResendCodeText,
                    title: 'ResetPassword',
                    loading: isLoading,
                },
                Component: FixedContinue,
            },
        ];
    };
    function changeOneTimeCode(otp: string) {
        setOneTimeCode(otp);
    }

    useEffect(() => {
        setResetFormRows(generatePasswordResetFormRows());
        // eslint-disable-next-line
    }, [isLoading, oneTimeCode]);

    return { resetFormRows, oneTimeCode };
};

export default useGenerateResetForm;
