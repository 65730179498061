import { useState } from 'react';
import { useSelector } from 'react-redux';
import { usePaymentState } from '../PaymentStore';
import { setDiscounts } from '../reducer/PaymentActions';
import { onKeyboardPressHandler } from '../../../utils/keyboard';
import { OnApplyMessageComponent } from './OnApplyMessageComponent';
import { OnApplyComponent } from './OnApplyComponent';
import { useHistory } from 'react-router-dom';
import { AppliedDiscounts } from './AppliedDiscounts';
import useCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import Styles from '../styles.module.scss';
import { getDiscountsCodeInfo, getGuestDiscountsCodeInfo } from '../../../Api.js';
import { BasketItemsMetaData, DiscountData, DiscountsApiData } from '../types.d';
import { LOGIN } from '../../../constants/URL.const';
import { APIError } from '../../../utils/APIErrorController';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { useSentry } from '../../../customHooks/useSentry';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import useGuestUserData from '../../../customHooks/useGuestUserData';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import useColors from '../../../customHooks/useColors';
import { useMultiVendorBasketView } from '../useMultiVendorBasketView';
import { useMultiVendorBasketDiscountCode } from './useMultiVendorBasketDiscountCode';
import { useMultiVendorBasket } from '../../../customHooks/useMultiVendorBasket';

export const discountsInitialValue = {
    code: '',
    message: '',
    value: null,
    error: false,
};

export enum DiscountType {
    ABSOLUTE = 'ABSOLUTE',
    PERCENTAGE = 'PERCENTAGE',
}
interface IDiscountComponent {
    subTotalCost: number;
    venueId?: string;
}
const DiscountsComponent = ({ subTotalCost, venueId }: IDiscountComponent) => {
    const { t } = useTranslation();
    const { formatCurrency } = useCurrency();
    const { retrieveAccessToken, isAuthenticated } = useAuthenticationContext();
    const Venue = useSelector((state: any) => state.Venue);
    const Venues = useSelector((state: any) => state.Group.venues);
    const { dispatch, discounts } = usePaymentState();
    const [isloading, setIsLoading] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');
    const history = useHistory();
    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const { captureException } = useSentry();
    const { consumerId, guestId, isGuest } = useGuestUserData();
    const { primaryBorderColor } = useColors();
    const { evaluateBasketItems } = useMultiVendorBasketView();
    const { isMultiVendorBasket } = useMultiVendorBasket();
    const { calculateMultiVendorBasketDiscount, basket } = useMultiVendorBasketDiscountCode(
        venueId,
        subTotalCost,
        setCode,
    );

    const useStyles = makeStyles({
        discountsInputWrapper: {
            '&:focus-within': {
                border: `1px solid ${primaryBorderColor} !important`,
            },
        },
    });
    function onChange(event) {
        const { value } = event.target;
        if (discounts.error) {
            dispatch(setDiscounts(discountsInitialValue));
        }
        setCode(value);
    }
    async function getCodeInfo() {
        setIsLoading(true);
        if (code) {
            const token = await retrieveAccessToken();
            const data = {
                venue_id: isMultiVendorBasket ? Venues?.[venueId]?.id : Venue.id,
                discount_code: code,
            };
            let discountApiData: DiscountsApiData;
            try {
                let response;
                if (isGuest && !isAuthenticated) {
                    response = await getGuestDiscountsCodeInfo({
                        ...data,
                        guest_session_id: guestId,
                        consumer_id: consumerId,
                    });
                } else {
                    response = await getDiscountsCodeInfo(token, data);
                }
                discountApiData = response.data.data;
            } catch (error) {
                captureException(error, { feature: 'place-order-discount' });
                let errMessage = t('ApiErrors.default') as string;
                if (error.response) {
                    const status = error.response.data.status;
                    if (status >= 400 && status < 500) {
                        if (status === 403) {
                            history.push(LOGIN);
                        } else {
                            const errors = error.response.data.data.errors;
                            errMessage = APIError(errors, t);
                        }
                    }
                }
                sendSnackbarMessage(errMessage, 'error');
            }
            if (venueId) {
                calculateMultiVendorBasketDiscount(discountApiData, code);
            } else {
                const discountData: DiscountData = discountApiData.discount;
                if (discountApiData.valid) {
                    let discountValue = discountData.value;
                    if (discountData.type === DiscountType.ABSOLUTE) {
                        discountValue = (discountData.value * 100) / subTotalCost;
                    }
                    if (discountData.minimum_order_value > subTotalCost) {
                        dispatch(
                            setDiscounts({
                                id: discountData.id,
                                code: code,
                                message: t('Payment.DiscountMinValidText', {
                                    price: formatCurrency(discountData.minimum_order_value),
                                }) as string,
                                value: 0,
                                error: true,
                                type: DiscountType.PERCENTAGE,
                            }),
                        );
                    } else {
                        dispatch(
                            setDiscounts({
                                id: discountData.id,
                                code: code,
                                message: '',
                                value: discountValue,
                                error: false,
                                type: DiscountType.PERCENTAGE,
                                absoluteValue: discountData.type === DiscountType.ABSOLUTE ? discountData.value : null,
                            }),
                        );
                        setCode('');
                    }
                } else {
                    dispatch(
                        setDiscounts({
                            code: '',
                            message: APIError([{ error_code: discountApiData.validation_error }], t),
                            value: 0,
                            error: true,
                        }),
                    );
                }
            }
        }
        setIsLoading(false);
    }

    function resetDiscountCode() {
        setCode('');
        if (venueId) {
            evaluateBasketItems(BasketItemsMetaData.DISCOUNT, discountsInitialValue, venueId);
        } else {
            dispatch(setDiscounts(discountsInitialValue));
        }
    }
    const classes = useStyles();

    return (
        <div className={Styles.TipsSectionDiv}>
            <div
                className={`${Styles.MessageInputDiv} ${Styles.discountsInputWrapper} ${
                    discounts.error ? [Styles.errorRedBorder, 'errorRedBorder'].join(' ') : undefined
                } ${classes.discountsInputWrapper}`}
                style={{
                    position: 'relative',
                }}
            >
                <input
                    data-component-name="DiscountInput"
                    type="text"
                    maxLength={12}
                    onKeyPress={onKeyboardPressHandler}
                    className={Styles.TipCardInput}
                    style={{
                        width: '100%',
                    }}
                    placeholder={t('Forms.AddDiscounts') as string}
                    onChange={onChange}
                    value={code}
                />
                <OnApplyComponent
                    isloading={isloading}
                    onClick={getCodeInfo}
                    reset={resetDiscountCode}
                    venueId={venueId}
                />
            </div>
            <OnApplyMessageComponent venueId={venueId} />
            {discounts.code && !discounts.error && <AppliedDiscounts />}
            {venueId && basket[venueId]?.discounts?.code && !basket[venueId]?.discounts?.error && (
                <AppliedDiscounts venueId={venueId} />
            )}
        </div>
    );
};

export default DiscountsComponent;
