import axios from 'axios';
import { getServiceEndpoints } from '../yoello-lib/modules/environments/services';
import { handleAxiosError } from '../yoello-lib/modules/API/APIError';

export function getFullURL() {
    const stage = process.env.REACT_APP_STAGE;
    const overwriteHost = process.env.REACT_APP_SERVICE_ENDPOINT;

    let services = getServiceEndpoints(stage, overwriteHost);
    return services['consumerAPI'];
}

export const URL = getFullURL();

const instance = axios.create({
    baseURL: URL,
    headers: {
        'content-type': 'application/json',
    },
    responseType: 'json',
});

instance.interceptors.response.use(undefined, handleAxiosError);
export default instance;
