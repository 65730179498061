import React from 'react';
import Button from '../../Widgets/Button';
import Styles from '../styles.module.scss';
import { setPosition } from '../state/ProductDetails.action';

interface IProductDetailsBackProps {
    position: number;
    isAllergyTags: boolean;
    isShownTags: boolean;
    dispatch: any;
    onClickShownTags: (isShown: boolean) => void;
}
export function ProductDetailsBack({
    position,
    isAllergyTags,
    isShownTags,
    dispatch,
    onClickShownTags,
}: IProductDetailsBackProps) {
    function goBack() {
        dispatch(setPosition(position - 1));
    }
    let backButton;
    if (position > 0) {
        backButton = (
            <Button
                iconClassName="consumer-left-arrow"
                onClick={goBack}
                dataComponentName={'BackButton'}
                style={{ color: '#AAB6D3', fontSize: '1.5rem', position: 'unset' }}
                isHeaderBtn
            />
        );
    }
    // if (isShownTags) {
    //   backButton = (
    //     <Button
    //       iconClassName="consumer-left-arrow"
    //       onClick={() => onClickShownTags(false)}
    //       dataComponentName={"BackButton"}
    //       style={{ color: "#AAB6D3", fontSize: "1.5rem", position: "unset" }}
    //       isHeaderBtn
    //     />
    //   );
    // } else
    if (isAllergyTags && position === 0 && !isShownTags) {
        backButton = (
            <Button
                onClick={() => onClickShownTags(true)}
                className={Styles.AllergensBtn}
                title={'Allergens'}
                dataComponentName="Allergens"
            />
        );
    }
    return <div>{backButton}</div>;
}
