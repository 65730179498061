import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextInput, PhoneInput } from '../../Inputs';
import IDynamicFormRow from '../../../yoello-lib/modules/dynamicForms/interfaces/IDynamicFormRow';
import useAPI from '../../../yoello-lib/modules/API/useAPI';
import { APIExtensions } from '../../../yoello-lib/modules/API/APIDefs';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';
import { isValidPhoneNumber } from 'libphonenumber-js';
import TermsCondition from '../ProfileView/TermsCondition';
import FixedContinue from '../util/FixedContinue';
import { SelectedOrderType } from '../../Payment/types.d';
import Description from './Description';
import { setCookie } from '../util/cookies';
import { GuestUser, ISessionData } from '../GuestView/types.d';
import { PAYMENT } from '../../../constants/URL.const';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApiVenueLanguages } from '../../../constants';
import { useMultiVendorBasket } from '../../../customHooks/useMultiVendorBasket';

export function useGuestView() {
    const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
    const { t } = useTranslation();
    const [formRows, setFormRows] = useState<IDynamicFormRow[]>([]);
    const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const venue = useSelector((state: any) => state.Venue);
    const group = useSelector((state: any) => state.Group);
    const { isMultiVendorBasket } = useMultiVendorBasket();
    const isoCode = useSelector((state: any) => state?.localization?.language?.toLowerCase());
    const history = useHistory();
    const hasPhone =
        SelectedMenuType === SelectedOrderType.COLLECTION_POINT ||
        SelectedMenuType === SelectedOrderType.CLICK ||
        SelectedMenuType === SelectedOrderType.DELIVERY;

    const { callAPI } = useAPI(APIExtensions.consumers, () => null, {
        query: 'guest_session',
        onMountDisabled: true,
    });
    useEffect(() => {
        const inputRows: IDynamicFormRow[] = [
            {
                keyName: 'guest_name',
                label: t('Forms.GuestName') as string,
                Component: TextInput,
                inputProperties: {
                    variant: 'outlined',
                },
                gridColumnOptions: { formCol: 12 },
                registerOptions: {
                    validate: (data: any) =>
                        data && data.trim().match(/^[a-z0-9,.'-_\s]+$/i)
                            ? true
                            : (t('ProfileBuilder.errors.first_name') as string),
                },
            },
            {
                keyName: 'guest_email',
                label: t('Forms.GuestEmail') as string,
                Component: TextInput,
                registerOptions: {
                    validate: (data: any) =>
                        data && data.trim().match(/^[^\s@]+@[^\s@.]+\.[^\s@]+$/)
                            ? true
                            : (t('ProfileBuilder.errors.contact_email') as string),
                },
            },
            {
                keyName: 'email_desc',
                label: '',
                gridColumnOptions: { formCol: 12 },
                formProps: {
                    text: 'emailDescription',
                },
                Component: Description,
            },
            {
                keyName: 'resend_code_submit',
                label: '',
                formProps: {
                    Component: TermsCondition,
                    title: 'Continue',
                    loading: isLoading,
                    icon: 'consumer-right-arrow',
                },
                Component: FixedContinue,
            },
        ];

        if (hasPhone && countryCode) {
            inputRows.splice(1, 0, {
                keyName: 'guest_phone',
                label: t('Forms.GuestMobile') as string,
                Component: PhoneInput,
                gridColumnOptions: { formCol: 12 },
                formProps: {
                    countryCode,
                },
                registerOptions: {
                    validate: (data: any) => {
                        if (data) {
                            return isValidPhoneNumber(`+${data}`)
                                ? true
                                : (t('ProfileBuilder.errors.contact_phone') as string);
                        } else return t('ProfileBuilder.errors.contact_phone') as string;
                    },
                },
            });
            inputRows.splice(2, 0, {
                keyName: 'phone_desc',
                label: '',
                gridColumnOptions: { formCol: 12 },
                formProps: {
                    text: 'phoneDescription',
                },
                Component: Description,
            });
        }

        setFormRows(inputRows);
        // eslint-disable-next-line
    }, [isLoading, countryCode]);

    useEffect(() => {
        if (isMultiVendorBasket) {
            setCountryCode(group?.address_country?.toLowerCase() || 'gb');
        } else {
            if (venue) {
                setCountryCode(venue.address_country.toLowerCase() || 'gb');
            }
        }

        // eslint-disable-next-line
    }, [venue, group]);

    function onSubmit(data: any) {
        setIsLoading(true);
        callAPI(HTTPMethods.POST, {
            body: {
                iso_code: ApiVenueLanguages[isoCode.toUpperCase()].toLowerCase() ?? 'en',
                name: data.guest_name,
                contact_email: data.guest_email,
                contact_phone: data.guest_phone,
            },
        }).then((data: ISessionData) => {
            if (data) {
                setCookie(
                    [
                        {
                            name: GuestUser.GuestID,
                            value: data.id,
                        },
                        {
                            name: GuestUser.ConsumerID,
                            value: data.consumer_id,
                        },
                    ],
                    360,
                );
                history.push(PAYMENT);
            }
        });
    }
    return {
        formRows,
        onSubmit,
    };
}
