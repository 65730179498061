import * as types from '../actions/ActionTypes';

export type isGroupVenue = boolean;

export function GroupVenue(state: isGroupVenue = false, payload) {
    switch (payload.type) {
        case types.SetIsGroupVenue:
            return payload.data;
        default:
            return state;
    }
}
