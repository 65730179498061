import { ITaxType } from '../../yoello-lib/modules/interfaces/ITaxes';
import { calculateAdditiveTax, calculateInclusiveTax } from '../../yoello-lib/modules/utils/TaxController';
import { IGroupVenue } from '../GroupPage/types';

export function calculateServiceFeesAndTaxes(
    totalPrice: number,
    transactionFee: number,
    tipTotal: number,
    venue: IGroupVenue,
) {
    let serviceChargeAdditiveTax = 0;
    let serviceChargeInclusiveTax = 0;
    let serviceCharge = 0;
    let pence = 0;
    let percentage = 0;
    let taxType;
    let taxPercentage = [];
    if (venue?.venue_fees_and_taxes && venue?.venue_fees_and_taxes['SERVICE']) {
        const service = venue?.venue_fees_and_taxes['SERVICE'];
        pence = service.fee_amount;
        percentage = service.fee_percentage;
        for (const taxItem of service.taxes) {
            taxType = taxItem?.tax_inclusion_type;
            taxPercentage.push(taxItem.tax_percentage);
        }
    } else if (venue?.service_charge_pence_amount || venue?.service_charge_percentage_amount) {
        pence = venue.service_charge_pence_amount;
        percentage = venue.service_charge_percentage_amount;
    }
    serviceCharge = pence + (percentage * (totalPrice - (transactionFee + tipTotal))) / 100;

    if (taxType === ITaxType.ADDITIVE) {
        serviceChargeAdditiveTax = taxPercentage.reduce((prevValue, nextItem) => {
            const calc = calculateAdditiveTax(nextItem, serviceCharge);
            return prevValue + calc;
        }, 0);
    } else {
        serviceChargeInclusiveTax = taxPercentage.reduce((prevValue, nextItem) => {
            const calc = calculateInclusiveTax(serviceCharge, nextItem);
            return prevValue + calc;
        }, 0);
    }
    return {
        serviceCharge,
        serviceChargeAdditiveTax,
        serviceChargeInclusiveTax,
    };
}
