import YoelloLogo from '../../../../imges/qrYoello.png';
import { QRCode } from 'react-qrcode-logo';

export function OrderQRCode({ id }) {
    return (
        <div data-component-name={`QR-CODE-${id}`}>
            <QRCode
                value={id}
                logoImage={YoelloLogo}
                quietZone={0}
                size={330}
                bgColor="transparent"
                ecLevel="M"
                logoWidth={64}
                logoHeight={64}
                qrStyle="dots"
            />
        </div>
    );
}
