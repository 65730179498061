import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    setIsLoading,
    setIsPaymentProcessingDrawerOpen,
    setSelectedPaymentMethod,
    setIsPaymentDrawerOpen,
} from '../reducer/PaymentActions';
import { usePaymentState } from '../PaymentStore';

import useOrderBody from './useOrderBody';
import useOnSuccessfulOrder from './useOnSuccessfulOrder';

import { LOGIN } from '../../../constants/URL.const';
import { ConsumerUpdatePreferences, CreateOrder, getConsumer, GuestCreateOrder } from '../../../Api.js';
import { APIError } from '../../../utils/APIErrorController';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { useSentry } from '../../../customHooks/useSentry';
import * as Sentry from '@sentry/browser';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import useGuestUserData from '../../../customHooks/useGuestUserData';
import { useGetAvailableMenus } from './useGetAvailableMenus';
import usePaymentView from './usePaymentView';
import useDelivery from './useDelivery';

const useCompleteOrder = () => {
    const {
        selectedTime,
        cartItemTaxAmount,
        serviceTaxAmount,
        message,
        selectedOrderType,
        deliveryPrice,
        tipTotal,
        areaName,
        selectedTable,
        totalPrice,
        deliveryAddress,
        isLoading,
        dispatch,
        deliveryTaxAmount,
        deliveryTimes,
        retrieveToken,
        selectedPreference,
        discounts,
        orderOnly,
        promotion,
        metadata,
    } = usePaymentState();
    const { generateOrderBody } = useOrderBody();
    const { backFunction } = usePaymentView();
    const { checkMenusAvailability } = useGetAvailableMenus();
    const { t } = useTranslation();
    const { onSuccessfulOrder } = useOnSuccessfulOrder();
    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const { captureException } = useSentry();
    const history = useHistory();
    const venue = useSelector((state: any) => state.Venue);
    const { consumerId, guestId, isGuest, guestUpdatePreferences, getGuestUser } = useGuestUserData();
    const { currentUser, setCurrentUser, isAuthenticated } = useAuthenticationContext();
    const DeliveryDistance = useSelector((state: any) => state.DeliveryDistance);

    const { setDeliveryChargeBasedOnAddress } = useDelivery();

    const onOrderFailure = (message?: string) => {
        dispatch(setSelectedPaymentMethod(null));
        dispatch(setIsPaymentProcessingDrawerOpen(false));
        if (message) {
            sendSnackbarMessage(t(message) as string, 'error');
        }
    };

    const completeOrder = async (isUsingPaymentRequest?: boolean) => {
        const price = totalPrice;
        let user;
        let userToken;
        let createOrderObject;
        try {
            dispatch(setIsLoading(true));
            const orderBody = {
                ...generateOrderBody(
                    selectedTime,
                    isUsingPaymentRequest,
                    cartItemTaxAmount,
                    serviceTaxAmount,
                    deliveryTaxAmount,
                    message,
                    selectedOrderType,
                    deliveryPrice,
                    tipTotal,
                    areaName,
                    selectedTable?.name,
                    selectedTable?.tableAreaId,
                    price,
                    deliveryAddress,
                    deliveryTimes,
                    discounts,
                    orderOnly,
                    promotion,
                    metadata,
                ),
            };

            /** Need to check if menu is enabled and if it’s not pre-order then also check that menu is available at current time */
            const { disabledItems } = await checkMenusAvailability(orderBody);

            if (disabledItems.length > 0) {
                backFunction(disabledItems);
                return;
            }

            if (isGuest && !isAuthenticated) {
                user = (await getGuestUser()) || currentUser;
            } else {
                userToken = await retrieveToken();
            }
            if (selectedOrderType === 'COLLECTION_POINT_PICKUP') {
                const originallySelectedPreference = user?.consumer_preferences?.notify_by_email ? 'email' : 'sms';
                if (selectedPreference && selectedPreference !== originallySelectedPreference) {
                    if (isGuest && !isAuthenticated) {
                        await guestUpdatePreferences({
                            notify_by_sms: selectedPreference === 'sms',
                            notify_by_email: selectedPreference === 'email',
                        });
                    } else {
                       if (!isGuest && userToken) {
                           await ConsumerUpdatePreferences(
                               {
                                   notify_by_sms: selectedPreference === 'sms',
                                   notify_by_email: selectedPreference === 'email',
                               },
                               userToken,
                           );
                           const consumer = await getConsumer(userToken);
                           setCurrentUser(consumer.data.data);
                       }
                    }
                }
            }
            if (isGuest && !isAuthenticated) {
                createOrderObject = await GuestCreateOrder({
                    ...orderBody,
                    consumer_id: consumerId,
                    guest_session_id: guestId,
                });
            } else {
                createOrderObject = await CreateOrder(orderBody, userToken, venue.id);
            }
            const orderId = createOrderObject.data.data.order_reference_number;
            const orderLongID = createOrderObject.data.data.order_id;
            Sentry.addBreadcrumb({
                level: 'info',
                message: orderId,
                data: orderBody,
            });

            if (isUsingPaymentRequest) {
                return createOrderObject;
            } else {
                onSuccessfulOrder(orderId, orderLongID);
            }
        } catch (error) {
            captureException(error, { feature: 'complete-order' });
            let errMessage = t('Payment.Errors.CreateOrderError') as string;
            if (error.response) {
                const status = error.response.data.status;
                if (status >= 400 && status < 500) {
                    if (status === 401) {
                        localStorage.removeItem(`user_token_${window.location.host}`);
                        history.push(LOGIN);
                    } else {
                        const errors = error.response.data.data.errors;
                        errMessage = APIError(errors, t);
                        if (errors[0]?.error_code === 'DELIVERY_FEES_NOT_MATCH') {
                            setDeliveryChargeBasedOnAddress(DeliveryDistance);
                        }
                    }
                }
            }
            onOrderFailure(errMessage);
            dispatch(setIsPaymentDrawerOpen(false));
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    return {
        completeOrder,
        isLoading,
        onOrderFailure,
    };
};

export default useCompleteOrder;
