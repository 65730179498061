import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setDisabledItems } from '../Components/Menu/state/Menu.Actions';
import { CART } from '../constants/URL.const';
import { useCartAvailability } from './useCartAvailablity';
import { useMultiVendorBasket } from './useMultiVendorBasket';

/**Whenever the menu item list changes this checks the cart */
export function useCartCheck(goToUrl: (isGroupVenue?: boolean) => string, dispatch) {
    const { checkAvailablity } = useCartAvailability();
    const Preferences = useSelector((state: any) => state.Preferences);
    const rawItem = useSelector((state: any) => state.MenuItem.raw);
    const Extras = useSelector((state: any) => state.Extras);
    const ExtraMenuItem = useSelector((state: any) => state.MenuItemsAsExtra);
    const history = useHistory();
    const { isMultiVendorBasket, isGroupVenue } = useMultiVendorBasket();

    //TODO:needs to check if checkAvailability is needed for multi venudor basket or not

    useEffect(() => {
        if (rawItem && !isMultiVendorBasket) {
            const { isSuccess, disabledItems } = checkAvailablity({
                menu_items: rawItem,
                menu_item_extra_groups: Extras,
                menu_item_preferences: Preferences,
                menu_items_as_extras: ExtraMenuItem,
            });
            if (!isSuccess) {
                dispatch(setDisabledItems(disabledItems));
                if (isGroupVenue) {
                    history.push(goToUrl(true) + '/menu' + CART, { disabledItems });
                } else {
                    history.push(goToUrl() + '/menu' + CART, { disabledItems });
                }
            }
        }
        //eslint-disable-next-line
    }, [rawItem, isMultiVendorBasket, isGroupVenue]);
}
