import SocialButton from './SocialButton';
import GoogleIcon from './Icons/GoogleIcon';
import { useTranslation } from 'react-i18next';

const GoogleButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <SocialButton
            onClick={onClick}
            text={t('Login.Texts.google').toString()}
            dataComponentName={'continue-with-google'}
        >
            <GoogleIcon />
        </SocialButton>
    );
};

export default GoogleButton;
