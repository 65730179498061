import { IAPIDefinitions } from '../types';
import { Dispatch } from 'react';
import { setLanguage } from '../../redux/actions/localization';
import { generateTradingTimesObject, ITradingTimes } from '../../utils/tradingTimesObject';
import {
    addNotification,
    resetCart,
    setDietaryTags,
    setExtras,
    setGroupInfo,
    setGroupName,
    setMenuItems,
    setMenuItemsAsExtra,
    setPreferences,
    setSelectedMenuType,
    setTaxItems,
    setIsGroupVenue,
    RepeatCart,
} from '../../redux/actions/consumer.actions';
import { checkVenueOpen } from '../../utils/venueOpen';
import { generateServiceURL } from '../utils';
import { VenueLanguages } from '../../API/apiDefs/ConsumerAPIDefs';

export enum APIConsumer {
    consumers = 'consumers',
    consumerVenues = 'consumerVenues',
    multiVendorGroup = 'multiVendorGroup',
    multiVendorGroupUpdate = 'multiVendorGroupUpdate',
    MultiVendorplaceOrder = 'MultiVendorplaceOrder',
    consumerMenus = 'consumerMenus',
}

export enum SelectedOrderType {
    CLICK = 'COLLECTION',
    TABLE = 'TABLE_DELIVERY',
    DELIVERY = 'HOME_DELIVERY',
    COLLECTION_POINT = 'COLLECTION_POINT_PICKUP',
    ORDER_ONLY = 'ORDER_ONLY',
}

export const consumerAPIDefs: IAPIDefinitions = {};

consumerAPIDefs[APIConsumer.multiVendorGroup] = {
    baseURL: generateServiceURL('venue_groups'),
    defaultURL: 'venue_groups/name',
    dataStorage: {
        selectorKey: () => {
            return 'Group';
        },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args) => {
            const lang = args
                ? (args[0] as keyof typeof VenueLanguages)
                : (result.language_iso.toUpperCase() as keyof typeof VenueLanguages);
            const language = VenueLanguages[lang];
            dispatch(setLanguage(language));
            dispatch(setGroupName(result.nickname));
            if (Array.isArray(args) && args.length > 0 && args[1] !== result.id) {
                dispatch(resetCart());
                dispatch(RepeatCart(null));
                dispatch(setSelectedMenuType(null));
            }
            let availableOrderTypes: string[] = [];

            const venues = result.venues
                .filter((groupV: any) => groupV.venue_nickname !== null)
                .map((groupV: any) => {
                    const settings = groupV['settings'];
                    delete groupV['settings'];
                    if (settings.table_enabled) {
                        availableOrderTypes.push(SelectedOrderType.TABLE);
                    }
                    if (settings.delivery_enabled) {
                        availableOrderTypes.push(SelectedOrderType.DELIVERY);
                    }
                    if (settings.pickup_enabled) {
                        availableOrderTypes.push(SelectedOrderType.CLICK);
                    }
                    if (settings.pickup_now_enabled) {
                        availableOrderTypes.push(SelectedOrderType.COLLECTION_POINT);
                    }
                    return {
                        ...groupV,
                        trading_times: generateTradingTimesObject(groupV.trading_times as ITradingTimes[]),
                        ...settings,
                    };
                });

            //@ts-ignore
            availableOrderTypes = [...new Set(availableOrderTypes)];

            const groupInfo = {
                ...result,
                venues: venues.reduce(
                    (obj: any, venue: any) => Object.assign(obj, { [venue.venue_nickname]: venue }),
                    {},
                ),
                availableOrderTypes,
            };

            dispatch(setGroupInfo(groupInfo));
            if (availableOrderTypes.length === 1) {
                dispatch(setSelectedMenuType(availableOrderTypes[0]));
            }
            dispatch(setIsGroupVenue(true));
        },
    },
    GET: {},
};
consumerAPIDefs[APIConsumer.multiVendorGroupUpdate] = {
    baseURL: generateServiceURL('venue_groups'),
    defaultURL: 'venue_groups/name',
    GET: {},
};
consumerAPIDefs[APIConsumer.MultiVendorplaceOrder] = {
    baseURL: generateServiceURL('placed_orders'),
    defaultURL: '',
    GET: {},
    POST: {},
};


consumerAPIDefs[APIConsumer.consumers] = {
    baseURL: generateServiceURL('consumers'),
    defaultURL: 'consumers',
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {},
    DELETE: {},
};

consumerAPIDefs[APIConsumer.consumerMenus] = {
    baseURL: generateServiceURL('menus'),
    defaultURL: `menus/venue/`,
    dataStorage: {
        selectorKey: () => {
            return 'MenuItem';
        },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args): void => {
            dispatch(
                setMenuItems({
                    menuItems: result.menu_items,
                    categories: result.subcategories,
                    menusList: result?.venue_details?.on_menu_setup ? false : null,
                }),
            );
            dispatch(setPreferences(result.menu_item_preferences));
            dispatch(setExtras(result.menu_item_extra_groups));
            dispatch(setMenuItemsAsExtra(result.menu_items_as_extras));
            dispatch(setDietaryTags(result.dietary_tags));
            if (result.taxes) {
                dispatch(setTaxItems(result.taxes));
            }
            if (Array.isArray(args) && args[0] && args[1]) {
                const venue: any = args[1];
                const group: any = args[2];
                const { message, status, languageProps } = checkVenueOpen(venue, args[0]);
                if (message) {
                    dispatch(
                        addNotification({
                            label: message,
                            status: status ?? 'error',
                            pathname: group
                                ? `/g/${group.nickname}/${venue.venue_nickname}/menu`
                                : `/v/${venue.venue_nickname}/menu`,
                            labelObj: languageProps,
                        }),
                    );
                }
            }
        },
    },
    GET: {},
};