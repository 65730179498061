import { useAPIV2 } from '../../../yoello-lib/modules/API2/useAPIv2';
import APIExtensions from '../../../yoello-lib/modules/API2/APIDefs';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { _SetDefaultTip } from '../../../redux/actions';
import { IDefaultTip } from '../../../redux/Reducers/DefaultTipReducer';

export enum TipType {
    ROUNDUP = 'ROUNDUP',
    PERCENTAGE = 'PERCENTAGE',
    CUSTOM = 'ABSOLUTE',
    ZERO = 'ZERO',
}
export interface ITippingOptionsDataType {
    value: number;
    venue_id: string;
    serial_id: number;
    deleted: boolean;
    metadata: boolean | null;
    created_on: string;
    updated_on: string;
    id: string;
    tip_type: TipType;
    enabled: true;
    sort_position: number;
}

const defaultTip = {
    tipType: TipType.ZERO,
    tipPercantage: 0,
    customValue: 0,
    isTipChanged: false,
};

export function useTip() {
    const [state, setState] = useState<IDefaultTip>(defaultTip);
    const dispatch = useDispatch<any>();
    const [tippingOptions, setTippingOptions] = useState<Array<ITippingOptionsDataType>>([]);
    const Venue = useSelector((state: any) => state.Venue);
    const { callAPI, apiData: tippingOptionsApiData } = useAPIV2<ITippingOptionsDataType[]>(APIExtensions.tipping, {
        params: {
            venue_id: Venue.id,
            include_disabled: true,
        },
        doNotCacheValue: true,
        onMountDisabled: true,
        query: 'public',
    });

    useEffect(() => {
        if (Venue.tipping_enabled) {
            callAPI(HTTPMethods.GET);
        }
        // eslint-disable-next-line
    }, [Venue]);

    useEffect(() => {
        if (tippingOptionsApiData?.length > 0) {
            // we are not sorting this on back-end and we always need RoundUp and Custom to be at first
            // first sorting based on sort position and then moving RoundUp and Custom to begining if they exist
            let tippingOptionsArray: Array<ITippingOptionsDataType> = tippingOptionsApiData;
            tippingOptionsArray.sort((a, b) => (a.sort_position > b.sort_position ? 1 : -1));

            tippingOptionsArray.sort((a, b) =>
                a.tip_type === TipType.CUSTOM && a.enabled ? -1 : b.tip_type === TipType.CUSTOM && b.enabled ? 1 : 0,
            );

            tippingOptionsArray.sort((a, b) =>
                a.tip_type === TipType.ROUNDUP && a.enabled ? -1 : b.tip_type === TipType.ROUNDUP && b.enabled ? 1 : 0,
            );

            setTippingOptions(tippingOptionsArray);
        }
    }, [tippingOptionsApiData]);

    useEffect(() => {
        if (tippingOptions.length > 0 && Venue.default_tipping_option_id && !state.isTipChanged) {
            const defaultOption = tippingOptions.find((option) => option.id === Venue.default_tipping_option_id);
            if (defaultOption?.tip_type) {
                const newState = {
                    tipType: defaultOption?.tip_type,
                    tipPercantage: defaultOption?.tip_type === TipType.PERCENTAGE ? defaultOption.value : 0,
                    customValue: 0,
                };
                setState(newState);
                dispatch(_SetDefaultTip(newState));
            }
        }
        // eslint-disable-next-line
    }, [tippingOptions, Venue, state.isTipChanged]);

    function clearTippingOption() {
        setState({
            ...defaultTip,
            isTipChanged: true,
        });
        dispatch(
            _SetDefaultTip({
                tipType: defaultTip.tipType,
                tipPercantage: defaultTip.tipPercantage,
                customValue: defaultTip.customValue,
            }),
        );
    }

    function handleTippingOptionClick(clickedTipType: TipType, clickedTipPercantage?: number) {
        const tip = {
            ...state,
            tipType: clickedTipType,
            tipPercantage: clickedTipPercantage ? clickedTipPercantage : defaultTip.tipPercantage,
        };
        setState({
            ...tip,
            isTipChanged: true,
        });
        dispatch(
            _SetDefaultTip({
                tipType: tip.tipType,
                tipPercantage: tip.tipPercantage,
                customValue: tip.customValue,
            }),
        );
    }

    return {
        tippingOptions,
        customValue: state.customValue,
        tipType: state.tipType,
        tipPercantage: state.tipPercantage,
        setState,
        state,
        clearTippingOption,
        handleTippingOptionClick,
    };
}
