import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './localisation/i18n';
import * as serviceWorker from './serviceWorker';
// import './style.scss';

// redux imports
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import reducers from './redux/Reducers/index';
import * as Sentry from '@sentry/browser';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import packageJSON from '../package.json';
import { SnackbarProvider } from './yoello-lib/modules/components/snackbar/SnackbarContext';
import DrawerProvider from './Components/Drawer/DrawerContext';
import SessionClient from './yoello-lib/modules/auth/SessionClient';
import TagManager from 'react-gtm-module';
import { AuthProvider } from './yoello-lib/modules/auth/AuthenticationContext';
import { Amplify } from 'aws-amplify';
//Amplify Cognito configuration
Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_WEBCLIENTID,
        mandatorySignIn: 'true',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        scope: ['openid, email, profile'],
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            scope: ['openid', 'email', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${window.location.origin}/login/oauthredirect`,
            redirectSignOut: window.location.origin,

            responseType: 'code',
        },
    },
});

/**Each time a user comes to the app we create them a session id */
SessionClient.createSession();

const tagManagerArgs = {
    gtmId: 'GTM-M74D97C',
};

if (process.env.REACT_APP_STAGE !== 'dev') {
    TagManager.initialize(tagManagerArgs);
}

// ReactDOM.render(<App />, document.getElementById('root'));
if (window.location.hostname !== 'localhost') {
    console.log(`Sentry is enabled in environment: ${process.env.REACT_APP_STAGE} - version: ${packageJSON.version}`);
    Sentry.init({
        dsn: 'https://0c02440fd4bc4aff98a9b58541dd347b@o329619.ingest.sentry.io/5215100',
        environment: process.env.REACT_APP_STAGE,
        release: `consumer-web@${packageJSON.version}`,
    });
}
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['ShowUp', 'MenuItem', 'Preferences', 'Notifications'],
};
const persistedReducer = persistReducer(persistConfig, reducers);
let store = createStore(persistedReducer, applyMiddleware(promiseMiddleware, ReduxThunk));
let persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider>
            <AuthProvider>
                <DrawerProvider>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </DrawerProvider>
            </AuthProvider>
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
