import { ComponentType, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { IPageComponentProps } from './types';
import useAPI, { IMessageOptions } from '../../yoello-lib/modules/API/useAPI';
import { APIExtensions } from '../../yoello-lib/modules/API/APIDefs';
import APIExtensionsV2 from '../../yoello-lib/modules/API2/APIDefs';
import { HTTPMethods } from '../../yoello-lib/modules/API/API';

import PageComponent from './PageComponent';
import { useManageQrQueryParams } from '../../customHooks/useManageQrQueryParams';
import { useAPIV2 } from '../../yoello-lib/modules/API2/useAPIv2';

interface IMenusPageContainerProps extends IPageComponentProps {
    ChildComponent: ComponentType<any>;
    LoadingComponent: ComponentType<any>;
    apiConfig: IMessageOptions;
    errorFunction: Function;
    APIExtension: APIExtensions;
}

function MenusPageContainer({
    ChildComponent,
    retrieveToken,
    apiConfig,
    LoadingComponent,
    errorFunction,
    APIExtension,
    ...props
}: IMenusPageContainerProps) {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const Venue = useSelector((state: any) => state.Venue);
    const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
    const specificMenuId = useSelector((state: any) => state.specificMenuId);

    const { isError: isMenusError, callAPI: callMenusAPIV2 } = useAPIV2<any>(APIExtensionsV2.consumerMenus, {
        ...apiConfig,
        canAPIGetCacheValue: false,
        doNotCacheValue: true,
        onMountDisabled: true,
    });
    const { callAPI: AvailableMenusAPI } = useAPI(APIExtensions.consumerAvailableMenus, () => null, {
        onMountDisabled: true,
        query: Venue.id,
    });

    useManageQrQueryParams();

    useEffect(() => {
        setIsPageLoading(true);
        callMenusAPIV2(HTTPMethods.GET, {
            ...apiConfig,
        }).then((res) => {
            if (res?.data?.venue_details?.on_menu_setup) {
                AvailableMenusAPI(HTTPMethods.GET, {
                    query: Venue.id,
                    params: {
                        order_type: SelectedMenuType,
                        datetime: `${moment.utc().format()}`,
                        menu_id: Boolean(specificMenuId) ? specificMenuId : undefined,
                    },
                });
            }
            setIsPageLoading(false);
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (isMenusError === true && typeof errorFunction === 'function') {
            errorFunction();
        }
        //eslint-disable-next-line
    }, [isMenusError]);

    return isPageLoading ? <LoadingComponent /> : <ChildComponent {...props} />;
}

export default PageComponent(MenusPageContainer as ComponentType);
