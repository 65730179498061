import { ADD_SPECIFIC_MENU_ID } from '../../yoello-lib/modules/redux/actions/consumer.actions';

export type IMenuId = number | null;

export function specificMenuURLReducer(
    state: IMenuId = null,
    action: {
        type: string;
        payload: IMenuId;
    },
) {
    switch (action.type) {
        case ADD_SPECIFIC_MENU_ID:
            return action.payload;
        default:
            return state;
    }
}
