import moment from 'moment-timezone';
import { generateTimeIntervalsTradingTimes, isVenueOpen } from './tradingTimesObject';
import { canVenuePreorder } from './venuePreOrderAvailable';

interface IVenueReturn {
    isVenueOpen: boolean;
    message: string | null;
    status: any;
    languageProps?: Object;
}
export enum SelectedOrderType {
    CLICK = 'COLLECTION',
    TABLE = 'TABLE_DELIVERY',
    DELIVERY = 'HOME_DELIVERY',
    COLLECTION_POINT = 'COLLECTION_POINT_PICKUP',
}
export function checkVenueOpen(venue: any, orderType: any): IVenueReturn {
    const day = moment().format('dddd').toLowerCase();
    const tradingTimes = venue?.trading_times;

    let isOpen = false;
    let message = null;
    let status = null;
    let languageProps = undefined;
    if (tradingTimes && day in tradingTimes) {
        const todayTradingTimes = tradingTimes[day];

        const deliveryTimes = generateTimeIntervalsTradingTimes(
            todayTradingTimes,
            venue.delivery_max_lead_time,
            venue.time_zone_name,
        );
        const collectionTimes = generateTimeIntervalsTradingTimes(
            todayTradingTimes,
            venue.food_prep_time,
            venue.time_zone_name,
        );
        if (
            canVenuePreorder(venue, tradingTimes, deliveryTimes, collectionTimes, orderType) &&
            venue?.enabled === true
        ) {
            message = 'ShopCart.Errors.PreOrderOnly';
            status = 'info';
            isOpen = true;
            languageProps = {
                time: todayTradingTimes.find(
                    (item: any) =>
                        item.isDisplayed && item.openTime > moment().tz(venue.time_zone_name).format('HH:mm'),
                )?.openTime,
            };
        } else if (isVenueOpen(tradingTimes, venue.time_zone_name) || venue?.enabled === true) {
            if (!isVenueOpen(tradingTimes, venue.time_zone_name) || venue?.enabled === false) {
                message = 'ShopCart.Errors.VenueClosed';
                status = 'error';
            }
            // if  the venue is about to close but still open then user can only view the menu
            else if (
                (orderType === SelectedOrderType.CLICK && collectionTimes.length === 0) ||
                (orderType === SelectedOrderType.DELIVERY && deliveryTimes.length === 0) ||
                (orderType === SelectedOrderType.TABLE &&
                    !isVenueOpen(tradingTimes, venue.time_zone_name, venue.food_prep_time))
            ) {
                message = getCanOnlyViewErrorText(orderType);
                status = 'error';
            } else {
                isOpen = true;
            }
        } else {
            message = 'ShopCart.Errors.VenueClosed';
            status = 'error';
        }
    }
    return {
        isVenueOpen: isOpen,
        message,
        status,
        languageProps,
    };
}

function getCanOnlyViewErrorText(SelectedMenuType: SelectedOrderType): string {
    if (SelectedMenuType === SelectedOrderType.CLICK) {
        return 'ShopCart.Errors.NoCollectionTimes';
    } else if (SelectedMenuType === SelectedOrderType.DELIVERY) {
        return 'ShopCart.Errors.NoDeliveryTimes';
    } else if (SelectedMenuType === SelectedOrderType.TABLE) {
        return 'ShopCart.Errors.NoServiceAvailable';
    }
    return '';
}
