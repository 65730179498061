import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const useFonts = () => {
    const venue = useSelector((state: any) => state.Venue);
    const [primaryFont, setPrimaryFont] = useState<string>();

    useMemo(() => {
        if (venue) {
            switch (venue.venue_nickname) {
                case 'poorya3':
                    setPrimaryFont('cursive');
                    break;
                case 'lostestate':
                    setPrimaryFont('Futura !important');
                    break;
                case 'nellyfood':
                    setPrimaryFont('cursive');
                    break;
                default:
                    setPrimaryFont('Open Sans');
                    break;
            }
        }
    }, [venue]);

    return {
        primaryFont,
    };
};

export default useFonts;
