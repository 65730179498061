export const API_ERRORS = 'ApiErrors.';

interface IErrorCodes {
    error_code?: string;
    message?: string;
    [key: string]: any;
}

/**Function that returns API error codes as strings */
export function APIError(errorCodes: IErrorCodes[], t: any): string {
    if (Array.isArray(errorCodes)) {
        return errorCodes
            .map((eC) => {
                if (typeof eC === 'string') {
                    return t(eC);
                }
                return t(API_ERRORS + (eC.error_code || eC.message), {
                    ...eC,
                });
            })
            .join(' , ');
    } else if (typeof errorCodes === 'string') {
        return t(API_ERRORS + errorCodes).toString();
    } else if (typeof errorCodes === 'object') {
        const ec = errorCodes as IErrorCodes;
        return t(API_ERRORS + ec.error_code, {
            ...ec,
        }).toString();
    } else {
        return t(API_ERRORS + 'default').toString();
    }
}
