import { IAPIDefinitions } from '../types';
import { generateServiceURL } from '../utils';

export enum APIOpenBanking {
    config = 'config',
    account = 'account',
}

export const openBankingDefs: IAPIDefinitions = {};

openBankingDefs[APIOpenBanking.config] = {
    baseURL: generateServiceURL('config'),
    defaultURL: 'security/keys',
    GET: {},
};
openBankingDefs[APIOpenBanking.account] = {
    baseURL: generateServiceURL('venues'),
    defaultURL: 'venues',
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {},
    DELETE: {},
};
