import ProductShopCard from '../../Widgets/ProductShopCard/ProductShopCard';

interface ICartItem {
    cartItem: any;
    index: number;
    updateProduct: (item: any, count: number) => void;
}

export function CartItem({ cartItem, index, updateProduct }: ICartItem) {
    const props = {
        data: cartItem,
        modifiers: cartItem.mods,
        po: cartItem.measure,
        info: cartItem.menuItem,
        count: cartItem.itemCount,
        addCount: () => updateProduct({ ...cartItem, index }, cartItem.itemCount + 1),
        reduceCount: () => updateProduct({ ...cartItem, index }, cartItem.itemCount - 1),
    };
    return (
        <div style={{ maxHeight: 500 }} id={cartItem.menuItem.menu_item_id}>
            <ProductShopCard card={props} />
        </div>
    );
}
