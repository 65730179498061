import { useEffect, useState } from 'react';

import { usePaymentState } from '../PaymentStore';
import { CreatePaymentDetails } from '../../../Api.js';
import { addPaymentDetails } from '../reducer/PaymentActions';

import useStripe from '../Stripe/useStripe';
import usePay360Card from '../PaymentDrawer/Pay360/usePay360Card';
import useSquareUserCard from '../../../customHooks/Square/useSquareUserCard';
import useGetPaymentProcessor from '../../../customHooks/useGetPaymentProcessor';

export interface IPaymentDetails {
    card_type: string;
    created_on: string;
    masked_card_number: string;
    name_on_card: string;
}

export interface ISquarePaymentDetails extends IPaymentDetails {}

export interface IStripePaymentDetails extends IPaymentDetails {}

const usePaymentDetails = () => {
    const { dispatch, retrieveToken, isAuthenticated } = usePaymentState();
    const [loadingCard, setLoadingCard] = useState(false);
    const { isLoading: isLoadingVenueType, paymentProcessor } = useGetPaymentProcessor();
    const { getCard: getStripeCard } = useStripe();

    const { getCard: getPay360Card } = usePay360Card();

    const {
        getCard: getSquareCard,
        createCard: createSquareCard,
        parseCardToDetails: parseSquareDetails,
    } = useSquareUserCard();

    const [paymentDetails, setPaymentDetails] = useState<any>();
    const [isLoadingPaymentDetails, setIsLoadingPaymentDetails] = useState(true);

    const getPaymentDetails = async () => {
        const userToken = await retrieveToken();
        if (paymentProcessor && !isLoadingVenueType && userToken && !loadingCard) {
            setLoadingCard(true);
            switch (paymentProcessor) {
                case 'SQUARE':
                    getSquareCard(userToken).then((details) => {
                        setLoadingCard(false);
                        setPaymentDetails(details);
                    });
                    break;
                case 'STRIPE':
                    getStripeCard(userToken).then((details) => {
                        setLoadingCard(false);
                        setPaymentDetails(details);
                    });
                    break;
                case 'PAY360':
                    getPay360Card(userToken).then((details) => {
                        setLoadingCard(false);
                        setPaymentDetails(details);
                    });
                    break;
                default:
                    getStripeCard(userToken).then((details) => {
                        setLoadingCard(false);
                        setPaymentDetails(details);
                    });
            }
            setIsLoadingPaymentDetails(false);
        }
    };

    const updateStripeDetails = async (
        paymentMethod: any,
        details: any,
        shouldSaveCard: boolean,
        didUserHaveDetails: boolean,
    ) => {
        if (shouldSaveCard) {
            const userToken = await retrieveToken();
            CreatePaymentDetails(paymentMethod, userToken, didUserHaveDetails);
        }
        setPaymentDetails({ ...details });
    };

    const updateSquareDetails = async (
        card_nonce: string,
        legal_first_name: string,
        legal_last_name: string,
        shouldSaveCard: boolean,
        cardData: any,
    ) => {
        const details = parseSquareDetails({ ...cardData, card_nonce });
        setPaymentDetails(details);
        if (shouldSaveCard) {
            const cardOnFileDetails = await createSquareCard(card_nonce, legal_first_name, legal_last_name);
            setPaymentDetails(parseSquareDetails(cardOnFileDetails.data.data));
        }
    };

    const updatePay360Details = async ({
        name_on_card,
        masked_card_number,
        card_token,
        expiry_date,
        billing_address,
        issuer,
        save_card,
    }: {
        name_on_card: string;
        masked_card_number: string;
        card_token: string;
        expiry_date: string;
        billing_address: any;
        issuer: string;
        save_card: boolean;
    }) => {
        setPaymentDetails({
            name_on_card,
            masked_card_number,
            card_token,
            expiry_date,
            billing_address,
            issuer,
            save_card,
        });
    };

    useEffect(() => {
        dispatch(addPaymentDetails(paymentDetails, true));
        //eslint-disable-next-line
    }, [paymentDetails]);

    useEffect(() => {
        if (!paymentDetails && isAuthenticated) {
            getPaymentDetails();
        }
        //eslint-disable-next-line
    }, [paymentProcessor, isLoadingVenueType, isAuthenticated]);

    return {
        paymentDetails,
        isLoadingPaymentDetails,
        setPaymentDetails,
        getPaymentDetails,
        updateStripeDetails,
        updateSquareDetails,
        updatePay360Details,
    };
};

export default usePaymentDetails;
