import { useEffect, useState } from 'react';
import { APIExtensions, APIDefinitions } from './APIDefs';
import API, { HTTPMethods, APIOptions } from './API';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbarMessages } from '../components/snackbar/SnackbarContext';

/** Optional message options for an API call */
export interface IMessageOptions<TBody = any> extends APIOptions<TBody> {
    /** Message to be displayed in the snackbar before the call is dispatched */
    beforeCall?: string;
    /** Message to be displayed in the snackbar if the call is successful */
    successCall?: string;
    /** Message to be displayed in the snackbar if the call fails */
    errorCall?: string | Record<string, string>;
    /** Responses are cached in redux by default, setting this to true bypasses the cache */
    doNotCacheValue?: boolean;
    /** Query URL parameters */
    query?: string;
    /** Params for selector key function as key/value object*/
    selectorKeyParams?: { [key: string]: any };
    /** Params for dispatcher function as an array of strings */
    dispatcherParams?: string[];
    /**Disable the onMount call */
    onMountDisabled?: boolean;
    /** Overrides set data function */
    overrideSetData?: boolean;
    /**If true API call function will return data from storage instead of API */
    canAPIGetCacheValue?: boolean;
    /** HTTP methods to show successCall */
    successCallMethods?: HTTPMethods[];
}

function resolveAPIMethod(extension: APIExtensions, messageOptions?: IMessageOptions) {
    const apiDefinition = APIDefinitions[extension];
    if (!apiDefinition) {
        //@ts-ignore
        throw Error(
            `Unsupported API endpoint "${APIExtensions[extension]}", available endpoints are ${Object.keys(
                APIDefinitions,
            //@ts-ignore    
            ).map((key) => APIExtensions[key])}`,
        );
    }
    const selectKey = apiDefinition.dataStorage?.selectorKey(messageOptions?.selectorKeyParams) || undefined;
    return {
        dispatchFunction: apiDefinition.dataStorage?.dispatchFunction || undefined,
        selectKey,
        baseURL: apiDefinition.baseURL,
        setDataTransform: apiDefinition.dataStorage?.setData || undefined,
    };
}

/** Hook for handling the getting of data from the onboarding api */
const useAPI = <T>(apiExtension: APIExtensions, tokenFunction: Function, messageOptions: IMessageOptions) => {
    const [data, setData] = useState<T | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState(undefined);
    const [isMounted, setIsMounted] = useState(true);
    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const dispatch = useDispatch();

    const { dispatchFunction, selectKey, baseURL, setDataTransform } = resolveAPIMethod(apiExtension, messageOptions);
    const selectorState = useSelector((state: any) => getSelectorKey(state, selectKey)) as T | undefined;
    const [api, setAPI] = useState<API | null>(null);

    useEffect(() => {
        if (api === null) {
            /**set this once to stop it from creating multiple instances of the api causing the cancel token to be broken */
            console.log('setting api', apiExtension);
            setAPI(new API(tokenFunction, baseURL));
        }
        return () => {
            setIsMounted(false);
            if (api !== null) {
                api.cancelCurrentRequest();
            }
        };
        // eslint-disable-next-line
    }, []);
    /**effect triggers once the API is set up  */
    useEffect(() => {
        if (api !== null) {
            /**  If the onMount is disabled then don't run onMount */
            if (!messageOptions?.onMountDisabled) {
                /**If we already have the data saved in redux, return that */
                if (selectorState && !messageOptions?.doNotCacheValue) {
                    setData(selectorState);
                    setIsLoading(false);
                } else {
                    callAPI(HTTPMethods.GET, messageOptions);
                }
            } else {
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [api]);
    /**
     * Dispatches the API call and caches in redux
     * @param method HTTP Method
     * @param msgOptions if you want to override send through options otherwise uses the options from initalise
     */
    async function callAPI(httpMethod: HTTPMethods, msgOptions?: IMessageOptions): Promise<T | undefined> {
        if (isMounted) {
            /**combines the two options, overrides any new options from msgOptions */
            const apiOptions = {
                ...messageOptions,
                ...msgOptions,
            };
            /**Checks if can get cache and if cache data then use it*/
            if (msgOptions?.canAPIGetCacheValue && selectorState !== undefined && httpMethod === HTTPMethods.GET) {
                const cacheData = setDataValue(apiOptions, selectorState);
                return cacheData;
            }
            if (!isLoading) {
                setIsLoading(true);
            }
            /**If there are multiple attempts we want to remove the error */
            if (isError) {
                setIsError(false);
            }
            if (apiOptions?.beforeCall) {
                sendSnackbarMessage(apiOptions?.beforeCall, 'info');
            }
            const url = checkMethodAndGetURL(httpMethod, apiExtension);
            try {
                let apiCall = api;
                if (apiCall === null) {
                    apiCall = new API(tokenFunction, baseURL);
                }
                const { data: result } = await apiCall.makeHTTPRequest<any, T>(url || '', httpMethod, apiOptions || {});
                if (isMounted) {
                    setData(setDataValue(apiOptions, result));
                    if (dispatchFunction) {
                        dispatchFunction(dispatch, result, apiOptions?.dispatcherParams);
                    }
                }
                const httpMethodShowsSuccess =
                    msgOptions?.successCallMethods?.includes(httpMethod) ||
                    msgOptions?.successCallMethods === undefined;
                if (apiOptions?.successCall && httpMethodShowsSuccess) {
                    sendSnackbarMessage(apiOptions?.successCall, 'success');
                }
                return result;
            } catch (error: any) {
                console.log(error, 'Api Error');
                if (isMounted) {
                    setErrorStatusCode(error?.response?.status);
                    setIsError(true);
                }
                if (apiOptions?.errorCall) {
                    const apiMessages = apiOptions.errorCall;
                    if (typeof apiMessages === 'string') {
                        sendSnackbarMessage(apiMessages, 'error');
                    } else if (typeof apiMessages === 'object') {
                        //@ts-ignore
                        let message: string = apiMessages[error?.response?.status as string];
                        if (!message) {
                            message = 'Network error';
                        }
                        sendSnackbarMessage(message, 'error');
                    }
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        }
    }
    function setDataValue(apiOptions: IMessageOptions, result: T) {
        return setDataTransform && !apiOptions.overrideSetData ? setDataTransform(result) : result;
    }
    /**Function to cancel API requests */
    function cancelRequest() {
        if (api !== null && api.cancelCurrentRequest) {
            console.log('canceled request');
            api.cancelCurrentRequest();
        }
    }
    // Return the api property observables and the callback to dispatch the request
    return {
        apiData: data,
        isLoading,
        isError,
        callAPI,
        cancelRequest,
        errorStatusCode,
    };
};

/**
 * Checks if the defintion has the method selected
 * Returns the URL for that method or the default URL
 */
function checkMethodAndGetURL(method: HTTPMethods, extension: APIExtensions): string {
    const apiDefinition = APIDefinitions[extension];
    let methodDefinition;
    switch (method) {
        case HTTPMethods.GET:
            methodDefinition = apiDefinition.GET;
            break;
        case HTTPMethods.POST:
            methodDefinition = apiDefinition.POST;
            break;
        case HTTPMethods.PUT:
            methodDefinition = apiDefinition.PUT;
            break;
        case HTTPMethods.DELETE:
            methodDefinition = apiDefinition.DELETE;
            break;
        case HTTPMethods.PATCH:
            methodDefinition = apiDefinition.PATCH;
    }
    /**throws if unsupported */
    if (!methodDefinition) {
        throw Error(
            `Attempted use of unsupported HTTP Method '${method}' for api endpoint '${
                APIExtensions[extension]
            }', supported methods for this API are ${Object.keys(apiDefinition)}`,
        );
    }
    return methodDefinition.url || apiDefinition.defaultURL;
}

/**Maps the redux state object to the string selector keys*/
const getSelectorKey = (obj: any, selectorKeys: string | undefined) => {
    if (selectorKeys === undefined) {
        return undefined;
    }
    try {
        return selectorKeys.split('.').reduce((prevVal, curVal) => {
            return prevVal[curVal];
        }, obj);
    } catch (error) {
        return undefined;
    }
};

export default useAPI;
