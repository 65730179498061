import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as FoodPlateSVG } from '../../../imges/FoodPlate.svg';
import { ReactComponent as BicycleSVG } from '../../../imges/Bicycle.svg';
import { ReactComponent as BagSVG } from '../../../imges/Bag_new.svg';
import Styles from './orderDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { IOrders } from '../../../typings/IOrders.d';
import useColors from '../../../customHooks/useColors';

interface IOrderTypesProps {
    orderItem: IOrders;
}

export function OrderTypes({ orderItem }: IOrderTypesProps) {
    const Venue = useSelector((state: any) => state.Venue);
    const { t } = useTranslation();
    const { primaryIconColor } = useColors();
    const { Icon, orderType } = renderItems(orderItem, Venue, t, primaryIconColor);

    return (
        <div className={Styles.OrderTypes}>
            <div>
                {Icon} <p>{orderType}</p>
            </div>
        </div>
    );
}

function renderItems(orderItem: IOrders, Venue: any, t: (key: string) => string, primaryIconColor: string) {
    const { placed_order_order_type, placed_order_frozen_order_type_label } = orderItem;
    let Icon = null;
    let orderType = null;
    switch (placed_order_order_type) {
        case 'TABLE_DELIVERY':
            Icon = <FoodPlateSVG fill={primaryIconColor} />;
            orderType = placed_order_frozen_order_type_label
                ? placed_order_frozen_order_type_label
                : (t('Venue.TableService') as string);
            break;
        case 'HOME_DELIVERY':
            Icon = <BicycleSVG fill={primaryIconColor} />;
            orderType = placed_order_frozen_order_type_label
                ? placed_order_frozen_order_type_label
                : (t('Venue.Delivery') as string);
            break;
        case 'COLLECTION_POINT_PICKUP':
            Icon = <BagSVG fill={primaryIconColor} />;
            orderType = placed_order_frozen_order_type_label
                ? placed_order_frozen_order_type_label
                : (t('Venue.pickupNow') as string);
            break;
        case 'COLLECTION':
            Icon = <BagSVG fill={primaryIconColor} />;
            orderType = placed_order_frozen_order_type_label
                ? placed_order_frozen_order_type_label
                : (t('Venue.Collection') as string);
            break;
    }
    return {
        Icon,
        orderType,
    };
}
