import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ButtonProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EColourPanel from './interfaces/EColourPanel';

interface DialogFormWizardButton extends ButtonProps {
    /**OnClick handler used if you dont want to submit the form*/
    onClick?: () => void;
    /**Colour for the buttons using material UI */
    color: EColourPanel;
    /**Button Label */
    label: string;
    /**Button type, submit type is used with the react-hook-form lib to handle submits */
    type: 'button' | 'submit' | 'reset' | undefined;
}

export interface IDialogFormWizardButtons {
    /**Button on the left */
    leftButton?: DialogFormWizardButton;
    /**Button on the right */
    rightButton?: DialogFormWizardButton;
    /**If the dynamic form has a formKey it's sent here too */
    formKey?: string;
    /**custom styles */
    leftButtonCustomClassName?: string;
    rightButtonCustomClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        DialogFormButtons: {
            background: '#FFFFFF',
            boxShadow: '0px 0px 20px rgba(33, 33, 33, 0.1)',
            borderRadius: '0px 0px 16px 16px',
            padding: '1.25rem 3.75rem',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 999,
        },
        leftButton: {
            marginRight: '1.5625rem',
            padding: '0.875rem 1.5625rem',
            boxShadow: 'none',
            backgroundColor: '#F3F4F9',
        },
        rightButton: {
            padding: '0.875rem 1.5625rem',
        },
    }),
);

/**Generating the dynamic form buttons */
const DialogFormWizardButtons = (props: IDialogFormWizardButtons) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid className={classes.DialogFormButtons}>
            {props.leftButton ? (
                <Button
                    className={`${classes.leftButton} ${props.leftButtonCustomClassName}`}
                    data-component-name={'LEFT_BUTTON'}
                    {...props.leftButton}
                    name={props.leftButton.label}
                    key={props.formKey + '1'}
                    variant="contained"
                >
                    {t(props.leftButton.label)}
                </Button>
            ) : null}
            {props.rightButton ? (
                <Button
                    className={`${classes.rightButton} ${props.rightButtonCustomClassName}`}
                    data-component-name={'RIGHT_BUTTON'}
                    {...props.rightButton}
                    name={props.rightButton.label}
                    key={props.formKey + '2'}
                    variant="contained"
                >
                    {t(props.rightButton.label)}
                </Button>
            ) : null}
        </Grid>
    );
};

export default DialogFormWizardButtons;
