import React from 'react';
import Button from '../Widgets/Button';

interface IBackButton {
    onClick: () => void;
    style?:object;
}

export function BackButton({ onClick,style }: IBackButton) {
    return (
        <Button
            iconClassName="consumer-left-arrow"
            onClick={onClick}
            dataComponentName={'BackButton'}
            style={style ?? { color: '#000000', fontSize: '1.5rem', position: 'unset' }}
            isHeaderBtn
        />
    );
}
