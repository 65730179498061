import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const useColors = () => {
    const venue = useSelector((state: any) => state.Venue);
    const [primaryBackgroundColor, setPrimaryBackgroundColor] = useState<string>();
    const [primaryBorderColor, setPrimaryBorderColor] = useState<string>();
    const [secondaryBackgroundColor, setSecondaryBackgroundColor] = useState<string>();
    const [lowerOpacityButtonColor, setLowerOpacityButtonColor] = useState<string>();
    const [secondaryLowerOpacityButtonColor, setSecondaryLowerOpacityButtonColor] = useState<string>();
    const [primaryIconColor, setPrimaryIconColor] = useState<string>();
    const [useDarkTheme, setUseDarkTheme] = useState<boolean>();

    useMemo(() => {
        if (venue) {
            switch (venue.venue_nickname) {
                case 'poorya3':
                    setPrimaryBackgroundColor('#A2DCEE');
                    setPrimaryBorderColor('#A2DCEE');
                    setSecondaryBackgroundColor('#9A9CEA');
                    setLowerOpacityButtonColor('#ADEEE2');
                    setSecondaryLowerOpacityButtonColor('#ADEEE2');
                    setPrimaryIconColor('#A2DCEE');
                    setUseDarkTheme(false);
                    break;
                case 'tle-demo':
                    setPrimaryBackgroundColor('#5683d7');
                    setPrimaryBorderColor('#5683d7');
                    setSecondaryBackgroundColor('#20414b');
                    setLowerOpacityButtonColor('#50a1bb');
                    setSecondaryLowerOpacityButtonColor('#50a1bb');
                    setPrimaryIconColor('#5683d7');
                    setUseDarkTheme(true);
                    break;
                case 'lostestate':
                    setPrimaryBackgroundColor('#5683d7');
                    setPrimaryBorderColor('#5683d7');
                    setSecondaryBackgroundColor('#20414b');
                    setLowerOpacityButtonColor('#50a1bb');
                    setSecondaryLowerOpacityButtonColor('#50a1bb');
                    setPrimaryIconColor('#5683d7');
                    setUseDarkTheme(true);
                    break;
                case 'nellyfood':
                    setPrimaryBackgroundColor('#64CCC9');
                    setPrimaryBorderColor('#65fbab');
                    setSecondaryBackgroundColor('#e0fae0');
                    setLowerOpacityButtonColor('#64ccc95c');
                    setSecondaryLowerOpacityButtonColor('#64ccc95c');
                    setPrimaryIconColor('#64CCC9');
                    setUseDarkTheme(false);
                    break;

                case 'spencertest1':
                    setPrimaryBackgroundColor('#A2DCEE');
                    setPrimaryBorderColor('#A2DCEE');
                    setSecondaryBackgroundColor('#9A9CEA');
                    setLowerOpacityButtonColor('#ADEEE2');
                    setSecondaryLowerOpacityButtonColor('#ADEEE2');
                    setPrimaryIconColor('#A2DCEE');
                    setUseDarkTheme(false);
                    break;

                default:
                    setPrimaryBackgroundColor('#FFDD02');
                    setPrimaryBorderColor('#FFDD02');
                    setSecondaryBackgroundColor('#faf6e0');
                    setLowerOpacityButtonColor('rgba(255, 221, 2, 0.3)');
                    setSecondaryLowerOpacityButtonColor('rgba(255, 221, 2, 0.1)');
                    setPrimaryIconColor('#FFDD02');
                    setUseDarkTheme(false);
                    break;
            }
        }
    }, [venue]);

    return {
        primaryBackgroundColor,
        primaryBorderColor,
        secondaryBackgroundColor,
        lowerOpacityButtonColor,
        secondaryLowerOpacityButtonColor,
        useDarkTheme,
        primaryIconColor,
    };
};

export default useColors;
