import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generateBulkPrice, generateDiscountPrice } from '../../../utils/pricingCalculator';
import useCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import Button from '../../Widgets/Button';
import Styles from './styles.module.scss';
import CountComponent from '../../Widgets/CountComponent/CountComponent';

interface IActionBarProps {
    dispatch: any;
    isContinue: boolean;
    menuItem: IMenuItem;
    extras: any;
    selectedOption: string | null;
    itemCount: number;
    position: number;
    isShownTags: boolean;
    displayAddToCart: boolean;
    addToCart: AddToCartFunction;
    count: number;
    changeCount: Function;
}

export function ActionBar({
    dispatch,
    isContinue,
    menuItem,
    extras,
    selectedOption,
    itemCount,
    position,
    isShownTags,
    displayAddToCart,
    addToCart,
    count,
    changeCount,
}: IActionBarProps) {
    const menuItemExtras = useSelector((state: any) => state.MenuItemsAsExtra);
    const { formatCurrency } = useCurrency();
    const [price, setPrice] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        const priceVal = calculatePrice(selectedOption, menuItem, extras, itemCount, menuItemExtras);
        if (price !== priceVal) {
            setPrice(priceVal);
        }
        // eslint-disable-next-line
    }, [selectedOption, menuItem, extras, itemCount]);

    const formattedPrice = formatCurrency(price);

    return (
        <div
            style={{
                // position: "absolute",
                height: '8rem',
                // bottom: "0",
                width: '100%',
                transition: '0.4s opacity ease-in',
                opacity: isShownTags ? '0.01' : '1',
                zIndex: isShownTags ? -1 : 'unset',
                position: 'sticky',
                top: 'calc(100vh - 6rem)',
            }}
        >
            <div className={Styles.BottomBar}>
                <CountComponent
                    noDelete={true}
                    count={count}
                    onMinusClick={() => {
                        if (count !== 1) {
                            changeCount(count - 1);
                        }
                    }}
                    onPlusClick={() => {
                        changeCount(count + 1);
                    }}
                    choiceBox={false}
                />
                <div
                    id="ContinueOrAddToCartDiv"
                    style={{
                        flex: '3',
                        marginLeft: '2px',
                    }}
                >
                    <Button
                        isYoelloBtn
                        style={{ flexDirection: 'row-reverse' }}
                        disabled={false}
                        //@ts-ignore
                        onClick={addToCart}
                        dataComponentName="ContinueAddToCartButton"
                        embeddedComponent={
                            <div className={Styles.ButtonTxt}>
                                <p>{t(`buttons.AddToCart`)}</p>
                                <p>{formattedPrice}</p>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
}

function calculatePrice(selectedOption, menuItem, extras, itemCount, menuItemExtras) {
    if (selectedOption && selectedOption.length > 0) {
        const pricingOptions = menuItem.menu_item_pricing_options[selectedOption];
        let price = pricingOptions?.option_price;
        if (pricingOptions?.option_bulk_qty > 0) {
            if (pricingOptions?.option_discount) {
                price =
                    (pricingOptions?.option_price -
                        (pricingOptions?.option_price * pricingOptions?.option_discount) / 100) *
                    pricingOptions.option_bulk_qty;
            } else {
                price = generateBulkPrice(pricingOptions?.option_price, pricingOptions?.option_bulk_qty);
            }
        }
        if (extras !== undefined) {
            Object.keys(extras).forEach((extra) => {
                const extrasArray = extras[extra];
                /**for each extra item add the price to the total item */
                extrasArray.forEach((extraItems) => {
                    const menuItem = menuItemExtras[extraItems.id];
                    const extraPricing = menuItem.extra_pricing_options_extras[extraItems.priceName];
                    const selectedExtraQuantity = extraItems.count;
                    /**if has a bulk option price */
                    if (extraPricing.option_bulk_qty > 0) {
                        /**if the object has a discount account for discount */
                        if (extraPricing.option_discount) {
                            price +=
                                generateBulkPrice(
                                    generateDiscountPrice(extraPricing.option_price, extraPricing.option_discount),
                                    extraPricing.option_bulk_qty,
                                ) * selectedExtraQuantity;
                        } else {
                            /**Add the pricing option and times it by the amount of items */
                            price +=
                                generateBulkPrice(extraPricing.option_price, extraPricing.option_bulk_qty) *
                                selectedExtraQuantity;
                        }
                    } else if (extraPricing.option_discount) {
                        price +=
                            generateDiscountPrice(extraPricing.option_price, extraPricing.option_discount) *
                            selectedExtraQuantity;
                    } else {
                        price += extraPricing.option_price * selectedExtraQuantity;
                    }
                });
            });
        }
        return price * itemCount;
    }
    return 0;
}
