import { useEffect, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from '../Auth/ProtectedRoute';
import OrdersRoutes from '../Orders/OrdersRoute';
import {
    ROOT,
    LOGIN,
    PAYMENT,
    VENUE,
    GROUP,
    PAYMENT_VERIFICATION,
    PAY360_VERIFICATION,
    NOT_FOUND,
    LOGINV2,
    ORDERS,
    ACCOUNT,
    OPENBANKING,
    NOT_MENU_AVAILABLE,
} from '../../constants/URL.const';
import PaymentStore from '../Payment/PaymentStore';
import { useNotifications } from '../../customHooks/useNotification';
import { VenueRouter } from './VenueRouter';
import PaymentContainer from '../Payment/PaymentContainer';
import Layout from '../layout/Layout';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { GroupRouter } from './GroupRouter';
import useFonts from '../../customHooks/useFonts';
import useColors from '../../customHooks/useColors';
import { useMultiVendorBasket } from '../../customHooks/useMultiVendorBasket';
import OpenBankingContainer from '../Payment/OpenBankingContainer';
import LoginV2 from '../LoginV2';

const ldClientId = process.env.REACT_APP_LD_CLIENT_ID ?? '62fcebd01f96ea11a39b2392';

function Router() {
    const venue = useSelector((state: any) => state.Venue);
    const { isMultiVendorBasket } = useMultiVendorBasket();
    const { primaryFont } = useFonts();
    const { useDarkTheme } = useColors();

    useEffect(() => {
        let body = document.body;
        body.style.fontFamily = primaryFont;
        if (useDarkTheme) {
            body.classList.add('dark-theme');
        } else {
            body.classList.remove('dark-theme');
        }
    }, [primaryFont, useDarkTheme]);

    return (
        <LDProvider
            clientSideID={ldClientId}
            deferInitialization
            reactOptions={{
                useCamelCaseFlagKeys: true,
            }}
            user={{ key: venue && venue.id, kind: 'user' }}
        >
            <BrowserRouter>
                <Layout>
                    <>
                        <RouterNotificationHandler />
                        <Switch>
                            <Route path={ROOT} exact component={lazy(() => import('../WithoutUrl/WithoutUrl'))} />
                            <Route path={[LOGIN, LOGINV2]} component={LoginV2} />
                            <ProtectedRoute path={ACCOUNT} component={lazy(() => import('../AccountContainer'))} />
                            <Route
                                path={PAY360_VERIFICATION}
                                exact
                                component={lazy(() => import('../Payment/PaymentVerification'))}
                            />
                            <Route
                                path={PAYMENT_VERIFICATION}
                                exact
                                component={lazy(() => import('../Payment/PaymentVerification'))}
                            />

                            {!isMultiVendorBasket && (
                                <Route
                                    path={OPENBANKING}
                                    render={() => <PaymentStore Component={OpenBankingContainer} />}
                                />
                            )}
                            <ProtectedRoute
                                path={PAYMENT}
                                render={() => <PaymentStore Component={PaymentContainer} />}
                            />

                            <Route path={GROUP} component={GroupRouter} />
                            <Route path={VENUE} component={VenueRouter} />

                            <ProtectedRoute path={ORDERS} component={OrdersRoutes} />
                            <Route
                                path={NOT_MENU_AVAILABLE}
                                exact
                                component={lazy(() => import('../Venue/NotMenuAvailable/NotMenuAvailable'))}
                            />
                            <Route
                                path={NOT_FOUND}
                                exact
                                component={lazy(() => import('../Error/NotFoundComponent'))}
                            />
                            <Route component={lazy(() => import('../WithoutUrl/WithoutUrl'))} />
                        </Switch>
                    </>
                </Layout>
            </BrowserRouter>
        </LDProvider>
    );
}

export default Router;

function RouterNotificationHandler() {
    useNotifications();

    return null;
}
