import makeStyles from '@material-ui/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Styles from '../styles.module.scss';
import Button from '../../Widgets/Button';
import { useForgeotPasswordView } from './useForgeotPasswordView';
import PageComponent from '../../Page/PageComponent';
import { useTranslation } from 'react-i18next';
import useColors from '../../../customHooks/useColors';

export interface IForgotPassword {
    email: string;
    handleUpdateEmail(value: string): void;
    handleEmailSubmit(email: string): void;
    sendCodeError: string | null;
    isValidEmail: boolean;
    codeLoading: boolean;
}

//Forgot password step
const ForgotPasswordView = () => {
    const { primaryBorderColor, useDarkTheme } = useColors();
    const useStyles = makeStyles({
        label: {
            width: '100%',
        },
        labelText: {
            color: '#626262',
            fontSize: '0.875rem',
            lineHeight: '2rem',
        },
        input: {
            border: 'none',
            outline: 'unset',
            width: '100%',
            position: 'relative',
            '& .MuiInput-root': {
                border: `1px solid ${useDarkTheme ? primaryBorderColor : '#F9F9F9'}`,
                backgroundColor: '#F9F9F9',
                transition: '0.25s ease-in-out',
                borderRadius: '0.5rem',
                width: '100%',
                display: 'block',
                boxSizing: 'border-box',
                '&.Mui-error': {
                    border: '1px solid #F44336',
                },
                '&.Mui-focused': {
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${primaryBorderColor}`,
                },
                '& input': {
                    padding: '1.6rem',
                    boxSizing: 'border-box',
                },
            },
        },
        inputWrapper: {
            marginBottom: 24,
        },
        errorText: {
            marginTop: 10,
            color: 'red',
        },
        title: {
            fontSize: '1.375rem',
            fontWeight: 600,
            marginBottom: 12,
            '@media (min-width: 768px)': {
                textAlign: 'center',
            },
        },
        subtitle: {
            '@media (min-width: 768px)': {
                textAlign: 'center',
            },
        },
    });
    const classes = useStyles();
    const { t } = useTranslation();
    const { email, handleUpdateEmail, handleEmailSubmit, isValidEmail, codeLoading = false } = useForgeotPasswordView();

    return (
        <div className={Styles.Login}>
            <div>
                <h2 className={Styles.Text1}>{t('ForgotPassword.Texts.forgottenPasswordQuery')}</h2>
                <p className={Styles.Text2}>{t('ForgotPassword.Texts.forgottenPasswordQuerySubtext')}</p>
                <div className={classes.inputWrapper}>
                    <label className={classes.label}>
                        <span className={classes.labelText}>{t('Account.Details.Email')}</span>
                        <TextField
                            //@ts-ignore
                            value={email}
                            name="Email"
                            className={classes.input}
                            InputProps={{ disableUnderline: true }}
                            type="text"
                            onChange={(evt) => handleUpdateEmail(evt.target.value)}
                        />
                    </label>
                </div>
                <div>
                    <Button
                        onClick={handleEmailSubmit}
                        disabled={!isValidEmail}
                        dataComponentName="UpdateEmailButton"
                        title={'Submit'}
                        isYoelloBtn
                        isLoading={codeLoading}
                        className="dark-theme-uninvert-svg-div"
                    />
                </div>
            </div>
        </div>
    );
};

export default PageComponent(ForgotPasswordView);
