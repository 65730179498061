import Styles from '../styles.module.scss';
import DynamicForm from '../../../yoello-lib/modules/dynamicForms/DynamicForms';
import makeStyles from '@material-ui/styles/makeStyles';
import useGenerateResetForm from './useGenerateResetForm';
import { SubmissionError, useForgeotPasswordView } from './useForgeotPasswordView';
import PageComponent from '../../Page/PageComponent';
import { useTranslation } from 'react-i18next';

export interface IResetPasswordStep {
    handlePasswordSubmit(data: any): void;
    email: string;
    setEmail(val: string): void;
    submissionError: SubmissionError | null;
    formSubmitting: boolean;
}

const ResetPasswordView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { resetPassword, formSubmitting } = useForgeotPasswordView();
    const { resetFormRows, oneTimeCode } = useGenerateResetForm(formSubmitting);

    return (
        <div className={`${Styles.Login} ${classes.root}`}>
            <div className={classes.formContents}>
                <div>
                    <div>
                        <h2 className={Styles.Text1}>{t('ForgotPassword.Texts.chooseNewPassword')}</h2>
                        <p className={Styles.Text2}>{t('ForgotPassword.Texts.chooseNewPasswordSubtext')}</p>
                    </div>
                    <DynamicForm
                        formData={{}}
                        formRows={resetFormRows}
                        formKeyName="test form"
                        onHandleSubmit={(data) => resetPassword({ ...data, code: oneTimeCode.toString() } as any)}
                        useFormOptions={{
                            mode: 'onBlur',
                            reValidateMode: 'onChange',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        height: 'calc(100% - 10rem)!important',
    },
    formContents: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '70%',
    },
    errorText: {
        marginTop: 10,
        color: 'red',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '30%',
    },
    title: {
        fontSize: '1.375rem',
        fontWeight: 600,
        marginBottom: 12,
        '@media (min-width: 768px)': {
            textAlign: 'center',
        },
    },
    subtitle: {
        '@media (min-width: 768px)': {
            textAlign: 'center',
        },
    },
});

export default PageComponent(ResetPasswordView);
