import { lazy, Suspense, useState } from 'react';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import Styles from './styles.module.scss';
import { IConfirmOrderProps } from './types.d';
import { SelectedOrderType } from '../../Payment/types.d';
import { _ChangeTable } from '../../../redux/actions/index';
import { ReactComponent as MouseIcon } from '../../../imges/BlueMouseIcon.svg';
import { AlertStatusCard } from '../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard';
import { Header } from './Header';
import { OrderConfirmation } from './OrderConfirmation';
import { useMultiVendorBasket } from '../../../customHooks/useMultiVendorBasket';
import useGuestUserData from '../../../customHooks/useGuestUserData';
import { Skeleton } from '@material-ui/lab';
import PageLoading from '../../Page/PageLoading';


const VenueReviews = lazy(() => import('./VenueReviews'));
const Title = lazy(() => import('./Title'));
const OrderDetails = lazy(() => import('./OrderDetails'));
const Button = lazy(() => import('../Button'));
const ABTestContainer = lazy(() => import('./ABTestContainer'));

function ConfirmOrder(props: IConfirmOrderProps) {
    const { enableConsumerAbTesting } = useFlags();
    const [showABTestForm, setShowABTestForm] = useState<boolean>(false)
    const { isMultiVendorBasket } = useMultiVendorBasket();
    const { isGuest } = useGuestUserData();
    const VenueReviewsEnabled = useSelector((state: any) => state.Venue?.reviews_enabled);
    const { t } = useTranslation();
    return (
        <>
            {showABTestForm ? (
                <Suspense fallback={<PageLoading />}>
                    <div className={Styles.ABTestContainer}>
                        <Header CloseDrawer={props.CloseDrawer} navigateBack={() => setShowABTestForm(false)} />
                        <ABTestContainer />
                    </div>
                </Suspense>
            ) : (
                <div className={Styles.ConfirmPayment}>
                    <Header {...props} />
                    <div className={Styles.ConfirmOrderContainer}>
                        <Suspense fallback={<Skeleton height="2rem" />}>
                            <Title />
                        </Suspense>
                        <div
                            style={{
                                marginTop: '0.625rem',
                            }}
                        >
                            <OrderConfirmation {...props} />

                            {enableConsumerAbTesting && (
                                <Suspense fallback={<Skeleton height="5rem" />}>
                                    <Button
                                        title={'ShapeOurFuture'}
                                        iconClassName="consumer-right-arrow"
                                        onClick={() => setShowABTestForm(true)}
                                        isYoelloBtn
                                        dataComponentName={'OnlineSurveyButton'}
                                        style={{ marginTop: '1.25rem' }}
                                    />
                                </Suspense>
                            )}

                            {props.selectedOrderType === SelectedOrderType.COLLECTION_POINT && (
                                <div className={Styles.infoBox}>
                                    <AlertStatusCard
                                        alertTitle={<b>{t('Payment.OrderNotification')}</b>}
                                        alertMessage={
                                            isMultiVendorBasket ? (
                                                <p>
                                                    <Trans
                                                        i18nKey={'Payment.MultiOrderNotificationDesc'}
                                                        components={{ bold: <strong /> }}
                                                    />
                                                </p>
                                            ) : (
                                                <p>{t(`Payment.OrderNotificationDesc`)}</p>
                                            )
                                        }
                                        alertProps={{
                                            color: 'info',
                                            icon: <MouseIcon />,
                                        }}
                                    />
                                </div>
                            )}
                            {isMultiVendorBasket && isGuest ? null : (
                                <Suspense fallback={<Skeleton height="3rem" />}>
                                    <OrderDetails {...props} />
                                </Suspense>
                            )}
                            {VenueReviewsEnabled && !isMultiVendorBasket && (
                                <Suspense fallback={<Skeleton height="5rem" />}>
                                    <VenueReviews orderID={props.history?.location?.state?.orderLongID} />
                                </Suspense>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps, {
    _ChangeTable,
})(ConfirmOrder);
