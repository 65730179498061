import { useSelector } from 'react-redux';
import { addTaxes } from '../../../yoello-lib/modules/utils/TaxController';
import { IOrderBody, OrderOption } from '../../../typings/IPayment.d';
import { SelectedOrderType, IDiscountStoreData } from '../types.d';
import { roundPriceOption } from '../../../utils/pricingCalculator';
import { IAddCartItem } from '../../../redux/Reducers/Cart';
import { itemTotalCount } from '../../../utils/cartController';
import { DiscountType } from '../Dsicounts/DiscountsComponent';
import { getAvailableMenuId } from '../../Utils/getAvailableMenuId';

const useGenerateMultiVendorOrderBody = () => {
    const Venues = useSelector((state: any) => state.Group.venues);
    const cartID = useSelector((state: any) => state.CartID);

    const generateOrderBody = (
        isUsingPaymentRequest: boolean,
        cartItemTaxAmount: number,
        serviceTaxAmount: number,
        message: string,
        selectedOrderType: any,
        tipTotal: number,
        areaName: string,
        selectedTable: string,
        tableAreaId: string,
        totalPrice: number,
        discounts?: IDiscountStoreData,
        orderOnly?: boolean,
        cart?: any,
        key?: string,
    ) => {
        const taxTotal = addTaxes(cartItemTaxAmount, serviceTaxAmount, null);
        const tip = roundPriceOption(tipTotal);
        const orderBody: Partial<IOrderBody> = {
            venue_id: Venues[key].id,
            intent: isUsingPaymentRequest,
            comment: message,
            gratuity: tip,
            delivery_table: selectedOrderType === SelectedOrderType.TABLE ? selectedTable : null,
            total_price: roundPriceOption(totalPrice - tipTotal + tip + taxTotal),
            table_area: selectedOrderType === SelectedOrderType.TABLE ? areaName : null,
            table_area_id: selectedOrderType === SelectedOrderType.TABLE ? tableAreaId : null,
            order_type: selectedOrderType,
            menu_items: [],
            group_id: Venues[key].group_id,
            cart_id: cartID,
            order_option: orderOnly ? OrderOption.ORDER_ONLY : OrderOption.ORDER_AND_PAY,
        };

        const menuItems = cart?.map((cartItem: IAddCartItem) => {
            let extras: {
                menu_id?: string;
                extra_id: string;
                extra_pricing_option_key: string;
            }[] = [];
            const menuId =
                cartItem.menuItem.menu_id ??
                getAvailableMenuId(cartItem.menuItem.menu_ids, Venues[key].available_menus);
            if (cartItem.extras) {
                for (const extraKey in cartItem.extras) {
                    const extraArray = cartItem.extras[extraKey];
                    extraArray.forEach((extra) => {
                        extras.push({
                            menu_id: menuId,
                            extra_id: extra.id,
                            extra_pricing_option_key: extra.priceName,
                            // @ts-ignore
                            quantity: extra.count ? extra.count * cartItem.itemCount : cartItem.itemCount,
                        });
                    });
                }
            }
            const { menuItem, measure, itemCount, preferences } = cartItem;

            return {
                menu_id: menuId,
                menu_item_id: menuItem.menu_item_id,
                quantity: itemTotalCount(menuItem, measure, itemCount),
                pricing_option_key: measure,
                extras: extras,
                modifiers: [],
                preferences: preferences
                    ? Object.keys(preferences).map((preference) => {
                          return preferences[preference];
                      })
                    : [],
            };
        });
        orderBody.menu_items = menuItems;
        if (!discounts?.error && discounts?.value) {
            orderBody.discount = {
                id: discounts.id,
                type: discounts.absoluteValue ? DiscountType.ABSOLUTE : discounts.type,
                value: discounts.absoluteValue ?? discounts.value,
                code: discounts.code,
                metadata: null,
            };
        }
        return orderBody;
    };

    return {
        generateOrderBody,
    };
};

export default useGenerateMultiVendorOrderBody;
