import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { ORDERS } from '../../constants/URL.const';
import useGuestUserData from '../../customHooks/useGuestUserData';
import { useMultiVendorBasket } from '../../customHooks/useMultiVendorBasket';

/**Gets the starting route to determine type of navigation method */
export function useOrdersNavigation() {
    const location = useLocation();
    const history = useHistory();
    const { isGuest } = useGuestUserData();
    const Venue = useSelector((state: any) => state.Venue);
    const Group = useSelector((state: any) => state.Group);
    const { isGroupVenue } = useMultiVendorBasket();
    const isStartRoute = useMemo(() => {
        if (location.pathname === ORDERS) {
            return true;
        }
        return false;
        //eslint-disable-next-line
    }, []);

    function navigateBack() {
        if (location.pathname.includes(`review`)) {
            const orderID = location.pathname.split('/')[2];
            history.replace(`${ORDERS}/${orderID}`);
            return;
        }
        if (isGuest) {
            if (isGroupVenue) {
                history.push(`/g/${Group.nickname}`);
            } else {
                history.push(`/v/${Venue.venue_nickname}`);
            }
            return;
        }
        if (isStartRoute) {
            history.goBack();
            return;
        }
        if (location.pathname === ORDERS) {
            if (isGroupVenue) {
                history.push(`/g/${Group.nickname}`);
            } else {
                history.push(`/v/${Venue.venue_nickname}`);
            }
        } else if (location.pathname.includes(`${ORDERS}/`)) {
            history.push(ORDERS);
        } else {
            history.goBack();
        }
        // }
    }
    return { navigateBack, isStartRoute };
}
