import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PaymentContext } from '../PaymentStore';

import { paymentContainerInitalState } from '../PaymentStore';

import useGetRedirectUrl from './useGetRedirectUrl';
import { changeStage, resetState, changeOrderType } from '../reducer/PaymentActions';
import { isObject } from '../../../utils/ObjectCheck';
import { PaymentStages } from '../types.d';

import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { CART } from '../../../constants/URL.const';
import { useAPIV2 } from '../../../yoello-lib/modules/API2/useAPIv2';
import APIExtensions from '../../../yoello-lib/modules/API2/APIDefs';

const usePaymentView = () => {
    const { t } = useTranslation();
    const venue = useSelector((state: any) => state.Venue);
    const cart = useSelector((state: any) => state.Cart);
    const group = useSelector((state: any) => state.Group);
    const selectedMenuType = useSelector((state: any) => state.SelectedMenuType);
    const { pathname } = useLocation();
    const { paymentStages, dispatch } = useContext(PaymentContext);
    const { getRedirectURL } = useGetRedirectUrl();
    const history = useHistory();
    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const { callAPI: callPlacedMultiOrdersAPI } = useAPIV2<any>(APIExtensions.MultiVendorplaceOrder, {
        canAPIGetCacheValue: false,
        doNotCacheValue: true,
        onMountDisabled: true,
    });

    function backFunction(disabledItems: any[] = []) {
        if (paymentStages === PaymentStages.PAYMENT) {
            let url = getRedirectURL();
            /**if they go back to the menu */
            if (url !== '/') {
                url += CART;
            }
            history.push(url, { disabledItems });
        } else {
            dispatch(changeStage(PaymentStages.PAYMENT));
        }
    }

    useEffect(() => {
        dispatch(changeOrderType(selectedMenuType));
        // eslint-disable-next-line
    }, [selectedMenuType]);

    useEffect(() => {
        if (!isObject(venue, true) && !pathname.includes('ob')) {
            sendSnackbarMessage(t('Payment.Errors.NoVenue').toString(), 'error');
        }

        return () => {
            /**On unmount reset the state */
            dispatch(resetState(paymentContainerInitalState));
        };
        // eslint-disable-next-line
    }, []);

    return {
        venue,
        cart,
        group,
        selectedMenuType,
        getRedirectURL,
        backFunction,
        dispatch,
        callPlacedMultiOrdersAPI,
    };
};

export default usePaymentView;
