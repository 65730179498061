import { useState } from 'react';
import Axios from 'axios';
import { useSelector } from 'react-redux';

import { usePaymentState } from '../../Components/Payment/PaymentStore';
import { generateSquareEndpoint } from './generateEndpoints';

const getSquareUser = (url: string, token: string, venue_id: string) => {
    return Axios.get(url, {
        params: { venue_id },
        headers: { Authorization: `Bearer ${token}` },
    });
};

const createSquareUser = (url: string, token: string, venue_id: string) => {
    return Axios.post(
        url,
        {},
        {
            headers: { Authorization: `Bearer ${token}` },
            params: { venue_id },
        },
    );
};

const useSquareUser = () => {
    const venue = useSelector((state: any) => state.Venue);
    const url = generateSquareEndpoint('consumers');
    const { retrieveToken, isAuthenticated } = usePaymentState();
    const [userLinked, setUserLinked] = useState<boolean>();

    const handleSquareUser = () => {
        if (isAuthenticated && !userLinked) {
            retrieveToken().then((userToken) => {
                return getSquareUser(url, userToken, venue.id)
                    .then((response) => {
                        setUserLinked(response.data.data.consumer_linked_to_square_account);
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            createSquareUser(url, userToken, venue.id)
                                .then((response) => {
                                    setUserLinked(response.data.data.consumer_linked_to_square_account);
                                })
                                .catch((error) => {
                                    setUserLinked(false);
                                });
                        } else {
                            //@TODO - error handling
                            console.log('something went wrong');
                            setUserLinked(false);
                        }
                    });
            });
        }
    };

    return {
        userLinked,
        handleSquareUser,
    };
};

export default useSquareUser;
