import Styles from './paymentPageSkeleton.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';
export default function PaymentButtonSkeleton() {
    return (
        <div className={Styles.PaymentButton}>
            {[...Array(2).keys()].map(() => (
                <Skeleton />
            ))}
        </div>
    );
}

export const PaymentInformationLoading = () => (
    <>
        <Skeleton style={{ marginBottom: '1rem', width: '100%' }} height={'4rem'} />
        <Skeleton style={{ marginBottom: '1rem', width: '100%' }} height={'4rem'} />
    </>
);

export const PaymentNavigation = () => (
    <div className={Styles.header}>
        <div className={Styles.headerBtn}>
            <Skeleton />
        </div>
        <div className={Styles.headerImg}>
            <Skeleton />
        </div>
        <div className={Styles.headerBtn}>
            <Skeleton />
        </div>
    </div>
);
