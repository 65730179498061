import ICommonFormProps from './interfaces/ICommonFormProps';

interface IPermissionReturn {
    isDisabled: boolean;
    isHidden: boolean;
}

export function generatePermission(
    disabledFields?: Record<string, Record<string, string[]>>,
    subscriptionKey?: string,
    formRowKey?: string,
    isDisabled?: boolean,
): IPermissionReturn {
    const permissionObject = {
        isDisabled: false,
        isHidden: false,
    };
    if (isDisabled && isDisabled === true) {
        permissionObject.isDisabled = true;
        return permissionObject;
    }
    if (disabledFields && subscriptionKey && formRowKey) {
        const formRow = disabledFields[formRowKey];
        /**Does form row exist for this key */
        if (formRow) {
            const subscriptionRow = formRow[subscriptionKey];
            /**Does it have a subscription row */
            if (subscriptionRow && Array.isArray(subscriptionRow)) {
                /**If its an array an empty its a hidden object
                 * if its not got write permissions then its disabled*/
                if (subscriptionRow.length === 0) {
                    permissionObject.isHidden = true;
                } else if (subscriptionRow.indexOf('Write') === -1) {
                    permissionObject.isDisabled = true;
                }
            }
        }
    }
    return permissionObject;
}

export function isJustSpace(value: string): boolean {
    const regex = new RegExp(/\s/);
    return regex.test(value);
}

/**Checks agaisnt the value and to set it - used for dirtying the form */
export function isValue(value: any, ignoreLength?: boolean) {
    if (value && Array.isArray(value) && (value.length > 0 || ignoreLength)) {
        return true;
    }
    if (value && typeof value === 'object' && (Object.keys(value).length > 0 || ignoreLength)) {
        return true;
    }
    if (value && typeof value === 'string') {
        return true;
    }
    return false;
}

/**Transforms the type of value data to the type of its form value */
export function typeTransform(value: any, formValue: any) {
    const valueType = typeof value;
    const formType = typeof formValue;
    if (valueType !== formType) {
        return parseType(formType, value);
    }
    return value;
}

/**Helper function for parsing the form type agaisnt the value type inside dynamic forms */
function parseType(
    formType: 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function',
    newValue: any,
) {
    switch (formType) {
        case 'number':
            return parseInt(newValue);
        default:
            return newValue;
    }
}

export function generateRegister(props: ICommonFormProps) {
    let spreadObj: any = {};
    if (props.register && typeof props.register === 'function') {
        const registerOps = props.register(props.keyName, {
            ...props.registerOptions,
        });
        spreadObj = { ...registerOps, inputRef: registerOps.ref };
    }
    return spreadObj;
}

export const onKeyboardPressHandler = (event: any) => {
    console.log(event.currentTarget);
    if (event.key === 'Enter') {
        blurKeyBoard(event.currentTarget);
    }

    // event
};

export const blurKeyBoard = (document: any) => {
    if (document && document.blur && typeof document.blur === 'function') {
        console.log('Attempting to blur');
        document.blur();
    }
};
