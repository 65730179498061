import { lazy, Suspense } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { useWindowWidth } from '../../customHooks/useWindowWidth';
import { useStyles } from './styles';
import PageLoading from '../Page/PageLoading';

interface ILayout {
    children: React.ReactNode;
}

const NotProductAvailable = lazy(() => import('../Venue/NotProductAvailable/NotProductAvailable'));

const NotProductAvailableComponent = () => (
    <Suspense fallback={<PageLoading />}>
        <NotProductAvailable />
    </Suspense>
);

const Layout = ({ children }: ILayout) => {
    const { root } = useStyles();
    const { disableConsumerApp } = useFlags();
    const width = useWindowWidth({ isResizeable: true });
    const isMobileView = width < 768;

    useEffect(() => {
        // this is for removing previusly logged in users with Auth0
        const key = localStorage.getItem(`user_token_${window.location.host}`);
        if (key) {
            localStorage.removeItem(`user_token_${window.location.host}`);
            window.location.reload();
        }
    }, []);

    if (isMobileView) {
        return <>{disableConsumerApp ? <NotProductAvailableComponent /> : children}</>;
    } else {
        return <div className={root}>{disableConsumerApp ? <NotProductAvailableComponent /> : children}</div>;
    }
};

export default Layout;
