import React, { ComponentType, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import NavigationHeader from '../NavigationHeader/NavigationHeader';
import { IPageComponentProps } from '../Page/types';
import LoginRouter from './LoginRouter';
import useGenerateNavPath from '../../customHooks/useGenerateNavPath';
import {
    VENUE,
    LOGINV2_VERIFY,
    LOGINV2_PROFILE,
    LOGIN_EMAIL_ERROR,
    LOGINV2_EMAIL_ERROR,
    COGNITO_OAUTH_REDIRECT,
    LOGIN_PROFILE,
    LOGINV2_FORGOT_PASSWORD,
    LOGINV2_PASSWORD,
    LOGINV2_RESTE_PASSWORD,
    LOGINV2_START,
    LOGINV2_GUEST,
    PAYMENT,
} from '../../constants/URL.const';
import useGetToAndFromPaths from './useGetToAndFromUrls';
import { Route, Switch } from 'react-router-dom';
import EmailErrorView from './EmailErrorView';
import Styles from './styles.module.scss';
import PageComponent from '../Page/PageComponent';
import { useAuthenticationContext } from '../../yoello-lib/modules/auth/AuthenticationContext';
import { OAuthRedirector } from './OAuthRedirector';
import ProfileView from './ProfileView';

// import EmailSentView from "./EmailSentView";

function LoginContainer({ openSideDrawer }: IPageComponentProps) {
    const history = useHistory();
    const location = useLocation();
    const specificMenuId = useSelector((state: any) => state.specificMenuId);
    const { compareNavPath, generateNavPath } = useGenerateNavPath();
    // const { isAuthenticated, logout } = useAuth0()!;
    const { isAuthenticated, userLogout } = useAuthenticationContext();
    const { fromPath } = useGetToAndFromPaths('login', '', VENUE);
    const [referrerPath, setReferrerPath] = useState('');

    const exitLogin = () => {
        if (
            (referrerPath === generateNavPath(VENUE) || referrerPath + '/' === generateNavPath(VENUE)) &&
            Boolean(specificMenuId)
        ) {
            history.push(referrerPath + `?menu_id=${specificMenuId}`);
        } else {
            history.push(referrerPath);
        }
    };

    useEffect(() => {
        setReferrerPath(generateNavPath(fromPath));
        //eslint-disable-next-line
    }, [fromPath]);

    async function goBack() {
        if (compareNavPath(location.pathname, LOGINV2_VERIFY)) {
            history.push(generateNavPath(LOGINV2_START));
            return;
        }
        if (compareNavPath(location.pathname, LOGINV2_GUEST)) {
            history.push(
                generateNavPath(LOGINV2_START, {
                    to: PAYMENT,
                }),
            );
            return;
        }
        if (compareNavPath(location.pathname, LOGINV2_PASSWORD)) {
            history.goBack();
            return;
        }
        if (compareNavPath(location.pathname, LOGINV2_FORGOT_PASSWORD)) {
            history.goBack();
            return;
        }
        if (compareNavPath(location.pathname, LOGINV2_RESTE_PASSWORD)) {
            history.goBack();
            return;
        }
        if (compareNavPath(location.pathname, LOGINV2_PROFILE)) {
            if (isAuthenticated) {
                await userLogout();
                history.push(fromPath);
            }
            return;
        }
        exitLogin();
    }
    return (
        <div className={Styles.LoginWrapper}>
            <NavigationHeader backURL={goBack} openSideDrawer={openSideDrawer} />
            <Switch>
                <Route exact path={COGNITO_OAUTH_REDIRECT} component={OAuthRedirector} />
                <Route exact path={[LOGIN_PROFILE, LOGINV2_PROFILE]} component={ProfileView} />
                <Route exact path={[LOGIN_EMAIL_ERROR, LOGINV2_EMAIL_ERROR]} component={EmailErrorView} />
                <Route component={LoginRouter} />
            </Switch>
        </div>
    );
}

export default PageComponent(LoginContainer as ComponentType);
