import makeStyles from '@material-ui/styles/makeStyles';
import ICommonFormProps from '../../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '1rem',
        color: '#737FA4',
        position: 'absolute',
        top: '-1.5rem',
        margin: 0,
    },
});

export default function Description(props: ICommonFormProps) {
    const { root } = useStyles();
    const { t } = useTranslation();
    return <p className={root}>{t(`Login.Texts.${props.formProps?.text}`)}</p>;
}
