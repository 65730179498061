import { useContext } from 'react';
import Styles from '../styles.module.scss';
import { PaymentContext } from '../PaymentStore';
import Loading from '../../Widgets/ButtonsLoading';
import Button from '../../Widgets/Button';
import { ReactComponent as CloseIcon } from '../../../imges/close-icon.svg';
import { useTranslation } from 'react-i18next';
export function OnApplyComponent(props) {
    const { t } = useTranslation();
    const { discounts, basket } = useContext(PaymentContext);
    function renderButton() {
        if ((!props.venueId && !discounts.error) || (props.venueId && !basket[props.venueId]?.discounts?.error)) {
            return (
                <Button
                    onClick={props.onClick}
                    title={t('Apply') as string}
                    className={Styles.applyDiscount}
                    dataComponentName="ApplyDiscountBtn"
                    iconClassName={'consumer-add'}
                />
            );
        } else {
            return (
                <Button
                    className={Styles.appliedDiscount}
                    onClick={props.reset}
                    Icon={<CloseIcon />}
                    dataComponentName="ResetDiscountBtn"
                />
            );
        }
    }
    return <>{props.isloading ? <Loading /> : renderButton()}</>;
}
