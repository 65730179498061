import Styles from './styles.module.scss';
import { lazy, Suspense } from 'react';
import Button from '../Widgets/Button';
import { SideMenuContentLoading, SideMenuHeaderLoading } from '../Widgets/Loading/SideMenu';
import Skeleton from '@material-ui/lab/Skeleton';

const SideMenuHeader = lazy(() => import('./SideMenuHeader'));
const SideMenuContent = lazy(() => import('./SideMenuContent'));
const SideMenuFooter = lazy(() => import('./SideMenuFooter'));

export function SideMenu(props) {
    return (
        <div
            data-component-name="SideMenuContainer"
            className={Styles.SideMenu}
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <div className={Styles.Header}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Suspense fallback={<SideMenuHeaderLoading />}>
                        <SideMenuHeader />
                    </Suspense>

                    <Button
                        onClick={props.closeSideDrawer}
                        dataComponentName="ExitButton"
                        iconClassName={'consumer-close'}
                        style={{
                            color: '#FFFFFF',
                            fontSize: '1.75rem',
                            background: 'rgba(255, 255, 255, 0.15)',
                            borderRadius: '0.75rem',
                            top: '1.875rem',
                            right: '1.875rem',
                        }}
                        isHeaderBtn
                    />
                </div>
            </div>
            <Suspense fallback={<SideMenuContentLoading />}>
                <SideMenuContent {...props} />
            </Suspense>

            <Suspense fallback={<Skeleton />}>
                <SideMenuFooter {...props} />
            </Suspense>
        </div>
    );
}
