export function getDeliveryAddress() {
    if (window.localStorage.getItem('BillingAddress')) {
        let address;
        if (typeof window.localStorage.getItem('BillingAddress') !== 'object') {
            address = JSON.parse(window.localStorage.getItem('BillingAddress'));
        } else {
            address = window.localStorage.getItem('BillingAddress');
        }
        
        const deliveryAddress = {
            apartment: address?.apartment,
            street_address: address?.street_address,
            city: address?.city,
            postcode: address?.postcode,
            latitude: address?.lat,
            longitude: address?.lng,
            location_name:"Home",
            location_type:"CONSUMER_ADDRESS"
        };
        return deliveryAddress;
    }
    return null;
}
