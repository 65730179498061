import { useState } from 'react';
import { SectionHeader } from './SectionHeader';
import Styles from './orderDetails.module.scss';
import { currencyFormat } from './PaymentDetails';
import TabList, { ITabItem } from '../../Widgets/TabList/TabList';
import { RefundedItemHeader } from './RefundedItemHeader';
import { Status } from '../../Widgets/OrderStatus';
import useFormatCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import { useTranslation } from 'react-i18next';
import { IOrders, IPlaceOrders } from '../../../typings/IOrders.d';
interface IItemSummaryProps {
    orderItem: IOrders;
}
enum OrderItemDetailsTabs {
    ITEMS = 'ITEMS',
    REFUNDEDITEMS = 'REFUNDEDITEMS',
}

export function ItemSummary({ orderItem }: IItemSummaryProps) {
    const [activeTab, setActiveTab] = useState<OrderItemDetailsTabs>(OrderItemDetailsTabs.ITEMS);
    const { t } = useTranslation();
    const isPartiallyRefundedOrder = orderItem.placed_order_status === Status.PARTIALLY_REFUNDED;
    const isRefundedOrder = orderItem.placed_order_status === Status.REFUNDED;
    const tabList: ITabItem[] = [
        {
            id: OrderItemDetailsTabs.ITEMS,
            label: t('OrderHistory.Items') as string,
            value: OrderItemDetailsTabs.ITEMS,
        },
        {
            id: OrderItemDetailsTabs.REFUNDEDITEMS,
            label: t('OrderHistory.RefundedItems') as string,
            value: OrderItemDetailsTabs.REFUNDEDITEMS,
        },
    ];
    function onChangeTab(value: any) {
        setActiveTab(value);
    }

    return (
        <div className={Styles.ItemSummary}>
            <SectionHeader label={'OrderHistory.ItemSummary'} />
            {isPartiallyRefundedOrder && (
                <TabList
                    tabs={tabList}
                    defaultTab={OrderItemDetailsTabs.ITEMS}
                    onChange={onChangeTab}
                    level="primary"
                    selectedTab={activeTab}
                />
            )}
            {activeTab === OrderItemDetailsTabs.REFUNDEDITEMS && <RefundedItemHeader item={orderItem} />}
            <div className={activeTab === OrderItemDetailsTabs.REFUNDEDITEMS ? Styles.RefundedItem : undefined}>
                {orderItem.placed_order_items.map((item) => {
                    if (activeTab === OrderItemDetailsTabs.REFUNDEDITEMS) {
                        if (item.main_in_order_item_refund_quantity > 0) {
                            return (
                                <>
                                    <p className={Styles.MenuTitle}>{item.main_in_order_menu_name_public}</p>
                                    <ItemEntity
                                        key={item.main_in_order_item_id}
                                        placedOrderItems={item}
                                        orderCurrencySymbol={orderItem.currency_symbol}
                                        isPartiallyRefunded={true}
                                    />
                                </>
                            );
                        } else return null;
                    } else {
                        if (
                            item.main_in_order_item_quantity - item.main_in_order_item_refund_quantity > 0 ||
                            isRefundedOrder
                        ) {
                            return (
                                <>
                                    <p className={Styles.MenuTitle}>{item.main_in_order_menu_name_public}</p>
                                    <ItemEntity
                                        key={item.main_in_order_item_id}
                                        placedOrderItems={item}
                                        orderCurrencySymbol={orderItem.currency_symbol}
                                        isRefundedOrder={isRefundedOrder}
                                    />
                                </>
                            );
                        } else return null;
                    }
                })}
            </div>
        </div>
    );
}

interface IItemEntityProps {
    placedOrderItems: IPlaceOrders;
    orderCurrencySymbol: string;
    isPartiallyRefunded?: boolean;
    isRefundedOrder?: boolean;
}
function ItemEntity({ placedOrderItems, orderCurrencySymbol, isPartiallyRefunded, isRefundedOrder }: IItemEntityProps) {
    const {
        main_in_order_item_name_public,
        main_in_order_item_pricing_option_name,
        main_in_order_item_quantity,
        main_in_order_item_preferences,
        main_in_order_item_extras,
        main_in_order_menu_item_id,
        main_in_order_item_refund_quantity,
        main_in_order_item_discount_code_amount,
        main_in_order_item_discount_code_percentage,
        main_in_order_item_discount_code_price,
    } = placedOrderItems;
    const { formatCurrency } = useFormatCurrency();
    const { t } = useTranslation();
    const orderItems = {
        currency_symbol: orderCurrencySymbol,
        ...placedOrderItems,
    };

    function calculateQuantity(refundedQuantity: number, itemQuantity: number) {
        //displaying items quantity based on partially refund
        const rest = itemQuantity - refundedQuantity;
        return isPartiallyRefunded
            ? refundedQuantity > 1 && `${refundedQuantity}x`
            : isRefundedOrder
            ? itemQuantity > 1 && `${itemQuantity}x`
            : rest > 1 && `${rest}x`;
    }
    function roundPercentage(num: number) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
    return (
        <div className={Styles.ItemEntity} key={main_in_order_menu_item_id}>
            <p className={Styles.Title}>{main_in_order_item_name_public}</p>
            <div className={Styles.Pricing}>
                <div>
                    <p>{main_in_order_item_pricing_option_name}</p>
                    <p>
                        {calculateQuantity(main_in_order_item_refund_quantity, main_in_order_item_quantity)}
                        {currencyFormat(orderItems, main_in_order_item_discount_code_price)}
                    </p>
                </div>
                {main_in_order_item_discount_code_amount > 0 && (
                    <div className={Styles.DiscountDetails}>
                        <p className="dark-theme-uninvert">
                            {t('OrderHistory.DiscountPrice')} {formatCurrency(main_in_order_item_discount_code_amount)}{' '}
                            ({roundPercentage(main_in_order_item_discount_code_percentage)}%)
                        </p>
                        <p>
                            {formatCurrency(
                                main_in_order_item_discount_code_price + main_in_order_item_discount_code_amount,
                            )}
                        </p>
                    </div>
                )}
            </div>
            <ul>
                {main_in_order_item_preferences.map((pref) => {
                    return <li>{pref}</li>;
                })}
                {main_in_order_item_extras.map((extras) => {
                    return (
                        <li key={extras.extra_in_order_item_id}>
                            <div className={Styles.Extras}>
                                <div>
                                    <p>{extras.extra_in_order_item_name_public}</p>
                                    <p>
                                        {calculateQuantity(
                                            extras.extra_in_order_item_refund_quantity,
                                            extras.extra_in_order_item_quantity,
                                        )}
                                        {currencyFormat(orderItems, extras.extra_in_order_item_discount_code_price)}
                                    </p>
                                </div>
                                {extras.extra_in_order_item_discount_code_percentage > 0 && (
                                    <div className={Styles.DiscountDetails}>
                                        <p className="dark-theme-uninvert">
                                            {t('OrderHistory.DiscountPrice')}{' '}
                                            {formatCurrency(extras.extra_in_order_item_discount_code_amount)} (
                                            {roundPercentage(extras.extra_in_order_item_discount_code_percentage)}
                                            %)
                                        </p>
                                        <p>
                                            {formatCurrency(
                                                extras.extra_in_order_item_discount_code_price +
                                                    extras.extra_in_order_item_discount_code_amount,
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
