import React, { createContext, MutableRefObject, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useCartCheck } from '../../customHooks/useCartCheck';
import { useWindowHeight } from '../../customHooks/useWindowHeight';
import useFormatCurrency, { FormatCurrency } from '../../yoello-lib/modules/localisation/useCurrency';
import { useBack, useItemSelect, useNavigateBack } from './hooks';
import { useCart } from './hooks/useCart';
import { setIsScrolled, setListHeight, setMenuKeys, setMenuTab, setPosition } from './state/Menu.Actions';
import { menuReducerInitState, MenuReducer } from './state/Menu.Reducer';
import { IMenuItemStateMenu, IMenuReducerState, MenuElementRefs, MenuItemState, MenuPayloadTypes } from './types';

interface IMenuContext {
    state: IMenuReducerState;
    dispatch: React.Dispatch<{
        type: string;
        payload: MenuPayloadTypes;
    }>;
    setScroll: (isScroll: boolean) => void;
    menuItemState: MenuItemState;
    menuItemRaw: IMenuItem[];
    getCurrentMenu: () => IMenuItemStateMenu;
    onChangePosition: (activeKey: string) => void;
    goBack: () => void;
    generateURL: (isGroupVenue?: boolean) => string;
    handleItemSelect: (menuItem: any) => void;
    elementRefs: MutableRefObject<MenuElementRefs>;
    formatCurrency: FormatCurrency;
    activeMenuId: string;
}

export const MenuContext = createContext<IMenuContext>(undefined);

export const useMenu = () => useContext<IMenuContext>(MenuContext);

export function MenuProvider({ children }) {
    const [state, dispatch] = useReducer(MenuReducer, menuReducerInitState);
    const menuItemState = useSelector((state: any) => state.MenuItem.tree);
    const menuItemRaw = useSelector((state: any) => state.MenuItem.raw);
    const menus = useSelector((state: any) => state.MenuItem.menus);
    const { formatCurrency } = useFormatCurrency();
    const { goBack, generateURL } = useBack();
    useCart(dispatch);
    const { handleItemSelect } = useItemSelect();
    const { menuKeys, menuPosition, isScrolled, position } = state;
    const elementRefs = useRef<MenuElementRefs>({ category: [] });

    const windowHeight = useWindowHeight({ isResizeable: true });

    useNavigateBack();
    useCartCheck(generateURL, dispatch);

    useEffect(() => {
        if (menuItemState) {
            dispatch(setMenuKeys(Object.keys(menuItemState)));
        }
    }, [menuItemState]);

    const activeMenuId = useMemo(() => {
        if (menuKeys.length > 0) {
            const menuKey = menuKeys[menuPosition];
            /**Set active menu id  */
            return menus[menuKey];
        }
        // eslint-disable-next-line
    }, [menuKeys, menuPosition]);

    /**Re-create list height each time the position changes */
    useEffect(() => {
        if (Object.keys(elementRefs.current).length) {
            let newHeight = windowHeight;
            if (elementRefs.current.topMenu?.clientHeight) {
                newHeight -= elementRefs.current?.topMenu?.clientHeight;
            }
            if (elementRefs.current.navigation?.clientHeight) {
                newHeight -= elementRefs.current.navigation.clientHeight;
            }
            if (!Number.isNaN(newHeight)) {
                _setListHeight(newHeight);
            }
        }
        if (isScrolled) {
            setScroll(false);
        }
        //eslint-disable-next-line
    }, [
        elementRefs.current.category,
        elementRefs.current?.topMenu?.clientHeight,
        elementRefs.current.navigation,
        position,
        menuPosition,
        windowHeight,
    ]);

    function _setListHeight(height: number) {
        dispatch(setListHeight(height));
    }

    function setScroll(isScroll: boolean) {
        dispatch(setIsScrolled(isScroll));
    }

    function getCurrentMenu() {
        if (!menuKeys || !menuItemState) return null;
        const menuKey = menuKeys[menuPosition];
        return menuItemState[menuKey] ?? null;
    }

    function onChangePosition(activeKey: string) {
        const newKey = parseInt(activeKey);
        dispatch(setPosition(newKey));
        const menu = getCurrentMenu();

        /**Reset if no menu */
        if (!menu) {
            dispatch(setMenuTab(0));
            dispatch(setPosition(0));
            return;
        }
        /**If we swiped passed categories change menu tab */
        if (newKey > menu.endIndex) {
            dispatch(setMenuTab(menuPosition + 1));
        } else if (newKey < menu.startIndex && menuPosition > 0) {
            dispatch(setMenuTab(menuPosition - 1));
        }
    }

    return (
        <MenuContext.Provider
            value={{
                state: state as IMenuReducerState,
                dispatch,
                setScroll,
                menuItemState,
                menuItemRaw,
                getCurrentMenu,
                onChangePosition,
                goBack,
                generateURL,
                handleItemSelect,
                elementRefs,
                formatCurrency,
                activeMenuId,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
}
