import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPaymentProcessors } from '../../../Api.js';
import { setPaymentProviders } from '../reducer/PaymentActions';

/**Retrieves the current venues payment providers */
export function usePaymentProviders(dispatch: any) {
    const Venue = useSelector((state: any) => state.Venue);

    useEffect(() => {
        getPaymentProcessors(Venue.id).then(({ data }) => {
            if (data.data) {
                if (Array.isArray(data.data)) {
                    const paymentProviders = data.data.reduce((prevItem, nextItem) => {
                        return {
                            ...prevItem,
                            [nextItem]: true,
                        };
                    }, {});
                    dispatch(setPaymentProviders(paymentProviders));
                }
            }
        });
        //eslint-disable-next-line
    }, []);
}
