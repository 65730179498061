import { useContext, Suspense, lazy } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PaymentContext } from './PaymentStore';
import { PAYMENT } from '../../constants/URL.const';
import useOnSuccessfulOrder from './PaymentHooks/useOnSuccessfulOrder';
import { useSnackbarMessages } from '../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { _updateCartID } from '../../redux/actions';
import { useAuthenticationContext } from '../../yoello-lib/modules/auth/AuthenticationContext';
import PageLoading from '../Page/PageLoading';

const PaymentRouter = lazy(() =>
    import('@yoello/open_banking').then(({ PaymentRouter }) => ({ default: PaymentRouter })),
);

export enum Stage {
    prod = 'prod',
    staging = 'staging',
    dev = 'dev',
}
export default function OpenBankingContainer() {
    const { placedOrderID } = useContext(PaymentContext);
    const totalPrice = useSelector((state: any) => state.totalPrice);

    const { retrieveAccessToken } = useAuthenticationContext();
    const VenueID = useSelector((state: any) => state.Venue.id);
    const { onSuccessfulOrder } = useOnSuccessfulOrder();
    const venue = useSelector((state: any) => state.Venue);
    const { sendSnackbarMessage } = useSnackbarMessages();
    const rxdDispatch = useDispatch<any>();
    const { push } = useHistory();

    return (
        <Suspense fallback={<PageLoading />}>
            <PaymentRouter
                venueId={VenueID}
                merchant={{
                    id: venue.id,
                    merchant_name: venue.venue_name,
                    merchant_nickname: venue.venue_nickname,
                }}
                paymentAmount={totalPrice}
                onCancelProcess={function (status): void {
                    if (status) {
                        switch (status) {
                            case 'DECLINED':
                                sendSnackbarMessage('payments declined', 'error');
                                break;

                            default:
                                sendSnackbarMessage('payments failed', 'error');
                                break;
                        }
                    }
                    rxdDispatch(_updateCartID(uuidv4()));
                    push(PAYMENT);
                }}
                onComplete={function (data: any): void {
                    if (data.status) {
                        onSuccessfulOrder('', '', true);
                        return;
                    }
                }}
                paymentReference={`Yoello - ${venue.venue_name}`}
                currencyISO={venue.currency_iso}
                authoriser={() => {
                    return retrieveAccessToken();
                }}
                language="en"
                placedOrderId={placedOrderID}
                stage={Stage[process.env.REACT_APP_STAGE]}
            />
        </Suspense>
    );
}
