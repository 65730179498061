import React from 'react';
import Styles from '../shopList.module.scss';

import Button from '../../Widgets/Button';
import { ReactComponent as BicycleSVG } from '../../../imges/Bicycle.svg';
import { TextField } from '../../Widgets/TextField';
import useColors from '../../../customHooks/useColors';
import useFormatCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { _resetCart } from '../../../redux/actions';
import { SelectedOrderType } from '../../Payment/types.d';
import { MultiVendorOrderingInfo } from './MultiVendorOrderingInfo';
import { useHistory } from 'react-router-dom';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import { GROUP } from '../../../constants/URL.const';
import { useMultiVendorBasket } from '../../../customHooks/useMultiVendorBasket';

interface ICartControllers {
    onOpenCart: () => void;
    cartCount: number;
    displayNavigateBtn?: boolean;
}

export function CartControllers({ onOpenCart, cartCount, displayNavigateBtn }: ICartControllers) {
    const { primaryIconColor } = useColors();
    const { formatCurrency } = useFormatCurrency();
    const DeliveryPrice = useSelector((state: any) => state.DeliveryPrice);
    const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
    const cartItems = useSelector((state: any) => state.Cart);
    const dispatch = useDispatch<any>();
    const { t } = useTranslation();
    const history = useHistory();
    const { generateNavPath } = useGenerateNavPath();
    const { isMultiVendorBasket } = useMultiVendorBasket();

    return (
        <div className={Styles.cartDetails}>
            {isMultiVendorBasket &&
                (displayNavigateBtn ? (
                    <MultiVendorOrderingInfo />
                ) : (
                    cartItems.length > 0 && (
                        <Button
                            title={'BrowsVenues'}
                            onClick={() => history.push(generateNavPath(GROUP))}
                            dataComponentName="ShoppingCartEmptyButton"
                            isYoelloBtn
                        />
                    )
                ))}
            {SelectedMenuType === SelectedOrderType.DELIVERY && (
                <div className={Styles.DeliveryPriceDiv}>
                    <BicycleSVG fill={primaryIconColor} />
                    <p>
                        {t('ShopCart.DeliveryFee')}: <span>{formatCurrency(DeliveryPrice)}</span>
                    </p>
                </div>
            )}
            <div className={Styles.cartCountsWrapper}>
                <div>
                    <span className={Styles.dot}></span>
                    <TextField
                        className={Styles.cartItemsCount}
                        data-component-name={'cartItemsCount'}
                        text={{
                            key: `buttons.Items`,
                            options: { Count: cartCount },
                        }}
                    />
                </div>
                <div>
                    <Button
                        title={'EmptyCart'}
                        className={Styles.emptyCart}
                        onClick={() => {
                            onOpenCart();
                            dispatch(_resetCart());
                        }}
                        dataComponentName="ShoppingCartEmptyButton"
                    />
                </div>
            </div>
        </div>
    );
}
