import React, { useContext } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { PaymentContext } from '../PaymentStore';
import { setDiscounts } from '../reducer/PaymentActions';
import { discountsInitialValue } from './DiscountsComponent';
import { useTranslation } from 'react-i18next';
import { useMultiVendorBasketView } from '../useMultiVendorBasketView';
import { BasketItemsMetaData } from '../types.d';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    root: {
        marginTop: '0.9375rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& >div:first-child': {
            '& p': {
                fontStyle: 'normal',
                fontSize: '1rem',
                alignItems: 'center',
                color: '#24C277',
                margin: 0,
            },
        },
        '& >div:last-child': {
            cursor: 'pointer',
            '& p': {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '0.8125rem',
                display: 'flex',
                alignItems: 'center',
                color: '#424242',
                margin: 0,
            },
        },
    },
});

interface IAppliedDiscounts {
    venueId?: string;
}
export function AppliedDiscounts({ venueId }: IAppliedDiscounts) {
    const { t } = useTranslation();
    const DefaultTip = useSelector((state: any) => state.DifaultTip);
    const { dispatch, discounts, basket } = useContext(PaymentContext);
    const { evaluateBasketItems } = useMultiVendorBasketView();
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div>
                <p>
                    <strong data-component-name="DiscountCode">
                        {venueId && basket[venueId]?.discounts ? basket[venueId]?.discounts.code : discounts.code}{' '}
                    </strong>{' '}
                    <span>{t('Forms.Applied')}</span>
                </p>
            </div>
            <div
                data-component-name="DiscountAppliedRemove"
                onClick={() => {
                    if (venueId) {
                        evaluateBasketItems(BasketItemsMetaData.DISCOUNT, discountsInitialValue, venueId, DefaultTip);
                    } else {
                        dispatch(setDiscounts(discountsInitialValue));
                    }
                }}
            >
                <p>{t('Forms.Remove')}</p>
            </div>
        </div>
    );
}
