import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import useAPI from '../../../yoello-lib/modules/API/useAPI';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';
import { APIExtensions } from '../../../yoello-lib/modules/API/APIDefs';
import { addSpecificMenuId } from '../../../yoello-lib/modules/redux/actions/consumer.actions';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import { NOT_MENU_AVAILABLE, VENUE } from '../../../constants/URL.const';
import { checkIfMenuIsAvailable } from '../../Utils/getAvailableMenuId';

export interface ITiming {
    start_time: string;
    end_time: string;
    enabled: boolean;
    iso_weekday: number;
}
export enum IsoWeekDay {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 7,
}

export function useSpecificMenuURL(Venue: any, menuIsNeeded?: boolean) {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    //@ts-ignore
    const { id } = useParams();

    const [isMenuAvailable, setIsMenuAvailable] = useState<boolean>(true);
    const [isMenuPageLoading, setIsMenuPageLoading] = useState<boolean>(true);
    const { sendSnackbarMessage } = useSnackbarMessages();
    const { generateNavPath } = useGenerateNavPath();

    const params = new URLSearchParams(location.search);
    const menuId = params.get('menu_id');

    const { callAPI: AvailableMenusAPI, apiData } = useAPI<any>(APIExtensions.consumerAvailableMenus, () => null, {
        doNotCacheValue: true,
        onMountDisabled: true,
    });
    const { callAPI: getMneuTimings } = useAPI(APIExtensions.menus, () => null, {
        onMountDisabled: true,
        query: Venue.id,
    });

    useEffect(() => {
        if (menuIsNeeded && menuId && Venue.id && Venue.venue_nickname === id) {
            setIsMenuPageLoading(true);
            AvailableMenusAPI(HTTPMethods.GET, {
                query: Venue.id,
                params: {
                    menu_id: menuId,
                    datetime: `${moment.utc().format()}`,
                },
                canAPIGetCacheValue: false,
            });
        }
        if (menuId) {
            dispatch(addSpecificMenuId(menuId));
        } else {
            if (location.pathname === generateNavPath(VENUE) || location.pathname + '/' === generateNavPath(VENUE)) {
                dispatch(addSpecificMenuId(null));
            }
            setIsMenuPageLoading(false);
        }

        // eslint-disable-next-line
    }, [menuId, Venue.id, id]);

    useEffect(() => {
        if (apiData) {
            if (apiData.length === 0 || !apiData[0].enabled) {
                sendSnackbarMessage('Errors.MenuNotAvailable', 'error');
                history.push(NOT_MENU_AVAILABLE);
            } else if (apiData[0].timings_enabled) {
                getTimings();
            } else {
                setIsMenuPageLoading(false);
            }
        }

        async function getTimings() {
            try {
                const timings = (await getMneuTimings(HTTPMethods.GET, {
                    query: `${menuId}/timings/public`,
                })) as ITiming[];

                if (timings && timings.length > 0) {
                    const isAvailable = checkIfMenuIsAvailable(timings);
                    if (!isAvailable) {
                        sendSnackbarMessage('Errors.MenuNotAvailable', 'error');
                        setIsMenuAvailable(false);
                    }
                }
                setIsMenuPageLoading(false);
            } catch (error) {
                setIsMenuPageLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [apiData]);

    return {
        isSpecificMenu: Boolean(menuId),
        menu: apiData && apiData.length > 0 && apiData[0],
        isMenuPageLoading,
        isMenuAvailable,
    };
}
