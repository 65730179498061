import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '../../../Widgets/Button';
import FormElement from '../../../../yoello-lib/modules/dynamicForms/FormElement';
// import { useStyles } from "./styles";
import { generateRegister } from '../../../../yoello-lib/modules/dynamicForms/utils';
import ICommonFormProps from '../../../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import useColors from '../../../../customHooks/useColors';
import makeStyles from '@material-ui/styles/makeStyles';

export interface IEmailInput extends ICommonFormProps {
    label: string;
    inputOptions?: {
        disabled?: boolean;
    };
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (email: string) => void;
}

export function EmailInput(props: IEmailInput) {
    const { primaryBorderColor, useDarkTheme } = useColors();
    const useStyles = makeStyles({
        root: {
            // marginTop: "2.25rem",
        },
        label: {
            width: '100%',
        },
        labelText: {
            color: '#616161',
            fontSize: '0.875rem',
            lineHeight: '1.1875rem',
            fontWeight: 400,
            fontStyle: 'normal',
            margin: 0,
            marginBottom: '0.3125rem',
            textAlign: 'left',
        },
        input: {
            border: 'none',
            outline: 'unset',
            width: '100%',
            position: 'relative',
            '& .MuiInput-root': {
                border: `1px solid ${useDarkTheme ? primaryBorderColor : '#F9F9F9'}`,
                backgroundColor: '#F9F9F9',
                transition: '0.25s ease-in-out',
                borderRadius: '0.5rem',
                width: '100%',
                display: 'flex',
                boxSizing: 'border-box',
                height: '3.125rem',
                '&.Mui-error': {
                    border: '1px solid #F44336',
                },
                '&.Mui-focused': {
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${primaryBorderColor}`,
                },
                '& input': {
                    padding: '1.6rem',
                    boxSizing: 'border-box',
                },
                '& .MuiInputAdornment-root': {
                    padding: '0.3125rem',
                    marginLeft: '0!important',
                },
            },
        },
    });
    const { root, input, labelText } = useStyles();
    useEffect(() => {
        if (props.value) {
            if (props.setValue) {
                props.setValue(props.keyName, props.value);
                props.triggerValidation!(props.keyName);
            }
        }
        // eslint-disable-next-line
    }, [props.value]);

    return (
        <div className={root}>
            <p className={labelText}>{props.label}</p>
            <div>
                <FormElement {...props} keyName={props.keyName}>
                    <TextField
                        data-component-name={'EmailInput'}
                        name={props.keyName}
                        value={props.value}
                        {...generateRegister(props)}
                        type={'email'}
                        className={input}
                        onChange={props.onInputChange}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={() => props.onSubmit(props.value)}
                                        dataComponentName="ApplyButton"
                                        iconClassName={'consumer-right-arrow'}
                                        style={{
                                            position: 'unset',
                                            fontSize: '1.5rem',
                                            height: '2.5rem',
                                            width: '2.5rem',
                                        }}
                                        isYoelloBtn
                                        disabled={props.disabled}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormElement>
            </div>
        </div>
    );
}
