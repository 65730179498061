import { roundPriceOption } from '../../../utils/pricingCalculator';
import { IPaymentContainerState } from '../types.d';
import {
    IS_COLLECTION_DRAWER_OPEN,
    IS_CARD_DETAILS_DRAWER_OPEN,
    IS_DELIVERY_DRAWER_OPEN,
    IS_PAYMENT_DRAWER_OPEN,
    IS_TABLE_SERVICE_OPEN,
    IS_PAYMENT_PROCESSING_OPEN,
    ADD_DELIVERY,
    ADD_PAYMENT_DETAILS,
    CHANGE_DELIVERY_DETAILS,
    CHANGE_ORDER_TYPE,
    CHANGE_STAGES,
    CHANGE_TABLE_NUMBER,
    IS_LOADING,
    IS_UPDATING_PAYMENT_DETAILS,
    ON_COMPLETE_DELIVERY,
    SELECT_TIME,
    SET_DELIVERY_PRICE,
    SET_MESSAGE,
    SET_SUB_TOTAL_COST,
    SET_TOTAL_PRICE_WITHOUT_TIPS,
    SET_TIP_PRICE,
    SET_TOTAL_PRICE_OVERRIDE,
    SET_TRANSACTION_FEE,
    SET_POSTCODE_CHECK_STATUS,
    SET_CART_TAX,
    SET_CART_INCLUSIVE_TAX,
    SET_SERVICE_TAX,
    SET_SERVICE_INCLUSIVE_TAX,
    SET_DELIVERY_INCLUSIVE_TAX,
    SET_DELIVERY_TAX,
    SET_USER_TOKEN,
    SET_IS_USING_PAYMENT_REQUEST,
    SET_DISCOUNTS,
    SET_DISCOUNTS_TOTAL_VALUE,
    SET_IS_PAYMENT_READY,
    SET_PAYMENT_PROVIDERS,
    SET_PLACED_ORDER,
    SET_SELECTED_PAYMENT_METHOD,
    SET_SELECTED_PREFERENCE,
    SET_PROMOTIONS,
    SET_PLACE_ORDER_METADATA,
    SET_MULTI_VENDOR_BASKET_METADATA,
    SET_CONFIRMED_ORDER_DATA,
} from './PaymentActions';

export interface IAction {
    type: string;
    payload: any;
}

interface IPaymentStoreState extends IPaymentContainerState {
    isAuthenticated: boolean;
}

/**Reducer for the payments container */
function PaymentReducer(state: IPaymentStoreState, action: IAction) {
    switch (action.type) {
        case ADD_DELIVERY:
            return {
                ...state,
                deliveryTimes: action.payload,
            };
        case IS_COLLECTION_DRAWER_OPEN:
            return {
                ...state,
                isCollectionDrawerOpen: action.payload,
            };
        case ADD_PAYMENT_DETAILS:
            return {
                ...state,
                paymentDetails: action.payload.paymentDetails,
                userHasPaymentDetails: action.payload.userHasPaymentDetails,
            };
        case SELECT_TIME:
            return {
                ...state,
                selectedTime: action.payload,
            };
        case IS_TABLE_SERVICE_OPEN:
            return {
                ...state,
                isTableServiceDrawerOpen: action.payload,
            };
        case IS_PAYMENT_PROCESSING_OPEN:
            return {
                ...state,
                isPaymentProcessingDrawerOpen: action.payload,
            };
        case CHANGE_TABLE_NUMBER:
            return {
                ...state,
                selectedTable: action.payload.tableNumber,
                areaName: action.payload?.areaName,
                isTableServiceDrawerOpen: false,
            };
        case CHANGE_ORDER_TYPE:
            return {
                ...state,
                selectedOrderType: action.payload,
            };
        case SET_USER_TOKEN:
            return {
                ...state,
                userToken: action.payload,
            };
        case CHANGE_DELIVERY_DETAILS:
            window.localStorage.setItem('BillingAddress', JSON.stringify(action.payload));
            return {
                ...state,
                deliveryAddress: action.payload,
            };
        case ON_COMPLETE_DELIVERY:
            return {
                ...state,
                isDeliveryDrawerOpen: false,
                isCollectionDrawerOpen: true,
            };
        case IS_DELIVERY_DRAWER_OPEN:
            return {
                ...state,
                isDeliveryDrawerOpen: action.payload,
            };
        case IS_PAYMENT_DRAWER_OPEN:
            return {
                ...state,
                isPaymentDrawerOpen: action.payload,
            };
        case IS_CARD_DETAILS_DRAWER_OPEN:
            return {
                ...state,
                isCardDetailsDrawerOpen: action.payload,
            };
        case CHANGE_STAGES:
            return {
                ...state,
                paymentStages: action.payload,
            };
        case IS_UPDATING_PAYMENT_DETAILS:
            return {
                ...state,
                isUpdatingPaymentDetails: action.payload,
            };
        case SET_IS_USING_PAYMENT_REQUEST:
            return {
                ...state,
                isUsingPaymentRequest: action.payload,
            };
        case SET_SUB_TOTAL_COST:
            return {
                ...state,
                subTotalCost: action.payload,
                totalPrice:
                    (state.totalDiscounts ? roundPriceOption(action.payload - state.totalDiscounts) : action.payload) +
                    state.tipTotal +
                    state.transactionFee +
                    state.deliveryPrice -
                    state.promotion?.value,
            };
        case SET_TOTAL_PRICE_WITHOUT_TIPS:
            return {
                ...state,
                subTotalCost: action.payload,
                totalPriceWithoutTips:
                    (state.totalDiscounts ? roundPriceOption(action.payload - state.totalDiscounts) : action.payload) +
                    state.tipTotal +
                    state.transactionFee +
                    state.deliveryPrice -
                    state.promotion?.value,
            };
        case SET_TOTAL_PRICE_OVERRIDE:
            return {
                ...state,
                totalPriceOverride: action.payload,
            };
        case SET_TIP_PRICE:
            const tip = Math.round(action.payload);
            return {
                ...state,
                tipTotal: tip,
                totalPrice:
                    tip +
                    (state.totalDiscounts
                        ? roundPriceOption(state.subTotalCost - state.totalDiscounts)
                        : state.subTotalCost) +
                    state.transactionFee +
                    state.deliveryPrice -
                    state.promotion?.value,
            };
        case SET_TRANSACTION_FEE:
            return {
                ...state,
                transactionFee: action.payload,
                totalPrice:
                    action.payload +
                    (state.totalDiscounts
                        ? roundPriceOption(state.subTotalCost - state.totalDiscounts)
                        : state.subTotalCost) +
                    state.tipTotal +
                    state.deliveryPrice -
                    state.promotion?.value,
            };
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_MESSAGE:
            return {
                ...state,
                message: action.payload,
            };
        case SET_DELIVERY_PRICE:
            return {
                ...state,
                deliveryPrice: action.payload,
                totalPrice:
                    action.payload +
                    (state.totalDiscounts
                        ? roundPriceOption(state.subTotalCost - state.totalDiscounts)
                        : state.subTotalCost) +
                    state.tipTotal +
                    state.transactionFee -
                    state.promotion?.value,
            };
        case SET_POSTCODE_CHECK_STATUS:
            return {
                ...state,
                postcodeCheckStatus: action.payload,
            };
        case SET_CART_TAX:
            return {
                ...state,
                cartItemTaxAmount: action.payload,
            };
        case SET_CART_INCLUSIVE_TAX:
            return {
                ...state,
                cartItemInclusiveTaxAmount: action.payload,
            };
        case SET_SERVICE_TAX:
            return {
                ...state,
                serviceTaxAmount: action.payload,
            };
        case SET_SERVICE_INCLUSIVE_TAX:
            return {
                ...state,
                serviceTaxInclusiveTaxAmount: action.payload,
            };
        case SET_DELIVERY_TAX:
            return {
                ...state,
                deliveryTaxAmount: action.payload,
            };
        case SET_DELIVERY_INCLUSIVE_TAX:
            return {
                ...state,
                deliveryInclusiveTaxAmount: action.payload,
            };
        case SET_DISCOUNTS:
            return {
                ...state,
                discounts: action.payload,
            };
        case SET_DISCOUNTS_TOTAL_VALUE:
            return {
                ...state,
                totalDiscounts: action.payload,
                totalPrice: state.totalPrice - action.payload,
            };
        case SET_IS_PAYMENT_READY:
            return {
                ...state,
                isPaymentReady: action.payload,
            };
        case SET_PAYMENT_PROVIDERS:
            return {
                ...state,
                paymentProviders: action.payload,
            };
        case SET_PLACED_ORDER:
            return {
                ...state,
                placedOrderID: action.payload,
            };
        case SET_SELECTED_PAYMENT_METHOD:
            return {
                ...state,
                selectedPaymentMethod: action.payload,
            };
        case SET_SELECTED_PREFERENCE:
            return {
                ...state,
                selectedPreference: action.payload,
            };
        case SET_PROMOTIONS:
            return {
                ...state,
                promotion: action.payload,
                totalPrice:
                    (state.totalDiscounts
                        ? roundPriceOption(state.subTotalCost - state.totalDiscounts)
                        : state.subTotalCost) +
                    state.tipTotal +
                    state.deliveryPrice -
                    (action.payload?.value ?? 0),
            };
        case SET_PLACE_ORDER_METADATA:
            console.log('SETTING METADATA', action);
            return {
                ...state,
                metadata: action.payload,
            };
        case SET_MULTI_VENDOR_BASKET_METADATA:
            return {
                ...state,
                basket: action.payload,
            };
        case SET_CONFIRMED_ORDER_DATA:
            return {
                ...state,
                confirmedOrder: action.payload,
            };
        default:
            return state;
    }
}

export default PaymentReducer;
