import React, { createContext, useContext, useReducer } from 'react';

import PaymentReducer from './reducer/PaymentReducer';
import { IPageComponentProps } from '../Page/types.d';
import { IPaymentContainerState, IPaymentContext, PaymentStages } from './types.d';
import PageComponent from '../Page/PageComponent';
import { usePaymentProviders } from './PaymentHooks/usePaymentProviders';
import { useSelector } from 'react-redux';

export const paymentContainerInitalState: IPaymentContainerState = {
    userHasPaymentDetails: false,
    isPaymentDrawerOpen: false,
    isCardDetailsDrawerOpen: false,
    isDeliveryDrawerOpen: false,
    isCollectionDrawerOpen: false,
    isPaymentProcessingDrawerOpen: false,
    isTableServiceDrawerOpen: false,
    deliveryTimes: null,
    selectedOrderType: null,
    paymentStages: PaymentStages.PAYMENT,
    selectedTable: null,
    areaName: null,
    deliveryAddress: {
        apartment: '',
        address: '',
        city: '',
        postcode: '',
    },
    disabledIds: [],
    selectedTime: null,
    isUpdatingPaymentDetails: false,
    subTotalCost: 0,
    tipTotal: 0,
    totalPrice: 0,
    transactionFee: 0,
    isLoading: false,
    message: '',
    deliveryPrice: 0,
    postcodeCheckStatus: null,
    cartItemTaxAmount: null,
    serviceTaxAmount: null,
    cartItemInclusiveTaxAmount: null,
    serviceTaxInclusiveTaxAmount: null,
    isUsingPaymentRequest: false,
    deliveryTaxAmount: null,
    deliveryInclusiveTaxAmount: null,
    paymentDetails: null,
    totalPriceOverride: null,
    discounts: {
        code: null,
        message: null,
        value: null,
        error: false,
    },
    totalDiscounts: null,
    isPaymentReady: false,
    paymentProviders: {},
    placedOrderID: null,
    selectedPaymentMethod: null,
    selectedPreference: '',
    orderOnly: false,
    promotion: {
        promotion_id: null,
        value: 0,
    },
    metadata: null,
    basket: {},
    confirmedOrder: null,
};

export const PaymentContext = createContext<Partial<IPaymentContext>>(paymentContainerInitalState);

export const usePaymentState = () => useContext(PaymentContext);

interface IPaymentStore extends IPageComponentProps {
    Component: any;
}

const PaymentStore = (props: IPaymentStore) => {
    const { Component } = props;
    const Venue = useSelector((state: any) => state.Venue);
    //@ts-ignore
    const [state, dispatch] = useReducer(PaymentReducer, paymentContainerInitalState);

    usePaymentProviders(dispatch);
    return (
        <PaymentContext.Provider
            value={{
                ...state,
                dispatch,
                retrieveToken: props.retrieveToken,
                isAuthenticated: props.isAuthenticated,
                orderOnly: Venue.order_only_enabled,
            }}
        >
            <Component {...props} isAuthenticated={props.isAuthenticated} />
        </PaymentContext.Provider>
    );
};

//@ts-ignore
export default PageComponent(PaymentStore);
