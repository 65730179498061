// eslint-disable-next-line
const whiteSpaceRegex = /^[^\s]+(\s+[^\s]+)*$/;

//Same email regex as design system for consistency
export const emailRegex =
    // eslint-disable-next-line
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

//Must match 8 chars minimum, min 1 uppercase letter, min 1 lowercase letter, min 1 number, min one symbol from @$!%*?&
export const defaultPasswordRegex =
    // eslint-disable-next-line
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$\\*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])[A-Za-z\d\^$\\*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`]{8,}$/;

//6 digits, all numbers
// eslint-disable-next-line
export const sixDigitNumberCodeRegex = /^([0-9]{6})$/;

const hasWhiteSpace = (value: string) => !whiteSpaceRegex.test(value);

type CheckKeys = string | string[];
export function whiteSpaceCheck(key: CheckKeys, formData: Record<string, any>, validationObject: Record<string, any>) {
    const message = 'errors.formerrors.pattern';
    if (Array.isArray(key)) {
        for (const k of key) {
            updateValidation(k);
        }
    } else {
        updateValidation(key);
    }
    function updateValidation(key: string) {
        const value = formData[key];
        if (value && hasWhiteSpace(value)) {
            validationObject[key] = createValidationObject(key, message);
        }
    }
}

export function createValidationObject(name: string, message: string) {
    return {
        name,
        message,
        type: 'validation',
    };
}

export function isRequiredCheck(key: CheckKeys, formData: Record<string, any>, validationObject: Record<string, any>) {
    const message = 'errors.formerrors.isRequired';
    if (Array.isArray(key)) {
        for (const k of key) {
            updateValidation(k);
        }
    } else {
        updateValidation(key);
    }

    function updateValidation(key: string) {
        const value = formData[key];
        if (!isRequiredValidation(value)) {
            validationObject[key] = createValidationObject(key, message);
        }
    }
}

function isRequiredValidation(value: any) {
    if (value === undefined || value === null) {
        return false;
    }
    if (typeof value === 'string' && value.length === 0) {
        return false;
    }
    if (Array.isArray(value) && value.length === 0) {
        return false;
    }
    if (typeof value === 'object' && Object.keys(value).length === 0) {
        return false;
    }
    return true;
}
