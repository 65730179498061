import makeStyles from '@material-ui/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import Styles from '../styles.module.scss';
import SocialLoginButtons from './SocialLoginButtons';
import { emailRegex } from '../../../yoello-lib/modules/dynamicForms/ValidationUtils';
import { EmailInput } from './EmailLogin';
import PageLoading from '../../Page/PageLoading';
import useLoginView from './useLoginView';
import SocialButton from './SocialLoginButtons/Buttons/SocialButton';
import { LoginViewTitle } from './Title';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsWebView } from '../../../customHooks/useIsWebView';
import { useSelector } from 'react-redux';
import { SelectedOrderType } from '../../../yoello-lib/modules/utils/venueOpen';

export const useStyles = makeStyles({
    root: {},
    title: {
        fontSize: '1.375rem',
        fontWeight: 600,
        marginBottom: '2.5rem',
    },
    description: {
        '@media (min-width: 768px)': {
            marginBottom: '3.5rem',
        },
    },
    form: {},
    dividerWrapper: {
        padding: '2rem 0',
    },
    dividerText: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '0 1rem',
        backgroundColor: '#FFFFFF',
        color: '#737FA4',
    },
    manualSignIn: {
        display: 'flex',
        justifyContent: 'center',
    },
    select: {
        width: '100%',
        marginBottom: '1rem',
        border: '1px solid #F9F9F9',
        backgroundColor: '#F9F9F9',
        borderRadius: '6px',
        padding: '0.875rem',
        '&.Mui-active': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #FFDD02',
        },
        '& option': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '8vw',
        },
    },
    input: {
        border: 'none',
        outline: 'unset',
        width: '100%',
        marginBottom: '1rem',
        position: 'relative',
        '& .MuiInput-root': {
            border: '1px solid #F9F9F9',
            backgroundColor: '#F9F9F9',
            transition: '0.25s ease-in-out',
            borderRadius: '6px',
            width: '100%',
            display: 'block',
            boxSizing: 'border-box',
            '&.Mui-error': {
                border: '1px solid #F44336',
            },
            '&.Mui-focused': {
                backgroundColor: '#FFFFFF',
                border: '1px solid #FFDD02',
            },
            '& input': {
                padding: '1.6rem',
                boxSizing: 'border-box',
            },
        },
    },
    bottomLink: {
        '& >p': {
            color: ' #4F3CC9',
            fontSize: '1rem',
            lineHeight: '1.375rem',
            fontWeight: 400,
            fontStyle: 'normal',
            margin: 0,
            textAlign: 'center',
        },
    },
    divider: {
        marginTop: '3rem',
        '@media (min-width: 768px)': {
            marginTop: '1.5rem',
        },
    },
    guestCheckoutButton: {
        marginTop: '3rem',
        '@media (min-width: 768px)': {
            marginTop: '1.5rem',
        },
    },
    infoText: {
        fontSize: '0.938rem',
        color: '#737FA4',
        marginBottom: '1.313rem',
    },
    header: {
        flex: '0 0 auto',
        maxHeight: '200px',
        overflow: 'hidden',
        transition: '0.15s ease-in-out',
        '@media (max-height: 500px)': {
            maxHeight: 0,
        },
        '@media (min-width: 768px)': {
            textAlign: 'center',
        },
        padding: '1.5rem',
    },
    Wrapper: {
        flex: '1 1 auto',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
});

const LoginView = ({ isLoading }: { isLoading: boolean }) => {
    const {
        onInputChange,
        username,
        checkIfUserExists,
        isTryingToLogin,
        isCheckingOut,
        onPressContinueAsGuest,
        method,
        paramsLoading,
        orderOnly,
        orderOnlyGuestEnabled,
    } = useLoginView(undefined);
    const classes = useStyles();
    const { t } = useTranslation();
    const { hideNonGuestCheckout } = useFlags();
    const { isWebview } = useIsWebView();
    const venue = useSelector((state: any) => state.Venue);
    const SelectedMenuType = useSelector((state:any)=>state.SelectedMenuType);
    if (isLoading || paramsLoading) return <PageLoading />;

    return (
        <div className={Styles.Login}>
            <div className={Styles.LoginViewDiv}>
                <header>
                    <LoginViewTitle isCheckingOut={isCheckingOut} isEmailLogin={!!method} />
                </header>
                {(!hideNonGuestCheckout || !isCheckingOut) && (
                    <>
                        {!method && !isWebview && <SocialLoginButtons />}

                        {!isCheckingOut && !method && !isWebview && (
                            <div className={classes.dividerWrapper}>
                                <Divider />
                                <span className={classes.dividerText}>OR</span>
                            </div>
                        )}
                        <EmailInput
                            keyName="email"
                            value={username}
                            label={t('Forms.ContinueWithEmail') as string}
                            register={undefined}
                            onInputChange={onInputChange}
                            onSubmit={checkIfUserExists}
                            disabled={!emailRegex.test(username) || isTryingToLogin}
                        />
                    </>
                )}
                <div className={`${classes.Wrapper} ${Styles.LoginMethods}`}>
                    {isCheckingOut && !method && (!orderOnly || orderOnlyGuestEnabled) && (
                        <>
                            {(!hideNonGuestCheckout || !isCheckingOut) && (
                                <div>
                                    <Divider className={classes.divider} />
                                    <span className={classes.dividerText}>OR</span>
                                </div>
                            )}

                            <div className={classes.guestCheckoutButton}>
                                <SocialButton
                                    text={t('Login.Texts.ContinueAsGuest').toString()}
                                    onClick={onPressContinueAsGuest}
                                    children={null}
                                    dataComponentName={'ContinueAsGuestButton'}
                                />
                            </div>
                            {SelectedMenuType === SelectedOrderType.DELIVERY && venue.address_country === "GB" && <div>
                                <p>{t('Login.Texts.DeliverySaveLogin')}</p>
                                </div>}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginView;
