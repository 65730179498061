import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useManageQrQueryParams = () => {
    const { search } = useLocation();
    const history = useHistory();

    const params = useMemo(() => {
        return new URLSearchParams(search);
    }, [search]);

    const reference = useMemo(() => params.get('reference'), [params]);

    const setTableFromReferenceId = useCallback(() => {
        sessionStorage.setItem('qr-table-reference', reference);
    }, [reference]);

    useEffect(() => {
        if (reference) {
            setTableFromReferenceId();
            params.delete('reference');
            history.replace({ search: params.toString() });
        }
    }, [reference, params, history, setTableFromReferenceId]);
};
