import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbarMessages } from '../yoello-lib/modules/components/snackbar/SnackbarContext';

/**
 * Based on pathname gets notifications out of redux and then pops them off there queue
 */
export function useNotifications() {
    const { t } = useTranslation();
    const { sendSnackbarMessage, isSnackbarOpen } = useSnackbarMessages();
    const history = useHistory();
    const notifications = useSelector((state: any) => state.Notifications[history.location.pathname]);

    useEffect(() => {
        if (notifications) {
            sendMessage();
        }
        // eslint-disable-next-line
    }, [notifications, history.location.pathname, !notifications?.isEmpty()]);

    useEffect(() => {
        /**If we have more than one message in the queue */
        if (!isSnackbarOpen && notifications && !notifications.isEmpty()) {
            /**Allow for closing annimation to finish */
            setTimeout(() => {
                sendMessage();
            }, 100);
        }
        // eslint-disable-next-line
    }, [isSnackbarOpen]);

    function sendMessage() {
        const item = notifications.dequeue();
        console.log('trying to send message', item);
        if (!item) return;
        sendSnackbarMessage(
            t(item.label, {
                ...item.labelObj,
            }).toLocaleString(),
            item.status,
            item?.notificationLength,
        );
    }
}
