import React from 'react';
import { IOrders } from '../../../typings/IOrders.d';
import { convertToUTCTime } from '../../../yoello-lib/modules/utils/TimeConversion';
import { OrderCollectionContainer } from './OrderCollection/OrderCollectionContainer';
import { OrderTitle } from './OrderTitle';
import { useTranslation } from 'react-i18next';

interface IOrderTypesDetailsProps {
    orderItem: IOrders;
}

export function OrderTypesDetails({ orderItem }: IOrderTypesDetailsProps) {
    const { t } = useTranslation();

    const { placed_order_order_type, placed_order_asap_delivery_requested } = orderItem;
    switch (placed_order_order_type) {
        case 'TABLE_DELIVERY':
            return <TableDelivery orderItem={orderItem} />;
        case 'HOME_DELIVERY':
            return <HomeDelivery orderItem={orderItem} asapRequested={placed_order_asap_delivery_requested} t={t} />;
        case 'COLLECTION_POINT_PICKUP':
            return <OrderCollectionContainer orderItem={orderItem} />;
        case 'COLLECTION':
            return <Collection orderItem={orderItem} asapRequested={placed_order_asap_delivery_requested} t={t} />;
    }
    return null;
}

function TableDelivery({ orderItem }) {
    const { placed_order_delivery_table, placed_order_table_area } = orderItem;

    return (
        <OrderTitle
            titleKey="OrderHistory.VenueArea"
            valueText={`${placed_order_table_area}, ${placed_order_delivery_table}`}
        />
    );
}

function HomeDelivery({ orderItem, asapRequested, t }) {
    const { placed_order_delivery_address, placed_order_takeaway_timeslot } = orderItem;

    const time = asapRequested
        ? t('Misc.ASAP') + `(~${convertToUTCTime(placed_order_takeaway_timeslot, 'HH:mma')})`
        : convertToUTCTime(placed_order_takeaway_timeslot, 'HH:mma');
    // Keeping address for older orders with different data
    const hasStreetAddress = !!(
        placed_order_delivery_address.apartment ||
        placed_order_delivery_address.address ||
        placed_order_delivery_address.street_address
    );

    const streetAddress = `${
        placed_order_delivery_address.apartment ? `${placed_order_delivery_address.apartment},` : ''
    } ${placed_order_delivery_address.address ? `${placed_order_delivery_address.address},` : ''}
    ${placed_order_delivery_address.street_address ? `${placed_order_delivery_address.street_address},` : ''}
    `;
    const deliveryAddress = `${hasStreetAddress ? `${streetAddress}` : ''}
      ${placed_order_delivery_address.city},
      ${placed_order_delivery_address.postcode}`;

    return (
        <>
            <OrderTitle titleKey="OrderHistory.DeliveryTime" valueText={time} />
            <OrderTitle titleKey="Forms.DeliveryAddress" valueText={deliveryAddress} />
        </>
    );
}

function Collection({ orderItem, asapRequested, t }) {
    const { placed_order_takeaway_timeslot } = orderItem;
    const time = asapRequested
        ? t('Misc.ASAP') + `(~${convertToUTCTime(placed_order_takeaway_timeslot, 'HH:mma')})`
        : convertToUTCTime(placed_order_takeaway_timeslot, 'HH:mma');

    return <OrderTitle titleKey="OrderHistory.CollectionTime" valueText={time} />;
}
