import * as types from '../actions/ActionTypes';

const LoginRedirectToMainPage = (state = 'VenueV', action) => {
    switch (action.type) {
        case types.ChangeRedirect:
            console.log('Changing url', action.data);
            state = action.data;
            return state;

        default:
            return state;
    }
};
export default LoginRedirectToMainPage;
