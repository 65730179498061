import {
    addCartItem,
    resetCart,
    updateCartItem,
    deleteCartItem,
    removeCartItems,
    setLatestOrder,
} from '../actions/ActionTypes';
import isEqual from 'lodash.isequal';

interface ICartAction {
    type: string;
    data?: PayloadTypes;
}

type PayloadTypes = IAddCartItem | IAddCartItem[] | IUpdateCount | IDeleteItem | IRemoveItem;

interface IUpdateCount {
    updateCount: number;
    index: number;
}
interface IDeleteItem {
    index: number;
}
interface IRemoveItem {
    menuItemId: string;
}
export interface IAddCartItem {
    itemCount: number;
    menuItem: IMenuItem;
    extras: Record<string, ISelectedExtras[]>;
    extrasPrice: number;
    preferences: Record<string, string>;
    measure: string;
    price: number;
    finalPrice?: number;
}
function Cart(state: IAddCartItem[] = [], action: ICartAction) {
    switch (action.type) {
        case addCartItem:
            const payload = action.data as IAddCartItem;
            if (state.length === 0) {
                const priceOption = payload.menuItem.menu_item_pricing_options[payload.measure];
                const finalPrice = getFinalPrice(payload, priceOption);
                state.push({ ...payload, finalPrice: finalPrice });
            } else {
                let index = null;
                for (let i = 0; i < state.length; i++) {
                    const cartItem = state[i];
                    /**IF the cart item equals an item already in the list */
                    if (
                        cartItem.menuItem.menu_item_id === payload.menuItem.menu_item_id &&
                        cartItem.measure === payload.measure &&
                        isEqual(cartItem.extras, payload.extras) &&
                        isEqual(cartItem.preferences, payload.preferences) &&
                        cartItem.menuItem.menu_id === payload.menuItem.menu_id
                    ) {
                        const newTotal = cartItem.itemCount + payload.itemCount;
                        const priceOption = cartItem.menuItem.menu_item_pricing_options[cartItem.measure];
                        const finalPrice = getFinalPrice({ ...payload, itemCount: newTotal }, priceOption);
                        state.splice(i, 1, { ...payload, itemCount: newTotal, finalPrice });
                        index = i;
                    }
                }
                /**If no index is found then its a new item */
                if (index === null) {
                    const priceOption = payload.menuItem.menu_item_pricing_options[payload.measure];
                    const finalPrice = getFinalPrice(payload, priceOption);
                    state.push({ ...payload, finalPrice: finalPrice });
                }
            }
            return [...state];
        case updateCartItem:
            const { updateCount, index } = action.data as IUpdateCount;
            state[index].itemCount = updateCount;
            const priceOp = state[index].menuItem.menu_item_pricing_options[state[index].measure];
            state[index].finalPrice = getFinalPrice(state[index], priceOp);
            // if (state[index].itemCount <= 0) {
            //   state.splice(index, 1);
            // }
            return [...state];
        case deleteCartItem:
            const data = action.data as IDeleteItem;
            state.splice(data.index, 1);
            return [...state];
        case removeCartItems:
            const { menuItemId } = action.data as IRemoveItem;
            let newState = [...state];
            //this fixing the issue of deleting based on index
            newState = [...newState.filter((item) => item.menuItem.menu_item_id !== menuItemId)];
            return [...newState];
        case resetCart:
            state = [];
            return state;
        case setLatestOrder:
            state = action.data as IAddCartItem[];
            return state ?? [];
        default:
            return state;
    }
}

const getFinalPrice = (data: IAddCartItem, PO: IPricingOptions) => {
    if (PO?.option_bulk_qty !== null) {
        return (
            (data.price * data.itemCount - (data.price * data.itemCount * PO.option_discount) / 100) *
                PO.option_bulk_qty +
            data.extrasPrice * data.itemCount
        );
    } else {
        return (
            data.price * data.itemCount -
            (data.price * data.itemCount * PO.option_discount) / 100 +
            data.extrasPrice * data.itemCount
        );
    }
};
export default Cart;
