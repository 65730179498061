import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Styles from './productShopCard.module.scss';
import { ReactComponent as PlaceholderSVG } from '../../../imges/placeholder.svg';
import CountComponent from '../CountComponent/CountComponent';
import { connect } from 'react-redux';
import useCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import { generateExtraPricingOptionName } from '../../../utils/generateExtraPricingOptionName';
const getPrice = (item) => {
    return item.finalPrice / item.itemCount;
};
function ProductShopCard({ card }: { card: any }) {
    const { formatCurrency } = useCurrency();
    const info = card.info;
    const extras = card.data.extras;
    const preferences = card.data.preferences;
    const formattedPrice = formatCurrency(getPrice(card.data));

    const _onMinusBtnClick = () => {
        card.reduceCount();
    };
    const _onPlusBtnClick = () => {
        card.addCount();
    };

    return (
        <div className={Styles.ProductShopCardContainer} data-component-name="ProductShopCard">
            <div>
                <div>
                    {info.menu_item_url_photo && (
                        <div className={[Styles.productImage, 'dark-theme-uninvert'].join(' ')}>
                            <LazyLoadImage
                                width="100%"
                                height="100%"
                                src={info.menu_item_url_photo}
                                alt={info.menu_item_name_public}
                                PlaceholderSrc={<PlaceholderSVG />}
                                effect="blur"
                            />
                        </div>
                    )}
                    <div
                        className={Styles.productDetails}
                        style={info.menu_item_url_photo ? { width: 'calc(100% - 68px)' } : { width: '100%' }}
                    >
                        <div>
                            <p>{info.menu_item_name_public}</p>
                        </div>
                        <div>
                            <div>
                                <p>{info.menu_item_pricing_options[card.po]?.option_name}</p>
                                <p>{formattedPrice}</p>
                            </div>
                            <div className={Styles.controllers}>
                                <CountComponent
                                    onMinusClick={_onMinusBtnClick}
                                    onPlusClick={_onPlusBtnClick}
                                    count={card.count}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {preferences
                    ? Object.keys(preferences).map((item, i) => {
                          let arr = preferences[item];
                          return (
                              <div key={i} className={Styles.description}>
                                  <span></span>
                                  <p>{arr}</p>
                              </div>
                          );
                      })
                    : null}
                {extras
                    ? Object.keys(extras).map((item, i) => {
                          const obj = extras[item];
                          return obj.map((extra, j) => {
                              const optionName = extra.option_name ?? null;
                              return (
                                  <div key={`ShopCard${i}${j}`} className={Styles.description}>
                                      <span></span>
                                      <p>
                                          {generateExtraPricingOptionName(extra?.extra_name_public, optionName)}{' '}
                                          <strong>{extra.count ? `x${extra.count * card.count}` : ''}</strong>
                                      </p>
                                  </div>
                              );
                          });
                      })
                    : null}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps, {})(React.memo(ProductShopCard));
