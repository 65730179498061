import React from 'react';
import Buttons from '../../Widgets/Button';
import ICommonFormProps from '../../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';

export default function SubmitButton(props: ICommonFormProps) {
    return (
        <Buttons
            dataComponentName=""
            iconClassName={'consumer-right-arrow'}
            title={props.label}
            style={{
                position: 'unset',
                fontSize: '1.5rem',
            }}
            isYoelloBtn
            isLoading={props.formProps.isLoading}
            disabled={props.disabled}
            className="dark-theme-uninvert-svg-div"
        />
    );
}
