import { useSelector } from 'react-redux';
import { abvCartCheck } from '../utils/abvController';
import { useMultiVendorBasketView } from '../Components/Payment/useMultiVendorBasketView';
import { useMultiVendorBasket } from './useMultiVendorBasket';

export function useABVCheck() {
    const Venue = useSelector((state: any) => state.Venue);
    const cartItems = useSelector((state: any) => state.Cart);
    const MenuItems = useSelector((state: any) => state.MenuItem.apiData);
    const { itemsInCartGroupedByVenueId } = useMultiVendorBasketView();
    const { isMultiVendorBasket } = useMultiVendorBasket();
    function abvCheck() {
        return abvCartCheck(
            isMultiVendorBasket ? itemsInCartGroupedByVenueId[Venue.venue_nickname] ?? [] : cartItems,
            MenuItems,
            Venue?.alc_drink_limit,
        );
    }

    return {
        abvCheck,
    };
}
