import Styles from './styles.module.scss';
import Button from '../Button';
import { ReactComponent as TimeCircleSVG } from '../../../imges/icon-time-circle.svg';
import { useTranslation } from 'react-i18next';

interface IDifferentTimezoneWarningProps {
    closeDrawer: () => void;
}
function DifferentTimezoneWarning({ closeDrawer }: IDifferentTimezoneWarningProps) {
    const { t } = useTranslation();
    return (
        <div data-component-name={'DifferentTimezoneWarning'} className={Styles.WarningComponent}>
            <header>
                <div className={Styles.WarningComponentHeaderDiv}>
                    <TimeCircleSVG data-component-name={'timezone-close'} width="18px" height="18px" />
                    <p>{t('Venue.DifferentTimezone')}</p>
                </div>
                <Button
                    onClick={closeDrawer}
                    dataComponentName="ExitButton"
                    iconClassName={'consumer-close'}
                    style={{
                        position: 'unset',
                        color: '#AAB6D3',
                        fontSize: '1.5rem',
                    }}
                    isHeaderBtn
                />
            </header>

            <div>
                <p className={Styles.DescriptionText}>
                    {t('Venue.TimezoneWarningPart1')} <strong>{t('Venue.differentTimezone')}</strong>{' '}
                    {t('Venue.TimezoneWarningPart2')}
                </p>
            </div>
        </div>
    );
}

export default DifferentTimezoneWarning;
