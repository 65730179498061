import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import FormElement from '../../yoello-lib/modules/dynamicForms/FormElement';
import ICommonFormProps from '../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import { generateRegister } from '../../yoello-lib/modules/dynamicForms/utils';

import './phone-input-style.scss';
import 'react-phone-input-2/lib/style.css';
import useColors from '../../customHooks/useColors';

export interface IPhoneInput extends ICommonFormProps {
    Component: React.FunctionComponent<any>;
    label: string;
    inputOptions?: {
        type: string;
    };
}

const TextInput = ({ setValue, keyName, label, ...props }: IPhoneInput) => {
    const { primaryBorderColor, useDarkTheme } = useColors();
    const useStyles = makeStyles({
        root: {
            '& input.form-control': {
                border: useDarkTheme ? `1px solid ${primaryBorderColor}` : '',
                '&:focus': {
                    backgroundColor: '#fff',
                    border: `1px solid ${primaryBorderColor}`,
                },
            },
        },
        label: {
            width: '100%',
        },
        labelText: {
            color: '#626262',
            fontSize: '0.875rem',
            lineHeight: '2rem',
        },
        input: {
            border: 'none',
            outline: 'unset',
            width: '100%',
            position: 'relative',
            '& .MuiInput-root': {
                border: `1px solid ${useDarkTheme ? primaryBorderColor : '#F9F9F9'}`,
                backgroundColor: '#F9F9F9',
                transition: '0.25s ease-in-out',
                borderRadius: '0.5rem',
                width: '100%',
                display: 'block',
                boxSizing: 'border-box',
                '&.Mui-error': {
                    border: '1px solid #F44336',
                },
                '&.Mui-focused': {
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${primaryBorderColor}`,
                },
                '& input': {
                    padding: '1.6rem',
                    boxSizing: 'border-box',
                },
            },
        },
    });
    const classes = useStyles();
    const [inputValue, setInputValue] = useState<string>(props.value);

    const handleChange = (value: string) => {
        setInputValue(value);
        //settimeout added so current value is validated not previous one.
        //the changes is for fixing 'https://yoello-jira.atlassian.net/browse/HS-5778'
        setTimeout(() => {
            if (props.errors) {
                props.triggerValidation(keyName);
            }
        }, 200);
    };

    useEffect(() => {
        setValue(keyName, inputValue);
    }, [inputValue, setValue, keyName]);

    return (
        <div className={classes.root} data-component-name={'PhoneInput'}>
            <label className={classes.label}>
                <span className={classes.labelText}>{label}</span>
            </label>
            <FormElement {...props} keyName={keyName}>
                <PhoneInput
                    copyNumbersOnly
                    enableLongNumbers
                    // @ts-ignore
                    {...generateRegister({ ...props, label, keyName, setValue })}
                    showDropdown={false}
                    value={inputValue ?? ''}
                    disableSearchIcon={true}
                    onChange={handleChange}
                    inputProps={{
                        autoFocus: false,
                    }}
                    name={keyName}
                    country={props.formProps.countryCode}
                    className="PhoneNumberInput"
                    data-component-name={'PhoneNumberInput'}
                />
            </FormElement>
        </div>
    );
};

export default TextInput;
