import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';

export function handleAxiosError(err: AxiosError) {
    if (typeof err === 'object') {
        Sentry.addBreadcrumb({
            category: 'API',
            level: 'error',
            message: `${err?.config?.url} - API failed`,
            data: err?.response?.data,
        });
    }
    throw err;
}
