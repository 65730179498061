import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useABVCheck } from '../../customHooks/useABVCheck';
import { useSnackbarMessages } from '../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { useMenu } from '../Menu/MenuContext';
import { _ChangeRedirect, _ShowUp } from '../../redux/actions';
import { ISnackbarMessageObject } from './types';
import { checkAbvContainsFood } from '../../utils/checkAbvContainsFood';
import { useVenueOpen } from '../../customHooks/useVenueOpen';
import { GROUP_MENU, PAYMENT, VENUE_MENU } from '../../constants/URL.const';
import { getRedirectPlaceURL, minPriceMap } from './utils';
import setRedirectURLs from '../../yoello-lib/modules/URL/setRedirectURLs';
import useGenerateNavPath from '../../customHooks/useGenerateNavPath';
import { useMultiVendorBasket } from '../../customHooks/useMultiVendorBasket';
import { useMultiVendorBasketView } from '../Payment/useMultiVendorBasketView';
import { checkVenueOpen } from '../../yoello-lib/modules/utils/venueOpen';
import { resetStore } from '../GroupPage/util';

export function useShopList(area: boolean) {
    const [snackbarMessageObject, setSnackbarMessageObject] = useState<ISnackbarMessageObject | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const hasRendered = useRef(false);
    const {
        generateURL,
        formatCurrency,
        state: { cartAmount },
    } = useMenu();
    const { t } = useTranslation();
    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const { abvCheck } = useABVCheck();
    const isVenueOpen = useVenueOpen();
    const dispatch = useDispatch<any>();
    const { generateNavPath } = useGenerateNavPath();
    const cartItems = useSelector((state: any) => state.Cart);
    const group = useSelector((state: any) => state.Group);
    const Venue = useSelector((state: any) => state.Venue);
    const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
    const { isGroupVenue, isMultiVendorBasket } = useMultiVendorBasket();
    const { itemsInCartGroupedByVenueId } = useMultiVendorBasketView();

    useEffect(() => {
        /**This stops it from bugging and closing */
        setTimeout(() => {
            hasRendered.current = true;
            setIsOpen(true);
        }, 35);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        /**Wait for it to render first*/
        if (!isOpen && hasRendered.current) {
            /**Allows animation to play */
            setTimeout(() => {
                if (isGroupVenue) {
                    history.push(generateURL(true) + '/menu');
                } else {
                    history.push(generateURL() + '/menu');
                }
            }, 100);
        }
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (snackbarMessageObject?.translationKey) {
            sendSnackbarMessage(
                //@ts-ignore
                t(snackbarMessageObject.translationKey, snackbarMessageObject.translationOptions).toLocaleString(),
                snackbarMessageObject.status,
                snackbarMessageObject.autoHideDuration,
            );
        }
        // eslint-disable-next-line
    }, [snackbarMessageObject]);

    function onOpenCart() {
        setIsOpen((prevOpen) => {
            return !prevOpen;
        });
    }

    function onConfirmationClick() {
        // if venue is busy return
        if (Venue?.is_busy) {
            setSnackbarMessageObject({
                translationKey: 'Errors.VenueIsBusy',
                translationOptions: {
                    venue_name: Venue?.venue_name,
                },
                status: 'error',
            });
            return;
        }

        // First: Cart Empty
        if (cartItems.length === 0) {
            setSnackbarMessageObject({
                translationKey: 'ShopCart.YourCartIsEmpty',
                translationOptions: {},
                status: 'error',
            });
            return;
        }

        if (isMultiVendorBasket) {
            for (let key of Object.keys(itemsInCartGroupedByVenueId)) {
                const { isVenueOpen } = checkVenueOpen(group.venues[key], SelectedMenuType);
                if (!isVenueOpen) {
                    setSnackbarMessageObject({
                        translationKey: 'ShopCart.Errors.VenueClosed',
                        translationOptions: {},
                        status: 'error',
                    });
                    return;
                }
                const venueCartItems = itemsInCartGroupedByVenueId[key];
                const formattedMinimumDelivery = formatCurrency(group.venues[key][minPriceMap[SelectedMenuType]]);
                const cartAmount = venueCartItems.reduce((total, item) => total + item.finalPrice, 0);
                if (cartAmount < group.venues[key][minPriceMap[SelectedMenuType]]) {
                    setSnackbarMessageObject({
                        translationKey: 'ShopCart.Errors.MultiOrderCartLessThan',
                        translationOptions: {
                            price: formattedMinimumDelivery,
                            venue: group.venues[key].venue_name,
                        },
                        status: 'error',
                    });
                    return;
                }
            }
        } else {
            const formattedMinimumDelivery = formatCurrency(Venue[minPriceMap[SelectedMenuType]]);
            // Second: Min delivery value check
            if (cartAmount < Venue[minPriceMap[SelectedMenuType]]) {
                setSnackbarMessageObject({
                    translationKey: 'ShopCart.Errors.CartLessThan',
                    translationOptions: {
                        price: formattedMinimumDelivery,
                    },
                    status: 'error',
                });
                return;
            }
        }
        // if the venue has an alcohol limit then enforce
        if (
            (Venue?.alc_drink_limit !== undefined || Venue?.alc_drink_limit !== null) &&
            typeof Venue?.alc_drink_limit === 'number' &&
            !abvCheck()
        ) {
            setSnackbarMessageObject({
                translationKey: 'Restrictions.alchoholRestrictionError',
                translationOptions: {
                    count: Venue?.alc_drink_limit,
                },
                status: 'error',
            });
            return;
        }
        // Third: ABV check
        if (area && !checkAbvContainsFood(cartItems, group?.abv_food_threshold)) {
            const minFoodPrice = formatCurrency(group?.abv_food_threshold);
            setSnackbarMessageObject({
                translationKey: 'ShopCart.Errors.MinFoodItems',
                translationOptions: {
                    price: minFoodPrice,
                },
                status: 'error',
            });
            return;
        }
        /**Is venue open  */
        if (!isVenueOpen) {
            setSnackbarMessageObject({
                translationKey: 'ShopCart.Errors.VenueClosed',
                translationOptions: {},
                status: 'error',
            });
            return;
        }
        dispatch(_ShowUp(false));
        setRedirectURLs(PAYMENT);
        dispatch(_ChangeRedirect(getRedirectPlaceURL()));
        if (isMultiVendorBasket) {
            resetStore(dispatch);
        }
        history.push(PAYMENT, {
            area: isGroupVenue,
            from: isGroupVenue ? generateNavPath(GROUP_MENU) : generateNavPath(VENUE_MENU),
            to: PAYMENT,
        });
    }

    return {
        isOpen,
        onConfirmationClick,
        onOpenCart,
    };
}
