import makeStyles from '@material-ui/styles/makeStyles';
import { useMultiVendorBasket } from '../../customHooks/useMultiVendorBasket';
import { ReactComponent as PlaceholderSVG } from '../../imges/placeholder.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        margin: '1rem 0 0.3rem 0',
        '& img': {
            width: '1.875rem',
            heigh: '1.875rem',
            maxWidth: '1.875rem',
            maxHeight: '1.875rem',
            margin: '0 1.188rem',
            borderRadius: '4px',
        },
        '& p': {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.125rem',
            lineHeight: '1.563rem',
            color: '#212121',
            margin: '0;',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            marginBottom: ' 0.125rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
});

interface IVenueWrapper {
    name?: string;
    logo?: string;
    children: any;
}

export function VenueWrapper({ name, logo, children }: IVenueWrapper) {
    const { isMultiVendorBasket } = useMultiVendorBasket();
    const classes = useStyles();

    if (isMultiVendorBasket) {
        return (
            <>
                <div className={classes.root}>
                    {logo && (
                        <LazyLoadImage
                            src={logo}
                            alt={'venue_logo'}
                            PlaceholderSrc={<PlaceholderSVG />}
                            effect="blur"
                        />
                    )}
                    {name && <p>{name}</p>}
                </div>

                {children}
            </>
        );
    }

    return <>{children}</>;
}
