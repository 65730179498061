import { useSelector } from 'react-redux';
import { SelectedOrderType } from '../Components/Payment/types.d';

export function useMultiVendorBasket() {
    const group = useSelector((state: any) => state.Group);
    const orderType = useSelector((state: any) => state.SelectedMenuType);
    const isGroupVenue = useSelector((state: any) => state.IsGroupVenue);

    const isOrderTypeAvailable =
        orderType === SelectedOrderType.COLLECTION_POINT || orderType === SelectedOrderType.TABLE;

    return {
        isGroupVenue,
        isMultiVendorBasket: isGroupVenue && group.multi_ordering_enabled && (isOrderTypeAvailable || !orderType),
    };
}
