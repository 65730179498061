import { FunctionComponent, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Styles from './selectOrderTypeDrawer.module.scss';

interface IOrderType {
    title: string;
    Icon: FunctionComponent<SVGProps<SVGElement>>;
    key: string;
    value: string;
}

export function OrderType({ title, Icon, value }: IOrderType) {
    const { t } = useTranslation();
    const orderType = useSelector((state: any) => state.SelectedMenuType);
    return (
        <div className={`${Styles.orderType} ${orderType === value ? Styles.selected : undefined}`}>
            {Icon && <Icon fill={orderType === value ? '#FFDD02' : 'transparent'} />}
            <p>{t(title)}</p>
            {orderType === value && <span />}
        </div>
    );
}
