import { AlertProps, Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import ICommonFormProps from '../../../dynamicForms/interfaces/ICommonFormProps';

interface IAlertStatusCard extends Partial<ICommonFormProps> {
    alertProps: AlertProps;
    alertTitle?: React.ReactNode;
    alertMessage: React.ReactNode;
    customComponent?: React.ReactNode;
}

export function AlertStatusCard({ alertProps, alertTitle, alertMessage, customComponent, ...props }: IAlertStatusCard) {
    return (
        <Alert {...(alertProps ?? props.formProps?.alertProps)}>
            {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
            {props.formProps?.alertTitle && <AlertTitle>{props.formProps?.alertTitle}</AlertTitle>}
            {alertMessage ?? props.formProps?.alertMessage}
            {customComponent ?? props.formProps?.customComponent}
        </Alert>
    );
}
