import moment from 'moment-timezone';
import createTimeIntervals from './timeIntervals';

export interface ITradingTimesEntity {
    openTime: string;
    closeTime: string;
    isDisplayed: boolean;
}
export interface ITradingTimes {
    [key: string]: any;
    deleted: boolean;
    enabled: boolean;
    exclusions: null;
    friday: boolean;
    id: string;
    monday: boolean;
    name_internal: string;
    name_public: string;
    open_from: string;
    open_to: string;
    saturday: boolean;
    serial_id: number;
    sunday: boolean;
    thursday: boolean;
    tuesday: boolean;
    updated_on: string;
    venue_id: string;
    wednesday: boolean;
}

/**Generates a usable trading times object */
export function generateTradingTimesObject(tradingTimesArray: ITradingTimes[]) {
    const tradingTimesObject: Record<string, ITradingTimesEntity[]> = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
    };
    const tradingTimesKeys = Object.keys(tradingTimesObject);
    if (Array.isArray(tradingTimesArray)) {
        for (let i = 0; i < tradingTimesArray.length; i++) {
            const obj = tradingTimesArray[i];
            let nextDayItem: any = {};
            for (const dayKeys of tradingTimesKeys) {
                /**Checks if anything is inside of the nextdayItem object which holds any entries for the next day */
                if (Object.keys(nextDayItem).length > 0) {
                    tradingTimesObject[dayKeys].push({
                        ...nextDayItem,
                        isDisplayed: false,
                    });
                    nextDayItem = {};
                }
                /**if the day object is trueß then push the times to the array of that day */
                if (obj[dayKeys] === true) {
                    const openTime = obj.open_from.slice(0, -3);
                    const closeTime = obj.open_to.slice(0, -3);
                    /**If greater than the opentime then we need an entry as part of the next day */
                    if (openTime > closeTime) {
                        nextDayItem = {
                            openTime: '00:00',
                            closeTime,
                        };
                        /**if we are on a sunday we need to complete the loop and just add these to monday */
                        if (dayKeys === 'sunday') {
                            tradingTimesObject['monday'].push({
                                ...nextDayItem,
                                isDisplayed: false,
                            });
                        }
                    }
                    tradingTimesObject[dayKeys].push({
                        openTime,
                        closeTime,
                        isDisplayed: true,
                    });
                }
            }
        }
    }
    for (const keys of tradingTimesKeys) {
        const tradingObj = tradingTimesObject[keys];
        if (tradingObj.length > 0) {
            tradingObj.sort((a, b) => parseInt(a.openTime) - parseInt(b.openTime));
        }
    }
    return tradingTimesObject;
}

export function generateTimeIntervalsTradingTimes(
    tradingTimesArray: ITradingTimesEntity[],
    venuePrepTime: number,
    timeZone?: string,
) {
    if (tradingTimesArray === undefined || tradingTimesArray === null) {
        return [];
    }
    let deliveryTimes: string[] = [];
    const currentDT = moment()
        .tz(timeZone ? timeZone : 'Europe/London')
        .format('YYYY-MM-DD HH:mm');
    tradingTimesArray.forEach((item) => {
        let { openTime, closeTime } = generateTradingTimesMoment(item);
        if (currentDT > openTime && currentDT < closeTime) {
            openTime = currentDT;
        } else if (currentDT > closeTime) {
            return;
        }
        /**add and subtract the preptime from the moment objs */
        const prepTime = venuePrepTime ? venuePrepTime : 0;
        openTime = moment(openTime).add(prepTime, 'minute');
        closeTime = moment(closeTime);

        const timeIntervals = createTimeIntervals(openTime, closeTime);
        deliveryTimes = deliveryTimes.concat(timeIntervals);
    });
    /**Create a new set so that all the entries are unique */
    const deliverySet = new Set(deliveryTimes);
    /**turn the set back into an array */
    //@ts-ignore
    return [...deliverySet];
}

function generateTradingTimesMoment(tradingTimes: ITradingTimesEntity) {
    const openTimeSplit = tradingTimes.openTime.split(':');
    /**@todo Fix typing here */
    let openTime: any = moment()
        .set('hour', parseInt(openTimeSplit[0]))
        .set('minute', parseInt(openTimeSplit[1]))
        .format('YYYY-MM-DD HH:mm');

    const closeTimeSplit = tradingTimes.closeTime.split(':');
    let closeTime: any = moment().set('hour', parseInt(closeTimeSplit[0])).set('minute', parseInt(closeTimeSplit[1]));
    /**if the open time is bigger than the close time then we're assuming its the next day */
    if (tradingTimes.openTime > tradingTimes.closeTime || tradingTimes.openTime === tradingTimes.closeTime) {
        closeTime = closeTime.add(1, 'day').format('YYYY-MM-DD HH:mm');
    } else {
        closeTime = closeTime.format('YYYY-MM-DD HH:mm');
    }
    return {
        openTime,
        closeTime,
    };
}

/**Checks if a venue is opened using the trading times */
export function isVenueOpen(tradingTimes: Record<string, ITradingTimesEntity[]>, timeZone?: string, prepTime?: string) {
    if (!tradingTimes) {
        return false;
    }
    const day = moment().format('dddd').toLowerCase();
    const dayTradingTimes = tradingTimes[day];
    console.log(day, dayTradingTimes);
    const currentDT = moment()
        .tz(timeZone ? timeZone : 'Europe/London')
        .format('YYYY-MM-DD HH:mm');
    if (dayTradingTimes?.length > 0) {
        let isOpen = false;
        dayTradingTimes.forEach((tradingTime) => {
            const openTimeSplit = tradingTime.openTime.split(':');
            /**@todo Fix typing here */
            let openTime: any = moment()
                .set('hour', parseInt(openTimeSplit[0]))
                .set('minute', parseInt(openTimeSplit[1]))
                .format('YYYY-MM-DD HH:mm');

            const closeTimeSplit = tradingTime.closeTime.split(':');
            let closeTime: any = moment()
                .set('hour', parseInt(closeTimeSplit[0]))
                .set('minute', parseInt(closeTimeSplit[1]))
                .format('YYYY-MM-DD HH:mm');
            /**If the closeTime is less than openTime and the current DT is less than the open time,
             * then remove 1 day from the openTime */
            if (closeTime < openTime && currentDT < openTime) {
                openTime = moment(openTime).subtract(1, 'day').format('YYYY-MM-DD HH:mm');
            } else if (closeTime === openTime) {
                closeTime = moment(closeTime).add(1, 'day').format('YYYY-MM-DD HH:mm');
            }
            /**if there is a prepTime, deduct that from the closeTime */
            if (prepTime) {
                const prepTimeParsed = parseInt(prepTime) || 0;
                closeTime = moment(closeTime).subtract(prepTimeParsed, 'minutes').format('YYYY-MM-DD HH:mm');
            }
            let startDif = moment(openTime);
            let endDif = moment(closeTime);
            const difference = endDif.diff(startDif, 'days', true);
            /** If the currentDT is greater than the close time & open time
             * and the difference is less than 0 then the close time is likeky the next day  */
            if (currentDT > closeTime && currentDT >= openTime && difference < 0) {
                closeTime = moment(closeTime).add('1', 'day').format('YYYY-MM-DD HH:mm');
            }
            /**If greater than openTime and less than close time then the venue is open */
            if (currentDT >= openTime && currentDT < closeTime) {
                isOpen = true;
            }
        });
        return isOpen;
    } else {
        return false;
    }
}
