import { useContext, useEffect, useState, lazy, Suspense } from 'react';
import PaymentPage from './PaymentPage/PaymentPage';
import { PaymentContext } from './PaymentStore';
import { PaymentStages, SelectedOrderType } from './types.d';
import Styles from './styles.module.scss';

import { setSubTotalCost, setCartTax, setCartInclusiveTax } from './reducer/PaymentActions';

import { getCartItemTaxAmount, getCartSubTotal } from '../../utils/pricingCalculator';

import { setDiscountsTotalValue, setTotalPriceWithoutTips } from './reducer/PaymentActions';
import { changeDeliveryDetails } from './reducer/PaymentActions';
import DiscountsComponent from './Dsicounts/DiscountsComponent';
import { checkTimeslotAgaintsMenuTimings } from '../../utils/checkTimeslotAgaintsMenuTimings';
import { useSelector } from 'react-redux';
import useSquareVenue from '../../customHooks/Square/useSquareVenue';
import usePaymentView from './PaymentHooks/usePaymentView';
import useFeesAndTaxes from './PaymentHooks/useFeesAndTaxes';
import { calculateTotalDiscountAmount } from '../Utils/CalcTotalDiscountAmount';
import useSquareUser from '../../customHooks/Square/useSquareUser';
import { setIsPaymentReady } from './reducer/PaymentActions';
import { useAuthenticationContext } from '../../yoello-lib/modules/auth/AuthenticationContext';
import Skeleton from '@material-ui/lab/Skeleton';
import { PaymentInformationLoading } from '../Widgets/Loading/Payment';

const OrderTypesComponent = lazy(() => import('./OrderTypesComponent/OrderTypesComponent'));
const PostCodeAlert = lazy(() => import('../Widgets/PostCodeChecker/PostCodeAlert/PostCodeAlert'));
const TipComponent = lazy(() => import('./Tips/TipComponent'));
const PricingComponent = lazy(() => import('./Pricing/PricingComponent'));
const MessageComponent = lazy(() => import('./MessageInput/MessageComponent'));
interface ISingelVendorBasketContainer {
    isVenueOpen: boolean;
    Venue: any;
}

function SingelVendorBasketContainer({ isVenueOpen, Venue }: ISingelVendorBasketContainer) {
    const {
        dispatch,
        paymentStages,
        subTotalCost,
        totalPrice,
        transactionFee,
        tipTotal,
        postcodeCheckStatus,
        discounts,
        selectedTime,
        selectedOrderType,
        orderOnly,
    } = useContext(PaymentContext);

    const [menuTimingAlerts, setMenuTimingAlerts] = useState<IAvailableMenus[]>([]);
    const AvailableMenus = useSelector((state: any) => state.AvailableMenus);
    const cartItems = useSelector((state: any) => state.Cart);
    const { isSquareVenue } = useSquareVenue();
    const { venue } = usePaymentView();
    const { setFeesAndTaxes } = useFeesAndTaxes();
    const { handleSquareUser } = useSquareUser();
    const { isAuthenticated } = useAuthenticationContext();

    useEffect(() => {
        // make sure if have a copy of the users address in state if it already existis in local
        const address = JSON.parse(window.localStorage.getItem('BillingAddress'));
        if (address && selectedTime && selectedOrderType === SelectedOrderType.DELIVERY) {
            dispatch(changeDeliveryDetails(address));
        }
       
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        //multi vendor basket works for Dine-in and Quick-pick-up only
        setMenuTimingAlerts(checkTimeslotAgaintsMenuTimings(selectedTime, cartItems, AvailableMenus));
        // eslint-disable-next-line
    }, [selectedTime]);

    useEffect(() => {
        dispatch(setDiscountsTotalValue(calculateTotalDiscountAmount(discounts, subTotalCost)));
        //eslint-disable-next-line
    }, [discounts]);

    useEffect(() => {
        if (subTotalCost > 0) {
            dispatch(setTotalPriceWithoutTips(subTotalCost));
        }
        //eslint-disable-next-line
    }, [subTotalCost]);

    useEffect(() => {
        setFeesAndTaxes(totalPrice, transactionFee, tipTotal, dispatch);
        if (isAuthenticated && isSquareVenue) {
            handleSquareUser();
        } else {
            dispatch(setIsPaymentReady(true));
        }
        //eslint-disable-next-line
    }, [isAuthenticated, isSquareVenue]);

    useEffect(() => {
        setFeesAndTaxes(totalPrice, transactionFee, tipTotal, dispatch);
        //eslint-disable-next-line
    }, [venue, totalPrice, tipTotal, discounts.value]);

    useEffect(() => {
        // remember to use this on menu page
        /**Whenever cart updates, update the cart subtotal  */
        const cartSubTotal = getCartSubTotal(cartItems);
        dispatch(setSubTotalCost(cartSubTotal));
        const taxAmount = getCartItemTaxAmount(cartItems, discounts);
        dispatch(setCartTax(taxAmount.totalTaxAmount));
        dispatch(setCartInclusiveTax(taxAmount.totalInclusiveTaxAmount));
        // eslint-disable-next-line
    }, [cartItems, discounts]);

    const renderPageComponents = () => {
        if (paymentStages === PaymentStages.PAYMENT) {
            return (
                <div className={Styles.ChooseMethod}>
                    <Suspense fallback={<Skeleton height={'8rem'} style={{ margin: '2rem 0' }} />}>
                        <OrderTypesComponent isVenueOpen={isVenueOpen} />
                    </Suspense>
                    <Suspense fallback={null}>
                        <PostCodeAlert postcodeCheckStatus={postcodeCheckStatus} menuTimingAlerts={menuTimingAlerts} />
                    </Suspense>

                    <div className={Styles.Informations}>
                        <Suspense fallback={<PaymentInformationLoading />}>
                            <MessageComponent />
                            {!orderOnly && !isSquareVenue && <DiscountsComponent subTotalCost={subTotalCost} />}
                        </Suspense>
                    </div>
                    {!orderOnly && Venue.tipping_enabled ? (
                        <Suspense fallback={null}>
                            <TipComponent />
                        </Suspense>
                    ) : (
                        ''
                    )}

                    <Suspense fallback={<Skeleton height={'5rem'} style={{ margin: '2rem 0' }} />}>
                        <PricingComponent />
                    </Suspense>
                </div>
            );
        }
    };

    return <PaymentPage renderPaymentComponents={renderPageComponents} />;
}

export default SingelVendorBasketContainer;
