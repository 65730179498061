import React from 'react';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { NOT_FOUND, ORDERS } from '../../constants/URL.const';
import { HTTPMethods } from '../../yoello-lib/modules/API/API';
import APIExtensions from '../../yoello-lib/modules/API/APIDefs';
import useAPI from '../../yoello-lib/modules/API/useAPI';
import { useAuthenticationContext } from '../../yoello-lib/modules/auth/AuthenticationContext';
import useGuestUserData from '../../customHooks/useGuestUserData';
import OrderDetailsSkeleton from '../Widgets/Loading/OrderDetails';
import { OrderDetails } from './OrderDetails/OrderDetails';

export function OrderDetailsContainer({ isStartRoute }) {
    let { id } = useParams<{ id: string }>();
    const { retrieveAccessToken, isAuthenticated } = useAuthenticationContext();
    const { isGuest, getGuestOrderDetails } = useGuestUserData();
    const [orderDetails, setOrderDetails] = useState<any>(undefined);
    const history = useHistory();
    let { path } = useRouteMatch();

    const { isLoading, apiData, callAPI, isError, errorStatusCode } = useAPI<any>(
        APIExtensions.consumerOrders,
        retrieveAccessToken,
        { onMountDisabled: true },
    );

    useEffect(() => {
        async function getOrderDetails() {
            const response = await getGuestOrderDetails(id);
            setOrderDetails(response);
        }
        if (isGuest) {
            if (!orderDetails) {
                getOrderDetails();
            }
        } else if (isAuthenticated) {
            callAPI(HTTPMethods.GET, { query: id });
        }
        //eslint-disable-next-line
    }, [isAuthenticated, isGuest, orderDetails]);

    if (isError) {
        if (errorStatusCode === 404) {
            history.push(NOT_FOUND, {
                orderNotFound: true,
            });
        } else {
            return <Redirect to={ORDERS} />;
        }
    }

    if (isLoading || (!apiData && !orderDetails)) {
        return <OrderDetailsSkeleton />;
    }

    return (
        <Switch>
            <Route path={path}>
                <OrderDetails isStartRoute={isStartRoute} orderItem={apiData || orderDetails} />
            </Route>
        </Switch>
    );
}
