import { useEffect, useState } from 'react';
import { GuestUser } from '../Components/LoginV2/GuestView/types.d';
import { getCookie } from '../Components/LoginV2/util/cookies';
import useAPI from '../yoello-lib/modules/API/useAPI';
import { APIExtensions } from '../yoello-lib/modules/API/APIDefs';
import { HTTPMethods } from '../yoello-lib/modules/API/API';
import { getGuestOrderHistoryView } from '../Api.js';
import { useSelector } from 'react-redux';
import { LOGINV2_START } from '../constants/URL.const';
import { useHistory, useLocation } from 'react-router-dom';
import IConsumerUser from '../yoello-lib/modules/auth/IConsumerUser';

export default function useGuestUserData(isConsumerNeeded?: boolean) {
    const location = useLocation();
    const [consumer, setConsumer] = useState<IConsumerUser | undefined>(undefined);
    const params = new URLSearchParams(location.search);
    const consumerId = params.get('consumer_id') || getCookie(GuestUser.ConsumerID);
    const venue = useSelector((state: any) => state.Venue);
    const guestId = params.get('session') || getCookie(GuestUser.GuestID);
    const history = useHistory();

    const { callAPI } = useAPI(APIExtensions.consumers, () => null, {
        onMountDisabled: true,
    });

    useEffect(() => {
        if (isConsumerNeeded) {
            getGuestUser();
        }
        // eslint-disable-next-line
    }, [isConsumerNeeded]);

    async function getGuestUser() {
        if (consumerId && guestId) {
            try {
                const consumer: any = await callAPI(HTTPMethods.GET, {
                    query: consumerId + '/guest/' + guestId,
                });
                setConsumer(consumer);
                return consumer;
            } catch (err) {
                return undefined;
            }
        }
    }
    async function guestUpdatePreferences(data) {
        try {
            await callAPI(HTTPMethods.PATCH, {
                query: consumerId + '/guest/' + guestId + '/preferences',
                body: data,
            });
        } catch (err) {
            console.log('error', { err });
        }
    }
    async function getGuestOrderDetails(orderId: string) {
        try {
            const order = await getGuestOrderHistoryView(consumerId, guestId, orderId);
            return order.data.data;
        } catch (err) {
            history.push(LOGINV2_START, {
                to: location.pathname,
            });
        }
    }

    return {
        consumerId,
        guestId,
        isGuest: guestId && consumerId && venue.payment_processor !== 'PAY360',
        getGuestUser,
        guestUpdatePreferences,
        getGuestOrderDetails,
        consumer: consumer,
    };
}
