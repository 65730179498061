import { useEffect, useState } from 'react';

interface IWindowHeightProps {
    /**false if you don't want to update the screen size */
    isResizeable: boolean;
}

/**Hook for getting the windows width */
export function useWindowWidth({ isResizeable }: IWindowHeightProps) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (isResizeable) {
            window.addEventListener('resize', windowEventListener);
        }
        return () => {
            if (isResizeable) {
                window.removeEventListener('resize', windowEventListener);
            }
        };
        // eslint-disable-next-line
    }, []);

    function windowEventListener() {
        setWindowWidth(window.innerWidth);
    }

    return windowWidth;
}
