import { ReactComponent as GBFlagSVG } from '../imges/GB_Flag.svg';
import { ReactComponent as FRFlagSVG } from '../imges/FR_Flag.svg';
import { ReactComponent as ESFlagSVG } from '../imges/ES_Flag.svg';
import { ReactComponent as USFlagSVG } from '../imges/US_Flag.svg';
import { ReactComponent as CAFlagSVG } from '../imges/CA_Flag.svg';

export const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const APP_LANGUAGES = {
    CA: {
        shortName: 'En',
        fullName: 'English (CA)',
        Flag: CAFlagSVG,
        ISO: 'en-ca',
    },
    EN: {
        shortName: 'En',
        fullName: 'English (UK)',
        Flag: GBFlagSVG,
        ISO: 'en-gb',
    },
    US: {
        shortName: 'En',
        fullName: 'English (US)',
        Flag: USFlagSVG,
        ISO: 'en-us',
    },
    FR: {
        shortName: 'Fr',
        fullName: 'French',
        Flag: FRFlagSVG,
        ISO: 'fr',
    },
    ES: {
        shortName: 'Es',
        fullName: 'Spanish',
        Flag: ESFlagSVG,
        ISO: 'es',
    },
};

export const ApiVenueLanguages = {
    EN: 'EN',
    CA: 'EN-CA',
    US: 'EN-US',
    FR: 'FR',
    ES: 'ES',
};

export const CARDIFF_CASTLE = 'cardiff-castle';

export const GOOGLE_MAPS_API = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAVh852Ll0UcYgz9eIH7s35FQje47qj48U&libraries=places';