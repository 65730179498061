import React from 'react';
import { ReactComponent as MinusSVG } from '../../../imges/Minus-r.svg';
import { ReactComponent as AddSVG } from '../../../imges/Add-r.svg';
import { ReactComponent as DeleteSVG } from '../../../imges/delete.svg';
import Styles from './styles.module.scss';
import useColors from '../../../customHooks/useColors';
import makeStyles from '@material-ui/styles/makeStyles';
interface ICountComponentProps {
    count: number;
    disableMinus?: boolean;
    onMinusClick?: Function;
    onPlusClick?: Function;
    noDelete?: boolean;
    disablePlus?: boolean;
    choiceBox?: boolean;
}

function CountComponent({
    count,
    disableMinus,
    onMinusClick,
    onPlusClick,
    noDelete,
    disablePlus,
    choiceBox,
}: ICountComponentProps) {
    const { primaryBackgroundColor } = useColors();

    const useStyles = makeStyles({
        CountDiv: {
            background: primaryBackgroundColor,
            borderRadius: '8px',
            width: '25px',
            height: '25px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& >p': {
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#212121',
                margin: '0',
            },
        },
    });

    const classes = useStyles();
    const renderIcon = () => {
        if (count > 1 || (noDelete && !choiceBox)) {
            return (
                <MinusSVG
                    width="16px"
                    onClick={() => {
                        if (!disableMinus && onMinusClick) {
                            onMinusClick();
                        }
                    }}
                    className="dark-theme-uninvert"
                    data-component-name="MinusItem"
                    style={{ cursor: 'pointer' }}
                />
            );
        }
        return (
            <DeleteSVG
                width="16px"
                onClick={() => {
                    if (!disableMinus && onMinusClick) {
                        onMinusClick();
                    }
                }}
                className="dark-theme-uninvert"
                data-component-name="DeleteItem"
                style={{ cursor: 'pointer' }}
            />
        );
    };
    return (
        <div className={`${classes.CountDiv} ${!choiceBox ? Styles.CountChangebleDiv : ''}`}>
            {!choiceBox && renderIcon()}
            {!disablePlus && (
                <p className={Styles.CountText} data-component-name="ItemCount">
                    {count}
                </p>
            )}
            {!disablePlus && !choiceBox && (
                <AddSVG
                    width="16px"
                    onClick={() => {
                        if (onPlusClick) {
                            onPlusClick();
                        }
                    }}
                    className="dark-theme-uninvert"
                    data-component-name="PlusItem"
                    style={{ cursor: 'pointer' }}
                />
            )}
        </div>
    );
}

export default CountComponent;
