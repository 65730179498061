import React, { useEffect, useRef } from 'react';
import FormWizardButtons, { IFormWizardButtons } from './FormWizardButtons';
import DialogFormWizardButtons from './DialogFormWizardButtons';
import IDynamicFormRow, { DataChangedFunction } from './interfaces/IDynamicFormRow';
import IDynamicFormGroup from './interfaces/IDynamicFormGroup';
import { useTranslation } from 'react-i18next';
import { FieldError, useForm, UseFormProps } from 'react-hook-form';
import FormTitle from './FormTitle';
import FormRow from './FormRow';
import { GridProps } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { generatePermission } from './utils';
import { isObjectEmpty } from '../utils/objectCheck';
import { DirtyFormContainer } from './DirtyFormContainer';
import DisabledActionWrapper from '../components/DisabledActionWrapper';

export interface IDynamicFormProps {
    /**Function to handle the submitting of data, returns back the forms data object */
    onHandleSubmit: (data: Record<string, any>) => void;
    /**Form buttons */
    formButtons?: IFormWizardButtons;
    /**use dialog buttons component if its a dialog form*/
    isDialog?: boolean;
    /**Form rows */

    formRows?: IDynamicFormRow[];
    /** Form Groups */
    formGroups?: IDynamicFormGroup[];
    /**The form's pre-populated data */
    formData?: Record<string, any>;
    /**Form key name, keep these unique across forms */
    formKeyName: string;
    /**Grid layout Defaults:
     * - direction="row"
     * - alignItems="center"
     * - justify="center"
     * - spacing={3}
     */
    gridLayoutProps?: GridProps;
    /**Some extend options for the use form validation */
    useFormOptions?: UseFormProps;
    disabledFields?: Record<string, Record<string, string[]>>;
    subscriptionKey?: string;
    disableAll?: boolean;
    disableAllInteraction?: Function;
    /**Function that returns back a boolean if the data has been changed */
    hasDataChanged?: DataChangedFunction;
    hasSpacing?: boolean;
    spacing?: string;
}
/**
 * Dynamic forms is a handy tool to help generate forms from a form schema
 *
 * @param props
 */

function DynamicForm(props: IDynamicFormProps): JSX.Element {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        watch,
        reset,
        clearErrors,
        setError,
        trigger,
        formState,
    } = useForm({
        shouldFocusError: true,
        ...props.useFormOptions,
        defaultValues: props.formData || {},
    });
    const { errors } = formState;
    console.log(props);
    const { t } = useTranslation();
    const isValuesSet = useRef(false);
    /**On submit handler */
    const onSubmit = (data: Record<string, any>) => {
        props.onHandleSubmit(data);
    };
    useEffect(() => {
        /**Forces the data into the form if its rendered first time but the data hasn't been set */
        if (!isObjectEmpty(props.formData) && isValuesSet.current === false) {
            reset(props.formData);
            isValuesSet.current = true;
        }
        //eslint-disable-next-line
    }, [props.formData]);

    const renderFormGroups = (groups?: IDynamicFormGroup[]) => {
        if (!groups) return null;
        return groups.map((formGroup: IDynamicFormGroup, index: number) => (
            <>
                <FormTitle title={formGroup.title} description={formGroup.description} />
                {renderFormRows(formGroup.formRows)}
                {index < groups.length - 1 && (
                    <Grid item xs={12}>
                        <Divider style={{ backgroundColor: '#F3F4F9', margin: '1rem 0' }} />
                    </Grid>
                )}
            </>
        ));
    };

    const renderFormRows = (rows?: IDynamicFormRow[]) => {
        if (!rows) return null;
        return rows.map((formRow, index) => {
            const { isDisabled, isHidden } = generatePermission(
                props.disabledFields,
                props.subscriptionKey,
                formRow.keyName,
                formRow.disabled,
            );
            if (isHidden) {
                return null;
            }
            return (
                <React.Fragment key={'formRow' + index}>
                    {formRow.gridColumnOptions?.leftCol && (
                        <Grid style={{ position: 'relative' }} item xs={formRow.gridColumnOptions?.leftCol || 2}></Grid>
                    )}
                    <Grid
                        item
                        xs={formRow.gridColumnOptions?.formCol || 12}
                        style={{
                            position: 'relative',
                            padding: props.hasSpacing ? props.spacing : '0rem 1rem',
                        }}
                    >
                        <FormRow
                            {...formRow}
                            key={props.formKeyName + index}
                            register={register}
                            registerOptions={formRow.registerOptions}
                            label={t(formRow.label)}
                            control={control}
                            value={props.formData?.[formRow.keyName]}
                            errors={errors[formRow.keyName] as FieldError}
                            setValue={setValue}
                            getValues={getValues}
                            watch={watch}
                            disabled={props.disableAll || isDisabled}
                            setError={setError}
                            triggerValidation={trigger}
                            clearError={clearErrors}
                        />
                    </Grid>
                    {formRow.gridColumnOptions?.rightCol && (
                        <Grid
                            item
                            xs={formRow.gridColumnOptions?.rightCol || 2}
                            style={{ position: 'relative' }}
                        ></Grid>
                    )}
                </React.Fragment>
            );
        });
    };

    /**Renders the form rows */
    const createForm = () => {
        return [renderFormGroups(props.formGroups), renderFormRows(props.formRows)];
    };
    return (
        <form key={props.formKeyName + '_mainform'} onSubmit={handleSubmit(onSubmit)}>
            <DisabledActionWrapper disabled={props.disableAll} onDisabledClick={props.disableAllInteraction}>
                <Grid>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                        spacing={3}
                        {...props.gridLayoutProps}
                    >
                        {createForm()}
                        {(props.formButtons?.leftButton || props.formButtons?.rightButton) && (
                            <>
                                {props.isDialog ? (
                                    <DialogFormWizardButtons {...props.formButtons} formKey={props.formKeyName} />
                                ) : (
                                    <FormWizardButtons {...props.formButtons} formKey={props.formKeyName} />
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
                {props.hasDataChanged && (
                    <DirtyFormContainer
                        control={control}
                        formData={props.formData}
                        hasDataChanged={props.hasDataChanged}
                    />
                )}
            </DisabledActionWrapper>
        </form>
    );
}

DynamicForm.defaultProps = {
    hasSpacing: true,
    spacing: '1rem',
} as Partial<IDynamicFormProps>;

export default DynamicForm;
