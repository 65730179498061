import { ADD_MERCHANTS, ADD_SINGLE_MERCHANT } from '../consts/merchant.actions';

export const addMerchants = (merchants: any[]) => {
    return {
        type: ADD_MERCHANTS,
        payload: merchants,
    };
};

export const addSingleMerchant = (merchant: any) => {
    return {
        type: ADD_SINGLE_MERCHANT,
        payload: merchant,
    };
};
