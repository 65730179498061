import Skeleton from '@material-ui/lab/Skeleton';
import Styles from './sidemenuLoading.module.scss';

export const SideMenuHeaderLoading = () => (
    <div className={Styles.Header}>
        <Skeleton />
        <p>
            <Skeleton />
        </p>
    </div>
);
export const SideMenuContentLoading = () => (
    <div className={Styles.Content}>
        <div>
            <Skeleton />
            <Skeleton />
        </div>
        {[...Array(7).keys()].map(() => (
            <Skeleton />
        ))}
    </div>
);
