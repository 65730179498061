import Styles from './orderHistoryListViewSkeleton.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';

export const OrderHistoryListViewSkeleton = () => {
    return (
        <div className={Styles.orderList}>
            {[...Array(10).keys()].map(() => (
                <div>
                    <Skeleton />
                </div>
            ))}
        </div>
    );
};

export default OrderHistoryListViewSkeleton;
