import { BackendTimeSlots } from '../Components/Payment/types';
import { IAddCartItem } from '../redux/Reducers/Cart';
import moment from 'moment';
export function checkTimeslotAgaintsMenuTimings(
    TimeSlot: BackendTimeSlots,
    cartItems: IAddCartItem[],
    AvailableMenus: IAvailableMenus[],
) {
    let unavailableMenus = new Set();
    let unavailableTempMenu = new Set();
    let flag = false;
    const selectedDay = moment(TimeSlot?.venue_time === 'ASAP' ? TimeSlot?.time_utc : TimeSlot?.venue_time)
        .parseZone()
        .day();
    if (TimeSlot) {
        cartItems.forEach((item, i) => {
            flag = false;
            unavailableTempMenu.clear();
            item.menuItem.menu_ids.forEach((menuID) => {
                const ItemMenu = AvailableMenus.find((menu) => menu.id === menuID);
                let Timings = ItemMenu?.timings?.filter((item) => item.iso_weekday === selectedDay);
                const VenuesTimes = TimeSlot?.time_utc;
                if (ItemMenu?.timings_enabled && Timings?.length === 0) {
                    Timings = ItemMenu?.timings?.filter(
                        (item) => item.iso_weekday === (selectedDay > 1 ? selectedDay - 1 : 7),
                    );
                    if (Timings?.length === 0) {
                        unavailableTempMenu.add(ItemMenu);
                    }
                }
                if (Timings === undefined) {
                    flag = true;
                } else {
                    Timings?.forEach((MenuTiming) => {
                        if (MenuTiming.start_time_utc > VenuesTimes || VenuesTimes > MenuTiming.end_time_utc) {
                            unavailableTempMenu.add(ItemMenu);
                        } else {
                            flag = true;
                        }
                    });
                }
            });
            if (!flag) {
                unavailableTempMenu.forEach((item) => unavailableMenus.add(item));
            }
        });
    }
    return Array.from(unavailableMenus) as IAvailableMenus[];
}
