import makeStyles from '@material-ui/styles/makeStyles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useState } from 'react';
import ICommonFormProps from '../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import type { toggleOption } from './index';
import { Controller } from 'react-hook-form';
import useColors from '../../customHooks/useColors';

export interface IToggleElement extends ICommonFormProps {
    Component: React.FunctionComponent<any>;
    inputOptions: {
        default: string;
        options: toggleOption[];
    };
}

const ToggleInput = ({ keyName, inputOptions, setValue, label, ...props }: IToggleElement) => {
    const [selectOptions] = useState(inputOptions);
    const { lowerOpacityButtonColor, primaryBorderColor } = useColors();

    const useStyles = makeStyles({
        root: {
            position: 'relative',
            width: '100%',
        },
        label: {
            width: '100%',
        },
        buttonWrapper: {
            width: '100%',
            display: 'inline-flex',
            padding: '0.3rem',
            backgroundColor: '#f9f9f9',
            borderRadius: '0.5rem',
            boxSizing: 'border-box',
            '& button:first-child': {
                marginRight: '0.625rem',
            },
            '& button:last-child': {
                marginLeft: 'unset!important',
            },
        },
        toggleButton: {
            flex: '1 1 auto',
            backgroundColor: 'transparent',
            border: '1px solid transparent',
            boxSizing: 'border-box',
            borderRadius: '0.5rem!important',
            height: 'unset',
            padding: '0.625rem 0',
            '& >span:first-child': {
                fontWeight: 'normal!important',
                fontSize: '1rem!important',
                lineHeight: '1.375rem!important',
                textAlign: 'center!important',
                color: '#212121!important',
            },
            '&.Mui-selected': {
                backgroundColor: lowerOpacityButtonColor,
                border: `1px solid ${primaryBorderColor}`,
                '&:active': {
                    backgroundColor: lowerOpacityButtonColor,
                },
                '&:hover': {
                    backgroundColor: lowerOpacityButtonColor,
                },
                '& >span:first-child': {
                    fontWeight: '600!important',
                },
            },
            '&.Mui-active': {
                backgroundColor: lowerOpacityButtonColor,
            },
        },
        helperTxt: {
            width: '100%',
            padding: '0.5rem 0',
        },
    });
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <label className={classes.label}>{label}</label>
            <Controller
                control={props.control}
                name={keyName}
                // defaultValue={props.value}
                rules={{ required: true }}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => {
                    function onChangeHandler(event, newOption) {
                        if (!newOption) return;
                        onChange(newOption);
                        props.formProps?.onOptionChenge?.(newOption);
                    }
                    return (
                        <ToggleButtonGroup
                            className={classes.buttonWrapper}
                            onChange={onChangeHandler}
                            value={value ?? props.value}
                            exclusive
                        >
                            {selectOptions.options.map((option: toggleOption) => {
                                return (
                                    <ToggleButton
                                        value={option.value}
                                        key={option.value}
                                        className={classes.toggleButton}
                                    >
                                        {option.name}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    );
                }}
            />
            {props?.formProps?.helperTxt && <p className={classes.helperTxt}>{props.formProps.helperTxt}</p>}
            {/* </label> */}
        </div>
    );
};

export default ToggleInput;
