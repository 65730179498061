import makeStyles from '@material-ui/styles/makeStyles';
import DynamicForm from '../../../yoello-lib/modules/dynamicForms/DynamicForms';
import useGenerateProfileForm from './useGenerateProfileForm';
import useSubmitProfile from './useSubmitProfile';
import PageLoading from '../../Page/PageLoading';
import useGetToAndFromPaths from '../useGetToAndFromUrls';
import { VENUE } from '../../../constants/URL.const';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        height: 'calc(100vh - 6.75rem)',
    },
    header: {
        flex: '0 0 auto',
        maxHeight: '200px',
        '& h3': {
            fontSize: '1.375rem',
        },
        overflow: 'hidden',
        transition: '0.15s ease-in-out',
        '@media (max-height: 500px)': {
            maxHeight: 0,
        },
        '@media (min-width: 768px)': {
            textAlign: 'center',
        },
        padding: '1.5rem',
    },
    content: {
        flex: '1 1 auto',
        position: 'relative',
        overflowY: 'scroll',
        //This is to stop unwanted scrolling caused by mui's +12/-12px margin logic
        overflowX: 'hidden',
        padding: '0 1.5rem 24rem 1.5rem',
    },
    footerBlocker: {
        height: '0',
        width: '100%',
        flex: '0 0 auto',
        transition: '0.15s ease-in-out',
    },
});

const ProfileView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { toPath } = useGetToAndFromPaths('login', '', VENUE);
    const { formRows, defaultData, isLoading } = useGenerateProfileForm();
    const { submitProfile, isSubmitting } = useSubmitProfile(toPath || sessionStorage.getItem('redirectURL'));

    if (isSubmitting || isLoading) return <PageLoading />;

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <h3>{t('ProfileBuilder.Header')}</h3>
                <p>{t('ProfileBuilder.Description')}</p>
            </div>
            <div className={classes.content}>
                <DynamicForm
                    formData={defaultData}
                    formRows={formRows}
                    formKeyName="profile-builder"
                    useFormOptions={{
                        mode: 'onBlur',
                        reValidateMode: 'onChange',
                    }}
                    onHandleSubmit={submitProfile}
                />
            </div>
            <div className={classes.footerBlocker} />
        </div>
    );
};

export default ProfileView;
