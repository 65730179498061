import { useSelector } from 'react-redux';
import { addTaxes } from '../../../yoello-lib/modules/utils/TaxController';
import { IOrderBody, IDeliveryAddress, OrderOption } from '../../../typings/IPayment.d';
import { SelectedOrderType, BackendTimeSlots, IDiscountStoreData, PromotionType } from '../types.d';
import { CARDIFF_CASTLE } from '../../../constants';
import { roundPriceOption } from '../../../utils/pricingCalculator';
import { IAddCartItem } from '../../../redux/Reducers/Cart';
import { itemTotalCount } from '../../../utils/cartController';
import { DiscountType } from '../Dsicounts/DiscountsComponent';
import { getAvailableMenuId } from '../../Utils/getAvailableMenuId';

const useOrderBody = () => {
    const venue = useSelector((state: any) => state.Venue);
    const group = useSelector((state: any) => state.Group);
    const cartID = useSelector((state: any) => state.CartID);
    const cart = useSelector((state: any) => state.Cart);
    const availableMenus = useSelector((state: any) => state.AvailableMenus);
    const DeliveryDistance = useSelector((state: any) => state.DeliveryDistance);

    const generateOrderBody = (
        selectedTime: BackendTimeSlots,
        isUsingPaymentRequest: boolean,
        cartItemTaxAmount: number,
        serviceTaxAmount: number,
        deliveryTaxAmount: number,
        message: string,
        selectedOrderType: any,
        deliveryPrice: number,
        tipTotal: number,
        areaName: string,
        selectedTable: string,
        tableAreaId: string,
        totalPrice: number,
        deliveryAddress: IDeliveryAddress,
        timeSlotsData: BackendTimeSlots[],
        discounts?: IDiscountStoreData,
        orderOnly?: boolean,
        promotion?: PromotionType,
        metadata?: any,
    ) => {
        let selectedTimeSlot = timeSlotsData?.find((item: BackendTimeSlots) => {
            return item?.venue_time === selectedTime?.venue_time;
        });
        let UTCtimeStamp = selectedTimeSlot?.time_utc;
        let VenueTimeStamp = selectedTimeSlot?.venue_time;

        const taxTotal = addTaxes(cartItemTaxAmount, serviceTaxAmount, deliveryTaxAmount);

        const orderBody: IOrderBody = {
            venue_id: venue.id,
            intent: isUsingPaymentRequest,
            comment: message,
            delivery_charge:
                selectedOrderType === SelectedOrderType.DELIVERY ||
                (group?.service_charge_enabled && group?.nickname === CARDIFF_CASTLE)
                    ? deliveryPrice
                    : null,
            gratuity: roundPriceOption(tipTotal),
            delivery_table: selectedOrderType === SelectedOrderType.TABLE ? selectedTable : null,
            total_price: roundPriceOption(totalPrice + taxTotal),
            table_area: selectedOrderType === SelectedOrderType.TABLE ? areaName : null,
            table_area_id: selectedOrderType === SelectedOrderType.TABLE ? tableAreaId : null,
            order_type: selectedOrderType,
            takeaway_timeslot: selectedOrderType === SelectedOrderType.TABLE ? null : UTCtimeStamp,
            venue_takeaway_timeslot: VenueTimeStamp,
            delivery_address: deliveryAddress,
            menu_items: [],
            group_id: undefined,
            cart_id: cartID,
            order_option: orderOnly ? OrderOption.ORDER_ONLY : OrderOption.ORDER_AND_PAY,
            delivery_distance: DeliveryDistance,
        };
        if (selectedTime?.asap_delivery_requested && selectedOrderType !== SelectedOrderType.TABLE) {
            const nextAvailableTimeSlot = timeSlotsData?.find(
                (item) => !item.asap_delivery_requested && (item.collection || item.delivery),
            );
            const currentUTCDateTime = new Date().toISOString();
            orderBody.asap_delivery_requested = true;
            orderBody.takeaway_timeslot = nextAvailableTimeSlot?.time_utc ?? currentUTCDateTime;
            orderBody.venue_takeaway_timeslot = nextAvailableTimeSlot?.venue_time ?? currentUTCDateTime;
        }
        if (group?.id && group?.nickname === CARDIFF_CASTLE) {
            orderBody.group_id = group?.id;
        }
        const menuItems = cart.map((cartItem: IAddCartItem) => {
            let extras: {
                menu_id?: string;
                extra_id: string;
                extra_pricing_option_key: string;
            }[] = [];

            const menuId = cartItem.menuItem.menu_id ?? getAvailableMenuId(cartItem.menuItem.menu_ids, availableMenus);
            if (cartItem.extras) {
                for (const extraKey in cartItem.extras) {
                    const extraArray = cartItem.extras[extraKey];
                    extraArray.forEach((extra) => {
                        extras.push({
                            menu_id: menuId,
                            extra_id: extra.id,
                            extra_pricing_option_key: extra.priceName,
                            // @ts-ignore
                            quantity: extra.count ? extra.count * cartItem.itemCount : cartItem.itemCount,
                        });
                    });
                }
            }
            const { menuItem, measure, itemCount, preferences } = cartItem;

            return {
                menu_id: menuId,
                menu_item_id: menuItem.menu_item_id,
                quantity: itemTotalCount(menuItem, measure, itemCount),
                pricing_option_key: measure,
                extras: extras,
                modifiers: [],
                preferences: preferences
                    ? Object.keys(preferences).map((preference) => {
                          return preferences[preference];
                      })
                    : [],
            };
        });
        orderBody.menu_items = menuItems;
        if (!discounts?.error && discounts?.value) {
            orderBody.discount = {
                id: discounts.id,
                type: discounts.absoluteValue ? DiscountType.ABSOLUTE : discounts.type,
                value: discounts.absoluteValue ?? discounts.value,
                code: discounts.code,
                metadata: null,
            };
        }
        if (promotion?.promotion_id) {
            orderBody.promotion = promotion;
        }
        if (metadata) {
            orderBody.metadata = metadata;
        }
        console.log('orderBody', orderBody);
        return orderBody;
    };

    return {
        generateOrderBody,
    };
};

export default useOrderBody;
