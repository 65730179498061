import React, { useEffect } from 'react';
import OtpInput from 'react-otp-input';
import makeStyles from '@material-ui/styles/makeStyles';
import { onKeyboardPressHandler } from '../../../utils/keyboard';
import ICommonFormProps from '../../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import FormElement from '../../../yoello-lib/modules/dynamicForms/FormElement';
import { generateRegister } from '../../../yoello-lib/modules/dynamicForms/utils';

export const useStyles = makeStyles({
    root: {
        '&>div': {
            background: '#F9F9F9',
            borderRadius: '0.5rem',
            justifyContent: 'center',
            padding: '.55rem 0',
            '&>div': {
                borderBottom: '1px solid #e0e0e0',
            },
        },
    },
    label: {
        width: '100%',
    },
    labelText: {
        color: '#616161',
        fontSize: '0.875rem',
        lineHeight: '1.1875rem',
        fontWeight: 400,
        fontStyle: 'normal',
        margin: 0,
        marginBottom: '0.3125rem',
        textAlign: 'left',
    },
    input: {
        border: 'unset',
        margin: '0 0.625rem',
        background: 'transparent',
        fontSize: '1.375rem',
        color: '#212121',
        fontWeight: 600,
        lineHeight: '1.875rem',
        width: '1.875rem',
        justifyContent: 'center',
        padding: 0,
        borderBottom: '1px solid #ccc',
    },
});

export function VerificationInput(props: ICommonFormProps) {
    const { root, input, labelText } = useStyles();
    useEffect(() => {
        if (props.formProps.value) {
            if (props.setValue) {
                props.setValue(props.keyName, props.value);
                props.triggerValidation!(props.keyName);
            }
        }
        // eslint-disable-next-line
    }, [props.formProps.value]);
    return (
        <div className={root}>
            <p className={labelText}>{props.label}</p>
            <FormElement {...props} keyName={props.keyName}>
                <OtpInput
                    name={props.keyName}
                    autoComplete="off"
                    shouldAutoFocus={true}
                    onKeyPress={onKeyboardPressHandler}
                    {...generateRegister(props)}
                    isInputNum={true}
                    className={input}
                    value={props.formProps.value}
                    onChange={props.formProps.onChange}
                    numInputs={6}
                    inputStyle={input}
                    renderInput={(props) => <input className={input} {...props} />}
                />
            </FormElement>
        </div>
    );
}
