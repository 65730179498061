import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMultiVendorBasket } from '../../customHooks/useMultiVendorBasket';
import ConfirmOrder from '../Widgets/ConfirmOrder/ConfirmOrder';

export default function ConfirmOrderContainer() {
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory<any>();
    const Venue = useSelector((state: any) => state.Venue);
    const ConfirmedOrder = useSelector((state: any) => state.ConfirmedOrder);
    const GroupName = useSelector((state: any) => state.GroupName);
    const [isMounted, setMounted] = useState(false);
    const { isGroupVenue } = useMultiVendorBasket();

    useEffect(() => {
        setIsOpen(true);
        setMounted(true);
        return () => {
            closeDraw();
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isOpen && isMounted) {
            /**Animation timeout */
            setTimeout(() => {
                if (isGroupVenue) {
                    history.replace(`/g/${GroupName}/${Venue.venue_nickname}/menu`);
                } else {
                    history.replace(`/v/${Venue.venue_nickname}/menu`);
                }
            }, 350);
        }
        //eslint-disable-next-line
    }, [isOpen, history]);

    function closeDraw() {
        setIsOpen(false);
    }
    const locationState = history?.location?.state;

    const props = {
        TimeSlot: locationState?.TimeSlot ?? ConfirmedOrder?.TimeSlot,
        selectedOrderType: locationState?.OrderType ?? ConfirmedOrder?.OrderType,
        CloseDrawer: closeDraw,
        orderId: locationState?.orderId ?? ConfirmedOrder?.orderId,
        deliveryAddress: locationState?.deliveryAddress ?? ConfirmedOrder?.deliveryAddress,
        tableArea: locationState?.tableArea ?? ConfirmedOrder?.tableArea,
        shareOnConfirmOrderPage: true,
        prepTime: Venue?.food_prep_time,
        orders: locationState?.orders ?? ConfirmedOrder?.orders,
        history,
        asapDeliveryRequested: locationState?.asapDeliveryRequested,
    };

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={isOpen}
            onClose={closeDraw}
            disableSwipeToOpen={true}
            disableDiscovery={true}
            id={'OrderQrCode'}
            transitionDuration={350}
            data-component-name="confirm-order-drawer"
            onOpen={() => null}
        >
            <ConfirmOrder {...props} />
        </SwipeableDrawer>
    );
}
