import * as types from '../actions/ActionTypes';

export type IDeliveryDistance = number | null;

export function DeliveryDistance(state: IDeliveryDistance = null, payload) {
    switch (payload.type) {
        case types.SetDeliveryDistance:
            return payload.data;
        default:
            return state;
    }
}
