import useColors from '../../../customHooks/useColors';
import Styles from './styles.module.scss';
import { TextField } from '../TextField';
import makeStyles from '@material-ui/styles/makeStyles';
import { ReactComponent as ClockIcon } from '../../../imges/ClockIcon.svg';
import { Trans, useTranslation } from 'react-i18next';
import { SelectedOrderType } from '../../Payment/types.d';
import { useMultiVendorBasket } from '../../../customHooks/useMultiVendorBasket';
import { VenueTitle } from '../../Payment/MultiVendorBasket/VenueTitle';



export function OrderConfirmation(props) {
    const { t } = useTranslation();
    const { lowerOpacityButtonColor, primaryBorderColor } = useColors();

    const { isMultiVendorBasket } = useMultiVendorBasket();
    const useStyles = makeStyles({
        OrderInfoBox: {
            background: lowerOpacityButtonColor,
            border: `1px solid ${primaryBorderColor}`,
        },
        OrdersSplitter: {
            borderBottom: `1px solid ${primaryBorderColor}`,
            '&> div': {
                p: {
                    margin: 0,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '1.125rem',
                    lineHeight: '1.563rem',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#212121',
                },
            },
        },
    });
    const classes = useStyles();

    const isMultiTableOrder = isMultiVendorBasket && props.selectedOrderType === SelectedOrderType.TABLE;
    const isMultiPickupOrder = isMultiVendorBasket && props.selectedOrderType === SelectedOrderType.COLLECTION_POINT;

    function setOrderText() {
        switch (props.selectedOrderType) {
            case SelectedOrderType.CLICK:
                return 'Payment.OrderCollectText';
            case SelectedOrderType.DELIVERY:
                return 'Payment.OrderDeliveryText';
            case SelectedOrderType.TABLE:
                return `Payment.OrderTableText`;
        }
    }

    function displayOrderDetails(hideOrdersTex: boolean, prepTime?: number, isLastOrder?: boolean) {
        if (hideOrdersTex) return null;
        return (
            ((props.selectedOrderType === SelectedOrderType.COLLECTION_POINT && (props.prepTime || prepTime)) ||
                props.selectedOrderType !== SelectedOrderType.COLLECTION_POINT) && (
                <div>
                    <p className={Styles.OrderSentText}>
                        {props.selectedOrderType === SelectedOrderType.COLLECTION_POINT ? (
                            <Trans i18nKey={'Payment.CollectionPointText'} components={{ bold: <strong /> }} />
                        ) : (
                            t(setOrderText())
                        )}
                    </p>
                    <p
                        className={`${Styles.OrderSentInfoText} ${
                            isMultiPickupOrder && !isLastOrder ? classes.OrdersSplitter : undefined
                        }`}
                    >
                        {getOrderFeedback(prepTime)}
                    </p>
                </div>
            )
        );
    }

    const getTimeSlot = () => {
        if (props.asapDeliveryRequested) {
            return `${t('Misc.ASAP')}(~${props.TimeSlot})`;
        } else {
            return props.TimeSlot;
        }
    };

    function getOrderFeedback(prepTime?: number) {
        switch (props.selectedOrderType) {
            case SelectedOrderType.CLICK:
                return <span>{getTimeSlot()}</span>;
            case SelectedOrderType.DELIVERY:
                return (
                    <span>
                        {getTimeSlot()}
                        <br />
                        {props.deliveryAddress?.apartment ?? ""} {props.deliveryAddress?.street_address} {props.deliveryAddress?.city} {props.deliveryAddress?.postcode}
                    </span>
                );
            case SelectedOrderType.TABLE:
                return (
                    <span>
                        {props.tableArea},<b>{props.Table.name}</b>
                    </span>
                );
            case SelectedOrderType.COLLECTION_POINT:
                return (
                    <span>
                        <ClockIcon />{' '}
                        <b>
                            {prepTime ?? props.prepTime} {t('Misc.minutes')}
                        </b>
                    </span>
                );
            default:
                return null;
        }
    }
    return (
        <div className={`${Styles.OrderInfoBox} ${classes.OrderInfoBox}`}>
            {isMultiVendorBasket ? (
                props?.orders?.map((order, index) => (
                    <>
                        <div
                            className={`${Styles.OrderIdDiv} ${isMultiTableOrder ? classes.OrdersSplitter : undefined}`}
                            style={isMultiTableOrder ? { paddingBottom: '0.938rem' } : {}}
                            key={order.orderId}
                        >
                            <VenueTitle venueName={order.venueName} logo={order.venueLogo} />
                            <p>
                                <strong data-component-name="orderId">{order.orderId}</strong>
                            </p>
                        </div>
                        {displayOrderDetails(isMultiTableOrder, order.prepTime, index === props.orders.length - 1)}
                    </>
                ))
            ) : (
                <div className={Styles.OrderIdDiv}>
                    <TextField text={{ key: `Payment.OrderId` }} />
                    <p>
                        <strong data-component-name="orderId">{props.orderId}</strong>
                    </p>
                </div>
            )}

            {displayOrderDetails(isMultiPickupOrder)}
        </div>
    );
}
