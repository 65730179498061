import { useTranslation } from 'react-i18next';
import { IOrders } from '../../../typings/IOrders.d';
import Styles from './orderDetails.module.scss';
import { currencyFormat } from './PaymentDetails';
interface IPaymentDiscountProps {
    orderItem: IOrders;
}
export function PaymentDiscount({ orderItem }: IPaymentDiscountProps) {
    const { placed_order_discount_frozen, placed_order_discount_code, placed_order_discount_value } = orderItem;
    const { t } = useTranslation();

    if (!placed_order_discount_frozen) {
        return null;
    }
    function renderItem() {
        if (placed_order_discount_frozen.type === 'PERCENTAGE') {
            return `-${placed_order_discount_frozen.value}%`;
        }
        return `-${currencyFormat(orderItem, placed_order_discount_frozen.value)}`;
    }
    return (
        <div className={Styles.PaymentDiscount}>
            <div>
                <div>{t('OrderHistory.Discount')}</div>
                <div>
                    {placed_order_discount_code} ({renderItem()})
                </div>
            </div>
            <p className="dark-theme-uninvert">-{currencyFormat(orderItem, placed_order_discount_value)}</p>
        </div>
    );
}
