import { ISelectedExtras } from '../types';

export const SET_POSITION = 'SET_POSITION';

export function setPosition(position: number) {
    return {
        type: SET_POSITION,
        payload: position,
    };
}

export const SET_PRICE = 'SET_PRICE';

export function setPrice(priceName: string, priceAmount: number) {
    return {
        type: SET_PRICE,
        payload: {
            priceName,
            priceAmount,
        },
    };
}

export const SET_PREFERENCES = 'SET_PREFERENCES';

export function setPreferences(preferences: Record<string, string>) {
    return {
        type: SET_PREFERENCES,
        payload: preferences,
    };
}
export const SET_EXTRAS = 'SET_EXTRAS';

export function setExtras(extraId: string, extras: ISelectedExtras[]) {
    return {
        type: SET_EXTRAS,
        payload: {
            extraId,
            extras,
        },
    };
}

export const SET_ITEM_COUNT = 'SET_ITEM_COUNT';

export function setItemCount(itemCount: number) {
    return {
        type: SET_ITEM_COUNT,
        payload: itemCount,
    };
}

export const SET_IS_CONTINUE = 'SET_IS_CONTINUE';

export function setIsContinue(isContinue: boolean) {
    return {
        type: SET_IS_CONTINUE,
        payload: isContinue,
    };
}

export const SET_IS_TAGS_SHOWN = 'SET_IS_TAGS_SHOWN';

export function setIsTagsShown(isTagsShown: boolean) {
    return {
        type: SET_IS_TAGS_SHOWN,
        payload: isTagsShown,
    };
}
export function setDisplayeExtrasAllergens(menuItem: any, isOpen: boolean) {
    return {
        type: SET_EXTRAS_ALLERGENS,
        payload: { menuItem: menuItem, isOpen: isOpen },
    };
}

export const SET_EXTRAS_ALLERGENS = 'SET_EXTRAS_ALLERGENS';
