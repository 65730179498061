import makeStyles from '@material-ui/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect, useState } from 'react';
import useColors from '../../customHooks/useColors';
import FormElement from '../../yoello-lib/modules/dynamicForms/FormElement';
import ICommonFormProps from '../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';

export interface IInfoCheckInput extends ICommonFormProps {
    Component: React.FunctionComponent<any>;
    inputOptions: {
        message: string;
        defaultChecked: boolean;
    };
}

const InfoCheckInput = ({ inputOptions, setValue, keyName, ...props }: IInfoCheckInput) => {
    const [isChecked, setIsChecked] = useState(inputOptions?.defaultChecked);
    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setIsChecked(e.currentTarget.checked);
    };
    const { primaryBackgroundColor } = useColors();
    useEffect(() => {
        setValue(keyName, isChecked);
    }, [isChecked, setValue, keyName]);

    const useStyles = makeStyles({
        label: {
            display: 'flex',
            alignItems: 'start',
            paddingBottom: '4rem',
            '& .Mui-checked': {
                color: primaryBackgroundColor,
            },
        },
        infoText: {
            margin: '0',
        },
    });
    const classes = useStyles();
    return (
        <FormElement {...props} keyName={keyName}>
            <label className={classes.label}>
                <Checkbox
                    style={{
                        padding: '0',
                    }}
                    checked={isChecked}
                    onChange={onChange}
                    color={'primary'}
                />
                <p className={classes.infoText}>{inputOptions?.message}</p>
            </label>
        </FormElement>
    );
};

export default InfoCheckInput;
