import { useEffect, useState } from 'react';
import { TextField } from '../TextField';
import { IButtonProps } from './types.d';
import Styles from './styles.module.scss';
import useColors from '../../../customHooks/useColors';
import makeStyles from '@material-ui/styles/makeStyles';

const Buttons = ({
    id,
    isHeaderBtn,
    title,
    titleVariables = {},
    disabled,
    onClick,
    className,
    Icon,
    iconClassName,
    style,
    embeddedComponent,
    isLoading = false,
    dataComponentName,
    selectedLanguage,
    isYoelloBtn,
    type,
    defaultBackground,
}: IButtonProps) => {
    const [language, setLanguage] = useState('');
    useEffect(() => {
        setLanguage(selectedLanguage);
        //eslint-disable-next-line
    }, [selectedLanguage]);
    const { primaryBackgroundColor } = useColors();

    const useStyles = makeStyles({
        YoelloButton: {
            background: primaryBackgroundColor,
            borderRadius: '0.625rem',
            border: 'unset',
            width: '100%',
            height: '3.125rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.5625rem 1.125rem',
            cursor: 'pointer',
            '& >p': {
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '1rem',
                lineHeight: '1.25rem',
                textAlign: 'center',
                color: '#212121',
                margin: '0',
            },
            '& >span': {
                fontSize: '1.5rem',
            },
        },
        defaultBackground: {
            background: primaryBackgroundColor,
        },
    });

    const classes = useStyles();
    return (
        <button
            style={style}
            id={id}
            className={`${className} ${Styles.Clickable} ${disabled ? Styles.disabled : undefined} ${
                isHeaderBtn ? Styles.headerBtn : undefined
            } ${language}
      ${isYoelloBtn ? classes.YoelloButton : undefined} ${defaultBackground ? classes.defaultBackground : undefined}`}
            onClick={onClick}
            data-component-name={dataComponentName}
            type={type}
        >
            {isLoading ? (
                <span className="loader"></span>
            ) : (
                <>
                    {embeddedComponent && embeddedComponent}
                    {title && <TextField text={{ key: `buttons.${title}`, options: { ...titleVariables } }} />}
                    {Icon && Icon}
                    {iconClassName && <span className={iconClassName}></span>}
                </>
            )}
        </button>
    );
};

export default Buttons;
