import axios from './api/index';
export const hostname = window && window.location && window.location.hostname;

function createHeaders(token) {
    return {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: token ? 'Bearer ' + token : undefined,
        // "yoello-session-id": SessionClient.sessionId
    };
}

export const GetVenues = (offset, limit) => {
    return axios({
        method: 'GET',
        url: 'venues' + (offset !== null ? '?offset=' + offset : '') + (limit ? '&limit=' + limit : ''),
        headers: createHeaders(),
    });
};

export const GetGroupVenues = (groupName) => {
    return axios({
        method: 'GET',
        url: 'venue-groups/' + groupName,
        headers: createHeaders(),
    });
};

export const GetVenueInfo = (id) => {
    return axios({
        method: 'GET',
        url: 'venues/' + id,
        headers: createHeaders(),
    });
};

export const GetMenuItems = (venue_id, group_id, orderType) => {
    return axios({
        method: 'GET',
        url:
            'menu-items/' +
            venue_id +
            '?' +
            `${orderType ? 'order_type=' + orderType + '&' : ''}` +
            `${group_id ? 'group_id=' + group_id + '&' : ''}`,
        headers: createHeaders(),
    });
};
export const GetMenuItemsSubcategories = (venue_id) => {
    return axios({
        method: 'GET',
        url: 'menu-item-subcategories/' + venue_id,
        headers: createHeaders(),
    });
};

export const ConsumerCreate = (data, token) => {
    return axios({
        method: 'post',
        url: 'v2/consumers',
        data: data,
        headers: createHeaders(token),
    });
};

export const ConsumerUpdate = (data, token) => {
    return axios({
        method: 'put',
        url: 'v2/consumers',
        data: data,
        headers: createHeaders(token),
    });
};

export const getConsumer = (token) => {
    return axios({
        method: 'get',
        url: 'v2/consumers',
        headers: createHeaders(token),
    });
};

export const getGuestOrders = (consumerId, guestId) => {
    return axios({
        method: 'get',
        url: 'v2/consumers/' + consumerId + '/guest/' + guestId + '/orders-latest',
        headers: createHeaders(),
    });
};

export const CreatePaymentDetails = (data, token, updatePaymentDetails) => {
    // venue id is being sent inside data
    return axios({
        method: updatePaymentDetails ? 'put' : 'post',
        url: 'v2/payment-details',
        data: data,
        headers: createHeaders(token),
    });
};

export const getPaymentDetails = (token) => {
    return axios({
        method: 'get',
        url: 'v2/payment-details',
        headers: createHeaders(token),
    });
};
export const CreateOrder = (data, token, venueId) => {
    return axios({
        method: 'post',
        url: 'v2/place-order',
        data: data,
        headers: createHeaders(token),
    });
};
export const CreateMultiVendorOrder = (data, token) => {
    return axios({
        method: 'post',
        url: 'v2/place-group-order',
        data: data,
        headers: createHeaders(token),
    });
};
export const GuestCreateOrder = (data) => {
    return axios({
        method: 'post',
        url: 'v2/guest/place-order',
        data: data,
        headers: createHeaders(),
    });
};
export const GuestCreateMultiVendorOrder = (data) => {
    return axios({
        method: 'post',
        url: 'v2/guest/place-group-order',
        data: data,
        headers: createHeaders(),
    });
};

export const getOrderHistoryList = (token) => {
    return axios({
        method: 'get',
        url: 'v2/orders',
        headers: createHeaders(token),
    });
};

export const getOrderHistoryView = (token, id) => {
    return axios({
        method: 'get',
        url: 'v2/orders/' + id,
        headers: createHeaders(token),
    });
};
export const getGuestOrderHistoryView = (consumerId, guestId, orderId) => {
    return axios({
        method: 'get',
        url: 'v2/consumers/' + consumerId + '/guest/' + guestId + '/orders/' + orderId,
        headers: createHeaders(),
    });
};

export const deleteUser = (token) => {
    return axios({
        method: 'delete',
        url: 'v2/data-cleanup',
        headers: createHeaders(token),
    });
};

export const getGoogleMapDistance = (VenueAdress, CustomerAddress) => {
    return axios({
        method: 'get',
        url: `https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=${VenueAdress}&destinations=${CustomerAddress}&key=AIzaSyAVh852Ll0UcYgz9eIH7s35FQje47qj48U`,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
};

export const getConsumerPreferences = (token) => {
    return axios({
        method: 'get',
        url: 'v2/consumer_preferences',
        headers: createHeaders(token),
    });
};

export const ConsumerUpdatePreferences = (data, token) => {
    return axios({
        method: 'PATCH',
        url: 'v2/consumer_preferences',
        data: data,
        headers: createHeaders(token),
    });
};

export const getVenueTimeSlotsList = (venue_nickname, order_type) => {
    return axios({
        method: 'get',
        url: 'venues/' + venue_nickname + '/time_slots?order_type=' + order_type,
        headers: createHeaders(),
    });
};

export const getPlacedOrderPaymentIntent = (token, placed_order_id) => {
    return axios({
        method: 'get',
        url: 'v2/place-order-payment-intent/' + placed_order_id,
        headers: createHeaders(token),
    });
};
export const getGuestPlacedOrderPaymentIntent = (consumerId, guestId, placed_order_id) => {
    return axios({
        method: 'get',
        url: 'v2/consumers/' + consumerId + '/guest/' + guestId + '/orders/' + placed_order_id + '/payment-intent',
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
};
export const getDiscountsCodeInfo = (token, data) => {
    return axios({
        method: 'post',
        url: 'v2/discounts/validate',
        data: JSON.stringify(data),
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + token,
        },
    });
};
export const getGuestDiscountsCodeInfo = (data) => {
    return axios({
        method: 'post',
        url: 'v2/guest/discounts/validate',
        data: JSON.stringify(data),
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
};

export const getVenueTippingOptions = (token, venue_nickname) => {
    return axios({
        method: 'get',
        url: 'venues/' + venue_nickname + '/tipping_options',
        headers: createHeaders(),
    });
};
export const getPaymentProcessors = (id) => {
    return axios({
        method: 'get',
        url: `v1/venues/${id}/flags/payment_processors`,
        headers: createHeaders(),
    });
};
