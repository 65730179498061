import * as types from '../actions/ActionTypes';

const CustomerName = (state = '', action) => {
    switch (action.type) {
        case types.ChangeCustomerName:
            state = action.data;
            return state;

        default:
            return state;
    }
};
export default CustomerName;
