import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCartSubTotal } from '../../../utils/pricingCalculator';
import { setCartData } from '../state/Menu.Actions';
import useFormatCurrency from '../../../yoello-lib/modules/localisation/useCurrency';

export function useCart(dispatch) {
    const cartItems = useSelector((state: any) => state.Cart);
    const { formatCurrency } = useFormatCurrency();

    useEffect(() => {
        let cartCount = 0;
        let cartAmount = null;

        if (cartItems) {
            cartCount = cartItems.reduce((prevItem, nextItem) => {
                return prevItem + nextItem.itemCount;
            }, 0);
            const amount = getCartSubTotal(cartItems);
            cartAmount = amount === 0 ? null : amount;
        }
        dispatch(setCartData(cartCount, cartAmount));
    }, [cartItems, formatCurrency, dispatch]);
}
