import Button from '../../../Widgets/Button';
import { TextField } from '../../../Widgets/TextField';
import Styles from './orderCollection.module.scss';
import { ReactComponent as BlackQRCode } from '../../../../imges/BlackQRCode.svg';
import { ReactComponent as CompleteQRCode } from '../../../../imges/CompleteQRCode.svg';
import useColors from '../../../../customHooks/useColors';
import makeStyles from '@material-ui/styles/makeStyles';

interface IOrderCollectionProps {
    collectionPointName: string;
    orderId: string;
    onClick: () => void;
}

export function OrderCollectionActive({ collectionPointName, orderId, onClick }: IOrderCollectionProps) {
    const { secondaryBackgroundColor, primaryBorderColor } = useColors();

    const useStyles = makeStyles({
        OrderCollectionContainer: {
            background: secondaryBackgroundColor,
            border: `1px solid ${primaryBorderColor}`,
        },
    });
    const classes = useStyles();
    return (
        <div className={`${Styles.OrderCollectionContainer} ${classes.OrderCollectionContainer}`}>
            <TextField className={Styles.title} text={{ key: 'OrderHistory.collectPoint' }} />
            <p className={Styles.CollectionName}>{collectionPointName}</p>
            <Button
                className={Styles.CollectionActiveButton}
                title="QRActive"
                Icon={<BlackQRCode />}
                dataComponentName="QRCodeActive"
                onClick={onClick}
                defaultBackground
            />
        </div>
    );
}

export function OrderCollectionComplete({ collectionPointName, orderId, onClick }: IOrderCollectionProps) {
    const { primaryIconColor } = useColors();
    return (
        <Button
            title="QRComplete"
            Icon={<CompleteQRCode fill={primaryIconColor} />}
            className={Styles.CollectionButtonComplete}
            dataComponentName="QRCodeComplete"
            onClick={onClick}
            defaultBackground
        />
    );
}
