import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';
import { BasketItemsMetaData, IDiscountStoreData, IMultiBasket } from './types.d';
import { useMemo } from 'react';
import { setMultiVendorBasketMetaData } from './reducer/PaymentActions';
import { usePaymentState } from './PaymentStore';
import { getCartItemTaxAmount, getCartSubTotal, priceToPounds, roundPriceOption } from '../../utils/pricingCalculator';
import { discountsInitialValue } from './Dsicounts/DiscountsComponent';
import { calculateTotalDiscountAmount } from '../Utils/CalcTotalDiscountAmount';
import { calculateServiceFeesAndTaxes } from '../Utils/CalcMultiVendorFeesAndTaxes';
import { addTaxes } from '../../yoello-lib/modules/utils/TaxController';
import { TipType } from './Tips/useTip';
import { IDefaultTip } from '../../redux/Reducers/DefaultTipReducer';

export const initialValue = {
    subTotalCost: 0,
    cartItemInclusiveTaxAmount: 0,
    cartItemTaxAmount: 0,
    serviceTaxAmount: 0,
    serviceTaxInclusiveTaxAmount: 0,
    tipTotal: 0,
    totalDiscounts: 0,
    totalPrice: 0,
    transactionFee: 0,
};

export function useMultiVendorBasketView() {
    const Venues = useSelector((state: any) => state.Group.venues);
    const cartItems = useSelector((state: any) => state.Cart);
    const { dispatch, basket } = usePaymentState();

    const itemsInCartGroupedByVenueId = useMemo(() => {
        const items = groupBy(cartItems, 'venueId');
        return items;
    }, [cartItems]);
    function evaluateBasketItems(key: string, data?: any, venueId?: string, defaultTip?: IDefaultTip) {
        let newBasket = { ...basket };
        switch (key) {
            case BasketItemsMetaData.INITIALSTATE:
                Object.keys(itemsInCartGroupedByVenueId).forEach((key: string) => {
                    const subTotalCost = getCartSubTotal(itemsInCartGroupedByVenueId[key]);
                    const { serviceCharge, serviceChargeAdditiveTax, serviceChargeInclusiveTax } =
                        calculateServiceFeesAndTaxes(subTotalCost, 0, 0, Venues[key]);
                    const taxAmount = getCartItemTaxAmount(itemsInCartGroupedByVenueId[key], newBasket[key]?.discounts);
                    newBasket[key] = {
                        discounts: discountsInitialValue,
                        message: '',
                        cartItemTaxAmount: taxAmount.totalTaxAmount,
                        cartItemInclusiveTaxAmount: taxAmount.totalInclusiveTaxAmount,
                        tipTotal: 0,
                        totalDiscounts: 0,
                        subTotalCost: subTotalCost,
                        serviceTaxAmount: serviceChargeAdditiveTax,
                        serviceTaxInclusiveTaxAmount: serviceChargeInclusiveTax,
                        totalPrice: subTotalCost + serviceCharge,
                        transactionFee: serviceCharge,
                    };
                });
                break;
            case BasketItemsMetaData.DISCOUNT:
                {
                    const { tipTotal, transactionFee, subTotalCost } = newBasket[venueId]!;
                    const taxAmount = getCartItemTaxAmount(itemsInCartGroupedByVenueId[venueId], data);
                    const newTotalDiscounts = calculateTotalDiscountAmount(data, newBasket[venueId]?.subTotalCost);
                    const { serviceCharge, serviceChargeAdditiveTax, serviceChargeInclusiveTax } =
                        calculateServiceFeesAndTaxes(
                            roundPriceOption(subTotalCost - newTotalDiscounts) + transactionFee + tipTotal,
                            transactionFee,
                            tipTotal,
                            Venues[venueId],
                        );

                    newBasket[venueId] = {
                        ...newBasket[venueId],
                        discounts: data as IDiscountStoreData,
                        totalDiscounts: newTotalDiscounts,
                        serviceTaxAmount: serviceChargeAdditiveTax,
                        serviceTaxInclusiveTaxAmount: serviceChargeInclusiveTax,
                        totalPrice: roundPriceOption(subTotalCost - newTotalDiscounts) + serviceCharge + tipTotal,
                        transactionFee: serviceCharge,
                        cartItemTaxAmount: taxAmount.totalTaxAmount,
                        cartItemInclusiveTaxAmount: taxAmount.totalInclusiveTaxAmount,
                    };
                    if (defaultTip) {
                        newBasket = { ...calculateTip({ ...newBasket }, defaultTip) };
                    }
                }
                break;
            case BasketItemsMetaData.MESSAGE:
                newBasket[venueId] = {
                    ...newBasket[venueId],
                    message: data,
                };
                break;
            case BasketItemsMetaData.TIPTOTAL: {
                newBasket = { ...calculateTip({ ...newBasket }, data) };
                break;
            }
            case BasketItemsMetaData.REMOVEFORMBASKET:
                delete newBasket[venueId];
                break;
            default:
                break;
        }
        if (dispatch) {
            dispatch(setMultiVendorBasketMetaData(newBasket));
        }
    }

    const calculateTip = (newBasket, data) => {
        const total = getCartItemsTotal({ ...newBasket });
        const { tipType, customValue, tipPercantage } = data;
        let totalTip = 0;
        const totalPrice = total.totalPrice;
        const totalTax = addTaxes(total.cartItemTaxAmount, total.serviceTaxAmount);
        switch (tipType) {
            case TipType.ZERO:
                totalTip = 0;
                break;
            case TipType.ROUNDUP:
                const roundUpPrice = priceToPounds(totalPrice + totalTax - total.tipTotal) as unknown as number;
                totalTip = (Math.ceil(roundUpPrice) - roundUpPrice) * 100;
                break;
            case TipType.PERCENTAGE:
                let price = totalPrice + totalTax - total.tipTotal;
                totalTip = (price * tipPercantage) / 100;
                break;
            case TipType.CUSTOM:
                if (customValue) {
                    totalTip = customValue * 100;
                }
                break;
            default:
                break;
        }

        Object.keys(itemsInCartGroupedByVenueId).forEach((key: string) => {
            if (newBasket[key]) {
                const { subTotalCost, transactionFee, totalDiscounts } = newBasket[key];
                const proportion = subTotalCost / total.subTotalCost;
                const venueTip = totalTip * proportion;
                const newTotalPrice =
                    venueTip +
                    (totalDiscounts ? roundPriceOption(subTotalCost - totalDiscounts) : subTotalCost) +
                    transactionFee;
                const { serviceCharge, serviceChargeAdditiveTax, serviceChargeInclusiveTax } =
                    calculateServiceFeesAndTaxes(newTotalPrice, transactionFee, venueTip, Venues[key]);
                newBasket[key] = {
                    ...newBasket[key],
                    tipTotal: venueTip,
                    serviceTaxAmount: serviceChargeAdditiveTax,
                    serviceTaxInclusiveTaxAmount: serviceChargeInclusiveTax,
                    transactionFee: serviceCharge,
                    totalPrice:
                        venueTip +
                        (totalDiscounts ? roundPriceOption(subTotalCost - totalDiscounts) : subTotalCost) +
                        serviceCharge,
                };
            }
        });

        return newBasket;
    };

    function getCartItemsTotal(basket: Record<string, IMultiBasket>) {
        let total = initialValue;
        if (Object.keys(basket).length > 0) {
            Object.keys(itemsInCartGroupedByVenueId).forEach((key: string) => {
                if (basket[key]) {
                    const {
                        cartItemInclusiveTaxAmount,
                        cartItemTaxAmount,
                        serviceTaxAmount,
                        serviceTaxInclusiveTaxAmount,
                        subTotalCost,
                        tipTotal,
                        totalDiscounts,
                        transactionFee,
                    } = basket[key];
                    total = {
                        subTotalCost: total.subTotalCost + subTotalCost,
                        cartItemInclusiveTaxAmount: total.cartItemInclusiveTaxAmount + cartItemInclusiveTaxAmount,
                        cartItemTaxAmount: total.cartItemTaxAmount + cartItemTaxAmount,
                        serviceTaxAmount: total.serviceTaxAmount + serviceTaxAmount,
                        serviceTaxInclusiveTaxAmount: total.serviceTaxInclusiveTaxAmount + serviceTaxInclusiveTaxAmount,
                        tipTotal: total.tipTotal + tipTotal,
                        totalDiscounts: total.totalDiscounts + totalDiscounts,
                        totalPrice:
                            total.totalPrice +
                            ((totalDiscounts ? roundPriceOption(subTotalCost - totalDiscounts) : subTotalCost) +
                                transactionFee +
                                cartItemTaxAmount +
                                serviceTaxAmount +
                                tipTotal),
                        transactionFee: total.transactionFee + transactionFee,
                    };
                }
            });
        }
        return total;
    }

    return {
        itemsInCartGroupedByVenueId,
        evaluateBasketItems,
        getCartItemsTotal,
    };
}
