import { GOOGLE_MAPS_API } from '../../constants';

export function addGMapsScript() {
    const script = document.createElement('script');
    script.id = 'Google-maps-api';
    script.src = GOOGLE_MAPS_API;
    script.defer = true;

    if (!document.getElementById('Google-maps-api')) {
        document.head.appendChild(script);
    }
}
