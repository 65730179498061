import { useTranslation } from 'react-i18next';
import { ReactComponent as RefundSVG } from '../../../imges/refundSvg.svg';
import { IOrders } from '../../../typings/IOrders.d';
import Styles from './refundedItemHeader.module.scss';
interface IRefundedItemHeader {
    item: IOrders;
}
enum RefundResons {
    STOCK = 'out_of_stock',
    OTHER = 'OTHER',
    DISSATISFACTION = 'DISSATISFACTION',
}
export function RefundedItemHeader({ item }: IRefundedItemHeader) {
    const { t } = useTranslation();
    return (
        <div className={Styles.RefundedReason}>
            <RefundSVG />
            <div>
                <p>
                    {t('OrderHistory.RefundedItemsCount', {
                        count: getRefundedItemsCount(item),
                    })}
                </p>
                <p>
                    {t('OrderHistory.Reason')} {renderRefundReason(item, t)}
                </p>
            </div>
        </div>
    );
}
function renderRefundReason(item: IOrders, t: (key: string) => string) {
    switch (item.placed_order_refund_reason) {
        case RefundResons.STOCK:
            return t('OrderHistory.STOCK');
        case RefundResons.DISSATISFACTION:
            return t('OrderHistory.CUSTOMERDISSATISFACTION');
        default:
            return t('OrderHistory.OTHER');
    }
}
function getRefundedItemsCount(item: IOrders) {
    return item.placed_order_items.reduce(function (acc, obj) {
        return acc + obj.main_in_order_item_refund_quantity;
    }, 0);
}
