/**FIFO queuing system */
export default class Queue<T> {
    public queue: T[] = [];
    public offset = 0;

    public isEmpty() {
        return this.queue.length === 0;
    }
    /**Add item to list */
    public enqueue(item: T) {
        this.queue.push(item);
    }

    public length() {
        return this.queue.length - this.offset;
    }
    /**Remove first item off list */
    public dequeue() {
        if (this.isEmpty()) return null;
        const length = this.queue.length;
        const item = this.queue[this.offset];
        /**Ofsets greater than length then restart */
        if (++this.offset * 2 >= length) {
            this.queue = this.queue.splice(this.offset);
            this.offset = 0;
        }
        return item;
    }
    /** Peek at item will not remove*/
    public peek() {
        return this.queue.length > 0 ? this.queue[this.offset] : null;
    }
}
