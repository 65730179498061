import isArray from 'lodash/isArray';
import moment from 'moment';
import { IsoWeekDay, ITiming } from '../Venue/hooks/useSpecificMenuURL';

interface IAvailableMenu {
    id: string;
    enabled: boolean;
    timings: ITiming[];
    timings_enabled: boolean;
}

export function checkIfMenuIsAvailable(timings: ITiming[]) {
    const isoDay = IsoWeekDay[moment().format('dddd').toUpperCase()];
    const format = 'HH:mm:ss';
    const currentTime = moment(moment());

    if (timings && timings.length > 0) {
        let isAvailable = false;
        timings.forEach((time: ITiming) => {
            if (time.enabled && time.iso_weekday === isoDay) {
                const startTime = moment(time.start_time, format);
                const endTime = moment(time.end_time, format);
                if (startTime >= endTime) {
                    endTime.add(1, 'day');
                }
                if (currentTime.isBetween(startTime, endTime)) {
                    isAvailable = true;
                }
            }
        });
        return isAvailable;
    }
}

export function getAvailableMenuId(menuIds: string[], menus: IAvailableMenu[]) {
    if (isArray(menuIds) && menuIds.length > 0) {
        if (menuIds.length === 1) {
            return menuIds[0];
        }
        for (let menuId of menuIds) {
            for (let menu of menus) {
                if (menu.id === menuId && menu.enabled) {
                    if (!menu.timings_enabled) {
                        return menuId;
                    } else {
                        if (menu.timings && menu.timings.length > 0) {
                            if (checkIfMenuIsAvailable(menu.timings)) {
                                return menuId;
                            }
                        }
                    }
                }
            }
        }
        return undefined;
    }
}
