import { SET_USER_PASSWORD } from './Login.actions';

export interface ILoginState {
    password: string;
}
export function LoginReducer(state: ILoginState, action: { type: string; payload: any }) {
    switch (action.type) {
        case SET_USER_PASSWORD:
            return {
                ...state,
                password: action.payload,
            };
    }
    return state;
}
