export const minPriceMap = {
    COLLECTION: 'min_collection_price',
    HOME_DELIVERY: 'min_delivery_price',
    TABLE_DELIVERY: 'min_dine_in_price',
    COLLECTION_POINT_PICKUP: 'min_pickup_now_price',
};

export function getRedirectPlaceURL() {
    if (window.location.pathname.split('/')[1] === 'g') {
        return 'PaymentA';
    } else {
        return 'PaymentV';
    }
}
