import { combineReducers } from 'redux';
import ShowUp from './ShowUp';
import Tip from './Tip';
import GroupName from './GroupName';
import Venue from './Venue';
import TokenExpireDate from './TokenExpireDate';
import LoginRedirectToMainPage from './LoginRedirectToMainPage';
import CustomerName from './CustomerName';
import Group from './Group';
import Table from './Table';
import Preferences from './Preferences';
import Extras from './Extras';
import MenuItemsAsExtra from './MenuItemsAsExtra';
import MenuItemReducer from './MenuItems';
import DietaryTags from './DietaryTags';
import Cart from './Cart';
import CartRefreshTime from './CartRefreshTime';
import CartID from './CartID';
import SelectedMenuType from './SelectedMenuType';
import { AvailableMenus } from './AvailableMenus';
import Localization from './../../yoello-lib/modules/redux/reducers/localization';
import { MenuExpireTime } from './MenuExpireTime';
import { TaxesReducer } from './Taxes';
import { Notifications } from './Notifications';
import { SelectedOrderType } from './SelectedOrderType';
import { IsSidebarOpenReducer } from './SidebarLogin';
import { TotalPriceReducer } from './TotalPrice';
import { specificMenuURLReducer } from './SpecificMenu';
import { DeliveryPrice } from './DeliveryPrice';
import { DeliveryDistance } from './DeliveryDistance';
import { GroupVenue } from './GroupVenue';
import { DefaultTipReducer } from './DefaultTipReducer';
import RepeatCart from './RepeatCart';

export default combineReducers({
    ShowUp,
    Tip,
    Venue,
    TokenExpireDate,
    Preferences,
    MenuItemsAsExtra,
    LoginRedirectToMainPage,
    Extras,
    CustomerName,
    GroupName,
    Table,
    Group,
    MenuItem: MenuItemReducer,
    DietaryTags,
    Cart,
    CartRefreshTime,
    CartID,
    SelectedMenuType,
    localization: Localization,
    MenuExpireTime,
    Taxes: TaxesReducer,
    AvailableMenus,
    Notifications: Notifications,
    OrderType: SelectedOrderType,
    openSidebar: IsSidebarOpenReducer,
    totalPrice: TotalPriceReducer,
    specificMenuId: specificMenuURLReducer,
    DeliveryPrice,
    DeliveryDistance,
    IsGroupVenue: GroupVenue,
    DifaultTip: DefaultTipReducer,
    RepeatCart: RepeatCart,
});
