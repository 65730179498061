import React, { Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { priceToPounds } from '../../utils/pricingCalculator';
import { timeConverterAsString } from '../../utils/timeConverter';
import Styles from './styles.module.scss';
import { useVenueOpen } from '../../customHooks/useVenueOpen';
import { useVenueTimezones } from '../../customHooks/useVenueTimezones';
import { setIsGroupVenue, setSelectedMenuType } from '../../yoello-lib/modules/redux/actions/consumer.actions';
import { _SetDeliveryPrice, _SetIsOpenSidebar } from '../../redux/actions';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    VenueContentLoading,
    VenueHeaderLoading,
    VenueOrderTypesLoading,
} from '../Widgets/Loading/VenueLandigPageSkeleton';

const CardiffCastleButton = lazy(() => import('./VenueDisplay/CardiffCastleButton'));
const VenueThreshold = lazy(() => import('./VenueDisplay/VenueThreshold'));
const VenueTopBanner = lazy(() => import('./VenueDisplay/VenueTopBanner'));
const VenueSocials = lazy(() => import('./VenueSocials/VenueSocials'));
const TradingTimes = lazy(() => import('../Widgets/TradingTimes'));
const ExternalLinks = lazy(() => import('../Widgets/ExternalLinks'));
const VenueOrderTypes = lazy(() => import('../Widgets/VenueOrderTypes/VenueOrderTypes'));
const VenueContent = lazy(() => import('./VenueDisplay/VenueContent'));

export function VenueComponent({ openSideDrawer, openShareDrawer, ...props }): JSX.Element {
    const dispatch = useDispatch<any>();
    const Venue = useSelector((state: any) => state.Venue);
    const isOpenDrawer = useSelector((state: any) => state.openSidebar);
    useVenueTimezones();
    useVenueOpen();

    React.useEffect(() => {
        dispatch(setSelectedMenuType(null));
        dispatch(setIsGroupVenue(false));
        if (isOpenDrawer && Venue) {
            openSideDrawer(true);
            dispatch(_SetIsOpenSidebar(false));
        }

        // resetting the delivery fee
        dispatch(_SetDeliveryPrice(0));

        // eslint-disable-next-line
    }, []);

    return (
        <div
            data-component-name="VenuePageContainer"
            id="venue_container"
            className={Styles.venueContainer}
            data-url={window.location.href}
            data-title={Venue.venue_name}
            data-description={Venue.venue_description}
            data-media={Venue.url_logo}
        >
            <Suspense fallback={<VenueHeaderLoading hideLogo={true} />}>
                <VenueTopBanner Venue={Venue} area={props.area} openSideDrawer={openSideDrawer} />
            </Suspense>
            <div
                className={`${Styles.venueDetails} ${Venue?.url_banner ? '' : Styles.venueDetailsNoBanner}`}
                id="venueDetailsId"
            >
                <div
                    className={`${Styles.venueContent} ${Venue?.url_banner ? '' : Styles.venueContentNoBanner} ${
                        props.isSpecificMenu ? Styles.menuContent : ''
                    }`}
                >
                    <Suspense fallback={<VenueContentLoading />}>
                        <VenueContent Venue={Venue} menu={props.menu} isSpecificMenu={props.isSpecificMenu} />
                    </Suspense>

                    <div>
                        <Suspense fallback={<VenueOrderTypesLoading />}>
                            <VenueOrderTypes
                                TableServiceEnable={Venue.table_enabled}
                                CollectionEnable={Venue.pickup_enabled}
                                DeliveryEnable={Venue.delivery_enabled}
                                PrepTime={Venue.food_prep_time > 0 ? timeConverterAsString(Venue.food_prep_time) : null}
                                DeliveyFee={Venue.delivery_charge}
                                DeliveyTime={
                                    Venue.delivery_max_lead_time
                                        ? timeConverterAsString(Venue.delivery_max_lead_time)
                                        : '0'
                                }
                                MinOrderValue={
                                    Venue.min_delivery_price > 0 ? priceToPounds(Venue.min_delivery_price) : null
                                }
                                MenuPageLink={getMenuPageLink(props, Venue)}
                                isSpecificMenu={props.isSpecificMenu}
                                isMenuAvailable={props.isMenuAvailable}
                            />
                        </Suspense>
                    </div>

                    {!props.isSpecificMenu && (
                        <>
                            <Suspense fallback={<Skeleton height={'2rem'} />}>
                                <VenueThreshold area={props.area} />
                            </Suspense>

                            <div className={Styles.venueTradingTimes}>
                                <Suspense fallback={<Skeleton height={'2rem'} />}>
                                    <TradingTimes trading_times={Venue?.trading_times} info={Venue} />
                                </Suspense>
                            </div>
                            <Suspense fallback={<Skeleton height={'4rem'} />}>
                                <ExternalLinks links={Venue?.custom_links} />
                            </Suspense>
                            <Suspense fallback={<Skeleton height={'5rem'} />}>
                                <VenueSocials Venue={Venue} />
                            </Suspense>
                        </>
                    )}
                </div>
            </div>
            <Suspense fallback={<Skeleton height={'2rem'} />}>
                <CardiffCastleButton Venue={Venue} />
            </Suspense>
        </div>
    );
}

function getMenuPageLink(props, venue) {
    if (props.area) {
        if (props.A) {
            return `/a/hayes-cardiff/ ${venue.venue_nickname}/menu`;
        }
        return `/g/${props.GroupName}/${venue.venue_nickname}/menu`;
    } else {
        return '/v/' + venue.venue_nickname + '/menu';
    }
}
