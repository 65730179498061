import { ConsumerUpdatePreferences } from '../Api.js';

export async function updateUserLanguagePreference(
    selectedLanguageKey: string,
    user: any,
    retrieveToken: () => Promise<string>,
    setUser: (user: any) => void,
) {
    const token = await retrieveToken();
    /**If no token just return */
    if (!token) return;
    await ConsumerUpdatePreferences(
        {
            language: selectedLanguageKey,
        },
        token,
    ).then((res) => {
        const newUserData = user;
        // @ts-ignore
        newUserData.consumer_preferences.language = selectedLanguageKey;
        setUser(newUserData);
    });
}
