import { SET_AVAILABLE_MENUS } from '../../yoello-lib/modules/redux/actions/consumer.actions';

export function AvailableMenus(state: any = [], action: any) {
    switch (action.type) {
        case SET_AVAILABLE_MENUS:
            let sortedList = action.data.sort(function (a, b) {
                return a.sort_position - b.sort_position;
            });

            return sortedList;
        default:
            return state;
    }
}
