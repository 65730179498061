import { useContext, useEffect, lazy, Suspense } from 'react';
import { useMultiVendorBasketView } from './useMultiVendorBasketView';
import PaymentPage from './PaymentPage/PaymentPage';
import { PaymentContext } from './PaymentStore';
import { PaymentStages, BasketItemsMetaData } from './types.d';
import Styles from './styles.module.scss';
import { setIsPaymentReady } from './reducer/PaymentActions';
import Skeleton from '@material-ui/lab/Skeleton';

const OrderTypesComponent = lazy(() => import('./OrderTypesComponent/OrderTypesComponent'));
const TipComponent = lazy(() => import('./Tips/TipComponent'));
const Informations = lazy(() => import('./MultiVendorBasket/Informations'));
const PricingComponent = lazy(() => import('./Pricing/PricingComponent'));

interface IMultiVendorBasketContainer {
    isVenueOpen: boolean;
    Venue: any;
}
function MultiVendorBasketContainer({ Venue, isVenueOpen }: IMultiVendorBasketContainer) {
    const { paymentStages, orderOnly, dispatch } = useContext(PaymentContext);
    const { evaluateBasketItems } = useMultiVendorBasketView();

    useEffect(() => {
        evaluateBasketItems(BasketItemsMetaData.INITIALSTATE);
        dispatch(setIsPaymentReady(true));
        // eslint-disable-next-line
    }, []);

    const renderPageComponents = () => {
        if (paymentStages === PaymentStages.PAYMENT) {
            return (
                <div className={Styles.ChooseMethod}>
                    <Suspense fallback={<Skeleton height={'8rem'} style={{ margin: '2rem 0' }} />}>
                        <OrderTypesComponent isVenueOpen={isVenueOpen} />
                    </Suspense>
                    <div className={Styles.Informations}>
                        <Suspense fallback={<Skeleton height={'10rem'} style={{ margin: '2rem 0' }} />}>
                            <Informations />
                        </Suspense>
                    </div>
                    {!orderOnly && Venue.tipping_enabled ? (
                        <Suspense fallback={null}>
                            <TipComponent />
                        </Suspense>
                    ) : (
                        ''
                    )}
                    <Suspense fallback={<Skeleton height={'5rem'} style={{ margin: '2rem 0' }} />}>
                        <PricingComponent />
                    </Suspense>
                </div>
            );
        }
    };
    return <PaymentPage renderPaymentComponents={renderPageComponents} />;
}

export default MultiVendorBasketContainer;
