export interface ITimeObject {
    hours: number;
    mins: number;
}

export function timeConverterAsString(time: number): string {
    const timeConverted = timeConverter(time);

    let timeInString = '';
    if (typeof timeConverted === 'object') {
        timeInString = `${timeConverted.hours}hr ${timeConverted.mins}min`;
    } else {
        timeInString = `${timeConverted} min`;
    }

    return timeInString;
}

/**Converts time greater than 60 into an object of hours and mins */
export function timeConverter(time: number): number | ITimeObject {
    if (time === null) {
        return 0;
    }
    if (time >= 60) {
        const hours = time / 60;
        const fHours = Math.floor(hours);
        const mins = (hours - fHours) * 60;
        const rMins = Math.round(mins);
        return {
            hours: fHours,
            mins: rMins,
        };
    }
    return time;
}

export function formatTimeTo_HH_MM(time: string): string {
    if (time.length === 1) {
        return `0${time}`;
    }
    return time;
}
