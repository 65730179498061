import { useEffect, useState } from 'react';
import DynamicForm from '../../../yoello-lib/modules/dynamicForms/DynamicForms';
import { defaultPasswordRegex } from '../../../yoello-lib/modules/dynamicForms/ValidationUtils';
import Styles from '../styles.module.scss';
import useGetLoginParams from '../useGetLoginParams';
import IDynamicFormRow from '../../../yoello-lib/modules/dynamicForms/interfaces/IDynamicFormRow';
import PasswordInput from '../../Inputs/PasswordInput';
import ApplyButton from './ApplyButton';
import useLoginView from '../LoginView/useLoginView';
import { useHistory } from 'react-router-dom';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import { LOGINV2_FORGOT_PASSWORD } from '../../../constants/URL.const';
import ForgotPasswordLink from './ForgotPasswordLink';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../Widgets/TextField';

interface IPasswordView {
    dispatch: any;
}

export function PasswordView({ dispatch }: IPasswordView) {
    const { username, stage } = useGetLoginParams();
    const { t } = useTranslation();
    const { tryToLogin, isTryingToLogin } = useLoginView(dispatch);
    const { generateNavPath } = useGenerateNavPath();
    const history = useHistory();
    const [formRows, setFormRows] = useState<IDynamicFormRow[]>([]);
    useEffect(() => {
        const inputRows: IDynamicFormRow[] = [
            {
                keyName: 'password',
                label: t('Login.Texts.userPassword') as string,
                Component: PasswordInput,
                inputProperties: {
                    variant: 'outlined',
                },
                gridColumnOptions: { formCol: 12 },
                registerOptions: {
                    validate: (value: string) => {
                        return value.trim().match(defaultPasswordRegex)
                            ? true
                            : (t('ForgotPassword.Errors.passwordValidationError') as string);
                    },
                },
            },
            {
                keyName: 'confirm',
                gridColumnOptions: { formCol: 12 },
                label: t('Login.Texts.confirm') as string,
                formProps: {
                    isTryingToLogin,
                },
                Component: ApplyButton,
            },
        ];
        if (stage === 'login') {
            inputRows.splice(1, 0, {
                keyName: 'forgot_password',
                gridColumnOptions: { formCol: 12 },
                label: t('Login.Texts.confirm') as string,
                formProps: {
                    onClick: () => history.push(generateNavPath(LOGINV2_FORGOT_PASSWORD)),
                },
                Component: ForgotPasswordLink,
            });
        }
        setFormRows(inputRows);
        // eslint-disable-next-line
    }, [isTryingToLogin, stage, username]);

    function onSubmit({ password }) {
        if (username && password) {
            tryToLogin(username, password);
        }
    }
    return (
        <div className={Styles.Login}>
            <div>
                <TextField
                    className={Styles.Text1}
                    text={{
                        key: stage === 'login' ? 'Login.Texts.signIn' : 'Login.Texts.setPassword',
                    }}
                />
                <TextField
                    className={Styles.Text2}
                    text={{
                        key: stage === 'login' ? 'Login.Texts.enterPassword' : 'Login.Texts.setPasswordDescription',
                    }}
                />
                {stage === 'login' && (
                    <p className={Styles.Text2}>
                        <strong>{username}</strong>
                    </p>
                )}
            </div>
            <DynamicForm
                formRows={formRows}
                formKeyName="profile-builder"
                useFormOptions={{
                    mode: 'onBlur',
                    reValidateMode: 'onChange',
                }}
                onHandleSubmit={onSubmit}
            />
        </div>
    );
}
