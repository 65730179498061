import { generateUserStausEndpoints } from '../../environments/services';
import { IAPIDefinitions } from '../types';

export enum APIUserStatus {
    userStatus = 'userStatus',
}

function generateUserStatusAPIURL() {
    const stage = process.env.REACT_APP_ENVIRONMENT;
    const overwriteHost = process.env.REACT_APP_SERVICE_ENDPOINT;
    const url = generateUserStausEndpoints(stage, overwriteHost);
    return url + '/auth-api/';
}

export const userStatusAPIDefs: IAPIDefinitions = {};

userStatusAPIDefs[APIUserStatus.userStatus] = {
    baseURL: generateUserStatusAPIURL(),
    defaultURL: '',
    GET: {},
    POST: {},
};
