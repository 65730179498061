import { useSelector } from 'react-redux';
import { isObject } from '../../../utils/ObjectCheck';

const useGetRedirectUrl = () => {
    const venue = useSelector((state: any) => state.Venue);
    const group = useSelector((state: any) => state.Group);
    const LoginRedirectToMainPage = useSelector((state: any) => state.LoginRedirectToMainPage);
    const getRedirectURL = () => {
        if (!isObject(venue, true)) {
            return '/';
        }
        if (LoginRedirectToMainPage === 'PaymentA') {
            return `/g/${group.nickname}/${venue.venue_nickname}/menu`;
        } else {
            return '/v/' + venue.venue_nickname + '/menu';
        }
    };
    return {
        getRedirectURL,
    };
};

export default useGetRedirectUrl;
