import * as types from '../actions/ActionTypes';

const MenuItemsAsExtra = (state = {}, action) => {
    switch (action.type) {
        case types.SetMenuItemsAsExtra:
            state = action.data;
            return state;

        default:
            return state;
    }
};
export default MenuItemsAsExtra;
