import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CognitoIdToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useSnackbarMessages } from '../components/snackbar/SnackbarContext';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export enum AuthenticationErrors {
    NotAuthorizedException = 'NotAuthorizedException',
    UsernameExistsException = 'UsernameExistsException',
    UserNotConfirmedException = 'UserNotConfirmedException',
    PasswordResetRequiredException = 'PasswordResetRequiredException',
    UserNotFoundException = 'UserNotFoundException',
    LimitExceededException = 'LimitExceededException',
}

export enum VerificationAttribute {
    Email = 'email',
    Phone = 'phone_number',
}

export enum Provider {
    Google = 'google',
    Facebook = 'facebook',
}

/**
 * this is a context based on AWS COGNITO
 */

export interface IAuthenticationContextValue {
    userLogout(): Promise<void>;
    globalUserLogout(): Promise<void>;
    userLogin(username: string, password: string): Promise<string>;
    userSignUp(username: string, password: string): Promise<void>;
    currentUser: Record<any, any> | undefined;
    verifyAuth(): any;
    confirmSignUp(username: string, code: string): Promise<void>;
    getCurrentSession(): Promise<CognitoUserSession | undefined>;
    federatedSignIn(providerChoice: string): Promise<void>;
    changeUserPassword(newPassword: string, oldPassword: string): Promise<void>;
    forgotPasswordSendCode(userName: string): Promise<void>;
    forgotPasswordSubmit(userName: string, code: string, newPassword: string): Promise<void>;
    forceResetPassword(userName: string, oldPassword: string, newPassword: string): Promise<void>;
    verifyUserAttribute(attributeValue: string): Promise<void>;
    verifyUserAttributeSubmit(attributeValue: string, code: string): Promise<void>;
    resendSignUp(userName: string): Promise<void>;
    cognitoEmailUsed(email: string): Promise<string>;
    retrieveAccessToken: () => Promise<string>;
    retrieveIdToken(): Promise<CognitoIdToken | undefined>;
    retrieveRefreshToken(): Promise<string | undefined>;
    isAuthenticated: boolean;
    setCurrentUser<T>(user: T | undefined): void;
    retrieveUser: () => Promise<any>;
    token: string | undefined;
    checkIfNewPasswordRequired: (username: string, password: string) => Promise<boolean>;
}

const AuthenticationContext = createContext<IAuthenticationContextValue | null>(null);

export interface IAuthenticationContextProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({ children }: IAuthenticationContextProviderProps) => {
    const [currentUser, setCurrentUser] = useState<any | undefined>(undefined);
    const [token, setToken] = useState<string | undefined>(undefined);
    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const appLanguage = useSelector((state: any) => state.localization.language);
    const { t } = useTranslation();

    //Manual login funcs
    const userLogout = useCallback(async () => {
        try {
            await Auth.signOut();
            setCurrentUser(undefined);
        } catch (err: any) {
            sendSnackbarMessage(err?.message, 'error');
        }
        // eslint-disable-next-line
    }, []);

    const globalUserLogout = useCallback(async () => {
        await Auth.signOut({ global: true });
        setCurrentUser(undefined);
    }, []);

    const userLogin = useCallback(async (username: string, password: string) => {
        try {
            const user = await Auth.signIn(username, password, {});
            if (user) {
                setCurrentUser(user);
            }
            return '';
        } catch (err: any) {
            return err.code;
        }
    }, []);

    //Checks a sign in attempt with an incorrect password to test whether the email exists in cognito based on an error code
    const cognitoEmailUsed = useCallback(async (email: string): Promise<string> => {
        return Auth.signIn(email, '123')
            .then((res) => {
                console.log(JSON.stringify(res));
                return '';
            })
            .catch((err) => {
                const code = err.code;
                return code;
            });
    }, []);

    const userSignUp = useCallback(
        async (username: string, password: string) => {
            try {
                await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        locale: appLanguage,
                    },
                });
            } catch (err: any) {
                throw new Error(err);
            }
        },
        [appLanguage],
    );

    //Attempt to verify the email address (username) via an emailed code
    const confirmSignUp = useCallback(async (username: string, code: string) => {
        try {
            await Auth.confirmSignUp(username, code);
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);

    //Resends the sign up confirmation code
    const resendSignUp = useCallback(async (userName: string) => {
        try {
            await Auth.resendSignUp(userName);
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);

    const verifyAuth = useCallback(async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user;
        } catch (err: any) {
            return null;
        }
    }, []);

    const retrieveAccessToken = useCallback(async () => {
        try {
            const currentSession = await Auth.currentSession();
            return currentSession.getAccessToken().getJwtToken();
        } catch (err: any) {
            if (
                err?.message === 'Refresh Token has expired' &&
                err?.code === AuthenticationErrors.NotAuthorizedException
            ) {
                sendSnackbarMessage(t('session.expired'), 'error');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
            return '';
        }
        // eslint-disable-next-line
    }, []);

    const retrieveIdToken = useCallback(async () => {
        try {
            const session = await Auth.currentSession();
            const idToken = session.getIdToken();
            return idToken;
        } catch (err: any) {
            return undefined;
        }
    }, []);

    const retrieveRefreshToken = useCallback(async () => {
        try {
            const session = await Auth.currentSession();
            const refreshToken = session.getRefreshToken();
            return refreshToken.getToken();
        } catch (err: any) {
            return undefined;
        }
    }, []);

    const getCurrentSession = useCallback(async () => {
        try {
            const session = await Auth.currentSession();
            return session;
        } catch (err: any) {
            return undefined;
        }
    }, []);

    // Account recovery methods

    //Method for an authenticated user to change their password
    const changeUserPassword = useCallback(async (oldPassword: string, newPassword: string) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                await Auth.changePassword(user, oldPassword, newPassword);
            }
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);

    //Sends a recovery verification code to reset password
    const forgotPasswordSendCode = useCallback(async (userName: string) => {
        try {
            await Auth.forgotPassword(userName, {
                locale: appLanguage,
            });
        } catch (err: any) {
            throw new Error(err);
        }
    }, [appLanguage]);

    //Submits the new password, verification code and username and updates password on success
    const forgotPasswordSubmit = useCallback(async (userName: string, code: string, newPassword: string) => {
        try {
            await Auth.forgotPasswordSubmit(userName, code, newPassword);
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);
    //cheking if needs to force reset password
    const checkIfNewPasswordRequired = useCallback(async (username: string, password: string) => {
        try {
            const user = await Auth.signIn(username, password);
            if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                return true;
            }
            return false;
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);
    //Submits the new password, username and updates password on success
    const forceResetPassword = useCallback(async (userName: string, oldPassword: string, newPassword: string) => {
        try {
            const user = await Auth.signIn(userName, oldPassword);
            await Auth.completeNewPassword(user, newPassword);
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);

    //Attribute verification
    //Sends the verification code to an authenticated user
    const verifyUserAttribute = useCallback(async (attribute: VerificationAttribute) => {
        try {
            await Auth.verifyCurrentUserAttribute(attribute);
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);

    //Verifies the attribute based on successful submission
    const verifyUserAttributeSubmit = useCallback(async (attribute: VerificationAttribute, code: string) => {
        try {
            await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);

    const getUser = useCallback(async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user;
        } catch (err: any) {
            return undefined;
        }
    }, []);

    //SSO funcs
    const retrieveUser = useCallback(async () => {
        try {
            const user = await Auth.currentUserInfo();
            return user;
        } catch (err: any) {
            return undefined;
        }
    }, []);

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    retrieveUser().then((user) => {
                        setCurrentUser(user);
                    });
                    break;
                case 'signOut':
                    setCurrentUser(undefined);
                    break;
                case 'signIn_failure':
                case 'cognitoHostendUI_failure':
                    console.error('Sign in failure', data);
                    break;
            }
        });

        getUser().then((user) => {
            setCurrentUser(user);
        });
        // eslint-disable-next-line
    }, [getUser]);

    useEffect(() => {
        if (!token && currentUser) {
            retrieveAccessToken().then((token: string) => {
                setToken(token);
            });
        }
        // eslint-disable-next-line
    }, [currentUser]);

    const isAuthenticated = useMemo(() => {
        return !!currentUser;
    }, [currentUser]);

    const federatedSignIn = useCallback(async (providerChoice: Provider) => {
        let provider;
        switch (providerChoice) {
            case Provider.Facebook:
                provider = CognitoHostedUIIdentityProvider.Facebook;
                break;
            case Provider.Google:
                provider = CognitoHostedUIIdentityProvider.Google;
                break;
        }
        try {
            if (provider === undefined) {
                await Auth.federatedSignIn();
                return;
            }
            await Auth.federatedSignIn({
                provider: provider,
            });
        } catch (err: any) {
            throw new Error(err);
        }
    }, []);

    return (
        <AuthenticationContext.Provider
            value={{
                userLogout,
                globalUserLogout,
                userLogin,
                currentUser,
                userSignUp,
                resendSignUp,
                verifyAuth,
                confirmSignUp,
                getCurrentSession,
                federatedSignIn,
                changeUserPassword,
                forgotPasswordSendCode,
                forgotPasswordSubmit,
                forceResetPassword,
                verifyUserAttribute,
                verifyUserAttributeSubmit,
                cognitoEmailUsed,
                retrieveAccessToken,
                retrieveIdToken,
                retrieveRefreshToken,
                isAuthenticated,
                setCurrentUser,
                retrieveUser,
                token,
                checkIfNewPasswordRequired,
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};

export const useAuthenticationContext = () => {
    const context = useContext(AuthenticationContext);
    if (context) {
        return context;
    }
    throw new Error('Error obtaining Cognito Context: Are you nested correctly in a CognitoContextProvider?');
};
