import { lazy, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './groupPage.module.scss';
import { SelectOrderTypeButton } from './SelectOrderType/SelectOrderTypeButton';
import { CARDIFF_CASTLE } from '../../constants';
import { _SetIsOpenSidebar } from '../../redux/actions';
import GroupDescription from './GroupDisplay/GroupDescription';
import { resetStore } from './util';
import { IGroup } from './types';
import { VenuesListLoading } from '../Widgets/Loading/GroupPage';
import { addGMapsScript } from '../Utils/addGooleMapApitoDom';

const GroupLogo = lazy(() => import('./GroupDisplay/GroupLogo'));
const GroupPageList = lazy(() => import('./GroupDisplay/GroupPageList'));
const GroupPageTop = lazy(() => import('./GroupDisplay/GroupPageTop'));

export function GroupComponent({ openSideDrawer, openShareDrawer, ...props }) {
    const Group: IGroup = useSelector((state: any) => state.Group);
    const isOpenDrawer = useSelector((state: any) => state.openSidebar);
    const dispatcher = useDispatch<any>();

    useEffect(() => {
        if (isOpenDrawer && Group) {
            openSideDrawer();
            dispatcher(_SetIsOpenSidebar(false));
        }
        resetStore(dispatcher);
        addGMapsScript();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={Styles.groupPageContainer} id="groupPageContainerId">
            <div className={Styles.header}>
                <Suspense fallback={null}>
                    <GroupPageTop openSideDrawer={openSideDrawer} />
                </Suspense>

                <div className={Styles.groupDetails}>
                    <Suspense fallback={null}>
                        <GroupLogo group={Group} />
                    </Suspense>
                    <div className={Styles.groupName}>
                        <p>{Group.name_public}</p>
                    </div>
                </div>
                <GroupDescription description={Group.description} />
            </div>

            <div className={Styles.content}>
                {props.match.params?.group !== CARDIFF_CASTLE && <SelectOrderTypeButton />}
                <div className={Styles.venuesListWrapper}>
                    <div className={Styles.venuesList}>
                        <Suspense fallback={<VenuesListLoading />}>
                            <GroupPageList />
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    );
}
