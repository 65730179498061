import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    COGNITO_OAUTH_REDIRECT,
    LOGINV2_PASSWORD,
    LOGINV2_VERIFY,
    LOGINV2_GUEST,
    PAYMENT,
} from '../../../constants/URL.const';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import useGetLoginParams from '../useGetLoginParams';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { useAuthenticationContext, AuthenticationErrors } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import { setUserPassword } from '../state/Login.actions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoginStorage } from '../../../constants/storageVars.const';

const useLoginView = (dispatch: any) => {
    const { cognitoEmailUsed, userLogin, userLogout, userSignUp, resendSignUp } = useAuthenticationContext();
    const [isTryingToLogin, setIsTryingToLogin] = useState(false);
    const { method, isLoading: paramsLoading, username: email } = useGetLoginParams();
    const venue = useSelector((state: any) => state.Venue);
    const { sendSnackbarMessage } = useSnackbarMessages();
    const { generateNavPath } = useGenerateNavPath();
    const [username, setUsername] = useState<string>();
    const history = useHistory();
    const { t } = useTranslation();
    function navigate(key: string, params: { username: string; method?: string; stage?: string }) {
        const path = generateNavPath(key, params);
        history.push(path);
    }

    useEffect(() => {
        if (email) {
            setUsername(email);
        }
        // eslint-disable-next-line
    }, [email, paramsLoading]);

    const isCheckingOut =
        localStorage.getItem(LoginStorage.LOGIN_TO) === PAYMENT && venue.payment_processor !== 'PAY360';

    async function tryToLogin(email: string, password: string) {
        setIsTryingToLogin(true);
        try {
            const result = await userLogin(email, password);
            if (result === AuthenticationErrors.UserNotFoundException) {
                try {
                    await userSignUp(email, password);
                    dispatch(setUserPassword(password));
                    navigate(LOGINV2_VERIFY, {
                        username: email,
                    });
                } catch (error) {
                    sendSnackbarMessage(t('Login.Errors.CouldntCreateUser').toString(), 'error');
                    await userLogout();
                }
            } else if (result === AuthenticationErrors.UserNotConfirmedException) {
                dispatch(setUserPassword(password));
                navigate(LOGINV2_VERIFY, { username: email });
            } else if (result === AuthenticationErrors.NotAuthorizedException) {
                sendSnackbarMessage(t('Login.Errors.IncorrectEmailOrPassword').toString(), 'error');
                setIsTryingToLogin(false);
            } else {
                history.push(
                    generateNavPath(COGNITO_OAUTH_REDIRECT, {
                        username: email,
                    }),
                );
            }
        } catch (error) {
            sendSnackbarMessage(t('Login.Texts.LoginIssue').toString(), 'error');
            await userLogout();
        }
    }

    async function checkIfUserExists() {
        setIsTryingToLogin(true);
        try {
            const code = await cognitoEmailUsed(username);
            switch (code) {
                case AuthenticationErrors.UserNotConfirmedException:
                    try {
                        await resendSignUp(username);
                        navigate(LOGINV2_VERIFY, {
                            username: username,
                        });
                    } catch (err) {
                        sendSnackbarMessage(t('Login.Texts.LoginIssue').toString(), 'error');
                        setIsTryingToLogin(false);
                    }

                    break;
                case AuthenticationErrors.NotAuthorizedException:
                    navigate(LOGINV2_PASSWORD, {
                        username: username,
                        stage: 'login',
                    });
                    break;
                default:
                    navigate(LOGINV2_PASSWORD, { username });
                    break;
            }
        } catch (error) {
            sendSnackbarMessage(t('Login.Texts.LoginIssue').toString(), 'error');
            setIsTryingToLogin(false);
        }
    }
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cleanedValue = e.currentTarget.value.replace(/\s/g, '');
        setUsername(cleanedValue);
    };

    function onPressContinueAsGuest() {
        history.push(
            generateNavPath(LOGINV2_GUEST, {
                to: PAYMENT,
            }),
        );
    }

    return {
        onInputChange,
        tryToLogin,
        isTryingToLogin,
        username,
        navigate,
        checkIfUserExists,
        isCheckingOut,
        onPressContinueAsGuest,
        paramsLoading,
        method,
        orderOnly: venue.order_only_enabled,
        orderOnlyGuestEnabled: venue?.order_only_guest_enabled,
    };
};

export default useLoginView;
