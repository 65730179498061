import { Suspense, useEffect } from 'react';
import './App.css';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Router from './Components/Router/Router';
import { _ChangeTokenExpireDate, _resetCart } from './redux/actions';
import { getCurrentEpochTime } from './yoello-lib/modules/auth/epochTime';
import { useCartDataLayer } from './customHooks/useCartDataLayer';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import useFonts from './customHooks/useFonts';
import PageLoading from './Components/Page/PageLoading';
interface IAppProps {
    CartRefreshTime: null | number;
    _resetCart: () => void;
}

function App({ CartRefreshTime, _resetCart }: IAppProps) {
    useCartDataLayer();
    const { i18n } = useTranslation();

    const lang = useSelector((state: any) => state?.localization?.language?.toLowerCase()) || 'en';
    useEffect(() => {
        i18n.changeLanguage(lang);
        //eslint-disable-next-line
    }, [lang]);
    function checkCart() {
        if (CartRefreshTime) {
            if (CartRefreshTime < getCurrentEpochTime()) {
                _resetCart();
            }
        }
    }
    const { primaryFont } = useFonts();

    const theme = createTheme({
        typography: {
            fontFamily: [
                primaryFont ?? 'Open Sans',
                '-apple-system',
                'BlinkMacSystemFont',
                'Open Sans',
                'Roboto',
                'Oxygen',
                'Ubuntu',
                'Cantarell',
                'Helvetica Neue',
                'sans-serif',
            ].join(','),
        },
    });
    function renderComponent() {
        checkCart();
        return (
            <ThemeProvider theme={theme}>
                <Suspense fallback={<PageLoading />}>
                    <Router />
                </Suspense>
            </ThemeProvider>
        );
    }
    return renderComponent();
}

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps, { _ChangeTokenExpireDate, _resetCart })(App);
