import { useEffect, useState } from 'react';
import './AddressForm.css';
import { ReactComponent as AlertErrorIcon } from '../../../imges/AlertErrorIcon.svg';
import { AlertStatusCard } from '../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard';
import { getDeliveryAddress } from '../../../utils/getdeliveryAddress';
import { useTranslation } from 'react-i18next';
interface IAddress {
    apartment: string;
    street_address: string;
    city: string;
    postcode: string;
    // changeMapLocation?:(address:string) => void;
}
export function AddressForm(props: any) {
    const [state, setState] = useState<IAddress>({
        apartment: '',
        street_address: '',
        city: '',
        postcode: '',
    });

    useEffect(() => {
        setState({ ...props.userSavedAddress });
    }, [props.userSavedAddress]);

    const { t } = useTranslation();
    useEffect(() => {
        const address = getDeliveryAddress();
        if (address) {
            setState(address);
            props.handleDeliveryInfoChange(address);
        }
        // eslint-disable-next-line
    }, []);

    function handleInputChange(e: any) {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: name === 'postcode' ? value?.toUpperCase() : value,
        }));
        if (name !== 'apartment') {
            props.setPostCodeChanged(true);
        }
        props.handleDeliveryInfoChange({
            ...state,
            [name]: value,
        });
    }
    return (
        <div
            className="AddressForm"
            style={{
                height: props.noFullName ? 'auto' : '',
            }}
        >
            {props.noFullName ? null : (
                <div
                    className="SignUpInputDivs"
                    style={{
                        marginTop: '10px',
                    }}
                >
                    <input
                        type="text"
                        className="SignUpInput"
                        placeholder={t('Forms.FullName') as string}
                        value={state.apartment}
                        name="apartment"
                        onChange={handleInputChange}
                    />
                </div>
            )}

            <div
                className="SignUpInputDivs"
                style={{
                    marginTop: '10px',
                }}
            >
                <input
                    id="pac-input"
                    type="text"
                    className="SignUpInput"
                    placeholder={t('Forms.StreetAddress') as string}
                    value={state.street_address}
                    name="street_address"
                    onChange={handleInputChange}
                />
            </div>

            <div
                className="SignUpInputDivs"
                style={{
                    marginTop: '10px',
                }}
            >
                <input
                    type="text"
                    className="SignUpInput"
                    placeholder={t('Forms.City') as string}
                    value={state.city}
                    name="city"
                    onChange={handleInputChange}
                />
            </div>

            <div
                className="SignUpInputDivs"
                style={{
                    marginTop: '10px',
                }}
            >
                <input
                    type="text"
                    className="SignUpInput"
                    placeholder={t('Forms.PostCode') as string}
                    value={state.postcode}
                    name="postcode"
                    onChange={handleInputChange}
                    onBlur={props.onPostcodeBlur}
                />
            </div>
            {props.postCodeInValid ? (
                <div className="mt-4">
                    <AlertStatusCard
                        alertProps={{
                            color: 'error',
                            icon: <AlertErrorIcon />,
                            style: {
                                color: '#F65164',
                                borderRadius: '0.75rem',
                                fontWeight: 600,
                            },
                        }}
                        alertTitle={t('Forms.InvalidPostcode')}
                        alertMessage={t('Forms.EnterValidPostCode')}
                    />
                </div>
            ) : null}
            {props.hasValidationError && (
                <AlertStatusCard
                    alertProps={{
                        color: 'error',
                        icon: <AlertErrorIcon />,
                        style: {
                            color: '#F65164',
                            borderRadius: '0.75rem',
                            fontWeight: 600,
                        },
                    }}
                    alertMessage={t('errors.formerrors.RequiredFields')}
                />
            )}
        </div>
    );
}

export default AddressForm;
