import Styles from './leavingWarning.module.scss';
import { ReactComponent as LeavingWarningSVG } from '../../../imges/leavingWarning.svg';
import Button from '../../Widgets/Button';
import { Trans, useTranslation } from 'react-i18next';
interface LeavingWarningProps {
    venueName: string;
    onCancel: any;
    onContinue: any;
}

const LeavingWarning = (props: LeavingWarningProps) => {
    const { t } = useTranslation();
    return (
        <div className={`${Styles.leavingWarningContainer}`} data-component-name={'WarningDetailsFromCart'}>
            <div className={`${Styles.leavingWarningDrawer} `}>
                <div className={Styles.header}>
                    <div className={Styles.lineBar} />
                </div>
                <div className={Styles.warningDetails} data-component-name={'WarningDetailsFromCartActions'}>
                    <div>
                        <Button
                            iconClassName="consumer-close"
                            isHeaderBtn
                            className={Styles.closeDrawer}
                            onClick={() => props.onCancel()}
                            dataComponentName={'closeButton'}
                        />
                    </div>
                    <div className={[Styles.icon, 'dark-theme-uninvert'].join(' ')}>
                        <LeavingWarningSVG />
                    </div>
                    <p data-component-name={'WarningDetailsTitle'}> {t('LeavingWarning.Question')}</p>
                    <p>
                        <Trans
                            i18nKey={'LeavingWarning.Description'}
                            components={{ bold: <strong /> }}
                            values={{ venueName: props.venueName }}
                            data-component-name={'WarningDetailsDescription'}
                        />
                    </p>
                    <div className={`${Styles.buttonContainer}`}>
                        <button
                            onClick={props.onCancel}
                            className={`${Styles.cancel}`}
                            data-component-name={'cancelButton'}
                        >
                            {t('buttons.Cancel')}
                        </button>
                        <button
                            onClick={() => props.onContinue()}
                            className={`${Styles.continue} leaving-button-dark-theme-uninvert`}
                            data-component-name={'continueButton'}
                        >
                            {t('buttons.Continue')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LeavingWarning;
