import { useTranslation } from 'react-i18next';

import Styles from '../shopList.module.scss';
import Button from '../../Widgets/Button';
import { AlertStatusCard } from '../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard';
import { ReactComponent as CircleIcon } from '../../../yoello-lib/modules/components/UI/MessageBox/icons/icon-info-circle.svg';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import { useHistory } from 'react-router-dom';
import { GROUP } from '../../../constants/URL.const';
export function MultiVendorOrderingInfo() {
    const { t } = useTranslation();
    const { generateNavPath } = useGenerateNavPath();
    const history = useHistory();

    return (
        <div className={Styles.infoBox}>
            <AlertStatusCard
                alertTitle={<b>{t('MultiVendorOrdering.CartItems.Info.title')}</b>}
                alertMessage={
                    <div className={Styles.content}>
                        <p>{t('MultiVendorOrdering.CartItems.Info.body')}</p>
                        <Button
                            title={'BrowsVenues'}
                            className={Styles.BrowsOtherVenues}
                            onClick={() => history.push(generateNavPath(GROUP))}
                            dataComponentName="BrowsOtherVenues"
                        />
                    </div>
                }
                alertProps={{
                    color: 'info',
                    icon: <CircleIcon />,
                }}
            />
        </div>
    );
}
