import * as types from '../actions/ActionTypes';

const Tip = (state = 0, action) => {
    switch (action.type) {
        case types.ChangeTip:
            state = action.data;
            return state;

        default:
            return state;
    }
};
export default Tip;
