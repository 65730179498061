import { getDynamicEnviroment } from '../../utils/enviromentSetup';
import { IAPIDefinitions } from '../APIDefs';

export enum APIEPos {
    onboarding = 'onboarding',
    syncMenu = 'syncMenu',
    settings = 'settings',
}

export const EPosAPIdefs: IAPIDefinitions = {};
const env = getDynamicEnviroment();
let envURL = '';
if (env === 'prod') {
    envURL = '';
} else if (env === 'staging') {
    envURL = 'staging-';
} else {
    envURL = 'dev-';
}

const baseURL = `https://eposnow.yllo.uk/${envURL}eposnow`;

EPosAPIdefs[APIEPos.onboarding] = {
    baseURL: baseURL,
    defaultURL: '/onboarding/',
    POST: {},
};
EPosAPIdefs[APIEPos.syncMenu] = {
    baseURL: baseURL,
    defaultURL: '',
    POST: {},
};
EPosAPIdefs[APIEPos.settings] = {
    baseURL: baseURL + '/v1/venues/',
    defaultURL: 'settings',
    PUT: {},
    GET: {},
};
