import React, { FunctionComponent } from 'react';
import { FormErrors } from './FormErrors';
import ICommonFormProps from './interfaces/ICommonFormProps';

export interface IFormRowProps extends ICommonFormProps {
    Component: FunctionComponent<any>;
}

/**Form row component, handles rendering the component and the error messages */
const FormRow = (props: IFormRowProps) => {
    return (
        <React.Fragment>
            <props.Component {...props} errors={props.isManualErrors ? undefined : props.errors} />
            {!props.isManualErrors && <FormErrors {...props} />}
        </React.Fragment>
    );
};

export default FormRow;
