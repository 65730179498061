import Styles from './orderDetailsSkeleton.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';

export const OrderDetailsSkeleton = () => {
    return (
        <div className={Styles.orderHistoryViewContainer}>
            <div className={Styles.orderDetails}>
                <div className={Styles.orderRefAndStatus}>
                    <div>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>
                <div className={Styles.dateAndTime}>
                    <div>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>

                <div className={Styles.venueDetails}>
                    <div>
                        <Skeleton />
                    </div>
                </div>

                <div className={Styles.deliveryDetails}>
                    <div>
                        <Skeleton />
                    </div>
                </div>

                <div className={Styles.timeSlotDetails}>
                    <div>
                        <Skeleton />
                    </div>
                </div>
            </div>
            <div className={Styles.paymentDetails}>
                <div className={Styles.totalPrice}>
                    <div>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>
                <div className={Styles.priceDetails}>
                    <div>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>
                <div className={Styles.priceDetails}>
                    <div>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>
                <div className={Styles.priceDetails}>
                    <div>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>
                <div className={Styles.priceDetails}>
                    <div>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>

                <div className={Styles.paymentCardDetails}>
                    <div>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton />
                    </div>
                </div>
            </div>
            <div className={Styles.productInfo}>
                <div>
                    <Skeleton />
                </div>
            </div>
        </div>
    );
};

export default OrderDetailsSkeleton;
