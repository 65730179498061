import IVenue from '../../API/interfaces/IVenue';
import { IFee } from '../../interfaces/IFee';
import { IVenueReducerState } from '../reducers/venueReducer';
export const SET_VENUE_DATA = 'SET_VENUE_DATA';

export const setVenueData = (dataKey: keyof IVenueReducerState, venueId: string, venueData: unknown) => {
    console.log(dataKey, venueId, venueData);
    return {
        type: SET_VENUE_DATA,
        payload: {
            venueId,
            venueDataKey: dataKey,
            venueData,
        },
    };
};

export const SET_USERS_VENUES = 'SET_USERS_VENUES';

export const setUsersVenues = (venuesArray: IVenue[]) => {
    return {
        type: SET_USERS_VENUES,
        payload: venuesArray,
    };
};

export const RESET_USERS_VENUES = 'RESET_USERS_VENUES';

export const resetUsersVenues = () => {
    return { type: RESET_USERS_VENUES };
};

export const SET_MERCHANT_DATA = 'SET_MERCHANT_DATA';

export function setVenueMerchant(data: any) {
    return {
        type: SET_MERCHANT_DATA,
        payload: data,
    };
}

export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export function setVenuePermissions(data: any) {
    return {
        type: SET_PERMISSIONS,
        payload: data,
    };
}
export const RESET_PERMISSIONS = 'RESET_PERMISSIONS';

export function resetVenuePermissions() {
    return { type: SET_PERMISSIONS };
}

export const UPDATE_VENUE_FEES = 'UPDATE_VENUE_FEES';

export function updateVenueFees(venueId: string, feeObject: IFee) {
    return {
        type: UPDATE_VENUE_FEES,
        payload: {
            venueId,
            feeObject,
        },
    };
}

export const SET_VENUE_TYPES = 'SET_VENUE_TYPES';

export function setVenueTypes(venueTypes: any) {
    return {
        type: SET_VENUE_TYPES,
        payload: venueTypes,
    };
}

export const SET_FEE_PLANS = 'SET_FEE_PLANS';

export function setFeePlans(feePlans: any) {
    return {
        type: SET_FEE_PLANS,
        payload: feePlans,
    };
}
