import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import { emailRegex } from '../../../yoello-lib/modules/dynamicForms/ValidationUtils';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import { LOGINV2_RESTE_PASSWORD, LOGINV2_START } from '../../../constants/URL.const';
import useGetLoginParams from '../useGetLoginParams';
import { useTranslation } from 'react-i18next';

export enum SendEmailError {
    MissingEmail = 'MISSING_EMAIL',
    NoUsernameInCognito = 'NO_USERNAME_IN_COGNITO',
    GenericError = 'GENERIC_EMAIL_ERROR',
    SignedUpWithSocial = 'SIGNED_UP_WITH_SOCIAL',
    AttemptLimitExceeded = 'ATTEMPT_LIMIT_EXCEEDED',
}
export enum SubmissionError {
    InvalidCode = 'INVALID_CODE',
    InvalidEmail = 'INVALID_EMAIL',
    PasswordMismatch = 'PASSWORD_MISMATCH',
    AttemptLimitExceeded = 'ATTEMPT_LIMIT_EXCEEDED',
    GenericError = 'GENERIC_SUBMISSION_ERROR',
}

export enum ForgotPasswordStage {
    InputEmail = 'INPUT_EMAIL',
    ResetPassword = 'RESET_PASSWORD',
}

interface IResetFormData {
    email?: string;
    code: string;
    password1: string;
    password2: string;
}

export function useForgeotPasswordView() {
    const { username } = useGetLoginParams();
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>('');
    const history = useHistory();
    const { generateNavPath } = useGenerateNavPath();
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);

    const [codeLoading, setCodeLoading] = useState<boolean>(false);
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

    const { forgotPasswordSendCode, forgotPasswordSubmit } = useAuthenticationContext();
    const { sendSnackbarMessage } = useSnackbarMessages();

    const navigateToLogin = useCallback(() => {
        history.push(generateNavPath(LOGINV2_START));
        // eslint-disable-next-line
    }, [history]);

    //Handle the password reset form submission, errors in the submission attempt and on success navigate to login
    const resetPassword = useCallback(
        async (data: IResetFormData) => {
            setFormSubmitting(true);
            if (data.password1 !== data.password2) {
                sendSnackbarMessage(t('ForgotPassword.Errors.passwordMismatch').toString(), 'error');
                setFormSubmitting(false);
                return;
            }
            try {
                const _email = username;
                await forgotPasswordSubmit(_email, data.code, data.password1);
                sendSnackbarMessage(t('ForgotPassword.Texts.updatePasswordSuccessful').toString(), 'success');
                setFormSubmitting(false);
                navigateToLogin();
            } catch (err: any) {
                if (err.message.toLowerCase().includes('invalid verification code')) {
                    sendSnackbarMessage(t('ForgotPassword.Errors.invalidCode').toString(), 'error');
                } else if (err.message.toLowerCase().includes('attempt limit exceeded')) {
                    sendSnackbarMessage(t('ForgotPassword.Errors.attemptLimitExceeded').toString(), 'error');
                } else if (err.message.toLowerCase().includes('id combination not found')) {
                    sendSnackbarMessage(t('ForgotPassword.Errors.invalidEmail').toString(), 'error');
                } else {
                    sendSnackbarMessage(t('ForgotPassword.Errors.genericResetError').toString(), 'error');
                }
                setFormSubmitting(false);
            }
        },
        // eslint-disable-next-line
        [username, forgotPasswordSubmit, sendSnackbarMessage, navigateToLogin],
    );

    //Handle the submission of an email to generate the code, any error and move to next step if success
    const handleEmailSubmit = useCallback(async () => {
        setCodeLoading(true);
        try {
            await forgotPasswordSendCode(email.trim());
            sendSnackbarMessage(t('ForgotPassword.Texts.sendCodeSuccessful').toString(), 'success');
            history.push(
                generateNavPath(LOGINV2_RESTE_PASSWORD, {
                    username: email,
                }),
            );
        } catch (err: any) {
            if (err.message.includes('cannot reset password')) {
                sendSnackbarMessage(t('ForgotPassword.Errors.invalidEmail').toString(), 'error');
            } else if (err.message.toLowerCase().includes('username/client id combination not found')) {
                sendSnackbarMessage(t('ForgotPassword.Errors.existingSocialAccount').toString(), 'error');
            } else if (err.message.toLowerCase().includes('attempt limit exceeded')) {
                sendSnackbarMessage(t('ForgotPassword.Errors.attemptLimitExceeded').toString(), 'error');
            } else {
                sendSnackbarMessage(t('ForgotPassword.Errors.genericCodeGenerationError').toString(), 'error');
            }
            setCodeLoading(false);
        }
        // eslint-disable-next-line
    }, [email, forgotPasswordSendCode, sendSnackbarMessage]);

    //Handles email in state for this component
    const handleUpdateEmail = useCallback(
        (value: string) => {
            setEmail(value);
            // if (sendCodeError) {
            //   setSendCodeError(null);
            // }
            if (value) {
                const emailValidator = new RegExp(emailRegex);
                const isValid = emailValidator.test(value.trim());
                isValid ? setIsValidEmail(true) : setIsValidEmail(false);
                return;
            }
            setIsValidEmail(false);
            // eslint-disable-next-line
        },
        [setEmail, setIsValidEmail],
    );

    return {
        email,
        setEmail,
        resetPassword,
        formSubmitting,
        handleUpdateEmail,
        handleEmailSubmit,
        isValidEmail,
        codeLoading,
    };
}
