import { BackendTimeSlots } from '../../Components/Payment/types';
import * as types from '../actions/ActionTypes';

interface TableService {
    selectedTable: number;
    areaName: string;
}
interface CollectionOrDeliveryTime {
    time: BackendTimeSlots;
}

interface ISelectedOrderTypeAction {
    type: string;
    data?: {
        orderType: TableService | CollectionOrDeliveryTime;
        settingTime: number;
    };
}

export function SelectedOrderType(
    state: {
        orderType: TableService | CollectionOrDeliveryTime;
        settingTime: number;
    } = null,
    action: ISelectedOrderTypeAction,
) {
    switch (action.type) {
        case types.SetOrderType:
            state = action.data;
            return state;
        default:
            return state;
    }
}
