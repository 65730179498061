import Button from '../Button';
import Styles from './styles.module.scss';

export function Header(props) {
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                }}
            >
                <Button
                    onClick={props.CloseDrawer}
                    dataComponentName="ExitButton"
                    iconClassName={'consumer-close'}
                    style={{
                        position: 'unset',
                        color: '#AAB6D3',
                        fontSize: '1.5rem',
                    }}
                    isHeaderBtn
                />
                {props.navigateBack && (
                    <Button
                        onClick={props.navigateBack}
                        dataComponentName="ExitButton"
                        iconClassName={'consumer-left-arrow'}
                        style={{
                            position: 'unset',
                            color: '#AAB6D3',
                            fontSize: '1.5rem',
                        }}
                        isHeaderBtn
                    />
                )}
            </div>

            <div className={Styles.lineBar} />
        </>
    );
}
