import { useEffect, useState } from 'react';
import { ReactComponent as PlaceholderSVG } from '../../../imges/placeholder.svg';
import { toLocalDateTime } from '../../../yoello-lib/modules/utils/TimeConversion';
import OrderStatus from '../../Widgets/OrderStatus';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Styles from './orderDetails.module.scss';
import { OrderTitle } from './OrderTitle';
import { OrderTypesDetails } from './OrderTypeDetails';
import { OrderTypes } from './OrderTypes';
import { OrderReviewButton } from './OrderReviewButton';
import { useSelector } from 'react-redux';
import { GetVenueInfo } from '../../../Api.js';
import { useTranslation } from 'react-i18next';
import { IOrders } from '../../../typings/IOrders.d';
import ExportToPdf from './ExportToPdf';

interface IOrderReferenceProps {
    orderItem: IOrders;
}

export function OrderReference({ orderItem }: IOrderReferenceProps) {
    const Venue = useSelector((state: any) => state.Venue);
    const [reviewsEnabled, setReviewsEnabled] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (orderItem.venue_id === Venue.id) {
            setReviewsEnabled(Venue.reviews_enabled);
        } else {
            GetVenueInfo(orderItem.venue_nickname).then((res) => {
                setReviewsEnabled(res.data.data.reviews_enabled);
            });
        }
        // eslint-disable-next-line
    }, []);
    return (
        <div className={Styles.OrderReference}>
            <div className={Styles.VenueDetails}>
                {orderItem.venue_logo && (
                    <LazyLoadImage
                        src={orderItem.venue_logo}
                        alt={orderItem.venue_name}
                        PlaceholderSrc={<PlaceholderSVG />}
                        effect="blur"
                    />
                )}
                <div>
                    <p data-component-name="venue_name">{orderItem.venue_name}</p>
                    <p className={Styles.AddressLine}>
                        {orderItem.venue_location_name}, {orderItem.venue_location_address_city}
                    </p>
                    {orderItem.venue_vat_number && (
                        <p className={Styles.taxCodes}>
                            {`${t('OrderHistory.VATNumber')}: ${orderItem.venue_vat_number}`}
                        </p>
                    )}
                    {orderItem.venue_exo_number && (
                        <p className={Styles.taxCodes}>
                            {`${t('OrderHistory.EXONumber')}: ${orderItem.venue_exo_number}`}
                        </p>
                    )}
                </div>
            </div>
            <div className={Styles.TopRow}>
                <OrderTitle
                    titleKey="OrderHistory.OrderId"
                    valueText={orderItem.placed_order_ref}
                    isLarge={true}
                    valueComponentName="OrderDetailsOrderID"
                />
                <div>
                    {orderItem.placed_order_status && (
                        <OrderStatus
                            status={orderItem.placed_order_status}
                            orderOnly={orderItem.placed_order_order_option === 'ORDER_ONLY'}
                        />
                    )}
                </div>
            </div>
            <div className={Styles.Columns}>
                <div>
                    <OrderTitle
                        titleKey="OrderHistory.OrderPlaced"
                        valueText={toLocalDateTime(orderItem.placed_order_created_on, 'HH:mma DD/MM/YYYY')}
                    />
                </div>
                <div className={Styles.OrderTypeSection}>
                    <OrderTitle
                        titleKey="Payment.OrderType"
                        valueComponment={<OrderTypes orderItem={orderItem} />}
                        isLarge={true}
                        valueComponentName="OrderDetailsOrderID"
                    />

                    {/* {orderItem.placed_order_order_option === OrderOption.ORDER_ONLY && (
            <div className={Styles.OrderOnlyStatus}>
              <OrderStatus status={orderItem.placed_order_order_option} />
            </div>
          )} */}
                </div>

                <OrderTypesDetails orderItem={orderItem} />
            </div>
            <div className={Styles.actionButtonsContainer}>
                {reviewsEnabled && (
                    <OrderReviewButton
                        buttonText={t('Payment.LeaveReview') as string}
                        className={Styles.OrderReviewButtonDiv}
                        orderID={orderItem.placed_order_id}
                    />
                )}
                <ExportToPdf
                    className={`${Styles.OrderReviewButtonDiv} ${
                        !reviewsEnabled ? Styles.fullWidthActionButton : undefined
                    }`}
                />
            </div>
        </div>
    );
}
