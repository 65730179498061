import React from 'react';
import { ReactComponent as GPayLogo } from '../../../imges/GPay.svg';
import Styles from './orderDetails.module.scss';

enum OrderPaymentMethods {
    CARD = 'Card',
    BANK_TRANSFER = 'Bank Transfer',
    GOOGLE_PAY = 'Google Pay',
    APPLE_PAY = 'Apple Pay',
}

export interface IPaymentMethod {
    method: string;
}

export function PaymentMethods({ method }: IPaymentMethod) {
    return (
        <div className={Styles.PaymentMethods}>
            {OrderPaymentMethods[method] === OrderPaymentMethods.GOOGLE_PAY && <GPayLogo />}
            <p>{OrderPaymentMethods[method]}</p>
        </div>
    );
}
