import * as types from './ActionTypes';
import { setLanguage, setCurrencies, setCurrency } from '../../yoello-lib/modules/redux/actions/localization';
//-----------------------------------------------------------------------------
const Preferences = (data) => ({
    type: types.SetPreferences,
    data,
});

export const _SetPreferences = (data) => (dispatch) => {
    dispatch(Preferences(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
const SetDietaryTags = (data) => ({
    type: types.SetDietaryTags,
    data,
});

export const _SetDietaryTags = (data) => (dispatch) => {
    dispatch(SetDietaryTags(data));
};
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
const SetMenuItemsAsExtra = (data) => ({
    type: types.SetMenuItemsAsExtra,
    data,
});

export const _SetMenuItemsAsExtra = (data) => (dispatch) => {
    dispatch(SetMenuItemsAsExtra(data));
};
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
const SetExtras = (data) => ({
    type: types.SetExtras,
    data,
});

export const _SetExtras = (data) => (dispatch) => {
    dispatch(SetExtras(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const RemoveItem = (data) => ({
    type: types.RemoveItem,
    data,
});

export const _RemoveItem = (data) => (dispatch) => {
    dispatch(RemoveItem(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
//---
//-----------------------------------------------------------------------------
const SaveRedux = (data) => ({
    type: types.SaveRedux,
    data,
});

export const _SaveRedux = (data) => (dispatch) => {
    dispatch(SaveRedux(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const ShowUp = (data) => ({
    type: types.ShowUp,
    data,
});

export const _ShowUp = (data) => (dispatch) => {
    dispatch(ShowUp(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const Tip = (data) => ({
    type: types.ChangeTip,
    data,
});

export const _ChangeTip = (data) => (dispatch) => {
    dispatch(Tip(data));
};
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
const GroupName = (data) => ({
    type: types.SetGroupName,
    data,
});

export const _SetGroupName = (data) => (dispatch) => {
    dispatch(GroupName(data));
};
//-----------------------------------------------------------------------------
const CustomerName = (data) => ({
    type: types.ChangeCustomerName,
    data,
});

export const _ChangeCustomerName = (data) => (dispatch) => {
    dispatch(CustomerName(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const TokenExpireDate = (data) => ({
    type: types.ChangeExpiry,
    data,
});

export const _ChangeTokenExpireDate = (data) => (dispatch) => {
    dispatch(TokenExpireDate(data));
};
//----
//-----------------------------------------------------------------------------
const LoginRedirectToMainPage = (data) => ({
    type: types.ChangeRedirect,
    data,
});

export const _ChangeRedirect = (data) => (dispatch) => {
    dispatch(LoginRedirectToMainPage(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const Venue = (data) => ({
    type: types.SetVenueInfo,
    data,
});

export const _SetVenueInfo = (data) => (dispatch) => {
    dispatch(Venue(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const AppCurrency = (data) => ({
    type: types.SetAppCurrency,
    data,
});

export const _SetAppCurrency = (data) => (dispatch) => {
    dispatch(AppCurrency(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const SelectedLanguage = (data) => ({
    type: types.SetLanguageIso,
    data,
});

export const _ChangeLng = (data) => (dispatch) => {
    dispatch(SelectedLanguage(data));
};

//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const SelectedMenuType = (data) => ({
    type: types.SetSelectedMenuType,
    data,
});

export const _SetSelectedMenuType = (data) => (dispatch) => {
    dispatch(SelectedMenuType(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const Group = (data) => ({
    type: types.SetGroupInfo,
    data,
});

export const _SetGroupInfo = (data) => (dispatch) => {
    dispatch(Group(data));
};
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
const Table = (data) => ({
    type: types.ChangeTable,
    data,
});

export const _ChangeTable = (data) => (dispatch) => {
    dispatch(Table(data));
};
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------

/**Set menu items */
const setMenuItems = (data) => ({
    type: types.setMenuItems,
    data,
});
export const _setMenuItems = (data) => (dispatch) => {
    dispatch(setMenuItems(data));
};

const resetMenuItems = () => ({
    type: types.resetMenuItems,
});

export const _resetMenuItems = (data) => (dispatch) => {
    dispatch(resetMenuItems());
};

const updateItemCount = (data) => ({
    type: types.updateCartItem,
    data,
});
export const _updateItemCount = (data) => (dispatch) => {
    dispatch(updateItemCount(data));
};
const deleteCartItem = (data) => ({
    type: types.deleteCartItem,
    data,
});
export const _deleteCartItem = (data) => (dispatch) => {
    dispatch(deleteCartItem(data));
};
const removeFromCart = (data) => ({
    type: types.removeCartItems,
    data,
});
export const _removeFromCart = (data) => (dispatch) => {
    dispatch(removeFromCart(data));
};
export const addCartItem = (data) => ({
    type: types.addCartItem,
    data,
});

export const _addCartItem = (data) => (dispatch) => {
    dispatch(addCartItem(data));
};
export const resetCart = () => ({
    type: types.resetCart,
});

export const _resetCart = () => (dispatch) => {
    dispatch(resetCart());
};

export const setLatestOrder = (data) => ({
    type: types.setLatestOrder,
    data,
});

export const _setLatestOrder = (data) => (dispatch) => {
    dispatch(setLatestOrder(data));
};

export const updateCartID = (id) => ({
    type: types.updateCartID,
    id,
});

export const _updateCartID = (id) => (dispatch) => {
    dispatch(updateCartID(id));
};

export const dispatchLanguage = (lang) => (dispatch) => {
    dispatch(setLanguage(lang));
};

export const dispatchSetCurrencies = (currencyObj) => (dispatch) => {
    console.log('dispatching');
    dispatch(setCurrencies(currencyObj));
};
export const dispatchSetCurrency = (currencyId) => (dispatch) => {
    dispatch(setCurrency(currencyId));
};

const SelectedOrderType = (data) => ({
    type: types.SetOrderType,
    data,
});

export const _SetSelectedOrderType = (data) => (dispatch) => {
    dispatch(SelectedOrderType(data));
};

const SetIsOpenSidebar = (data) => ({
    type: types.SetIsOpenSidebar,
    data,
});

export const _SetIsOpenSidebar = (data) => (dispatch) => {
    dispatch(SetIsOpenSidebar(data));
};
const SetTotalPrice = (data) => ({
    type: types.SetTotalPrice,
    data,
});

export const _SetTotalPrice = (data) => (dispatch) => {
    dispatch(SetTotalPrice(data));
};

const SetDeliveryPrice = (data) => ({
    type: types.SetDeliveryPrice,
    data,
});

export const _SetDeliveryPrice = (data) => (dispatch) => {
    dispatch(SetDeliveryPrice(data));
};

const SetDeliveryDistance = (data) => ({
    type: types.SetDeliveryDistance,
    data,
});

export const _SetDeliveryDistance = (data) => (dispatch) => {
    dispatch(SetDeliveryDistance(data));
};

const SetDefaultTip = (data) => ({
    type: types.SetDefaultTip,
    data,
});

export const _SetDefaultTip = (data) => (dispatch) => {
    dispatch(SetDefaultTip(data));
};

const RepeatCart = (data) => ({
    type: types.repeatCart,
    data,
});

export const _RepeatCart = (data) => (dispatch) => {
    dispatch(RepeatCart(data));
};
