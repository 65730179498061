import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../Widgets/TextField';
import Styles from '../shopList.module.scss';
import useCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
interface ICartOrderText {
    cartLength: number;
    totalPrice: number;
}

function CartOrderText({ cartLength, totalPrice }: ICartOrderText) {
    const { formatCurrency } = useCurrency();
    const { t } = useTranslation();
    const formattedTotal = formatCurrency(totalPrice);
    return (
        <div>
            {' '}
            {cartLength > 0 ? (
                <div className={Styles.subTotalPrice} data-component-name={'cartSubtotal'}>
                    <TextField text={{ key: `ShopCart.Subtotal` }} />
                    <span>{formattedTotal}</span>
                </div>
            ) : (
                <div className={Styles.emptyCartText}>
                    <p>{t('ShopCart.YourCartIsEmpty')}</p>
                </div>
            )}
        </div>
    );
}

export default CartOrderText;
