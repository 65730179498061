import React from 'react';
import { ThemeProvider } from '@material-ui/core';

interface IProps {
    children: React.ReactNode;
    theme: any;
}

const ThemeWrapper = (props: IProps) => {
    return <ThemeProvider theme={props.theme}>{props.children}</ThemeProvider>;
};

export default ThemeWrapper;
