import React from 'react';
import Styles from './styles.module.scss';
import Button from '../Button';
import { BackButton } from '../../NavigationHeader/BackButton';

interface IDrawerHeaderProps {
    title: string;
    closeDrawer: () => void;
    hasBackButton?:boolean;
    onBackClick?:()=>void;
}

function DrawerHeader({ title, closeDrawer,hasBackButton,onBackClick }: IDrawerHeaderProps) {
    return (
        <div className={Styles.DrawerHeader}>
            <div className={Styles.lineBar}>
                {' '}
                <span></span>{' '}
            </div>

            <div className={Styles.DeliveryHeader}>
                <div className="Flex1" >
                    {hasBackButton && <BackButton style={{
                            position: 'unset',
                            color: '#AAB6D3',
                            fontSize: '1.5rem',
                        }} onClick={onBackClick}/>}
                </div>
                <p>{title}</p>
                <div className="Flex1">
                    <Button
                        onClick={closeDrawer}
                        dataComponentName="ExitButton"
                        iconClassName={'consumer-close'}
                        style={{
                            position: 'unset',
                            color: '#AAB6D3',
                            fontSize: '1.5rem',
                        }}
                        isHeaderBtn
                    />
                </div>
            </div>
            <div className={Styles.divider}></div>
        </div>
    );
}

export default DrawerHeader;
