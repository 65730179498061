import makeStyles from '@material-ui/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import React, { useState } from 'react';
import useColors from '../../customHooks/useColors';
import FormElement from '../../yoello-lib/modules/dynamicForms/FormElement';
import ICommonFormProps from '../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import { generateRegister } from '../../yoello-lib/modules/dynamicForms/utils';

export interface IPasswordInput extends ICommonFormProps {
    Component: React.FunctionComponent<any>;
    label: string;
    inputOptions?: {
        disabled?: boolean;
    };
}

const PasswordInput = (props: IPasswordInput) => {
    const { primaryBorderColor, useDarkTheme } = useColors();

    const useStyles = makeStyles({
        root: {},
        label: {
            width: '100%',
        },
        labelText: {
            color: '#626262',
            fontSize: '0.875rem',
            lineHeight: '2rem',
        },
        input: {
            border: 'none',
            outline: 'unset',
            width: '100%',
            position: 'relative',
            '& .MuiInput-root': {
                border: `1px solid ${useDarkTheme ? primaryBorderColor : '#F9F9F9'}`,
                backgroundColor: '#F9F9F9',
                transition: '0.25s ease-in-out',
                borderRadius: '0.5rem',
                width: '100%',
                display: 'flex',
                boxSizing: 'border-box',
                '&.Mui-error': {
                    border: '1px solid #F44336',
                },
                '&.Mui-focused': {
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${primaryBorderColor}`,
                },
                '& input': {
                    padding: '1.6rem',
                    boxSizing: 'border-box',
                },
            },
        },
    });
    const classes = useStyles();
    const { keyName, label, inputOptions } = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={classes.root}>
            <label className={classes.label}>
                <span className={classes.labelText}>{label}</span>
                <FormElement {...props} keyName={keyName}>
                    <TextField
                        data-component-name={'PasswordInput'}
                        name={keyName}
                        disabled={inputOptions?.disabled ?? false}
                        {...generateRegister(props)}
                        type={showPassword ? 'text' : 'password'}
                        className={classes.input}
                        inputProps={{
                            autoComplete: 'new-password',
                        }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disabled={inputOptions?.disabled ?? false} onClick={toggleShowPassword}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormElement>
            </label>
        </div>
    );
};

export default PasswordInput;
