import { BasketItemsMetaData, DiscountData, DiscountsApiData } from '../types.d';
import { DiscountType } from './DiscountsComponent';
import { useTranslation } from 'react-i18next';
import useCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import { useMultiVendorBasketView } from '../useMultiVendorBasketView';
import { APIError } from '../../../utils/APIErrorController';
import { calculateTotalDiscountAmount } from '../../Utils/CalcTotalDiscountAmount';
import { getStripeMinimumOrderValue } from '../../../utils/getStripeMinimumOrderValue';
import { useSelector } from 'react-redux';
import { usePaymentState } from '../PaymentStore';

export function useMultiVendorBasketDiscountCode(
    venueId: string,
    subTotalCost: number,
    setCode: (code: string) => void,
) {
    const { basket } = usePaymentState();
    const Group = useSelector((state: any) => state.Group);
    const DefaultTip = useSelector((state: any) => state.DifaultTip);
    const { t } = useTranslation();
    const { formatCurrency } = useCurrency();
    const { evaluateBasketItems } = useMultiVendorBasketView();

    function calculateMultiVendorBasketDiscount(discountApiData: DiscountsApiData, code: string) {
        const discountData: DiscountData = discountApiData.discount;
        if (discountApiData.valid) {
            let discountValue = discountData.value;
            if (discountData.type === DiscountType.ABSOLUTE) {
                discountValue = (discountData.value * 100) / subTotalCost;
            }
            if (discountData.minimum_order_value > subTotalCost) {
                setDiscount(
                    discountData.id,
                    code,
                    t('Payment.DiscountMinValidText', {
                        price: formatCurrency(discountData.minimum_order_value),
                    }),
                    0,
                    true,
                    DiscountType.PERCENTAGE,
                );
            } else {
                setDiscount(
                    discountData.id,
                    code,
                    '',
                    discountValue,
                    false,
                    DiscountType.PERCENTAGE,
                    discountData.type === DiscountType.ABSOLUTE ? discountData.value : null,
                );
            }
        } else {
            setDiscount('', '', APIError([{ error_code: discountApiData.validation_error }], t), 0, true);
        }
    }

    function setDiscount(
        id: string,
        code: string,
        message: string,
        value: number,
        error: boolean,
        type?: DiscountType,
        absoluteValue?: number | null,
    ) {
        let discount = {
            id,
            code,
            message,
            value,
            error,
            type,
            absoluteValue,
        };
        const totalDiscounts = calculateTotalDiscountAmount(discount, subTotalCost);
        const stripeMinimumOrderValue = getStripeMinimumOrderValue(Group.currency_iso);
        if (subTotalCost < totalDiscounts) {
            setDiscount(
                id,
                code,
                t('Payment.Errors.BelowMinimum', {
                    price: `${stripeMinimumOrderValue}${Group.currency_iso === 'GBP' ? 'p' : 'c'}`,
                }),
                0,
                true,
                type,
            );
        } else {
            evaluateBasketItems(BasketItemsMetaData.DISCOUNT, discount, venueId, DefaultTip);
            if (!error) {
                setCode('');
            }
        }
    }
    return {
        calculateMultiVendorBasketDiscount,
        basket,
    };
}
