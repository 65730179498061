import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
    root: {
        '@media (min-width: 768px)': {
            width: '768px',
            maxWidth: '768px',
            background: ' #FFFFFF',
            boxShadow: ' 0px 6px 30px rgba(33, 33, 33, 0.1)',
            borderRadius: '2.5rem',
            height: '100%',
        },
    },
});
