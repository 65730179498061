import { AlertStatusCard } from '../../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard';
import { ReactComponent as AlertErrorIcon } from '../../../../imges/AlertErrorIcon.svg';
import { ReactComponent as AlertSuccessIcon } from '../../../../imges/AlertSuccessIcon.svg';
import { postcodeCheckStatusType } from '../PostCodeChecker';
import { useTranslation } from 'react-i18next';
interface IPostCodeAlertProps {
    postcodeCheckStatus: postcodeCheckStatusType;
    menuTimingAlerts?: IAvailableMenus[];
}

function PostCodeAlert({ postcodeCheckStatus, menuTimingAlerts }: IPostCodeAlertProps) {
    const { t } = useTranslation();
    const renderAlert = () => {
        function getMenusTimings(item: IAvailableMenus) {
            let timings = [];
            item.timings.forEach((time) => {
                timings.push(` ${time.start_time.slice(0, -3)} - ${time.end_time.slice(0, -3)}`);
            });
            return timings;
        }
        let alertsList = [];

        if (menuTimingAlerts?.length) {
            menuTimingAlerts.forEach((item) => {
                alertsList.push(
                    <AlertStatusCard
                        key={item.created_on}
                        alertProps={{
                            color: 'error',
                            icon: <AlertErrorIcon />,
                            style: {
                                color: '#F65164',
                                borderRadius: '0.75rem',
                                fontWeight: 600,
                                marginBottom: '12px',
                            },
                        }}
                        alertMessage={t('Forms.InvalidTimeSlotForMenu', {
                            menuName: item.name_public,
                            menuTime: getMenusTimings(item),
                        })}
                    />,
                );
            });
        }

        switch (postcodeCheckStatus) {
            case postcodeCheckStatusType.PostCodeInValid:
                alertsList.push(
                    <AlertStatusCard
                        key={'InvalidPostcode'}
                        alertProps={{
                            color: 'error',
                            icon: <AlertErrorIcon />,
                            style: {
                                color: '#F65164',
                                borderRadius: '0.75rem',
                                fontWeight: 600,
                            },
                        }}
                        alertTitle={t('Forms.InvalidPostcode')}
                        alertMessage={t('Forms.EnterValidPostCode')}
                    />,
                );
                break;

            case postcodeCheckStatusType.OutOfDeliveryRadius:
                alertsList.push(
                    <AlertStatusCard
                        key="OurOfDeliveryRadius"
                        alertProps={{
                            color: 'error',
                            icon: <AlertErrorIcon />,
                            style: {
                                color: '#F65164',
                                borderRadius: '0.75rem',
                                fontWeight: 600,
                            },
                        }}
                        alertMessage={t('Forms.OurOfDeliveryRadius')}
                    />,
                );
                break;

            case postcodeCheckStatusType.InDeliveryRadius:
                alertsList.push(
                    <AlertStatusCard
                        key="InDeliveryRadius"
                        alertProps={{
                            color: 'success',
                            icon: <AlertSuccessIcon />,
                            style: {
                                color: '#24C277',
                                borderRadius: '0.75rem',
                            },
                        }}
                        alertTitle={t('Forms.GreatNews')}
                        alertMessage={t('Forms.InDeliveryRadius')}
                    />,
                );
                break;

                case postcodeCheckStatusType.PinFarFromAddress:
                    alertsList.push(
                        <AlertStatusCard
                            key="InDeliveryRadius"
                            alertProps={{
                                color: 'error',
                                icon: <AlertErrorIcon />,
                                style: {
                                    color: '#F65164',
                                    borderRadius: '0.75rem',
                                },
                            }}
                            alertTitle={t('Forms.AreYouSure')}
                            alertMessage={t('Forms.PinFarFromAddress')}
                        />,
                    );
                    break;
                    case postcodeCheckStatusType.LocationNotFound:
                        alertsList.push(
                            <AlertStatusCard
                                key={'InvalidPostcode'}
                                alertProps={{
                                    color: 'error',
                                    icon: <AlertErrorIcon />,
                                    style: {
                                        color: '#F65164',
                                        borderRadius: '0.75rem',
                                        fontWeight: 600,
                                    },
                                }}
                                alertTitle={t('Forms.LocationNotFound')}
                                alertMessage={t('Forms.AmendAddres')}
                            />,
                        );
                        break;

                    default:
                break;
        }
        return alertsList;
    };

    return <div>{renderAlert()}</div>;
}

export default PostCodeAlert;
