import Skeleton from '@material-ui/lab/Skeleton';
import Layout from '../../../layout/Layout';
import Styles from './groupPageSkeleton.module.scss';
const GroupPageSkeleton = () => {
    return (
        <Layout>
            <div className={Styles.groupPageContainer}>
                <div className={Styles.header}>
                    <div className={Styles.SideMenuDrawerBtn}>
                        <Skeleton />
                    </div>
                    <div className={Styles.groupDetails}>
                        <div className={Styles.logo}>
                            <Skeleton />
                        </div>
                        <div>
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className={Styles.content}>
                    <div className={Styles.venuesList}>
                        <VenuesListLoading />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default GroupPageSkeleton;

export const VenuesListLoading = () => (
    <>
        {[...Array(10).keys()].map((index) => (
            <div key={`${index} - venue`} style={{ marginBottom: '1rem', height: '6.25rem' }}>
                <Skeleton />
            </div>
        ))}
    </>
);
