import { useRef, lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import OrderHistoryListViewSkeleton from '../Widgets/Loading/OrderHistoryListView';
import Styles from './orders.module.scss';
import { OrderDetailsContainer } from './OrdersDetailsContainer';
import { BY_ID } from '../../constants/URL.const';
import { useOrdersNavigation } from './useOrdersNavigation';
import PageComponent from '../Page/PageComponent';
import { REVIEW } from '../../constants/URL.const';
import OrderDetailsSkeleton from '../Widgets/Loading/OrderDetails';
import { MenuItemsNavigation } from '../Widgets/Loading/MenuItemsPage';

const OrderHeader = lazy(() => import('./OrderHeader'));
const OrderReviewContainer = lazy(() => import('./OrderReviewContainer'));
const OrderListContainer = lazy(() => import('./OrderListContainer'));
export function OrdersRoute() {
    let { path } = useRouteMatch();
    const { navigateBack, isStartRoute } = useOrdersNavigation();
    const headerRef = useRef();

    return (
        <>
            <div className={Styles.settingsPageContainer} id={'settingsPageContainerId'}>
                <div ref={headerRef}>
                    <Suspense fallback={<MenuItemsNavigation />}>
                        <OrderHeader navigateBack={navigateBack} />
                    </Suspense>
                </div>
                <Switch>
                    <Route exact path={path}>
                        <Suspense fallback={<OrderHistoryListViewSkeleton />}>
                            <OrderListContainer headerRef={headerRef} />
                        </Suspense>
                    </Route>
                    <Route exact path={`${path}${BY_ID}${REVIEW}`}>
                        <Suspense fallback={null}>
                            <OrderReviewContainer />
                        </Suspense>
                    </Route>
                    <Route path={`${path}${BY_ID}`}>
                        <Suspense fallback={<OrderDetailsSkeleton />}>
                            <OrderDetailsContainer isStartRoute={isStartRoute} />
                        </Suspense>
                    </Route>
                </Switch>
            </div>
        </>
    );
}

export default PageComponent(OrdersRoute);
