import Styles from './selectOrderTypeDrawer.module.scss';
import Button from '../../Widgets/Button';
import { useTranslation } from 'react-i18next';
import { SelectedOrderType } from '../../Payment/types.d';
import { OrderType } from './OrderType';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMenuType } from '../../../yoello-lib/modules/redux/actions/consumer.actions';
import { ordertypes } from '../util';
import { useMemo } from 'react';
import { _RepeatCart, _SetSelectedOrderType, _resetCart } from '../../../redux/actions';
import { useLeavingWarning } from './useLeavingWarning';
import { GuestUser } from '../../LoginV2/GuestView/types.d';
import { deleteCookie } from '../../LoginV2/util/cookies';

interface ISelectOrderTypeDrawer {
    onCloseDrawer: () => void;
}

function SelectOrderTypeDrawer({ onCloseDrawer }: ISelectOrderTypeDrawer) {
    const { t } = useTranslation();
    const cartItems = useSelector((state: any) => state.Cart);
    const { openWarningDrawer } = useLeavingWarning();
    const dispatch = useDispatch<any>();
    const availableOrderTypes = useSelector((state: any) => state.Group.availableOrderTypes);
    const orderType = useSelector((state: any) => state.SelectedMenuType);

    const OrderTypes = useMemo(() => {
        if (availableOrderTypes.length > 0) {
            return availableOrderTypes.map((orderType) => ordertypes[orderType]);
        }
    }, [availableOrderTypes]);

    function onOrderTypeChange(value: SelectedOrderType) {
        onCloseDrawer();
        if (value !== orderType) {
            dispatch(_SetSelectedOrderType(null));
            dispatch(_RepeatCart(null));
            deleteCookie([GuestUser.ConsumerID, GuestUser.GuestID]);
            if (cartItems.length > 0) {
                setTimeout(() => {
                    openWarningDrawer({
                        onContinue: () => {
                            dispatch(_resetCart());
                            dispatch(setSelectedMenuType(value));
                            onCloseDrawer();
                        },
                    });
                }, 350);
            } else {
                dispatch(setSelectedMenuType(value));
                onCloseDrawer();
            }
        }
    }
    return (
        <div className={Styles.selectOrderTypesContainer}>
            <div className={Styles.lineBar}>
                <span />
            </div>
            <header>
                <div>
                    <p>{t('Payment.ChoosePaymentMethod')}</p>
                </div>
                <div>
                    <Button
                        onClick={onCloseDrawer}
                        dataComponentName="ExitButton"
                        iconClassName={'consumer-close'}
                        style={{
                            position: 'unset',
                            color: '#AAB6D3',
                            fontSize: '1.5rem',
                        }}
                        isHeaderBtn
                    />
                </div>
            </header>
            <div className={Styles.orderTypesWrapper}>
                {OrderTypes?.map((orderType, i) => {
                    return (
                        <div
                            onClick={() => onOrderTypeChange(orderType.value)}
                            key={orderType.key}
                            data-component-name={orderType.key}
                        >
                            <OrderType {...orderType} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SelectOrderTypeDrawer;
