const stripeCurrencyList = {
    GBP: {
        currencyName: 'British Pound',
        minimumOrderValue: 30,
    },
    EUR: {
        currencyName: 'Euro',
        minimumOrderValue: 50,
    },
    AUD: {
        currencyName: 'Australian Dollar',
        minimumOrderValue: 50,
    },
    NZD: {
        currencyName: 'New Zealand Dollar',
        minimumOrderValue: 50,
    },
    USD: {
        currencyName: 'American Dollar',
        minimumOrderValue: 50,
    },
    CAD: {
        currencyName: 'Canadian Dollar',
        minimumOrderValue: 50,
    },
};
export function getStripeMinimumOrderValue(CurrencyName: string) {
    return stripeCurrencyList[CurrencyName].minimumOrderValue ?? 50;
}
