import { TipType } from '../../Components/Payment/Tips/useTip';
import * as types from '../actions/ActionTypes';

export type IDefaultTip = {
    tipType: TipType;
    customValue: number;
    tipPercantage: number;
    isTipChanged?: boolean;
};

export function DefaultTipReducer(state: IDefaultTip = null, payload) {
    switch (payload.type) {
        case types.SetDefaultTip:
            return payload.data;
        default:
            return state;
    }
}
