import { ComponentType, useState } from 'react';
import { useCurrentUserData } from '../../customHooks/useCurrentUserData';
import { useDispatch } from 'react-redux';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { updateUserLanguagePreference } from '../../utils/updateUserLanguagePreference';
import withSnackbarWrapper from '../../yoello-lib/modules/components/snackbar/SnackbarWrapper';
import { setLanguage } from '../../yoello-lib/modules/redux/actions/localization';
import { useDrawer } from '../Drawer/DrawerContext';
import { SideMenu } from '../SideMenu/SideMenu';
import IConsumerUser from '../../yoello-lib/modules/auth/IConsumerUser';
import { ApiVenueLanguages } from '../../constants';
/**
 * Wrapper component to handle the authentication of components
 */

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

function PageComponent(Component: ComponentType) {
    function WrappedComponent(props: any) {
        const { getTokenOrLogout, isAuthenticated, retrieveToken, setUser, user, hasNoConsumer } = useCurrentUserData();
        const [isOpenDrawer, setIsOpenDrawer] = useState(false);
        const { openDrawer, closeDrawer } = useDrawer()!;
        const dispatch = useDispatch();

        const sidebarProps = {
            closeSideDrawer: () => setIsOpenDrawer(false),
            openDrawer,
            closeDrawer,
            dispatchLanguage: (key) => {
                dispatch(setLanguage(key));

                //JPN Needs to be updated via cognito
                updateUserLanguagePreference(
                    ApiVenueLanguages[key].toLowerCase(),
                    user as IConsumerUser,
                    retrieveToken,
                    setUser,
                );
            },
        };

        return (
            <>
                <Component
                    {...props}
                    apiConfig={props.apiConfig}
                    retrieveToken={getTokenOrLogout}
                    User={user}
                    isAuthenticated={isAuthenticated}
                    openDrawer={openDrawer}
                    closeDrawer={closeDrawer}
                    openSideDrawer={() => setIsOpenDrawer(true)}
                    hasNoConsumer={hasNoConsumer}
                />
                <SwipeableDrawer
                    anchor={'left'}
                    open={isOpenDrawer === undefined ? false : isOpenDrawer}
                    onClose={() => setIsOpenDrawer(false)}
                    onOpen={() => setIsOpenDrawer(true)}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    // transitionDuration={0}
                    transitionDuration={350}
                    id="SIDEMENUDRAWER"
                >
                    <SideMenu {...sidebarProps} />
                </SwipeableDrawer>
            </>
        );
    }

    return withSnackbarWrapper(WrappedComponent);
}

export default PageComponent;
