import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../Widgets/Button';

interface IExportToPdfProps {
    className: string;
}

export default function ExportToPdf(props: IExportToPdfProps) {
    const { className } = props;

    const [loading, setLoading] = useState(false);

    const handleGeneratePDF = useCallback(async () => {
        const width = 600;
        const eReceiptPrintableArea = document.querySelector("[data-component-name='print-reciept-area']");

        const buttonTags = eReceiptPrintableArea?.getElementsByTagName('button');
        if (buttonTags) {
            for (let i = 0; i < buttonTags.length; i++) {
                buttonTags[i].setAttribute('data-html2canvas-ignore', 'true');
            }
        }

        if (eReceiptPrintableArea instanceof HTMLElement) {
            try {
                const html2canvas = await import('html2canvas');
                const contentHeight = eReceiptPrintableArea.scrollHeight;

                const canvas = await html2canvas.default(eReceiptPrintableArea, {
                    windowWidth: width,
                    windowHeight: contentHeight + 250, // Adjust the offset as needed
                    allowTaint: true,
                    scale: 2,
                    useCORS: true,
                });

                const { jsPDF } = await import('jspdf');
                const contentDataURL = canvas.toDataURL('image/png');

                const eReceipt = new jsPDF('p', 'pt', [width, contentHeight]);

                eReceipt.addImage(contentDataURL, 'PNG', 0, 0, width, contentHeight + 250, '', 'FAST');

                eReceipt.save(`yoello-order-details.pdf`);

                if (buttonTags) {
                    for (let i = 0; i < buttonTags.length; i++) {
                        buttonTags[i].removeAttribute('data-html2canvas-ignore');
                    }
                }

                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    useEffect(() => {
        if (loading) {
            handleGeneratePDF();
        }
    }, [loading, handleGeneratePDF]);

    return (
        <Button
            disabled={loading}
            title={'DownloadAsPdf'}
            onClick={() => {
                setLoading(true);
            }}
            className={className}
            dataComponentName={'export-as-pdf'}
            data-html2canvas-ignore
        />
    );
}
