import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentContext } from '../PaymentStore';
import { CARDIFF_CASTLE } from '../../../constants';
import { checkAbvContainsFood } from '../../../utils/checkAbvContainsFood';
import { BasketItemsMetaData, SelectedOrderType } from '../types.d';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import useFormatCurrency from '../../../yoello-lib/modules/localisation/useCurrency';
import { getStripeMinimumOrderValue } from '../../../utils/getStripeMinimumOrderValue';
import APIExtensions from '../../../yoello-lib/modules/API2/APIDefs';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';
import { setSelectedMenuType } from '../../../yoello-lib/modules/redux/actions/consumer.actions';
import { useCheckIfOrderTypeSelected } from './useCheckIfOrderTypeSelected';
import { useAPIV2 } from '../../../yoello-lib/modules/API2/useAPIv2';
import { IGroup } from '../../GroupPage/types';
import { useMultiVendorBasketView } from '../useMultiVendorBasketView';
import { useHistory } from 'react-router-dom';
import { GROUP } from '../../../constants/URL.const';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import { _removeFromCart } from '../../../redux/actions';
import usePaymentView from './usePaymentView';

const minPriceMap = {
    COLLECTION: 'min_collection_price',
    HOME_DELIVERY: 'min_delivery_price',
    TABLE_DELIVERY: 'min_dine_in_price',
    COLLECTION_POINT_PICKUP: 'min_pickup_now_price',
};
const orderTypeEnabled = {
    TABLE_DELIVERY: 'table_enabled',
    HOME_DELIVERY: 'delivery_enabled',
    COLLECTION: 'pickup_enabled',
    COLLECTION_POINT_PICKUP: 'pickup_now_enabled',
};

const useCanMultiVendorOderBeProcessed = () => {
    const dispatch = useDispatch<any>();
    const { t } = useTranslation();
    const group = useSelector((state: any) => state.Group);
    const venue = useSelector((state: any) => state.Venue);
    const cart = useSelector((state: any) => state.Cart);
    const orderType = useSelector((state: any) => state.SelectedMenuType);
    const { generateNavPath } = useGenerateNavPath();
    const { evaluateBasketItems } = useMultiVendorBasketView();

    const { callAPI } = useAPIV2<IGroup>(APIExtensions.multiVendorGroupUpdate, {
        query: `${group.nickname}/public`,
        canAPIGetCacheValue: false,
        doNotCacheValue: true,
        onMountDisabled: true,
    });

    const { basket, orderOnly, selectedOrderType, selectedTable } = useContext(PaymentContext);

    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const { renderOrderTypeDrawer } = useCheckIfOrderTypeSelected();
    const { formatCurrency } = useFormatCurrency();
    const { backFunction } = usePaymentView();
    const history = useHistory();
    const { getCartItemsTotal, itemsInCartGroupedByVenueId } = useMultiVendorBasketView();

    const canMultiOrderPaymentBeProcessed = async () => {
        if (!orderOnly) {
            renderOrderTypeDrawer();
        }
        const { data: updatedGroup, isSuccessful } = await callAPI(HTTPMethods.GET);

        if (isSuccessful) {
            const stripeMinimumOrderValue = getStripeMinimumOrderValue(updatedGroup.currency_iso);
            const { subTotalCost, totalDiscounts, totalPrice } = getCartItemsTotal(basket);
            let errMsg = t('Payment.Errors.PaymentCouldNotProceed');
            const venues = { ...groupVenuesByNickName(updatedGroup.venues) };
            for (let key of Object.keys(itemsInCartGroupedByVenueId)) {
                const { totalPrice } = basket[key];
                const venueName = group?.venues[key]?.venue_name;
                if (!venues[key]) {
                    sendSnackbarMessage(t('Payment.Errors.NoVenueAvailable', { venue: venueName }), 'error');
                    evaluateBasketItems(BasketItemsMetaData.REMOVEFORMBASKET, undefined, key);
                    if (venue.venue_nickname === key) {
                        itemsInCartGroupedByVenueId[key].forEach((cartItem) => {
                            dispatch(_removeFromCart({ menuItemId: cartItem.menuItem.menu_item_id }));
                        });
                        history.push(generateNavPath(GROUP));
                    } else {
                        const disabledItems = getDisabledItems(key);
                        if (disabledItems.length > 0) {
                            backFunction(disabledItems);
                            return;
                        }
                    }
                    return false;
                } else if (totalPrice < venues[key][minPriceMap[selectedOrderType]]) {
                    sendSnackbarMessage(
                        t('ShopCart.Errors.MultiOrderCartLessThan', {
                            price: formatCurrency(venues[key][minPriceMap[selectedOrderType]]),
                            venue: venues[key].venue_name,
                        }),
                        'error',
                    );
                    return false;
                } else if (!venues[key]?.[orderTypeEnabled[orderType]]) {
                    if (Object.keys(itemsInCartGroupedByVenueId).length === 1) {
                        dispatch(setSelectedMenuType(null));
                    }
                    sendSnackbarMessage(
                        t('Payment.Errors.MultiOrderSelectOrderTypeNotAvailable', {
                            venue: venues[key].venue_name,
                        }),
                        'error',
                    );
                    if (venue.venue_nickname === key) {
                        history.push(generateNavPath(GROUP));
                    } else {
                        const disabledItems = getDisabledItems(key);
                        if (disabledItems.length > 0) {
                            backFunction(disabledItems);
                            return;
                        }
                    }
                    return false;
                }
            }

            if (selectedOrderType === null) {
                errMsg = t('Payment.Errors.SelectOrderType');
            } else if ((totalPrice < stripeMinimumOrderValue || totalDiscounts > subTotalCost) && !orderOnly) {
                errMsg = t('Payment.Errors.BelowMinimum', {
                    price: `${stripeMinimumOrderValue}${updatedGroup.currency_iso === 'GBP' ? 'p' : 'c'}`,
                });
            } else if (selectedOrderType === SelectedOrderType.TABLE && selectedTable === null) {
                errMsg = t('Payment.Errors.WithoutVenueArea');
            } else if (updatedGroup?.abv_food_threshold && updatedGroup?.nickname === CARDIFF_CASTLE) {
                if (!checkAbvContainsFood(cart, updatedGroup?.abv_food_threshold)) {
                    const price = formatCurrency(updatedGroup?.abv_food_threshold);
                    errMsg = t('Payment.Errors.CartMustContainMin', {
                        price: price,
                    });
                    // NEEDS currency localization
                } else {
                    return true;
                }
            } else {
                return true;
            }
            sendSnackbarMessage(errMsg as string, 'error');
            return false;
        } else {
            sendSnackbarMessage('Payment.Errors.PaymentCouldNotProceed', 'error');
        }
    };

    function getDisabledItems(key: string) {
        let disabledItems = [];
        itemsInCartGroupedByVenueId[key].forEach((cartItem) => {
            disabledItems.push(cartItem.menuItem.menu_item_id);
        });
        return disabledItems;
    }

    return {
        canMultiOrderPaymentBeProcessed,
    };
};

function groupVenuesByNickName(venues) {
    const groupedVenues = venues
        .filter((groupV: any) => groupV.venue_nickname !== null)
        .map((groupV: any) => {
            const settings = groupV['settings'];
            delete groupV['settings'];
            return {
                ...groupV,
                ...settings,
            };
        });
    return groupedVenues.reduce((obj: any, venue: any) => Object.assign(obj, { [venue.venue_nickname]: venue }), {});
}

export default useCanMultiVendorOderBeProcessed;
