import { ReactNode, lazy, Suspense } from 'react';

import Styles from '../styles.module.scss';
import PaymentFooter from '../PaymentFooter';

const PaymentViewDrawers = lazy(() => import('../PaymentViewDrawers'));
interface IPaymentPage {
    renderPaymentComponents: () => ReactNode;
}

export default function PaymentPage({ renderPaymentComponents }: IPaymentPage) {
    return (
        <div className={Styles.Payment}>
            {renderPaymentComponents()}
            <Suspense fallback={null}>
                <PaymentViewDrawers />
            </Suspense>
            <PaymentFooter />
        </div>
    );
}
