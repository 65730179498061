function PageLoading() {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100% - 6rem)',
            }}
        >
            <div className="LottieLoading" data-component-name="page-loading" style={{ width: '22rem' }}>
                <span className="loader"></span>
            </div>
        </div>
    );
}

export default PageLoading;
