import { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { VenueWrapper } from '../VenueWrapper';
import { CartItem } from './CartItem';
import { useSelector } from 'react-redux';

interface ICartListProps {
    cartItems: any[];
    updateProduct: (item: any, count: number) => void;
}

export function MultiVendorBasketCartList({ cartItems, updateProduct }: ICartListProps) {
    const Venues = useSelector((state: any) => state.Group.venues);
    const cart = useMemo(() => {
        const els = [];
        const cartItemsWithIndex = [];

        cartItems.forEach(function (item, index) {
            cartItemsWithIndex.push({
                ...item,
                index,
            });
        });
        const items: Record<string, any[]> = groupBy(cartItemsWithIndex, 'venueId');
        for (let key in items) {
            els.push(
                <VenueWrapper name={Venues?.[key]?.venue_name} logo={Venues?.[key]?.url_logo} key={Venues?.[key]?.id}>
                    {items[key].map((cartItem) => (
                        <CartItem
                            cartItem={cartItem}
                            index={cartItem.index}
                            updateProduct={updateProduct}
                            key={`${key}_Item_${cartItem.index}`}
                        />
                    ))}
                </VenueWrapper>,
            );
        }
        return els;
        // eslint-disable-next-line
    }, [cartItems]) as Record<string, any>;

    return <>{cart}</>;
}
