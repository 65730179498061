import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Styles from '../shopList.module.scss';
import { useMultiVendorBasket } from '../../../customHooks/useMultiVendorBasket';
import { CartControllers } from './CartControllers';
import CartList from '../list/CartList';

interface ICartBody {
    onOpenCart: () => void;
    cartCount: number;
    isOpen: boolean;
}

export function CartBody({ onOpenCart, cartCount, isOpen }: ICartBody) {
    const { isMultiVendorBasket } = useMultiVendorBasket();
    const cartItems = useSelector((state: any) => state.Cart);

    const displayNavigateBtn = useMemo(() => {
        if (isMultiVendorBasket) {
            if (cartItems.length === 0) {
                return false;
            } else {
                if (cartItems.length === 1) {
                    return true;
                }
                const items = groupBy(cartItems, 'venueId');
                return Object.keys(items).length === 1;
            }
        }
    }, [isMultiVendorBasket, cartItems]);

    return (
        <div className={Styles.body}>
            <CartControllers onOpenCart={onOpenCart} cartCount={cartCount} displayNavigateBtn={displayNavigateBtn} />
            <div className={Styles.cartItems} id="cartItemsId">
                <div className={Styles.shadowTop}></div>
                <CartList cartItems={cartItems} isOpen={isOpen} />
            </div>
        </div>
    );
}
