import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkVenueOpen } from '../yoello-lib/modules/utils/venueOpen';

export function useVenueOpen(showSnackMessages = true) {
    const Venue = useSelector((state: any) => state.Venue);
    const OrderType = useSelector((state: any) => state.SelectedMenuType);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!Venue) {
            return;
        }
        const { isVenueOpen } = checkVenueOpen(Venue, OrderType);

        if (isOpen !== isVenueOpen) {
            setIsOpen(isVenueOpen);
        }
        //eslint-disable-next-line
    }, [Venue]);
    return isOpen;
}
