import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router';
import { GROUP_MENU, NOT_FOUND } from '../../constants/URL.const';
import { MenuContainer } from '../Menu/MenuContainer';
import MenuItemsSkeleton from '../Widgets/Loading/MenuItemsPage';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import GroupPageSkeleton from '../Widgets/Loading/GroupPage';
import { ApiVenueLanguages } from '../../constants';
import { GroupComponent } from '../GroupPage/GroupContainer';
import GroupPageContainer from '../Page/GroupPageContainer';
import MenusPageContainer from '../Page/MenusPageContainer';

export function GroupRouter() {
    let { path } = useRouteMatch();
    const venue = useSelector((state: any) => state.Venue);
    const Group = useSelector((state: any) => state.Group);
    const orderType = useSelector((state: any) => state.SelectedMenuType);
    const localization = useSelector((state: any) => state.localization);

    const ldClient = useLDClient();

    if (venue && venue.id) {
        ldClient?.identify({ key: venue.id, kind: 'user' });
    }

    return (
        <Switch>
            <Route
                exact
                path={path}
                render={(props) => (
                    <GroupPageContainer
                        {...props}
                        area={true}
                        apiConfig={{
                            query: `${props.match.params['group']}/public`,
                            dispatcherParams: [ApiVenueLanguages?.[localization.language] ?? 'EN', Group.id],
                            headers: {},
                        }}
                        ChildComponent={GroupComponent}
                        LoadingComponent={GroupPageSkeleton}
                        errorFunction={() =>
                            props.history.push(NOT_FOUND, {
                                message: 'Group not found',
                            })
                        }
                    />
                )}
            />
            <Route
                path={GROUP_MENU}
                render={(props) => (
                    <MenusPageContainer
                        {...props}
                        area={false}
                        apiConfig={{
                            query: props.match.params['id'],
                            headers: {},
                            params: { order_type: orderType },
                            dispatcherParams: [orderType, venue, Group],
                        }}
                        ChildComponent={MenuContainer}
                        errorFunction={() =>
                            props.history.push(NOT_FOUND, {
                                message: 'Menu not found',
                            })
                        }
                        LoadingComponent={MenuItemsSkeleton}
                    />
                )}
            />
        </Switch>
    );
}
