import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Styles from './orderStatus.module.scss';
interface IOrderStatus {
    status: string;
    orderOnly?: boolean;
}
export enum Status {
    STARTED = 'STARTED',
    PLACED = 'PLACED',
    FUNDS_RECEIVED = 'FUNDS_RECEIVED',
    FINISHED = 'FINISHED',
    ACCEPTED = 'ACCEPTED',
    REFUNDED = 'REFUNDED',
    CLOSED = 'CLOSED',
    REJECTED = 'REJECTED',
    DECLINED = 'DECLINED',
    VOID = 'VOID',
    REFUND_FAILED = 'REFUND_FAILED',
    PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
    PROCESSING_REFUND = 'PROCESSING_REFUND',
    READY_FOR_COLLECTION = 'READY_FOR_COLLECTION',
    PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
    ORDER_ONLY = 'ORDER_ONLY',
    DISPUTED = 'DISPUTED',
}
const OrderStatus = ({ status, orderOnly }: IOrderStatus) => {
    const { t } = useTranslation();
    const venue = useSelector((state: any) => state.Venue);

    const renderStatusMarkup = () => {
        if (orderOnly === undefined) {
            return <StatusView t={t} condition={!venue.order_only_enabled} status={status} />;
        } else {
            return <StatusView t={t} condition={!orderOnly} status={status} />;
        }
    };
    return <>{renderStatusMarkup()}</>;
};

function StatusView(props: { status: string; condition: boolean; t: (key: string) => string }) {
    const { t, status, condition } = props;
    const renderStatus = (status) => {
        switch (status) {
            case Status.STARTED:
                return Styles.STARTED;
            case Status.PLACED:
                return Styles.PLACED;
            case Status.FUNDS_RECEIVED:
                return Styles.FUNDS_RECEIVED;
            case Status.FINISHED:
                return Styles.FINISHED;
            case Status.ACCEPTED:
                return Styles.ACCEPTED;
            case Status.REFUNDED:
                return Styles.REFUNDED;
            case Status.CLOSED:
                return Styles.CLOSED;
            case Status.REJECTED:
                return Styles.REJECTED;
            case Status.REFUND_FAILED:
                return Styles.REFUND_FAILED;
            case Status.PARTIALLY_REFUNDED:
                return Styles.PARTIALLY_REFUNDED;
            case Status.PROCESSING_REFUND:
                return Styles.PROCESSING_REFUND;
            case Status.READY_FOR_COLLECTION:
                return Styles.READY_FOR_COLLECTION;
            case Status.PAYMENT_PROCESSING:
                return Styles.PAYMENT_PROCESSING;
            case Status.ORDER_ONLY:
                return Styles.ORDER_ONLY;
            case Status.DECLINED:
                return Styles.DECLINED;
            case Status.DISPUTED:
                return Styles.PLACED;
            default:
                return undefined;
        }
    };
    return (
        <>
            {status === Status.VOID || (status === Status.DECLINED && condition) ? (
                <div className={`${Styles.orderStatusContainer} ${Styles.REFUNDED}`}>
                    <p data-component-name="orderStatus">{(t(`OrderTypes.refunded`) as string).toUpperCase()}</p>
                </div>
            ) : (
                <div className={`${Styles.orderStatusContainer} ${renderStatus(status)}`}>
                    <p>{(t(`OrderTypes.${status.toLowerCase()}`) as string).toUpperCase()}</p>
                </div>
            )}
        </>
    );
}
export default React.memo(OrderStatus);
