export const onKeyboardPressHandler = (event) => {
    if (event.key === 'Enter') {
        blurKeyBoard(event.currentTarget);
    }
};

export const blurKeyBoard = (document) => {
    if (document && document.blur && typeof document.blur === 'function') {
        document.blur();
    }
};
