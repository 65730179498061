import { DiscountType } from '../Components/Payment/Dsicounts/DiscountsComponent';
import { PromotionType } from '../Components/Payment/types';

declare enum SelectedOrderType {
    CLICK = 'COLLECTION',
    TABLE = 'TABLE_DELIVERY',
    DELIVERY = 'HOME_DELIVERY',
}

declare interface IDeliveryAddress {
    apartment: string;
    address: string;
    city: string;
    postcode: string;
}

export enum OrderOption {
    ORDER_AND_PAY = 'ORDER_AND_PAY',
    ORDER_ONLY = 'ORDER_ONLY',
}

declare interface IOrderBody {
    venue_id: string;
    intent: boolean;
    comment: string;
    delivery_charge: string | null | number;
    gratuity: number;
    delivery_table: string;
    total_price: number;
    table_area: string | null;
    table_area_id: string | null;
    order_type: SelectedOrderType;
    takeaway_timeslot: number | null | string;
    venue_takeaway_timeslot: string | null;
    delivery_address: IDeliveryAddress;
    menu_items: IOrderBodyMenuItems[];
    group_id: string;
    cart_id: string;
    discount?: {
        id: string;
        type: DiscountType;
        value: number;
        code: string;
        metadata: null;
    };
    order_option: OrderOption;
    promotion?: PromotionType;
    metadata?: any;
    delivery_distance: number;
    asap_delivery_requested?: boolean;
}
declare interface IPlaceOrder {
    status: number;
    message: string;
    data: {
        order_reference_number: string;
        payment_intent_key: string;
        order_id: string;
    };
}

export interface IErrorPlaceOrder {
    data: {
        errors: Record<string, string | number>[];
    };
}

declare interface IOrderBodyMenuItems {
    //adding menu id if the order is placed by specific menu url
    menu_id?: string;
    menu_item_id: string;
    quantity: number;
    pricing_option_key: string;
    extras: {
        //adding menu id if the order is placed by specific menu url
        menu_id?: string;
        extra_id: string;
        extra_pricing_option_key: string;
        quantity: number;
    }[];
    modifiers: any[];
    preferences: Record<string, string>[];
}
