export function generateExtraPricingOptionName(ExtraName: string, ExtraPricingOptionName: string) {
    if (ExtraName) {
        return `${ExtraName} ${
            ExtraPricingOptionName && ExtraName.toLocaleLowerCase() !== ExtraPricingOptionName.toLocaleLowerCase()
                ? `(${ExtraPricingOptionName})`
                : ''
        }`;
    } else {
        return '';
    }
}
