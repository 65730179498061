import React, { useState } from 'react';
import Styles from '../groupPage.module.scss';
import ReactTextMoreLess from 'react-text-more-less';
export default function GroupDescription({ description }) {
    const [expanded, setExpanded] = useState(true);

    return (
        <div className={Styles.GroupDescription}>
            <ReactTextMoreLess
                collapsed={expanded}
                text={description}
                lessHeight={50}
                showMoreText="... more"
                showMoreElement={
                    <span>
                        ... <span className={Styles.ShowMoreText}>more</span>
                    </span>
                }
                showLessElement={<span className={Styles.ShowMoreText}> less</span>}
                onClick={() => {
                    setExpanded(!expanded);
                }}
            />
        </div>
    );
}
