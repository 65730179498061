import React from 'react';
import ProductPrice from './ProductPrice';
import Styles from '../styles.module.scss';
import makeStyles from '@material-ui/styles/makeStyles';
import useColors from '../../../customHooks/useColors';

interface IProductPriceOptionProps {
    isSelected: boolean;
    pricingOptions: IPricingOptions;
    onClick: () => void;
}

function ProductPriceOption({ isSelected, pricingOptions, onClick }: IProductPriceOptionProps) {
    const { primaryBackgroundColor, secondaryLowerOpacityButtonColor, primaryBorderColor } = useColors();

    const useStyles = makeStyles({
        PricingOptionDiv: {
            background: '#f9f9f9',
            borderRadius: '0.875rem',
            borderBottom: '0.70775px solid #ffffff',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            padding: '0.875rem 1.5rem',
            cursor: 'pointer',
        },
        PricingOptionDivSelected: {
            background: `linear-gradient(0deg, ${secondaryLowerOpacityButtonColor}, ${secondaryLowerOpacityButtonColor}), #f9f9f9 !important`,
            border: `1px solid ${primaryBorderColor} !important`,
            boxSizing: 'border-box',
            '&>p': {
                fontWeight: 600,
            },
            '&>.PricingOptionCurrentPrice': {
                fontWeight: 600,
            },
        },
        SelectedOptionLine: {
            background: primaryBackgroundColor,
            borderRadius: '0.75rem',
            width: '0.25rem',
            marginRight: '-0.75rem',
            marginLeft: '0.625rem',
            height: '1.25rem',
        },
    });

    const classes = useStyles();
    return (
        <div
            data-component-name="ProductPricingOption"
            className={`${classes.PricingOptionDiv} ${isSelected ? classes.PricingOptionDivSelected : ''}`}
            onClick={onClick}
        >
            <p
                className={Styles.PricingOptionName}
                data-component-name={isSelected ? 'ProductPricingOptionSelected' : undefined}
            >
                {pricingOptions.option_name}{' '}
                {pricingOptions.option_discount ? `(${pricingOptions.option_discount}% off)` : ''}
            </p>
            <div className="Flex AlignCenter">
                <ProductPrice {...pricingOptions} />
                {isSelected && <div className={classes.SelectedOptionLine}></div>}
            </div>
        </div>
    );
}

export default React.memo(ProductPriceOption);
