import { repeatCart } from '../actions/ActionTypes';
import { IAddCartItem } from './Cart';

interface ICartAction {
    type: string;
    data?: PayloadTypes;
}

type PayloadTypes = IAddCartItem[];

function RepeatCart(state: IAddCartItem[] = [], action: ICartAction) {
    switch (action.type) {
        case repeatCart:
            state = action.data;
            return state;
        default:
            return state;
    }
}
export default RepeatCart;
