import { useTranslation } from 'react-i18next';
import { LOGIN } from '../../../constants/URL.const';
import { APIError } from '../../../utils/APIErrorController';
import { usePaymentState } from '../PaymentStore';
import { useMultiVendorBasketView } from '../useMultiVendorBasketView';
import {
    ConsumerUpdatePreferences,
    CreateMultiVendorOrder,
    GuestCreateMultiVendorOrder,
    getConsumer,
} from '../../../Api.js';
import { setIsLoading, setIsPaymentDrawerOpen } from '../reducer/PaymentActions';
import { useGetAvailableMenus } from './useGetAvailableMenus';
import useGenerateMultiVendorOrderBody from './useGenerateMultiVendorOrderBody';
import usePaymentView from './usePaymentView';
import { useHistory } from 'react-router-dom';
import useOnSuccessfulOrder from './useOnSuccessfulOrder';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import useGuestUserData from '../../../customHooks/useGuestUserData';
import useCompleteOrder from './useCompleteOrder';
import { useSentry } from '../../../customHooks/useSentry';
import { HTTPMethods } from '../../../yoello-lib/modules/API/API';
import { useSelector } from 'react-redux';

const useCompleteMultiOrderBasket = () => {
    const {
        isLoading,
        dispatch,
        basket,
        selectedOrderType,
        areaName,
        selectedTable,
        orderOnly,
        retrieveToken,
        selectedPreference,
    } = usePaymentState();
    const { generateOrderBody } = useGenerateMultiVendorOrderBody();
    const { checkMultiOrderMenusAvailability } = useGetAvailableMenus();
    const Group = useSelector((state: any) => state.Group);
    const { onOrderFailure } = useCompleteOrder();

    const { isAuthenticated } = useAuthenticationContext();
    const { consumerId, guestId, isGuest, guestUpdatePreferences, getGuestUser } = useGuestUserData();
    const { backFunction, callPlacedMultiOrdersAPI } = usePaymentView();
    const history = useHistory();
    const { t } = useTranslation();
    const { onSuccessfulMultiOrder } = useOnSuccessfulOrder();
    const { itemsInCartGroupedByVenueId } = useMultiVendorBasketView();
    const { captureException } = useSentry();
    const cartID = useSelector((state: any) => state.CartID);

    const completeMultiOrder = async (isUsingPaymentRequest?: boolean) => {
        let user;
        let userToken;
        let createOrderObject;
        try {
            dispatch(setIsLoading(true));
            const orders = [];
            for (let key of Object.keys(itemsInCartGroupedByVenueId)) {
                const item = basket[key];
                const orderBody = {
                    ...generateOrderBody(
                        isUsingPaymentRequest,
                        item.cartItemTaxAmount,
                        item.serviceTaxAmount,
                        item.message,
                        selectedOrderType,
                        item.tipTotal,
                        areaName,
                        selectedTable?.name,
                        selectedTable?.tableAreaId,
                        item.totalPrice,
                        item.discounts,
                        orderOnly,
                        itemsInCartGroupedByVenueId[key],
                        key,
                    ),
                };
                const { disabledItems } = await checkMultiOrderMenusAvailability(orderBody, key);
                if (disabledItems.length > 0) {
                    backFunction(disabledItems);
                    return;
                } else {
                    orders.push(orderBody);
                }
            }
            if (isGuest && !isAuthenticated) {
                user = await getGuestUser();
            } else {
                userToken = await retrieveToken();
                user = (await getConsumer(userToken)).data.data;
            }
            if (selectedOrderType === 'COLLECTION_POINT_PICKUP') {
                const originallySelectedPreference = user?.consumer_preferences?.notify_by_email ? 'email' : 'sms';
                if (selectedPreference && selectedPreference !== originallySelectedPreference) {
                    if (isGuest && !isAuthenticated) {
                        await guestUpdatePreferences({
                            notify_by_sms: selectedPreference === 'sms',
                            notify_by_email: selectedPreference === 'email',
                        });
                    } else {
                        if (!isGuest && userToken) {
                            await ConsumerUpdatePreferences(
                                {
                                    notify_by_sms: selectedPreference === 'sms',
                                    notify_by_email: selectedPreference === 'email',
                                },
                                userToken,
                            );
                        }
                    }
                }
            }
            const { error, groupOrderId, message } = await getGroupOrderId(user.id);
            if (error) {
                throw new Error(message);
            }
            const body = {
                group_order_id: groupOrderId,
                venue_group_id: Group.id,
                cart_id: cartID,
                orders: [...orders],
            };
            if (isGuest && !isAuthenticated) {
                createOrderObject = await GuestCreateMultiVendorOrder({
                    ...body,
                    consumer_id: consumerId,
                    guest_session_id: guestId,
                });
            } else {
                createOrderObject = await CreateMultiVendorOrder(body, userToken);
            }
            if (isUsingPaymentRequest) {
                return {
                    orders: createOrderObject.data.data,
                    group_order_id: groupOrderId,
                };
            } else {
                onSuccessfulMultiOrder({
                    orders: createOrderObject.data.data,
                    group_order_id: groupOrderId,
                });
            }
        } catch (error) {
            captureException(error, { feature: 'complete-order' });
            let errMessage = t(error.message ?? 'Payment.Errors.CreateOrderError') as string;
            if (error.response) {
                const status = error.response.data.status;
                if (status >= 400 && status < 500) {
                    if (status === 401) {
                        localStorage.removeItem(`user_token_${window.location.host}`);
                        history.push(LOGIN);
                    } else {
                        const errors = error.response.data.data.errors;
                        errMessage = APIError(errors, t);
                    }
                }
            }
            onOrderFailure(errMessage);
            dispatch(setIsPaymentDrawerOpen(false));
        } finally {
            dispatch(setIsLoading(false));
        }
        return false;
    };

    async function getMultiVendorPlacedOrderPaymentIntent(
        orderId: string,
        isGuest?: boolean,
        consumerId?: string,
        guestId?: string,
    ): Promise<{
        intent: string;
        error: boolean;
    }> {
        const intent = await callPlacedMultiOrdersAPI(HTTPMethods.POST, {
            query: `venue_group_orders/${isGuest ? 'guest/' : ''}${orderId}/payments`,
            body: isGuest
                ? {
                      consumer_id: consumerId,
                      guest_session_id: guestId,
                  }
                : undefined,
        });
        if (intent.isSuccessful && intent.data) {
            return {
                intent: intent.data,
                error: false,
            };
        }
        return {
            intent: intent?.data ?? '',
            error: true,
        };
    }

    async function getGroupOrderId(consumer_id?: string) {
        const query = isGuest && !isAuthenticated ? 'venue_group_orders/guest' : 'venue_group_orders';
        
        const body =
            isGuest && !isAuthenticated
                ? {
                      venue_group_id: Group.id,
                      consumer_id: consumerId,
                      guest_session_id: guestId,
                  }
                : {
                      venue_group_id: Group.id,
                      consumer_id: consumer_id,
                  };
        const { data, isSuccessful } = await callPlacedMultiOrdersAPI(HTTPMethods.POST, {
            query: query,
            body: body,
        });
        if (data && isSuccessful) {
            return {
                groupOrderId: data?.id as string,
                message: '',
                error: false,
            };
        } else {
            const errorResponse = data?.response?.data?.data;
            if (errorResponse && Array.isArray(errorResponse)) {
                if (errorResponse[0].code === 'VENUE_GROUP_FOUND') {
                    return {
                        groupOrderId: '',
                        message: 'ApiErrors.VENUE_GROUP_FOUND',
                        error: true,
                    };
                }
            }
        }

        return {
            groupOrderId: '',
            message: 'Payment.Errors.CreateOrderError',
            error: true,
        };
    }

    return {
        completeMultiOrder,
        getMultiVendorPlacedOrderPaymentIntent,
        isLoading,
    };
};

export default useCompleteMultiOrderBasket;
