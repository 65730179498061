export function getPageTitle(menuItem: IMenuItem, isShownTags: boolean, extras: any) {
    const { menu_item_preference_ids, menu_item_name_public, menu_item_extras_groups_ids } = menuItem;
    const preferenceGreater = menu_item_preference_ids?.length > 0;
    const positions = [];

    // add allergen title if needed
    if (isShownTags) positions.push('buttons.Allergens');
    // add menu item
    positions.push(menu_item_name_public);
    // add preferences if needed
    if (preferenceGreater) positions.push('Menu.Preferences');
    // add any in stock extra pages
    menu_item_extras_groups_ids.forEach((extraId) => {
        const extra = extras[extraId];
        const numberOfItemsInStock = extra?.menu_item_extra_group_item_id_list?.length ?? 0;
        const minQuanity = extra?.menu_item_extra_group_quantity_min ?? 0;

        if (numberOfItemsInStock || (!numberOfItemsInStock && minQuanity > 0)) {
            positions.push(extra?.menu_item_extra_group_name_public);
        }
    });
    // add the confirmation page
    positions.push('Menu.Confirmation');

    return positions;
}
