import { ITax } from '../yoello-lib/modules/interfaces/ITaxes';

export function countCartTotalItems(cartItems: any[]) {
    let counter = 0;
    cartItems.forEach((item) => {
        counter += item.itemCount;
    });
    return counter;
}

export function itemTotalCount(menuItem: IMenuItem, pricingOptionName: string, itemCount: number) {
    const pricingOption = menuItem.menu_item_pricing_options[pricingOptionName];
    if (pricingOption.option_bulk_qty) {
        return pricingOption.option_bulk_qty * itemCount;
    } else {
        return itemCount;
    }
}

/**Generates an object to be used by the cart */
export function generateItemsForCart(
    Extras: ISelectedCartExtras,
    Preferences: Record<string, string>,
    priceOptionName: string,
    price: number,
    productInfo: IMenuItem,
    count: number,
    menuItemAsExtra,
    venueId: string,
) {
    const extrasPrice = generateExtrasPrice(Extras, menuItemAsExtra);
    return {
        menuItem: productInfo,
        itemCount: count,
        price: price,
        measure: priceOptionName,
        preferences: Preferences,
        extras: Extras,
        extrasPrice: extrasPrice,
        venueId,
    };
}

function generateExtrasPrice(Extras: ISelectedCartExtras, menuItemAsExtras) {
    let extrasPrice = 0;
    for (const keys in Extras) {
        const extrasObj = Extras[keys];
        for (const extras of extrasObj) {
            const menuItems = menuItemAsExtras[extras.id];
            const priceOps = menuItems?.extra_pricing_options_extras[extras.priceName];
            if (priceOps) {
                extrasPrice =
                    extrasPrice +
                    getAfterDiscountPrice(priceOps.option_price, priceOps.option_discount) * (extras.count ?? 1);
            }
        }
    }
    return extrasPrice;
}

export function generateItemTaxes(menuItem: IMenuItem, Taxes: Record<string, ITax>) {
    const menu_item_taxes = [];
    if (menuItem?.menu_item_tax_ids?.length > 0) {
        menuItem.menu_item_tax_ids.forEach((id: string) => {
            if(Taxes[id]){
                menu_item_taxes.push({
                    tax_inclusion_type: Taxes[id].tax_inclusion_type,
                    tax_percentage: Taxes[id].tax_percentage,
                });
            }
        });
    }
    return menu_item_taxes;
}

const getAfterDiscountPrice = (price: number, discount: number) => {
    if (discount) {
        return price - (price * discount) / 100;
    }
    return price;
};
