import { Suspense, lazy } from 'react';
import { MenuProvider } from './MenuContext';
import { MenuItemsNavigation, MenusListLoading } from '../Widgets/Loading/MenuItemsPage';
import Styles from './styles.module.scss';
import smoothScroll from 'smoothscroll-polyfill';
import './home.css';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CART, CONFIRM } from '../../constants/URL.const';
import ShoppingCart from '../ShopList/ShoppingCartList';
import ConfirmOrderContainer from './ConfirmOrderContainer';

const MenuContents = lazy(() => import('./MenuContents'));
const MenuNavigation = lazy(() => import('./UI/MenuNavigation'));
const CartButtons = lazy(() => import('./UI/CartButtons'));

export function MenuContainer({ openSideDrawer, area }) {
    let { path } = useRouteMatch();
    smoothScroll.polyfill();

    return (
        <MenuProvider>
            <div className={Styles.MenuContainer}>
                <Suspense fallback={<MenuItemsNavigation />}>
                    <MenuNavigation openSideDrawer={openSideDrawer} />
                </Suspense>
                <Suspense fallback={<MenusListLoading />}>
                    <MenuContents />
                </Suspense>
                <div className={Styles.FixedCheckout} data-component-name="cart-button-wrapper">
                    <Suspense fallback={null}>
                        <CartButtons checkoutBtnLabel={'ViewCart'} />
                    </Suspense>
                </div>
            </div>
            <Switch>
                <Route exact path={`${path}${CONFIRM}`}>
                    <ConfirmOrderContainer />
                </Route>
                <Route exact path={`${path}${CART}`}>
                    <ShoppingCart area={area} />
                </Route>
            </Switch>
        </MenuProvider>
    );
}
