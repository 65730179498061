import { ComponentType, useEffect, useState } from 'react';

import { IPageComponentProps } from './types';
import { IMessageOptions } from '../../yoello-lib/modules/API2/types';
import { HTTPMethods } from '../../yoello-lib/modules/API/API';

import PageComponent from './PageComponent';
import { useManageQrQueryParams } from '../../customHooks/useManageQrQueryParams';

import useAPI from '../../yoello-lib/modules/API/useAPI';
import APIExtensions from '../../yoello-lib/modules/API/APIDefs';
import { useSpecificMenuURL } from '../Venue/hooks/useSpecificMenuURL';
import { useSelector } from 'react-redux';

interface IPageContainerProps extends IPageComponentProps {
    ChildComponent: ComponentType<any>;
    LoadingComponent: ComponentType<any>;
    apiConfig: IMessageOptions;
    errorFunction: Function;
}

function VenuePageContainer({
    ChildComponent,
    retrieveToken,
    apiConfig,
    LoadingComponent,
    errorFunction,
    ...props
}: IPageContainerProps) {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const Venue = useSelector((state: any) => state.Venue);
    const { isSpecificMenu, menu, isMenuAvailable, isMenuPageLoading } = useSpecificMenuURL(Venue, true);

    const { isError, callAPI } = useAPI(APIExtensions.consumerVenue, retrieveToken, {
        ...apiConfig,
        canAPIGetCacheValue: false,
        doNotCacheValue: true,
        onMountDisabled: true,
    });

    useManageQrQueryParams();

    useEffect(() => {
        setIsPageLoading(true);
        callAPI(HTTPMethods.GET, {
            ...apiConfig,
        }).then(() => {
            setIsPageLoading(false);
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (isError === true && typeof errorFunction === 'function') {
            errorFunction();
        }
        //eslint-disable-next-line
    }, [isError]);

    const ChildComponentProps = {
        ...props,
        menu,
        isMenuAvailable,
        isSpecificMenu,
    };

    return isPageLoading || isMenuPageLoading ? <LoadingComponent /> : <ChildComponent {...ChildComponentProps} />;
}

export default PageComponent(VenuePageContainer as ComponentType);
