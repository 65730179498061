import { LANGUAGE_SET, CURRENCY_SET, CURRENCIES_SET } from '../consts';

export interface ILanguage {
    english_name: string;
    id: string;
    iso_code: string;
}

export interface ICurrency {
    id: string;
    iso_code: string;
    symbol: string;
    english_name: string;
}
export type ReduxAction = {
    type: string;
    payload: ICurrency[] | string | null | undefined;
};

export const setLanguage = (language: string | null | undefined): ReduxAction => ({
    type: LANGUAGE_SET,
    payload: language,
});

export const setCurrency = (currency: string | null | undefined): ReduxAction => ({
    type: CURRENCY_SET,
    payload: currency,
});

export const setCurrencies = (currencies: ICurrency[] | undefined | null): ReduxAction => ({
    type: CURRENCIES_SET,
    payload: currencies,
});
export const SET_LANGUAGES = 'SET_LANGUAGES';

export const setLanguages = (languages: ILanguage[]) => ({
    type: SET_LANGUAGES,
    payload: languages,
});
