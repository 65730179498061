export interface ISessionData {
    id: string;
    consumer_id: string;
    created_on: string;
    updated_on: string;
}
export enum GuestUser {
    ConsumerID = '_coId',
    GuestID = '_guId',
}
