import Styles from './shopList.module.scss';
import { useMenu } from '../Menu/MenuContext';
import { useShopList } from './useShopList';
import { useCartDrawer } from './useCartDrawer';
import { CartHeader } from './display/CartHeader';
import { CartBody } from './display/CartBody';
import { CartFooter } from './display/CartFooter';

interface IShoppingCartListProps {
    area: boolean;
}

function ShoppingCartList({ area }: IShoppingCartListProps) {
    const {
        state: { cartAmount, cartCount },
    } = useMenu();
    const { isOpen, onConfirmationClick, onOpenCart } = useShopList(area);
    const { handleTouchEnd, handleTouchStart } = useCartDrawer(onOpenCart);

    return (
        <div className={Styles.shoppingCartContainer} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            {
                <div
                    className={`${Styles.shoppingCartDrawer} ${isOpen && Styles.openCart}`}
                    id="shoppingCartDrawer"
                    data-compoennt-name={'shoppingCartDrawer'}
                >
                    <div className={Styles.myCart}>
                        <div className={Styles.shopListContainer} id="shopListContainerId">
                            <CartHeader onOpenCart={onOpenCart} />
                            <CartBody onOpenCart={onOpenCart} cartCount={cartCount} isOpen={isOpen} />
                            <CartFooter cartAmount={cartAmount} onConfirmationClick={onConfirmationClick} />
                        </div>
                    </div>
                </div>
            }
            <div
                id="backgroundMaskId"
                className={`${Styles.drawerMask} ${isOpen && Styles.maskBackground}`}
                onClick={onOpenCart}
            ></div>
        </div>
    );
}

export default ShoppingCartList;
