/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { checkMethodAndGetData, generateErrorMessage, getSelectorKey, resolveAPIMethod } from './utils';
// import { useAuth0 } from '../auth/Auth0';
import API, { HTTPMethods } from '../API/API';
import { useDispatch, useSelector } from 'react-redux';
import { APIExtensions, IAPIReturnObject, IChildrenDefinitions, IMessageOptions, IReturnData } from './types';
import { useSnackbarMessages } from '../components/snackbar/SnackbarContext';
import { useAuthenticationContext } from '../auth/AuthenticationContext';

/**
 * hook for performing api calls with the ability to store straight into redux
 * @param extension The API thats to be used
 * @param messageOptions The configuration object
 */
export function useAPIV2<T>(extension: APIExtensions, messageOptions: IMessageOptions) {
    const { retrieveAccessToken } = useAuthenticationContext();

    const { sendSnackbarMessage } = useSnackbarMessages()!;
    const dispatch = useDispatch();

    const {
        dispatchFunction,
        selectKey,
        baseURL,
        setDataTransform,
        childrenAPI,
        //eslint-disable-next-line
    } = useMemo(() => resolveAPIMethod(extension, messageOptions), [extension]);
    /**Keep the api object in memory   */

    const api = useMemo(
        () => new API(retrieveAccessToken, baseURL),
        // eslint-disable-next-line
        [],
    );

    const selectorState = useSelector((state: any) => getSelectorKey(state, selectKey)) as T | undefined;
    /**state */
    const isComponentMounted = useRef(true);
    const [data, setData] = useState<IReturnData<T>>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (api) {
            /**  If the onMount is disabled then don't run onMount */
            if (!messageOptions.onMountDisabled) {
                /**If we already have the data saved in redux, return that */
                if (selectorState && !messageOptions?.doNotCacheValue) {
                    setData(selectorState);
                    setIsLoadingState(false);
                } else {
                    callAPI(HTTPMethods.GET);
                }
            }
        }
        return () => {
            isComponentMounted.current = false;
            if (api) {
                api.cancelCurrentRequest();
            }
        };
        //eslint-disable-next-line
    }, []);
    /**
     * calls the API can either return you back cache'd data or will recall the API with the ability to stash into redux
     * @param method HTTP Method
     * @param msgOptions if you want to override send through options otherwise uses the options from initalise
     */
    const callAPI = useCallback(
        (httpMethod: HTTPMethods, msgOptions?: IMessageOptions) => {
            function setDataValue(apiOptions: IMessageOptions, result: T) {
                return setDataTransform && !apiOptions.overrideSetData ? setDataTransform(result) : result;
            }
            async function makeCall() {
                const returnObject: IAPIReturnObject<T> = {
                    isSuccessful: false,
                    data: null,
                };
                /**If no longer mounted we return back */
                if (!isComponentMounted.current) {
                    return returnObject;
                }
                /** Creates the params object */
                const params = {
                    ...messageOptions?.params,
                    ...messageOptions?.yoelloAPIParams,
                    ...msgOptions?.params,
                    ...msgOptions?.yoelloAPIParams,
                };
                /**Combines the options overriding the original message options if needed*/
                const apiOptions: IMessageOptions = {
                    /**Default sets the api version to v1 */
                    apiVersion: 'v2',
                    ...messageOptions,
                    ...msgOptions,
                    params,
                };
                /**Checks if you can get the item from cache if available returns the cached data */
                if (msgOptions?.canAPIGetCacheValue && selectorState !== undefined && httpMethod === HTTPMethods.GET) {
                    const cacheData = setDataValue(apiOptions, selectorState);
                    return { data: cacheData, isSuccessful: true };
                }
                if (!isLoading) {
                    setIsLoadingState(true);
                }
                if (apiOptions?.beforeCall) {
                    sendSnackbarMessage(apiOptions?.beforeCall, 'info');
                }
                try {
                    const result = await makeHTTPRequest(httpMethod, extension, apiOptions, childrenAPI);
                    const data = setDataValue(apiOptions, result);
                    if (isComponentMounted.current) {
                        setData(data);
                        if (dispatchFunction && !apiOptions?.disableDispatcher) {
                            dispatchFunction(dispatch, result, apiOptions?.dispatcherParams);
                        }
                        const httpMethodShowsSuccess =
                            msgOptions?.successCallMethods?.includes(httpMethod) ||
                            msgOptions?.successCallMethods === undefined;
                        if (apiOptions?.successCall && httpMethodShowsSuccess) {
                            sendSnackbarMessage(apiOptions?.successCall, 'success');
                        }
                    }
                    returnObject.data = data;
                    returnObject.isSuccessful = true;
                    return returnObject;
                } catch (error) {
                    if (isComponentMounted.current) {
                        setIsError(true);
                        /**If errorCall is provided then will generate an api message */
                        if (apiOptions.errorCall) {
                            //@ts-ignore
                            const message = generateErrorMessage(apiOptions.errorCall, error);
                            sendSnackbarMessage(message, 'error');
                        }
                    }
                    returnObject.isSuccessful = false;
                    //@ts-ignore
                    returnObject.data = error;
                    return returnObject;
                } finally {
                    setIsLoadingState(false);
                }
            }
            return makeCall();
            // eslint-disable-next-line
        },
        [isComponentMounted, isLoading, selectorState, dispatchFunction, dispatch, extension, childrenAPI],
    );

    /**Memo'd function for making the http request */
    const makeHTTPRequest = useCallback(
        (
            httpMethod: HTTPMethods,
            extension: APIExtensions,
            apiOptions: IMessageOptions,
            childrenAPI?: IChildrenDefinitions<T>[],
            baseURL?: string,
        ) => {
            async function makeRequest() {
                /**Generates the API Data */
                const { url, disabledFields } = checkMethodAndGetData(httpMethod, extension, apiOptions);

                let { data: result, count } = await api.makeHTTPRequest<any, T>(
                    url,
                    httpMethod,
                    apiOptions,
                    disabledFields,
                    baseURL,
                );

                /**If api options permit and they have children APIs */
                if ((apiOptions.getChildrenData && childrenAPI) || apiOptions.isPaginated) {
                    const workload = [];
                    if (apiOptions.getChildrenData && childrenAPI) {
                        for (const api of childrenAPI) {
                            let childMessageOps = {
                                ...apiOptions,
                            };
                            const { childrenAPI, baseURL } = resolveAPIMethod(api.extension, childMessageOps);
                            /**pipe function to extend new api config*/
                            if (api.apiConfigFunction) {
                                const apiConfig = api.apiConfigFunction(result);
                                childMessageOps = {
                                    ...apiOptions,
                                    ...apiConfig,
                                };
                            }
                            workload.push(
                                makeHTTPRequest(httpMethod, api.extension, childMessageOps, childrenAPI, baseURL),
                            );
                        }
                        /**Wait for the workload to complete */
                        const workLoadComplete = await Promise.all(workload);
                        /**Reduce the results objects into one result object  */
                        result = workLoadComplete.reduce((prevItem, newItem, index) => {
                            const { key, setValue } = childrenAPI[index];
                            return {
                                ...prevItem,
                                [key]: setValue ? setValue(newItem) : newItem,
                            };
                        }, result);
                    } else {
                        /**If no minium is set then default to 30 */
                        const minimum = apiOptions.params?.size || 30;
                        /**IF we get a count header back from the API then use that
                         * otherwise If the result is an array and is the same length as the minium
                         * we are gonna assume that more data is available
                         */
                        if (Array.isArray(result) && result.length === minimum) {
                            if (count && typeof count === 'number') {
                                const totalPages = Math.round(count / minimum);
                                const workload = [];
                                for (let page = 2; page <= totalPages; page++) {
                                    workload.push(
                                        api
                                            .makeHTTPRequest<any, T>(extension, httpMethod, {
                                                ...apiOptions,
                                                params: {
                                                    ...apiOptions.params,
                                                    page,
                                                },
                                            })
                                            .then(({ data }) => data),
                                    );
                                }
                                const completedWorkload = await Promise.all(workload);
                                result.push(...completedWorkload);
                            } else {
                                /**
                                 * If no page is set then set up the page param
                                 * default to 2 because at this point we would have atleast one run
                                 * and that 2 will be passed down to the children
                                 */
                                const page = apiOptions?.params?.page ? apiOptions?.params?.page + 1 : 2;

                                const res = await makeHTTPRequest(httpMethod, extension, {
                                    ...apiOptions,
                                    params: { ...apiOptions.params, page },
                                });
                                if (Array.isArray(res)) {
                                    result.push(...res);
                                }
                            }
                        }
                    }
                }
                return result;
            }
            return makeRequest();
        },
        [api],
    );

    const setIsLoadingState = useCallback(
        (isLoading: boolean) => {
            if (isComponentMounted.current) {
                setIsLoading(isLoading);
            }
        },
        [isComponentMounted],
    );

    return {
        isError,
        apiData: data,
        callAPI,
        isLoading,
    };
}
