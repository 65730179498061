import React from 'react';
import DrawerHeader from '../../Widgets/DrawerHeader/DrawerHeader';
import UnmountDrawerWrapper from '../UnmountDrawerWrapper';
import Styles from './customDrawer.module.scss';
import { IDrawer } from './types.d';
export default function Drawer({ isOpen, setIsOpen, children, title, className }: IDrawer) {
    return (
        <>
            <div className={`${Styles.DrawerOpen} ${className} ${isOpen && Styles.openCart} `}>
                <DrawerHeader title={title} closeDrawer={() => setIsOpen(false)} />
                {children}
            </div>
            <div
                id="backgroundMaskId"
                className={`${Styles.drawerMask} ${isOpen && Styles.maskBackground}`}
                onClick={() => setIsOpen(false)}
            ></div>
            {isOpen ? (
                <UnmountDrawerWrapper
                    closeDrawer={() => {
                        setIsOpen(false);
                    }}
                >
                    <></>
                </UnmountDrawerWrapper>
            ) : null}
        </>
    );
}
