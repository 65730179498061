import React, { useEffect, useState } from 'react';
import Styles from '../styles.module.scss';
import { useTranslation } from 'react-i18next';
import { getPageTitle } from './getPageTitle';

interface IPageTitleProps {
    position: number;
    menuItem: IMenuItem;
    isShownTags: boolean;
    extras: any;
}

function PageTitle({ position, isShownTags, menuItem, extras }: IPageTitleProps) {
    const [pageTitles, setPageTitles] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
        setPageTitles(getPageTitle(menuItem, isShownTags, extras));
        //eslint-disable-next-line
    }, [isShownTags]);

    return (
        <p
            className={`${Styles.PageTitle} 
      ${position === 0 && Styles.productDetailsPageTitle}`}
        >
            {t(pageTitles[position])}
        </p>
    );
}

export default PageTitle;
