import Styles from './venueLoading.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';

interface ILoadingProps {
    isCardiffCastle: boolean;
}
const venueLandingPageLoader = ({ isCardiffCastle }: ILoadingProps) => (
    <div id="venue_container" className={Styles.venueContainer}>
        <VenueHeaderLoading hideLogo={false} />
        <div className={Styles.venueDetails}>
            <div className={Styles.venueContent}>
                <div className={Styles.venueNameAndStatus}>
                    <Skeleton />
                </div>
                <div className={Styles.addressName}>
                    <Skeleton />
                </div>
                <div className={Styles.description}>
                    <Skeleton />
                </div>
                {!isCardiffCastle && (
                    <div className={Styles.orderTypes}>
                        <Skeleton />
                    </div>
                )}
                <div className={Styles.AlcoholTextDiv}>
                    <Skeleton />
                </div>
                <div className={Styles.AlcoholTextDiv}>
                    <Skeleton />
                </div>
                <div className={Styles.venueTradingTimes}>
                    <Skeleton />
                </div>
                <VenueSharesLoading />
            </div>
        </div>
    </div>
);

export const VenueHeaderLoading = ({ hideLogo }: { hideLogo: boolean }) => (
    <div className={Styles.venueImage}>
        <div className={Styles.remote}>
            <Skeleton />
        </div>

        {!hideLogo && (
            <div className={Styles.logo}>
                <Skeleton />
            </div>
        )}
    </div>
);

export const VenueSharesLoading = () => (
    <div className={Styles.shareButtons}>
        {[...Array(4).keys()].map((index) => (
            <Skeleton key={`${index}-shareButtons`} />
        ))}
    </div>
);

export const VenueOrderTypesLoading = () => (
    <div className={Styles.VenueOrderTypes}>
        {[...Array(3).keys()].map((index) => (
            <Skeleton height={'5rem'} key={`${index}-VenueOrderTypes`} />
        ))}
    </div>
);

export const VenueContentLoading = () => (
    <>
        {[...Array(2).keys()].map((index) => (
            <Skeleton style={{ marginBottom: '1rem' }} key={`${index}-VenueConten`} />
        ))}
    </>
);

export default venueLandingPageLoader;
