import React from 'react';
import ICommonFormProps from '../../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps';
import Buttons from '../../Widgets/Button';

export interface IApplyButton extends ICommonFormProps {}
const ApplyButton = (props: IApplyButton) => {
    return (
        <Buttons
            type="submit"
            title={'Confirm'}
            isLoading={props.formProps?.isTryingToLogin}
            isYoelloBtn
            className="dark-theme-uninvert-svg-div"
            data-dataComponentName={'ConfirmButton'}
        />
    );
};

export default ApplyButton;
