const AvailableCountries = [
    {
        Note: 'Known as the postcode. The first letter(s) indicate the postal area, such as the town or part of London. Placed on a separate line below the city (or county, if used). The UK postcode is made up of two parts separated by a space. These are known as the outward postcode and the inward postcode. The outward postcode is always one of the following formats: AN, ANN, AAN, AANN, ANA, AANA, AAA. The inward postcode is always formatted as NAA. A valid inward postcode never contains the letters: C, I, K, M, O or V. The British Forces Post Office has a different system, but as of 2012 has also adopted UK-style postcodes that begin with "BF1" for electronic compatibility.',
        Country: 'United Kingdom',
        ISO: 'GB',
        Format: 'A(A)N(A/N)NAA (A[A]N[A/N] NAA)',
        Regex: '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
        // "([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))s?[0-9][A-Za-z]{2})",
    },
    {
        Note: 'The first two digits give the département number, while in Paris, Lyon and Marseille, the last two digits of the postal code indicates the arrondissement. Both of the 2 corsican départements use "20" as the first two digits. Also used by French overseas departments and territories. Monaco is also part of the French postal code system, but the country code MC- is used for Monegasque addresses.',
        Country: 'France',
        ISO: 'FR',
        Format: 'NNNNN',
        Regex: '^\\d{5}$',
    },
    {
        Note: 'The system was gradually introduced starting in April 1971 in Ottawa. The letters D, F, I, O, Q, and U are not used to avoid confusion with other letters or numbers.',
        Country: 'Canada',
        ISO: 'CA',
        Format: 'ANA NAN',
        Regex: '^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d$',
    },
    {
        Note: 'Known as the ZIP Code with five digits 99999* or the ZIP+4 Code with nine digits 99999-9999* (while the minimum requirement is the first five digits, the U.S. Postal Service encourages everyone to use all nine). Also used by the former US Pacific Territories: Federated States of Micronesia; Palau; and the Marshall Islands, as well as in current US territories American Samoa, Guam, Northern Mariana Islands, Puerto Rico, and the United States Virgin Islands. An individual delivery point may be represented as an 11-digit number, but these are usually represented by Intelligent Mail barcode or formerly POSTNET bar code.',
        Country: 'United States',
        ISO: 'US',
        Format: 'NNNNN (optionally NNNNN-NNNN)',
        Regex: '^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$',
    },
    {
        Note: 'Postcodes were originally intended for bulk mailing and were not needed for addressing individual items. However, new post codes for general use were phased in from June 2006 and came into force by July 2008.',
        Country: 'New Zealand',
        ISO: 'NZ',
        Format: 'NNNN',
        Regex: '^\\d{4}$',
    },
    {
        Note: 'In general, the first digit identifies the state or territory.',
        Country: 'Australia',
        ISO: 'AU',
        Format: 'NNNN',
        Regex: '^\\d{4}$',
    },
    {
        Note: 'Currently no postal codes; however, Dublin is divided into postal districts on syntax Dublin 9. A national post code system is planned. See also Republic of Ireland postal addresses.',
        Country: 'Ireland',
        ISO: 'IE',
        Format: '- no codes -',
        Regex: '(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$',
    },
    {
        Note: '',
        Country: 'Malta',
        ISO: 'MLT',
        Format: '- 3 x letters followed by 1 x space and 4 x numbers eg GRB 1021, VLT 1430 -',
        Regex: '^[A-Z]{3}[ ]?\\d{3,4}$',
    },
];

export function PostCodeValidator(postCode: string) {
    let flag = false;
    AvailableCountries.forEach((element) => {
        if (postCode.match(element.Regex)) {
            console.log('Post code match Country: ', element.Country);
            flag = true;
        }
    });
    return flag;
}
