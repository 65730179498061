import React from 'react';
import { useTranslation } from 'react-i18next';

interface ITextField {
    text: {
        key: string;
        options?: Record<string, any>;
    };
    className?: string;
    id?: string;
    onClick?: () => void;
    dataComponentName?: string;
}
export function TextField({ text, id, className, onClick, dataComponentName }: ITextField) {
    const { t } = useTranslation();
    return (
        <p
            className={className}
            id={id}
            onClick={onClick}
            data-componentc-name={dataComponentName ?? 'consumerTextField'}
        >
            {t(text.key, text.options)}
        </p>
    );
}
