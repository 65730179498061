import Button from '../../../Widgets/Button';
import { TextField } from '../../../Widgets/TextField';
import { OrderQRCode } from './OrderQRCode';
import Styles from './ordersQRCode.module.scss';
import { ReactComponent as CompleteQRCode } from '../../../../imges/CompleteQRCode.svg';
import { OrderTitle } from '../OrderTitle';
import { AlertStatusCard } from '../../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard';
import { ReactComponent as BlueInfoIconSVG } from '../../../../imges/info-circle-blue.svg';
import { useTranslation } from 'react-i18next';
import { IOrders } from '../../../../typings/IOrders.d';
import useColors from '../../../../customHooks/useColors';

interface IQRCodeDialogProps {
    orderItem: IOrders;
    closeDrawer: () => void;
}

export function QRCodeDialog({ orderItem, closeDrawer }: IQRCodeDialogProps) {
    const { t } = useTranslation();
    const { primaryIconColor } = useColors();

    return (
        <div className={Styles.qrCodeContainer}>
            <div className={Styles.lineBar} />
            <div className={Styles.TopBar}>
                <div>
                    <CompleteQRCode fill={primaryIconColor} />
                    <TextField text={{ key: 'OrderHistory.QRCode.header' }} />
                </div>
                <Button
                    onClick={closeDrawer}
                    dataComponentName="ExitButton"
                    iconClassName={'consumer-close'}
                    className={Styles.CloseButton}
                />
            </div>

            <div className={Styles.Content}>
                {orderItem.placed_order_status !== 'FINISHED' ? (
                    <div className={Styles.TopText}>
                        <TextField
                            className={Styles.QRSubtitle}
                            text={{
                                key: 'OrderHistory.QRCode.title',
                            }}
                        />
                        <p className={Styles.CollectionPoint}>
                            <b>{orderItem.collection_point_name}</b>
                        </p>
                    </div>
                ) : (
                    <TextField className={Styles.InfoText} text={{ key: 'OrderHistory.QRCode.info' }} />
                )}
                <div className={Styles.QRCode}>
                    {/* <Ring /> */}
                    <OrderQRCode id={orderItem.placed_order_id} />
                </div>
                <div className={Styles.TextContent}>
                    <OrderTitle
                        titleKey="OrderHistory.OrderId"
                        valueText={orderItem.placed_order_ref}
                        isLarge={true}
                        valueComponentName="OrderDetailsOrderID"
                    />
                </div>
                {orderItem.placed_order_status !== 'FINISHED' && (
                    <div className={Styles.QRCodeInfo}>
                        <TextField text={{ key: 'OrderHistory.QRCode.additionalInfo' }} />
                    </div>
                )}

                <div>
                    <AlertStatusCard
                        alertMessage={t('OrderHistory.QRCode.screenBrightnessText')}
                        alertProps={{
                            color: 'info',
                            icon: <BlueInfoIconSVG />,
                            style: {
                                margin: '0 1.5rem',
                                borderRadius: '14px',
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
