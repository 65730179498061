import { useCallback } from 'react';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import makeStyles from '@material-ui/styles/makeStyles';
import { useSnackbarMessages } from '../../../yoello-lib/modules/components/snackbar/SnackbarContext';
import useGetLoginParams from '../useGetLoginParams';
import { useTranslation } from 'react-i18next';

//Resend code footer text
const ResendCodeText = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { username } = useGetLoginParams();
    const { forgotPasswordSendCode } = useAuthenticationContext();
    const { sendSnackbarMessage } = useSnackbarMessages();

    const handleResend = useCallback(async () => {
        if (!username) {
            sendSnackbarMessage(t('ForgotPassword.Errors.missingEmail').toString(), 'error');
            return;
        }
        try {
            await forgotPasswordSendCode(username);
            sendSnackbarMessage(t('ForgotPassword.Texts.sendCodeSuccessful').toString(), 'success');
        } catch (err: any) {
            if (err.message.toLowerCase().includes('cannot reset password')) {
                sendSnackbarMessage(t('ForgotPassword.Errors.invalidEmail').toString(), 'error');
            } else if (err.message.toLowerCase().includes('username/client id combination not found')) {
                sendSnackbarMessage(t('ForgotPassword.Errors.existingSocialAccount').toString(), 'error');
            } else if (err.message.toLowerCase().includes('attempt limit exceeded')) {
                sendSnackbarMessage(t('ForgotPassword.Errors.attemptLimitExceeded').toString(), 'error');
            } else {
                sendSnackbarMessage(t('ForgotPassword.Errors.genericResetError').toString(), 'error');
            }
        }
        // eslint-disable-next-line
    }, [username, forgotPasswordSendCode, sendSnackbarMessage]);

    return (
        <div>
            <p className={`${classes.root}`}>
                {t('ForgotPassword.Texts.didntReceiveEmail')}
                <strong onClick={() => handleResend()}>{t('ForgotPassword.Texts.resendEmail')}</strong>
            </p>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.9375rem',
        lineHeight: '1.25rem',
        color: '#4F3CC9',
        margin: 0,
        '& strong': {
            cursor: 'pointer',
        },
    },
    disabled: {
        color: '#9e9e9e',
    },
});

export default ResendCodeText;
