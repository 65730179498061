import { DiscountType } from '../Payment/Dsicounts/DiscountsComponent';
import { IDiscountStoreData } from '../Payment/types';

export function calculateTotalDiscountAmount(discount: IDiscountStoreData, subTotalCost: number) {
    let totalDiscountAmount = 0;
    if (subTotalCost > 0) {
        if (discount.type === DiscountType.ABSOLUTE) {
            totalDiscountAmount = discount.value;
        } else if (discount.type === DiscountType.PERCENTAGE) {
            totalDiscountAmount = (discount.value * subTotalCost) / 100;
        }
    }
    return totalDiscountAmount;
}
