import { useEffect, useState } from 'react';
import { TextInput, ToggleInput, InfoCheckInput, PhoneInput } from '../../Inputs';
import type { InputRow } from '../../Inputs';
import { isValidPhoneNumber } from 'libphonenumber-js';
import FixedContinue from '../util/FixedContinue';

import IDynamicFormRow from '../../../yoello-lib/modules/dynamicForms/interfaces/IDynamicFormRow';
import { useAuthenticationContext } from '../../../yoello-lib/modules/auth/AuthenticationContext';
import TermsCondition from './TermsCondition';
import { useHistory } from 'react-router-dom';
import useGetToAndFromPaths from '../useGetToAndFromUrls';
import useGenerateNavPath from '../../../customHooks/useGenerateNavPath';
import { VENUE } from '../../../constants/URL.const';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const defaultFormElement: Partial<IDynamicFormRow> = {
    inputProperties: {
        variant: 'outlined',
    },
    gridColumnOptions: { formCol: 12 },
    registerOptions: {
        required: true,
    },
};

const generateFormRows = (countryCode: string, t: (key: string) => string) => {
    const inputRows: InputRow[] = [
        {
            keyName: 'first_name',
            Component: TextInput,
            registerOptions: {
                validate: (data: any) =>
                    data && data.trim().match(/^[a-z0-9,.'-_\s]+$/i) ? true : t('ProfileBuilder.errors.first_name'),
            },
        },
        {
            keyName: 'last_name',
            Component: TextInput,
            registerOptions: {
                validate: (data: any) =>
                    data && data.trim().match(/^[a-z0-9,.'-_\s]+$/i) ? true : t('ProfileBuilder.errors.last_name'),
            },
        },
        {
            keyName: 'contact_phone',
            Component: PhoneInput,
            gridColumnOptions: { formCol: 12 },
            formProps: {
                countryCode,
            },
            registerOptions: {
                validate: (data: any) => {
                    if (data) {
                        return isValidPhoneNumber(`+${data}`) ? true : t('ProfileBuilder.errors.contact_phone');
                    } else return t('ProfileBuilder.errors.contact_phone');
                },
            },
        },
        {
            keyName: 'contact_email',
            Component: TextInput,
            registerOptions: {
                validate: (data: any) =>
                    data && data.trim().match(/^[^\s@]+@[^\s@.]+\.[^\s@]+$/)
                        ? true
                        : t('ProfileBuilder.errors.contact_email'),
            },
        },
        {
            keyName: 'notification_method',
            Component: ToggleInput,
            formProps: {
                helperTxt: t('ProfileBuilder.helperText'),
            },
            inputOptions: {
                options: [
                    { value: 'sms', name: t('ProfileBuilder.sms') },
                    { value: 'email', name: t('ProfileBuilder.email') },
                ],
            },
        },
        {
            keyName: 'opt_out',
            Component: InfoCheckInput,
            inputOptions: {
                message: t('ProfileBuilder.opt_out'),
                defaultChecked: false,
            },
            registerOptions: {
                required: false,
            },
        },
        {
            keyName: 'profile_submit',
            formProps: {
                Component: TermsCondition,
                title: 'Continue',
                icon: 'consumer-right-arrow',
            },
            Component: FixedContinue,
        },
    ];
    return inputRows.map((row: InputRow) => {
        return {
            ...defaultFormElement,
            ...row,
            label:
                row.keyName === 'notification_method'
                    ? `${t(`ProfileBuilder.${row.keyName}`)} :`
                    : t(`ProfileBuilder.${row.keyName}`),
            Component: row.Component,
            formProps: row.formProps,
        } as IDynamicFormRow;
    });
};

const useGenerateProfileForm = () => {
    const { t } = useTranslation();
    const venue = useSelector((state: any) => state.Venue);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { verifyAuth } = useAuthenticationContext();
    const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
    const { generateNavPath } = useGenerateNavPath();
    const { toPath } = useGetToAndFromPaths('login', generateNavPath(VENUE), generateNavPath(VENUE));
    const history = useHistory();
    const [defaultData, setDefaultData] = useState<any>({});
    const [formRows, setFormRows] = useState<IDynamicFormRow[]>([]);

    useEffect(() => {
        async function checkIfUserSignedIn() {
            setIsLoading(true);
            const user = await verifyAuth();
            if (!user) {
                history.push(toPath);
                setIsLoading(false);
            } else {
                setDefaultData({
                    notification_method: 'sms',
                    contact_email: user.attributes?.email,
                });
                if (countryCode) {
                    setFormRows(generateFormRows(countryCode, t));
                }
            }
        }

        checkIfUserSignedIn().then(() => {
            setIsLoading(false);
        });
        // eslint-disable-next-line
    }, [countryCode]);
    useEffect(() => {
        if (Object.keys(venue).length) {
            setCountryCode(venue.address_country.toLowerCase() || 'gb');
        } else {
            setCountryCode('gb');
        }
        // eslint-disable-next-line
    }, [venue]);
    return {
        formRows,
        defaultData,
        isLoading: isLoading || !countryCode,
    };
};

export default useGenerateProfileForm;
