import React from 'react';
import { useWindowWidth } from '../../../../customHooks/useWindowWidth';
import globalStylesheet, { getClassNames } from '../styles';

function useGlobalStyles(duration: number, hideScrollbars: boolean) {
    const identifier = React.useMemo(() => Math.random().toString(36).substr(2), []);
    const width = useWindowWidth({ isResizeable: true });
    const classNames = React.useMemo(() => getClassNames(identifier), [identifier]);
    React.useEffect(() => {
        if (typeof document === 'undefined') {
            return;
        }
        const styles = globalStylesheet(identifier, width >= 768 ? true : false, {
            duration,
            hideScrollbars,
        });

        const tag = document.createElement('style');
        tag.setAttribute('Arash-react-drawer', identifier);
        tag.innerHTML = styles;

        document.head.appendChild(tag);

        return function () {
            const stylesheet = document.querySelector(`style[Arash-react-drawer='${identifier}']`);
            if (stylesheet) {
                stylesheet.remove();
            }
        };
        //eslint-disable-next-line
    }, [duration, hideScrollbars, width]);

    return classNames;
}

export default useGlobalStyles;
