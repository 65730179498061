import { SelectedOrderType } from '../../typings/IPayment';
import * as types from '../actions/ActionTypes';

type OrderTypes = SelectedOrderType;

interface ISelectedMenuTypeAction {
    type: string;
    data?: OrderTypes;
}

function SelectedMenuType(state: OrderTypes = null, action: ISelectedMenuTypeAction) {
    switch (action.type) {
        case types.SetSelectedMenuType:
            state = action.data;
            return state;
        default:
            return state;
    }
}

export default SelectedMenuType;
