import { useState } from 'react';

export function useCartDrawer(onOpenCart: () => void) {
    const threshold = 200; //required min distance traveled to be considered swipe
    const [touchStartLocation, setTouchStartLocation] = useState(null);

    function handleTouchStart(e) {
        const firstTouchEvent = e.touches[0];
        const location = {
            x: firstTouchEvent.clientX,
            y: firstTouchEvent.clientY,
        };
        setTouchStartLocation(location);
    }
    function handleTouchEnd(e) {
        const firstTouchEvent = e.changedTouches[0];
        const location = {
            x: firstTouchEvent.clientX, //get the location of the end of the touch
            y: firstTouchEvent.clientY,
        };
        const differences = {
            x: location.x - touchStartLocation.x, //find the difference from the start to the end touch
            y: location.y - touchStartLocation.y,
        };
        if (differences.y > threshold && location.y > 0) {
            onOpenCart();
        }
    }

    return {
        handleTouchStart,
        handleTouchEnd,
    };
}
