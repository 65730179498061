import { useMemo } from 'react';

export function useIsWebView() {
    const isWebview = useMemo(() => {
        const navigator = window.navigator;
        const userAgent = navigator.userAgent;
        const normalizedUserAgent = userAgent.toLowerCase();
        const standalone = 'standalone' in window.navigator && window.navigator['standalone'];
        const isIos =
            /ip(ad|hone|od)/.test(normalizedUserAgent) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        const isAndroid = /android/.test(normalizedUserAgent);
        const isSafari = /safari/.test(normalizedUserAgent);
        return (
            (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
            (isIos && !standalone && !isSafari) ||
            /webview|wv|ip((?!.*Safari)|(?=.*like Safari))/i.test(userAgent)
        );
    }, []);

    console.log({ isWebview });

    return {
        isWebview,
    };
}
