import * as types from '../actions/ActionTypes';

export type IDeliveryPrice = number | null;

export function DeliveryPrice(state: IDeliveryPrice = null, payload) {
    switch (payload.type) {
        case types.SetDeliveryPrice:
            return payload.data;
        default:
            return state;
    }
}
