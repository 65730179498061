import { ComponentType, useEffect, useState } from 'react';

import { IPageComponentProps } from './types';
import { IMessageOptions } from '../../yoello-lib/modules/API2/types';
import { HTTPMethods } from '../../yoello-lib/modules/API/API';
import APIExtensions from '../../yoello-lib/modules/API2/APIDefs';

import { useAPIV2 } from '../../yoello-lib/modules/API2/useAPIv2';

import PageComponent from './PageComponent';
import { useManageQrQueryParams } from '../../customHooks/useManageQrQueryParams';
import { IGroup } from '../GroupPage/types';

interface IPageContainerProps extends IPageComponentProps {
    ChildComponent: ComponentType<any>;
    LoadingComponent: ComponentType<any>;
    apiConfig: IMessageOptions;
    errorFunction: Function;
}

function GroupPageContainer({
    ChildComponent,
    retrieveToken,
    apiConfig,
    LoadingComponent,
    errorFunction,
    ...props
}: IPageContainerProps) {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const { isError, callAPI } = useAPIV2<IGroup>(APIExtensions.multiVendorGroup, {
        ...apiConfig,
        canAPIGetCacheValue: false,
        doNotCacheValue: true,
        onMountDisabled: true,
    });

    useManageQrQueryParams();

    useEffect(() => {
        setIsPageLoading(true);
        callAPI(HTTPMethods.GET, {
            ...apiConfig,
        }).then(() => {
            setIsPageLoading(false);
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (isError === true && typeof errorFunction === 'function') {
            errorFunction();
        }
        //eslint-disable-next-line
    }, [isError]);

    return isPageLoading ? <LoadingComponent /> : <ChildComponent {...props} />;
}

export default PageComponent(GroupPageContainer as ComponentType);
