import Styles from '../styles.module.scss';
import { ReactComponent as PlaceholderSVG } from '../../../imges/placeholder.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface IVenueTitle {
    logo?: string;
    venueName: string;
}

export function VenueTitle({ venueName, logo }: IVenueTitle) {
    return (
        <div className={Styles.VenueNickName}>
            {logo && <LazyLoadImage src={logo} alt={'venue_logo'} PlaceholderSrc={<PlaceholderSVG />} effect="blur" />}
            <p>{venueName}</p>
        </div>
    );
}
