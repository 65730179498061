import React from 'react';

const GoogleIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dark-theme-uninvert"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.0039 12.2673C24.0039 11.4506 23.9359 10.634 23.7998 9.83398H12.2524V14.4505H18.868C18.5959 15.9338 17.7115 17.2671 16.419 18.1005V21.1004H20.3645C22.6774 19.0171 24.0039 15.9338 24.0039 12.2673Z"
                fill="#4285F4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2511 24.0006C15.5503 24.0006 18.3394 22.9339 20.3632 21.1006L16.4177 18.1007C15.3122 18.834 13.9007 19.2507 12.2511 19.2507C9.05386 19.2507 6.34983 17.1341 5.38046 14.3008H1.31592V17.4007C3.39071 21.4506 7.62532 24.0006 12.2511 24.0006Z"
                fill="#34A853"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.37831 14.3004C4.86811 12.8171 4.86811 11.2004 5.37831 9.70045V6.61719H1.31375C-0.437917 10.0004 -0.437917 14.0004 1.31375 17.3836L5.37831 14.3004Z"
                fill="#FBBC04"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2511 4.75062C14.0027 4.71729 15.6864 5.36728 16.9449 6.55058L20.4482 3.11732C18.2203 1.08404 15.2952 -0.0326073 12.2511 0.000725334C7.62532 0.000725334 3.39071 2.56734 1.31592 6.61725L5.38046 9.71718C6.34983 6.86724 9.05386 4.75062 12.2511 4.75062Z"
                fill="#EA4335"
            />
        </svg>
    );
};

export default GoogleIcon;
