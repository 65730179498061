import { useContext, useState, lazy, Suspense } from 'react';
import { PaymentContext } from './PaymentStore';
import { TextField } from '../Widgets/TextField';
import Styles from './styles.module.scss';
import { useMultiVendorBasket } from '../../customHooks/useMultiVendorBasket';

import useCanPaymentBeProcessed from './PaymentHooks/useCanPaymentBeProcessed';
import useCompleteOrder from './PaymentHooks/useCompleteOrder';

import { setIsPaymentDrawerOpen, setSelectedPaymentMethod } from './reducer/PaymentActions';
import useCanMultiVendorOderBeProcessed from './PaymentHooks/useCanMultiVendorOderBeProcessed';
import useCompleteMultiOrderBasket from './PaymentHooks/useCompleteMultiOrderBasket';
import PaymentButtonSkeleton from '../Widgets/Loading/Payment';

const MultiBasketFooter = lazy(() => import('./PaymentFooter/MultiBasketFooter'));
const SingelBasketFooter = lazy(() => import('./PaymentFooter/SingelBasketFooter'));

const PaymentFooter = () => {
    const [isProcessed, setCanProcessed] = useState<boolean>(false);
    const { isMultiVendorBasket } = useMultiVendorBasket();
    const { isLoading, selectedOrderType, selectedPaymentMethod, dispatch, orderOnly } = useContext(PaymentContext);
    const { canPaymentBeProcessed } = useCanPaymentBeProcessed();
    const { canMultiOrderPaymentBeProcessed } = useCanMultiVendorOderBeProcessed();
    const { completeOrder } = useCompleteOrder();
    const { completeMultiOrder } = useCompleteMultiOrderBasket();

    const handleOnClick = async () => {
        setCanProcessed(true);
        if (selectedPaymentMethod) {
            dispatch(setSelectedPaymentMethod(null));
        }

        let paymentCanBeProcessed;
        if (isMultiVendorBasket) {
            paymentCanBeProcessed = await canMultiOrderPaymentBeProcessed();
        } else {
            paymentCanBeProcessed = await canPaymentBeProcessed();
        }

        if (paymentCanBeProcessed && !isLoading) {
            if (orderOnly) {
                if (isMultiVendorBasket) {
                    completeMultiOrder();
                } else {
                    completeOrder();
                }
            } else {
                dispatch(setIsPaymentDrawerOpen(true));
            }
        }
        setCanProcessed(false);
    };

    return (
        <div className={Styles.BottomBar}>
            <Suspense fallback={<PaymentButtonSkeleton />}>
                {selectedOrderType === null && (
                    <div className={Styles.NotSelected}>
                        <TextField
                            className={Styles.OrderTypeNotSelectedText}
                            text={{ key: `Payment.OrderTypeNotSelected` }}
                        />
                    </div>
                )}
                {isMultiVendorBasket ? (
                    <MultiBasketFooter handleOnClick={handleOnClick} isProcessed={isProcessed} />
                ) : (
                    <SingelBasketFooter handleOnClick={handleOnClick} isProcessed={isProcessed} />
                )}
            </Suspense>
        </div>
    );
};

export default PaymentFooter;
