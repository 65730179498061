import { timeConverterAsString } from '../../../utils/timeConverter';
import IVenue from '../../../yoello-lib/modules/API/interfaces/IVenue';
import {
    setDietaryTags,
    setExtras,
    setMenuItemsAsExtra,
    setTaxItems,
    setAvailableMenus,
    addNotification,
    setVenue,
} from '../../../yoello-lib/modules/redux/actions/consumer.actions';
import { SelectedOrderType } from '../../Payment/types.d';

import { ReactComponent as CollectionBagSVG } from '../../../imges/CollectionBag.svg';
import { ReactComponent as BicycleSVG } from '../../../imges/Bicycle.svg';
import { ReactComponent as FoodPlateSVG } from '../../../imges/FoodPlate.svg';
import { IGroup, IGroupVenue } from '../types';
import { setCurrencies, setCurrency } from '../../../yoello-lib/modules/redux/actions/localization';
import { _SetDefaultTip } from '../../../redux/actions';

export function resetStore(dispatcher: any) {
    dispatcher(setExtras(null));
    dispatcher(setDietaryTags(null));
    dispatcher(setMenuItemsAsExtra(null));
    dispatcher(setTaxItems(null));
    dispatcher(setAvailableMenus([]));
    dispatcher(_SetDefaultTip(null));
}

export function isDisplayed(orderType, venue) {
    if (!orderType) {
        return true;
    }
    if (orderType === SelectedOrderType.TABLE) {
        if (venue.table_enabled) {
            return true;
        }
    }
    if (orderType === SelectedOrderType.DELIVERY) {
        if (venue.delivery_enabled) {
            return true;
        }
    }
    if (orderType === SelectedOrderType.CLICK) {
        if (venue.pickup_enabled) {
            return true;
        }
    }
    if (orderType === SelectedOrderType.COLLECTION_POINT) {
        if (venue.pickup_now_enabled) {
            return true;
        }
    }

    return false;
}

export function getOrderTypeInfo(selectedOrderType: string, venue?: IVenue , formatCurrency?:(value:number) => string) {
    switch (selectedOrderType) {
        case SelectedOrderType.TABLE:
            return {
                title: 'Payment.TableService',
                PrepTime: venue?.food_prep_time > 0 ? timeConverterAsString(venue?.food_prep_time) : null,
                MinOrderValue: venue?.min_dine_in_price,
                DeliveryFee: null,
                Icon: FoodPlateSVG,
            };
        case SelectedOrderType.CLICK:
            return {
                title: 'Payment.Collection',
                PrepTime: venue?.food_prep_time > 0 ? timeConverterAsString(venue?.food_prep_time) : null,
                MinOrderValue: venue?.min_collection_price,
                DeliveryFee: null,
                Icon: CollectionBagSVG,
            };
        case SelectedOrderType.COLLECTION_POINT:
            return {
                title: 'Payment.pickupNow',
                PrepTime: venue?.food_prep_time > 0 ? timeConverterAsString(venue?.food_prep_time) : null,
                MinOrderValue: venue?.min_pickup_now_price,
                DeliveryFee: null,
                Icon: CollectionBagSVG,
            };
        case SelectedOrderType.DELIVERY:
            return {
                title: 'Payment.Delivery',
                PrepTime:
                    venue?.delivery_max_lead_time > 0 ? timeConverterAsString(venue?.delivery_max_lead_time) : null,
                MinOrderValue: venue?.min_delivery_price,
                DeliveryFee: venue?.delivery_fees ? calculateRange(venue, formatCurrency) : venue?.delivery_charge,
                Icon: BicycleSVG,
            };

        default:
            return {
                title: 'Payment.ChoosePaymentMethod',
                PrepTime: null,
                MinOrderValue: null,
                DeliveryFee: null,
                Icon: null,
            };
    }
}

export function setUpGroupVenue(group: IGroup, venue: IGroupVenue, dispatch: any) {
    const currencyObj = {
        id: group.currency_id,
        iso_code: group.currency_iso,
        english_name: group.currency_name,
        symbol: group.currency_symbol,
    };
    if (
        (venue?.alc_drink_limit !== undefined || venue?.alc_drink_limit !== null) &&
        typeof venue?.alc_drink_limit === 'number'
    ) {
        dispatch(
            addNotification({
                label: 'Restrictions.alcoholRestriction',
                status: 'warning',
                labelObj: {
                    count: venue?.alc_drink_limit,
                },
                pathname: `/g/${group.nickname}/${venue.venue_nickname}/menu`,
                notificationLength: 10000,
            }),
        );
    }
    dispatch(setCurrencies([currencyObj]));
    dispatch(setCurrency(currencyObj.id));
    dispatch(setVenue(venue));
}

export const ordertypes = {
    [SelectedOrderType.TABLE]: {
        title: 'Venue.TableService',
        value: SelectedOrderType.TABLE,
        Icon: FoodPlateSVG,
        key: SelectedOrderType.TABLE,
    },
    [SelectedOrderType.DELIVERY]: {
        title: 'Venue.Delivery',
        value: SelectedOrderType.DELIVERY,
        Icon: BicycleSVG,
        key: SelectedOrderType.DELIVERY,
    },
    [SelectedOrderType.CLICK]: {
        title: 'Venue.Collection',
        value: SelectedOrderType.CLICK,
        Icon: CollectionBagSVG,
        key: SelectedOrderType.CLICK,
    },
    [SelectedOrderType.COLLECTION_POINT]: {
        title: 'Venue.pickupNow',
        value: SelectedOrderType.COLLECTION_POINT,
        Icon: CollectionBagSVG,
        key: SelectedOrderType.COLLECTION_POINT,
    },
};

export function calculateRange(venue?: IVenue, formatCurrency?: (value: number) => string) {
    if (venue?.delivery_fees?.min_fee === venue?.delivery_fees?.max_fee) {
        return venue?.delivery_fees?.max_fee;
    }
    return `${formatCurrency?.(venue?.delivery_fees?.min_fee)} - ${formatCurrency?.(venue?.delivery_fees?.max_fee)}`;
}
