import * as types from '../actions/ActionTypes';

const Venue = (state = {}, action) => {
    switch (action.type) {
        case types.SetVenueInfo:
            const venueData = action.data;
            state = {
                ...venueData,
            };
            return state;

        default:
            return state;
    }
};
export default Venue;
