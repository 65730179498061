import { useMemo } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

interface ILoginViewTitle {
    isCheckingOut: boolean;
    isEmailLogin: boolean;
}

export const useStyles = makeStyles({
    title: {
        fontSize: '1.375rem',
        fontWeight: 600,
        marginBottom: '2.5rem',
        '@media (min-width: 768px)': {
            marginBottom: '1rem',
        },
    },
    infoText: {
        fontSize: '0.938rem',
        color: '#737FA4',
        marginBottom: '1.313rem',
        '@media (min-width: 768px)': {
            marginTop: '0',
        },
    },
});

export function LoginViewTitle({ isCheckingOut, isEmailLogin }: ILoginViewTitle) {
    const classes = useStyles();
    const { t } = useTranslation();
    const title = useMemo(() => {
        return (
            <>
                {isEmailLogin ? (
                    <h2 className={classes.title}>{t('Login.Texts.login')}</h2>
                ) : (
                    <>
                        <h2 className={classes.title}>
                            {!isCheckingOut ? t('Login.Texts.LoginOrSignUp') : t('Login.Texts.LetsCompleteYourOrder')}
                        </h2>

                        {isCheckingOut && <h6 className={classes.infoText}>{t('Login.Texts.SignInOrSignUpInfo')}</h6>}
                    </>
                )}
            </>
        );
        // eslint-disable-next-line
    }, [isCheckingOut, isEmailLogin]);
    return <>{title}</>;
}
