import { v4 as uuidv4 } from 'uuid';
import { resetCart, updateCartID } from '../actions/ActionTypes';

function CartID(state: string = uuidv4(), action: any) {
    switch (action.type) {
        case resetCart:
            state = uuidv4();
            return state;
        case updateCartID:
            state = uuidv4();
            return state;
        default:
            return state;
    }
}

export default CartID;
