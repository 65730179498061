import useColors from '../../../customHooks/useColors';
import Styles from '../shopList.module.scss';

import { ReactComponent as CartSVG } from '../../../imges/cart_new.svg';
import Button from '../../Widgets/Button';
import { useTranslation } from 'react-i18next';

interface ICartHeader {
    onOpenCart: () => void;
}
export function CartHeader({ onOpenCart }: ICartHeader) {
    const { primaryIconColor } = useColors();
    const { t } = useTranslation();

    return (
        <div className={Styles.header}>
            <div>
                <div className={Styles.lineBar} onClick={onOpenCart} />
            </div>

            <div>
                <div>
                    <CartSVG fill={primaryIconColor} />
                    <p>{t('ShopCart.MyCart')}</p>
                </div>
                <Button
                    iconClassName="consumer-close"
                    isHeaderBtn
                    className={Styles.closeDrawer}
                    onClick={() => onOpenCart()}
                    dataComponentName={'closeDrawer'}
                />
            </div>
        </div>
    );
}
