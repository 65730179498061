import * as types from '../actions/ActionTypes';

export type ITotalPrice = number | null;

export function TotalPriceReducer(state: ITotalPrice = null, payload) {
    switch (payload.type) {
        case types.SetTotalPrice:
            return payload.data;
        default:
            return state;
    }
}
