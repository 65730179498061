import { ComponentType, useEffect, Suspense, lazy } from 'react';

import Styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IPageComponentProps } from '../Page/types.d';

import { useVenueOpen } from '../../customHooks/useVenueOpen';
import usePaymentView from './PaymentHooks/usePaymentView';
import usePaymentDetails from './PaymentHooks/usePaymentDetails';
import PageComponent from '../Page/PageComponent';
import { _SetIsOpenSidebar } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import useGuestUserData from '../../customHooks/useGuestUserData';
import { LOGINV2_PROFILE } from '../../constants/URL.const';
import { useHistory } from 'react-router-dom';
import { useMultiVendorBasket } from '../../customHooks/useMultiVendorBasket';
import SingelVendorBasketContainer from './SingelVendorBasketContainer';
import MultiVendorBasketContainer from './MultiVendorBasketContainer';
import { PaymentNavigation } from '../Widgets/Loading/Payment';

const NavigationHeader = lazy(() => import('../NavigationHeader/NavigationHeader'));

interface IPaymentContainerProps extends IPageComponentProps {}

/**
 * Payment container handles all the different state changes of creating an order
 * @param param0
 */
function PaymentContainer({ isAuthenticated, openSideDrawer, hasNoConsumer }: IPaymentContainerProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const { backFunction } = usePaymentView();
    const { getPaymentDetails } = usePaymentDetails();
    const { isGuest } = useGuestUserData();
    const isVenueOpen = useVenueOpen(false);
    const Venue = useSelector((state: any) => state.Venue);
    const dispatcher = useDispatch<any>();
    const isOpenDrawer = useSelector((state: any) => state.openSidebar);
    const { isMultiVendorBasket } = useMultiVendorBasket();

    useEffect(() => {
        if (isOpenDrawer && Venue) {
            openSideDrawer();
            dispatcher(_SetIsOpenSidebar(false));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isAuthenticated && !isGuest) {
            getPaymentDetails();
        }
        //eslint-disable-next-line
    }, [isAuthenticated]);

    useEffect(() => {
        if (hasNoConsumer && !isGuest) {
            history.push(LOGINV2_PROFILE);
        }
        //eslint-disable-next-line
    }, [hasNoConsumer, isGuest]);

    return (
        <>
            <Suspense fallback={<PaymentNavigation />}>
                <NavigationHeader
                    openSideDrawer={openSideDrawer}
                    title={t('Payment.Checkout') as string}
                    displayVenueImage={false}
                    backURL={backFunction}
                    className={Styles.paymentNavigationHeader}
                />
            </Suspense>
            {isMultiVendorBasket ? (
                <MultiVendorBasketContainer Venue={Venue} isVenueOpen={isVenueOpen} />
            ) : (
                <SingelVendorBasketContainer Venue={Venue} isVenueOpen={isVenueOpen} />
            )}
        </>
    );
}
export default PageComponent(PaymentContainer as ComponentType);
