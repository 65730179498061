import { getServiceEndpoints } from '../../environments/services';
import { IAPIDefinitions } from '../APIDefs';
import { Dispatch } from 'react';
import {
    addNotification,
    resetCart,
    setDietaryTags,
    setExtras,
    setGroupInfo,
    setGroupName,
    setMenuItems,
    setMenuItemsAsExtra,
    setPreferences,
    setSelectedMenuType,
    setTaxItems,
    setVenue,
    setVenueOpenNow,
    setAvailableMenus,
    addSpecificMenuId,
    RepeatCart,
} from '../../redux/actions/consumer.actions';
import { setCurrencies, setCurrency, setLanguage } from '../../redux/actions/localization';
import { generateTradingTimesObject, ITradingTimes } from '../../utils/tradingTimesObject';
import { checkVenueOpen } from '../../utils/venueOpen';

import { groupVenueType } from '../../interfaces/GroupInfo.type';
import { generateServiceURL } from '../../API2/utils';

export enum APIConsumer {
    consumerVenue = 'consumerVenue',
    consumerGroup = 'consumerGroup',
    consumerMenu = 'consumerMenu',
    consumerOrders = 'consumerOrders',
    consumerLatestOrder = 'consumerLatestOrder',
    consumerPay360Cards = 'consumerPay360Cards',
    consumerVenueTimeSlots = 'consumerVenueTimeSlots',
    consumerAvailableMenus = 'consumerAvailableMenus',
    consumers = 'consumers',
    orderLocationGroups = 'orderLocationGroups',
    menus = 'menus',
    availableMenus = 'availableMenus',
}

console.log('test');


export const VenueLanguages = {
    EN: 'EN',
    'EN-CA': 'CA',
    'EN-US': 'US',
    FR: 'FR',
    ES: 'ES',
    'ES-MX': 'MX',
};

const stage = process.env.REACT_APP_STAGE;
const overwriteHost = process.env.REACT_APP_SERVICE_ENDPOINT;
function getFullURL() {
    if (!stage) {
        return;
    }
    let services = getServiceEndpoints(stage, overwriteHost);
    return services['consumerAPI'];
}
const url = getFullURL();
export const consumerAPIDefs: IAPIDefinitions = {};

consumerAPIDefs[APIConsumer.consumerVenue] = {
    baseURL: url,
    defaultURL: `/venues/`,
    dataStorage: {
        defaultDataKey: 'Venue',
        selectorKey: () => {
            return 'Venue';
        },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args) => {
            if (Array.isArray(args) && !args[0]) {
                const lang = result.language_iso.toUpperCase() as keyof typeof VenueLanguages;
                const language = VenueLanguages[lang];
                dispatch(resetCart());
                dispatch(setLanguage(language));
                dispatch(addSpecificMenuId(null));
                dispatch(RepeatCart(null));
            }
            const currencyObj = {
                id: result.currency_id,
                iso_code: result.currency_iso,
                english_name: result.currency_name,
                symbol: result.currency_symbol,
            };
            if (
                (result?.alc_drink_limit !== undefined || result?.alc_drink_limit !== null) &&
                typeof result?.alc_drink_limit === 'number'
            ) {
                dispatch(
                    addNotification({
                        label: 'Restrictions.alcoholRestriction',
                        status: 'warning',
                        labelObj: {
                            count: result?.alc_drink_limit,
                        },
                        pathname: `/v/${result.venue_nickname}/menu`,
                        notificationLength: 10000,
                    }),
                );
            }
            const trading_times = generateTradingTimesObject(result.trading_times);

            const venue = {
                ...result,
                trading_times,
            };
            /**dispatch venue check notifications */
            const { message, status, languageProps } = checkVenueOpen(venue, undefined);
            if (message) {
                dispatch(
                    addNotification({
                        label: message,
                        status: status ?? 'error',
                        pathname: `/v/${venue.venue_nickname}`,
                        labelObj: languageProps,
                    }),
                );
            }

            dispatch(setCurrencies([currencyObj]));
            dispatch(setCurrency(currencyObj.id));
            dispatch(setVenue(venue));
        },
    },
    GET: {},
};

consumerAPIDefs[APIConsumer.consumerGroup] = {
    baseURL: url,
    defaultURL: `/venue-groups/`,
    dataStorage: {
        defaultDataKey: 'Group',
        selectorKey: () => {
            return 'Group';
        },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args) => {
            //@ts-ignore
            const lang = args ? args[0] : (result.group_language_iso.toUpperCase() as keyof typeof VenueLanguages);
            //@ts-ignore
            const language = VenueLanguages[lang];
            dispatch(setLanguage(language));
            dispatch(setGroupName(result.group_name_public));
            const venues = result.group_venues;
            const group_venues = venues
                .filter((groupV: groupVenueType) => groupV.venue_nickname !== null)
                .map((groupV: groupVenueType) => {
                    return {
                        ...groupV,
                        trading_times: generateTradingTimesObject(groupV.venue_trading_times as ITradingTimes[]),
                    };
                });
            const groupInfo = {
                ...result,
                group_venues,
            };
            dispatch(setGroupInfo(groupInfo));
            dispatch(setSelectedMenuType(null));
        },
    },
    GET: {},
};

consumerAPIDefs[APIConsumer.consumerMenu] = {
    baseURL: url,
    defaultURL: `/menus-api/v1/menus/venue/`,
    dataStorage: {
        defaultDataKey: 'MenuItem',
        selectorKey: () => {
            return 'MenuItem';
        },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args): void => {
            dispatch(
                setMenuItems({
                    menuItems: result.menu_items,
                    categories: result.subcategories,
                    menusList: result?.venue_details?.on_menu_setup ? false : null,
                }),
            );
            dispatch(setPreferences(result.menu_item_preferences));
            dispatch(setExtras(result.menu_item_extra_groups));
            dispatch(setMenuItemsAsExtra(result.menu_items_as_extras));
            dispatch(setDietaryTags(result.dietary_tags));
            if (result.taxes) {
                dispatch(setTaxItems(result.taxes));
            }
            if (Array.isArray(args) && args[0] && args[1]) {
                const venue: any = args[1];
                const group: any = args[2];
                const { message, status, languageProps } = checkVenueOpen(venue, args[0]);
                if (message) {
                    dispatch(
                        addNotification({
                            label: message,
                            status: status ?? 'error',
                            pathname: group
                                ? `/g/${group.nickname}/${venue.venue_nickname}/menu`
                                : `/v/${venue.venue_nickname}/menu`,
                            labelObj: languageProps,
                        }),
                    );
                }
            }
        },
    },
    GET: {},
};

consumerAPIDefs[APIConsumer.consumerAvailableMenus] = {
    baseURL: url,
    defaultURL: `/menus/`,
    dataStorage: {
        defaultDataKey: 'AvailableMenus',
        selectorKey: () => {
            return 'AvailableMenus';
        },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args): void => {
            console.log(result, 'resultresult');
            dispatch(
                setMenuItems({
                    menuItems: null,
                    categories: null,
                    menusList: result,
                }),
            );
            dispatch(setAvailableMenus(result));
        },
    },
    GET: {},
};

consumerAPIDefs[APIConsumer.consumerOrders] = {
    baseURL: url,
    defaultURL: '/v2/orders',
    GET: {},
};

consumerAPIDefs[APIConsumer.consumerVenueTimeSlots] = {
    baseURL: url + '/v2/venues/',
    defaultURL: 'time_slots',
    dataStorage: {
        defaultDataKey: 'VenueOpenNow',
        selectorKey: () => {
            return 'VenueOpenNow';
        },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args): void => {
            if (result) {
                dispatch(setVenueOpenNow(result.open_now));
            }
        },
    },
    GET: {},
};

consumerAPIDefs[APIConsumer.consumerLatestOrder] = {
    baseURL: url,
    defaultURL: '/v2/orders-latest',
    GET: {},
};
consumerAPIDefs[APIConsumer.consumers] = {
    baseURL: generateServiceURL('consumers'),
    defaultURL: 'v2/consumers',
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {},
    DELETE: {},
};
consumerAPIDefs[APIConsumer.menus] = {
    baseURL: generateServiceURL('menus'),
    defaultURL: 'v2/menus',
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {},
    DELETE: {},
};
consumerAPIDefs[APIConsumer.orderLocationGroups] = {
    baseURL: generateServiceURL('order_locations'),
    defaultURL: 'v2/order_location_groups',
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {},
    DELETE: {},
};

consumerAPIDefs[APIConsumer.availableMenus] = {
    baseURL: url,
    defaultURL: `/menus/`,
    GET: {},
};

//Pay 360

export const generatePay360Url = () => {
    const stage = process.env.REACT_APP_STAGE;
    const prefix = stage === 'prod' ? '' : `${stage}-`;
    return `https://${prefix}pay360.yllo.uk/api/v2`;
};

const pay360Url = generatePay360Url();

consumerAPIDefs[APIConsumer.consumerPay360Cards] = {
    baseURL: pay360Url,
    defaultURL: '/cards',
    GET: {},
};
